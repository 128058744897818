import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { DropdownProps, Grid, Label, Table } from 'semantic-ui-react'
import { history } from "../../..";
import agent from "../../../app/api/agent";
import { _DrawTypeOptionsWithAll, _NoOfDrawOptions, _PrizeTypeOptions } from "../../../app/lib/CommonData";
import { _CanDisplay, _DisplayHeader, _DisplayPercentage, _DisplayZero, _GetSavedDrawNo, _GetSavedDrawType4D, _GetSavedPrizeType, _GetSavedSum3DType, _SetSavedDrawNo, _SetSavedDrawType4D,  _SetSavedPrizeType, _SetSavedSum3DType } from "../../../app/lib/CommonFunctions";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import RedirectTo4D from "../../../app/lib/RedirectTo4D";
import {  PatternResult } from "../../../app/models/IDayResult";
import { useStore } from "../../../app/store/store";


export default observer(function Sum3DHistory(){
    const [data, setData] = useState<PatternResult[]>([]);
    const [drawType, setDrawType] = useState(_GetSavedDrawType4D("A"));
    const [prizeType, setPrizeType] = useState(_GetSavedPrizeType("T"));
    
    const [noOfDraw, setNoOfDraw] = useState(_GetSavedDrawNo("interval",36));
    const [intervalType, setIntervalType] = useState(_GetSavedSum3DType('S'));

    const [loading, setLoading] = useState(true);
    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
    }
  

    const intervalTypeOptions = [
        {
            key: 'S',
            text: 'All Prizes',
            value: 'S'
        },
        {
            key: 'T',
            text: 'Top3',
            value: 'T'
        }
    ]

    
    const noOfDrawChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setNoOfDraw(parseInt(option.value+""));
        _SetSavedDrawNo("interval", parseInt(option.value+""));
        setLoading(true);
    }

    const prizeTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setPrizeType(option.value + "");
         _SetSavedPrizeType(option.value + "");
         setLoading(true);
    }

    const handleDropDownInterval = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setIntervalType(option.value + "");
        _SetSavedSum3DType(option.value + "");
        setLoading(true);
   }

   const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setDrawType(option.value + "");
        _SetSavedDrawType4D(option.value + "");
        setLoading(true);
    }

    useEffect(()=>{
        
        agent.Draw4DResult.getSum3DHistory(prizeType, noOfDraw, drawType, intervalType).then((response)=>{
            setData(response);
        }).finally(()=>{
            setLoading(false);
        });

    },[drawType, noOfDraw, intervalType, prizeType]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }

    const allColumns = (data === null || data.length === 0) ? [] : data[0].columnNames.split('|');

    return (
      <div style={{overflowX:"auto"}}>
                  <Grid>
                    <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={4} computer={4}>
                        <MyDropDown onChange={prizeTypeChanged} placeholder="Select Prize Type" 
                        toolTip="Select Prize Type" zIndex="21"
                        options={_PrizeTypeOptions} defaultValue = {prizeType}>
                        </MyDropDown>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" mobile={8} tablet={4} computer={4}>
                        <MyDropDown onChange={noOfDrawChanged} placeholder="Select No of Draw" 
                        toolTip="Select No Of Draw" zIndex="21"
                        options={_NoOfDrawOptions} defaultValue = {noOfDraw}>
                        </MyDropDown>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={4} computer={4}>
                    <MyDropDown onChange={handleDropDownInterval} placeholder="Select Interval Type" 
                      toolTip="Select Interval Type" zIndex="20"
                      options={intervalTypeOptions} defaultValue = {intervalType}>
                    </MyDropDown>
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle" mobile={8} textAlign="center" tablet={4} computer={4}>
                      <MyDropDown onChange={drawTypeChanged} placeholder="Select Draw Type" 
                      toolTip="Select Draw Type" zIndex="20"
                      options={_DrawTypeOptionsWithAll} defaultValue = {drawType}>
                      </MyDropDown>
                  </Grid.Column>
                  </Grid>
          
                  <Table celled  sortable unstackable className="freeze-head-and-col">
                  <Table.Header>
                  <Table.Row>
                  <Table.HeaderCell colSpan="18">
                      4D Sum 3D
                  </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                  <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
                  {_CanDisplay(allColumns,1) &&
                  <Table.HeaderCell textAlign="center">
                      <RedirectTo4D range={_DisplayHeader(allColumns,1)} code={"C" + intervalType} drawType={drawType}></RedirectTo4D>
                  </Table.HeaderCell>}
                  {_CanDisplay(allColumns,2) &&
                  <Table.HeaderCell textAlign="center">
                      <RedirectTo4D range={_DisplayHeader(allColumns,2)} code={"C" + intervalType} drawType={drawType}></RedirectTo4D>
                  </Table.HeaderCell>}
                  {_CanDisplay(allColumns,3) &&
                  <Table.HeaderCell textAlign="center">
                      <RedirectTo4D range={_DisplayHeader(allColumns,3)} code={"C" + intervalType} drawType={drawType}></RedirectTo4D>
                  </Table.HeaderCell>
                }
                  {_CanDisplay(allColumns,4) &&
                  <Table.HeaderCell textAlign="center">
                      <RedirectTo4D range={_DisplayHeader(allColumns,4)} code={"C" + intervalType} drawType={drawType}></RedirectTo4D>
                  </Table.HeaderCell>}

                  {_CanDisplay(allColumns,5) &&
                  <Table.HeaderCell textAlign="center">
                      <RedirectTo4D range={_DisplayHeader(allColumns,5)} code={"C" + intervalType} drawType={drawType}></RedirectTo4D>
                  </Table.HeaderCell>}

                  {_CanDisplay(allColumns,6) &&
                  <Table.HeaderCell textAlign="center">
                      <RedirectTo4D range={_DisplayHeader(allColumns,6)} code={"C" + intervalType} drawType={drawType}></RedirectTo4D>
                  </Table.HeaderCell>}
                  {_CanDisplay(allColumns,7) &&
                  <Table.HeaderCell textAlign="center">
                      <RedirectTo4D range={_DisplayHeader(allColumns,7)} code={"C" + intervalType} drawType={drawType}></RedirectTo4D>
                  </Table.HeaderCell>}
                  {_CanDisplay(allColumns,8) &&
                  <Table.HeaderCell textAlign="center">
                      <RedirectTo4D range={_DisplayHeader(allColumns,8)} code={"C" + intervalType} drawType={drawType}></RedirectTo4D>
                  </Table.HeaderCell>}
                  {_CanDisplay(allColumns,9) &&
                  <Table.HeaderCell textAlign="center">
                      <RedirectTo4D range={_DisplayHeader(allColumns,9)} code={"C" + intervalType} drawType={drawType}></RedirectTo4D>
                  </Table.HeaderCell>}
                  {_CanDisplay(allColumns,10) &&
                  <Table.HeaderCell textAlign="center">
                      <RedirectTo4D range={_DisplayHeader(allColumns,10)} code={"C" + intervalType} drawType={drawType}></RedirectTo4D>
                  </Table.HeaderCell>}
                  </Table.Row>
                  </Table.Header>
                  <Table.Body>
                      {data.map((x,i) => {
                      
                      if (i === 0){
                          var total = x.col1 + x.col2  + x.col3  + x.col4 + x.col5 +
                                      x.col6 + x.col7  + x.col8  + x.col9 + x.col10;

                          if (total === 0){
                              return (
                                  <Table.Row key={i}>
                                      <Table.Cell textAlign="center">
                                          Summary
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                          No Result
                                      </Table.Cell>
                                  </Table.Row>
                              )
                          }
                          return (
                              <Table.Row key={i}>
                                  <Table.Cell  textAlign="center">
                                  Hit/% Summary
                                  </Table.Cell>
                                  {_CanDisplay(allColumns,1) &&
                                    <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col1 }</Label>
                                      <Label >{_DisplayPercentage(x.col1, total, 0)}</Label>                    
                                  </Table.Cell>}
                                  {_CanDisplay(allColumns,2) &&
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col2 }</Label>
                                      <Label >{_DisplayPercentage(x.col2, total, 0)}</Label>                    
                                  </Table.Cell>}
                                  {_CanDisplay(allColumns,3) &&
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col3}</Label>
                                      <Label >{_DisplayPercentage(x.col3, total, 0)}</Label>                    
                                  </Table.Cell>}
                                  {_CanDisplay(allColumns,4) &&
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col4}</Label>
                                      <Label >{_DisplayPercentage(x.col4, total, 0)}</Label>                    
                                  </Table.Cell>}
                                  {_CanDisplay(allColumns,5) &&
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col5}</Label>
                                      <Label >{_DisplayPercentage(x.col5, total, 0)}</Label>                    
                                  </Table.Cell>}
                                  {_CanDisplay(allColumns,6) &&
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col6}</Label>
                                      <Label >{_DisplayPercentage(x.col6, total, 0)}</Label>                    
                                  </Table.Cell>}
                                  {_CanDisplay(allColumns,7) &&
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col7}</Label>
                                      <Label >{_DisplayPercentage(x.col7, total, 0)}</Label>                    
                                  </Table.Cell>}
                                  {_CanDisplay(allColumns,8) &&
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col8}</Label>
                                      <Label >{_DisplayPercentage(x.col8, total, 0)}</Label>                    
                                  </Table.Cell>
                                  }
                                  {_CanDisplay(allColumns,9) &&
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col9}</Label>
                                      <Label >{_DisplayPercentage(x.col9, total, 0)}</Label>                    
                                  </Table.Cell>
                                  }
                                  {_CanDisplay(allColumns,10) &&
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col10 }</Label>
                                      <Label >{_DisplayPercentage(x.col10, total, 0)}</Label>                    
                                  </Table.Cell>
                                  }
                              </Table.Row>
                          )
                      }
                      
                      
                      return (
                      <Table.Row key={i}>
                          <Table.Cell  textAlign="center">
                              {x.draw_Date}
                          </Table.Cell>
                          {_CanDisplay(allColumns,1) &&
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col1)}
                          </Table.Cell>}
                          {_CanDisplay(allColumns,2) &&
                          <Table.Cell  textAlign="center">
                              {  _DisplayZero(x.col2)}
                          </Table.Cell>}
                          {_CanDisplay(allColumns,3) &&
                          <Table.Cell  textAlign="center">
                              {  _DisplayZero(x.col3)}
                          </Table.Cell>}
                          {_CanDisplay(allColumns,4) &&
                          <Table.Cell  textAlign="center">
                              {  _DisplayZero(x.col4)}
                          </Table.Cell>}
                          {_CanDisplay(allColumns,5) &&
                          <Table.Cell  textAlign="center">
                              {  _DisplayZero(x.col5)}
                          </Table.Cell>}
                          {_CanDisplay(allColumns,6) &&
                          <Table.Cell  textAlign="center">
                              {  _DisplayZero(x.col6)}
                          </Table.Cell>}
                          {_CanDisplay(allColumns,7) &&
                          <Table.Cell  textAlign="center">
                              {  _DisplayZero(x.col7)}
                          </Table.Cell>}
                          {_CanDisplay(allColumns,8) &&
                          <Table.Cell  textAlign="center">
                              {  _DisplayZero(x.col8)}
                          </Table.Cell>}
                          {_CanDisplay(allColumns,9) &&
                          <Table.Cell  textAlign="center">
                              {  _DisplayZero(x.col9)}
                          </Table.Cell>}
                          {_CanDisplay(allColumns,10) &&
                          <Table.Cell  textAlign="center">
                              {  _DisplayZero(x.col10)}
                          </Table.Cell>}
                      </Table.Row>
                      );
                    }  
                    )} 
                    </Table.Body>
                </Table>                 
   </div>
    );
});