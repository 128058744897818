import { observer } from "mobx-react-lite"
import React from "react"
import { Image, Popup, Table } from "semantic-ui-react"
import { _GetDirectHit, _GetImage, _GetRating, _GetRatingContent } from "../../app/lib/CommonFunctions"
import NumberStatus from "../../app/lib/NumberStatus"
import NumberStatusShort from "../../app/lib/NumberStatusShort"
import { DrawDetailResult } from "../../app/models/IDayResult"


interface Props{
    drawResult: DrawDetailResult[];
}

export default observer(function DetailResult1({drawResult} : Props){

return (
    <Table celled  sortable unstackable className="freeze-head-and-col">
    <Table.Header>
        <Table.Row>
        <Table.HeaderCell textAlign="center">
            Prize Type
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Prize Number
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Is Direct?
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Rating
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Awaken
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            System Interval (All)
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Top3 Interval (System)
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Direct Interval (All)
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Number Status (All)
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Number Status (Top3)
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            <Popup content="System 3D at position 1 (lowest order)" trigger={<span>System 3D (1)</span>}></Popup>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            <Popup content="System 3D at position 2" trigger={<span>System 3D (2)</span>}></Popup>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            <Popup content="System 3D at position 3" trigger={<span>System 3D (3)</span>}></Popup>
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            <Popup content="System 3D at position 4" trigger={<span>System 3D (4)</span>}></Popup>
        </Table.HeaderCell>


        <Table.HeaderCell textAlign="center">
            All Rank
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Top3 Rank
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            All Rank Monthly
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Top3 Rank Monthly
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
            Sum 3D
        </Table.HeaderCell>
        </Table.Row>
    </Table.Header>


    {   drawResult && drawResult.length > 0 &&
            drawResult.map((x,i)=>{
                return (
                    <Table.Row key={i}>
                        <Table.Cell textAlign="center">
                            <Image centered  src={_GetImage(x.prize_Code)} size="mini"></Image>
                        </Table.Cell>

                        <Table.Cell textAlign="center">{x.prize_Number}</Table.Cell>
                        <Table.Cell textAlign="center">
                            <Popup content={x.is_Direct_Hit === "Yes" ? "Direct Hit" : "No Direct Hit"} 
                                trigger={<Image centered  src={_GetDirectHit(x.is_Direct_Hit)} size="mini"></Image>}>
                            </Popup>

                        </Table.Cell>
                    
                    
                        <Table.Cell textAlign="center">
                        <Popup content={_GetRatingContent(x.rating)}
                            trigger ={<Image avatar src={_GetRating(x.rating)} />}>
                        </Popup>
                        </Table.Cell>

                        <Table.Cell textAlign="center">
                            {x.awaken}
                        </Table.Cell>


                        <Table.Cell textAlign="center">{x.system_Interval}</Table.Cell>
                        <Table.Cell textAlign="center">{x.top3_Interval}</Table.Cell>
                        <Table.Cell textAlign="center">{x.interval_Direct}</Table.Cell>

                        <Table.Cell textAlign="center">                      
                        <NumberStatus status = {x.systemInRange} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">                      
                        <NumberStatus status = {x.top3InRange} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">{x.system3D_1.split('|').length === 1 ? "":
                                                        <>
                                                        {x.system3D_1.split('|')[0]}<br/>
                                                        <NumberStatusShort status={x.system3D_1.split('|')[1]} />
                                                        <NumberStatusShort status={x.system3D_1.split('|')[2]} />
                                                        </>
                                                        }</Table.Cell>
                        <Table.Cell textAlign="center">{x.system3D_2.split('|').length === 1 ? "":
                                                        <>
                                                        {x.system3D_2.split('|')[0]}<br/>
                                                        <NumberStatusShort status={x.system3D_2.split('|')[1]} />
                                                        <NumberStatusShort status={x.system3D_2.split('|')[2]} />
                                                        </>
                                                        }</Table.Cell>
                        <Table.Cell textAlign="center">{x.system3D_3.split('|').length === 1 ? "":
                                                        <>
                                                        {x.system3D_3.split('|')[0]}<br/>
                                                        <NumberStatusShort status={x.system3D_3.split('|')[1]} />
                                                        <NumberStatusShort status={x.system3D_3.split('|')[2]} />
                                                        </>
                                                        }</Table.Cell>
                        <Table.Cell textAlign="center">{x.system3D_4.split('|').length === 1 ? "":
                                                        <>
                                                        {x.system3D_4.split('|')[0]}<br/>
                                                        <NumberStatusShort status={x.system3D_4.split('|')[1]} />
                                                        <NumberStatusShort status={x.system3D_4.split('|')[2]} />
                                                        </>
                                                        }</Table.Cell>


                        <Table.Cell textAlign="center">{x.rank_All}</Table.Cell>
                        <Table.Cell textAlign="center">{x.rank_Top3}</Table.Cell>

                        <Table.Cell textAlign="center">{x.month_Rank_All}</Table.Cell>
                        <Table.Cell textAlign="center">{x.month_Rank_Top3}</Table.Cell>
                        <Table.Cell textAlign="center">{x.sum3DTop3}</Table.Cell>
                    </Table.Row>
                )
            })
    }
    </Table>
    )
})