import { observer } from "mobx-react-lite"
import React from "react"
import {  Popup, Statistic, Image, Segment, Header } from "semantic-ui-react"
import { _GetRating, _GetRatingContent } from "../../app/lib/CommonFunctions"
import { DrawDetailResult } from "../../app/models/IDayResult"


interface Props{
    drawResult: DrawDetailResult[];
}

export default observer(function DetailResult3({drawResult} : Props){
    let rating = [0,0,0,0,0,0,0,0]
    let ratingMonthly = [0,0,0,0,0,0,0,0]

    for(var i = 0; i < drawResult.length; i++){
      rating[drawResult[i].rating - 1] ++;
      ratingMonthly[drawResult[i].forecast_Rating - 1] ++;
    }

return (
    <div>
    <Segment.Group compact>
    <Segment compact>
        <Header as="h2" textAlign="left">Rating</Header>

        <Statistic.Group header="Group">
        {rating.map((x,i)=>{
                return (
                  <Statistic>
                  <Statistic.Value>{rating[i]}</Statistic.Value>
                  <Statistic.Label>
                  <Popup content={_GetRatingContent(i + 1)}
                       trigger ={<Image avatar src={_GetRating(i + 1)} />}>
                      </Popup>

                  
                  </Statistic.Label>
                </Statistic>
                )
                })
        }
        </Statistic.Group>
      </Segment>  
      <Segment compact>
        <Header as="h2" textAlign="left">Forecast Rating</Header>

        <Statistic.Group header="Group">

        {ratingMonthly.map((x,i)=>{
                    return (
                      <Statistic>
                      <Statistic.Value>{ratingMonthly[i]}</Statistic.Value>
                      <Statistic.Label>
                      <Popup content={_GetRatingContent(i + 1)}
                          trigger ={<Image avatar src={_GetRating(i + 1)} />}>
                          </Popup>

                      
                      </Statistic.Label>
                    </Statistic>
                    )
                    })
        }
        </Statistic.Group>
    </Segment>
    </Segment.Group>
  </div>
  )
})