import React, { SyntheticEvent, useEffect, useState } from "react";
import { DropdownProps, Grid, Table } from "semantic-ui-react";
import { history } from "../..";
import agent from "../../app/api/agent";
import { _3DPattern, _StatusOptions } from "../../app/lib/CommonData";
import Draw3DLink from "../../app/lib/draw3dlink";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyDropDown from "../../app/lib/MyDropDown";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import NumberStatus from "../../app/lib/NumberStatus";
import { Best3DResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default function Best4D(){
    const [numberType, setNumberType] = useState("TA");
    const [data, setData] = useState<Best3DResult[]>([]);
    const [systemInRange, setSystemInRange] = useState("0");
    const [top3InRange, setTop3InRange] = useState("0");
    const [column, setColumn] = useState("Strike");
    const [direction, setDirection] = useState<any>("descending");
    const [type3D, setType3D] = useState("ABC");
    const [isLoading, setIsLoading] = useState(true);
    const {userStore} = useStore();
    
    if(!userStore.isLogged){
      history.push("/");
    }

    const numberTypeOptiosn = [
        {
            text: "All Prizes",
            value: "A"
        },
        {
            text: "Top 3",
            value: "T3"
        },
        {
            text: "By Month All Prizes",
            value: "MA"
        },
        {
            text: "By Month Top3",
            value: "TA"
        },
        {
            text: "By Quarter All Prizes",
            value: "QA"
        },
        {
            text: "By Quarter Top3",
            value: "TQ"
        }

    ]

    const handleColumnClick = (columnName : string)=>{
        if (column !== columnName){
          setDirection("ascending");
        }
        else{
          if (direction === undefined){
            setDirection('ascending');
          }
          else{
            setDirection(direction === "ascending" ? "descending" : "ascending");
          }
        }
        setColumn(columnName);
        setIsLoading(true);
    }

    
    const handle3DDrawTypeStateChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        //setPage(1);
        setType3D(option.value + "");
        setIsLoading(true);
    }


    const handleNumberTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        //setPage(1);
        setNumberType(option.value + "");
        setIsLoading(true);
    }

    const handleSystemStateChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        //setPage(1);
        setSystemInRange(option.value + "");
        setIsLoading(true);
        
    }

    const handleTop3StateChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        //setPage(1);
        setTop3InRange(option.value + "");
        setIsLoading(true);
        
    }
    
    
    useEffect(()=>{

        agent.Draw4DResult.getBest3D(numberType, systemInRange, top3InRange, column, direction, type3D).then((response)=>{
            setData(response);
            setIsLoading(false);
            
        });


    },[numberType, systemInRange, top3InRange,column, direction, type3D]);


    if (isLoading){
        return (<LoadingComponent></LoadingComponent>);
    }
    return(
        <>
        <Grid centered>
        <Grid.Column verticalAlign="middle" mobile={8} tablet={8} computer={8}>
            <MyDropDown zIndex="21"
                placeholder="Select 3D Draw Type"
                toolTip="Select 3D Draw Type" 
                onChange = {handle3DDrawTypeStateChanged}
                options={_3DPattern}
                defaultValue={type3D} >
            </MyDropDown>
        </Grid.Column>

        <Grid.Column textAlign="left" verticalAlign="middle" mobile={8} tablet={8} computer={8}>
          <MyDropDown zIndex="21"
              toolTip = "Select 3D Type"
              onChange = {handleNumberTypeChanged}
              placeholder='Select 3D Type'
              options={numberTypeOptiosn}
              defaultValue = {numberType}>
          </MyDropDown>
        </Grid.Column>

        <Grid.Column verticalAlign="middle" mobile={8} tablet={8} computer={8}>
            <MyDropDown zIndex="20"
                toolTip = "Select System State"
                onChange = {handleSystemStateChanged}
                placeholder='Select System State'
                options={_StatusOptions}
                defaultValue = {systemInRange}>
            </MyDropDown>
        </Grid.Column>

        <Grid.Column verticalAlign="middle" mobile={8} tablet={8} computer={8}>
        <MyDropDown zIndex="20"
            placeholder="Select Top3 State"
            toolTip="Select Top3 State" 
            onChange = {handleTop3StateChanged}
            options={_StatusOptions}
            defaultValue={top3InRange} >
        </MyDropDown>
      </Grid.Column>


        <Grid.Column verticalAlign="middle" mobile={16} tablet={16} computer={16}>
        3D Best Numbers
        </Grid.Column>
      </Grid>

        <div style={{overflowX:"auto", marginTop:"10px"}}>
        <Table celled  sortable unstackable className="freeze-head-and-col">
        <Table.Header>
          <Table.Row>
              <MyTableWithSort column={column} columnName="System3D" handleClick={handleColumnClick} textAlign="center" direction={direction}>
                System <br/>3D
              </MyTableWithSort>

              <MyTableWithSort column={column} columnName="Strike" handleClick={handleColumnClick} textAlign="center" direction={direction}>
                Strike
              </MyTableWithSort>
              <MyTableWithSort column={column} columnName="Rank" handleClick={handleColumnClick} textAlign="center" direction={direction}>
                Order
              </MyTableWithSort>

              <MyTableWithSort column={column} columnName="SystemInRange" handleClick={handleColumnClick} textAlign="center" direction={direction}>
                System <br/>State
              </MyTableWithSort>

              <MyTableWithSort column={column} columnName="Top3InRange" handleClick={handleColumnClick} textAlign="center" direction={direction}>
                Top3 <br/>State
              </MyTableWithSort>

              <MyTableWithSort column={column} columnName="SystemInterval" handleClick={handleColumnClick} textAlign="center" direction={direction}>
                System <br/>Interval
              </MyTableWithSort>

              <MyTableWithSort column={column} columnName="Top3Interval" handleClick={handleColumnClick} textAlign="center" direction={direction}>
                Top3 <br/>Interval
              </MyTableWithSort>
         </Table.Row>
        </Table.Header>
        <Table.Body>
            {data && data.length > 0 && data.map(( (x,i) => {
                return (
                    <Table.Row key={x.system3D}>
                        <Table.Cell textAlign="center">
                          <Draw3DLink title = ""  number={x.system3D}></Draw3DLink>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {x.strike} 
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {x.rank} 
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <NumberStatus status={x.systemInRange} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <NumberStatus status={x.top3InRange} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {x.systemInterval} 
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {x.top3Interval} 
                        </Table.Cell>
                        

                    </Table.Row>
                )
            }))}
        </Table.Body>
        </Table>
    </div>              
    </>
    );
}