import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Grid, Header, List } from "semantic-ui-react";
import DrawDateDropDown from "../../app/lib/drawdate";
import DrawLink from "../../app/lib/drawlink";
import SplashScreen from "../../app/lib/SplashScreen";
import { useStore } from "../../app/store/store";

export default observer(function LatestDraw(){
    let i = 0;
    let j = 0;
    let k = 0;
    let l = 0;

    let marginTop="20px";

    marginTop = window.innerWidth <= 768 ? "5px" : "20px";


    const {drawResultStore, userStore} = useStore();

    const {dayResult} = drawResultStore;
    
    useEffect(()=>{
        //drawResultStore.loadDates();
        
        if (drawResultStore.selectedDate === ""){
            drawResultStore.loadDates().catch((err) => {
                toast.error(err);        
                drawResultStore.setLoadingInitial(false);
            });
        }
    }, [drawResultStore, userStore.isLogged]);
    
    if(drawResultStore.loadingInitial){
        return (<SplashScreen></SplashScreen>)
    }
    return (
        <div>
        <Grid centered>
            <Grid.Row>
            <Grid.Column width={16} textAlign="center">
                <Header >
                 Date:  <DrawDateDropDown></DrawDateDropDown>
                </Header>
            </Grid.Column>
            </Grid.Row>

            <Grid.Row>
            <Grid.Column width={16} textAlign="center">
                {dayResult && <DrawLink title={"1st Prize:"} number={dayResult!.first_Prize.prize_Number}></DrawLink>}
            </Grid.Column>
            </Grid.Row>
            
            <Grid.Row>
            <Grid.Column width={16} textAlign="center">
                {dayResult && <DrawLink title={"2nd Prize:"} number={dayResult!.second_Prize.prize_Number}></DrawLink>}
            </Grid.Column>
            </Grid.Row>

            <Grid.Row>
            <Grid.Column width={16} textAlign="center">
                {dayResult && <DrawLink title={"3rd Prize:"} number={dayResult!.third_Prize.prize_Number}></DrawLink>}
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8} textAlign="center">
                <Header style={{marginTop:marginTop}}>Starter</Header>
               
                </Grid.Column>
                <Grid.Column width={8} textAlign="center">
                <Header style={{marginTop:marginTop}}>Consolation</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={4} textAlign="right">
                <List>
                    {dayResult != null ? dayResult!.starter.map((x => {
                        k++;
                        if (k % 2 !== 0){
                            return (<List.Item key={x.draw_No}>
                                <Header>
                                <DrawLink title={""} number={x.prize_Number}>
                                        {x.prize_Number}
                                </DrawLink>                                        
                                </Header>
                                </List.Item>
                            );
                        }
                        else{
                            return null;
                        }
                    })) : "N/A"} 
                </List>
                </Grid.Column>
                <Grid.Column width={4} textAlign="left">
                <List>
                    {dayResult != null ? dayResult!.starter.map((x => {
                        l++;
                        if (l % 2 === 0){
                            return (<List.Item key={x.draw_No} >
                                <Header>
                                <DrawLink title={""} number={x.prize_Number}>
                                        {x.prize_Number}
                                </DrawLink>                                        
                                </Header>
                                </List.Item>
                            );
                        }
                        else{
                            return null;
                        }
                    })): "N/A"} 
                </List>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                    <List>
                    {
                        dayResult != null ? dayResult!.consolidation.map((x => {
                            i++;
                            if (i % 2 !== 0){
                                return (
                                    <List.Item key={x.draw_No}>
                                        <Header>
                                        <DrawLink title={""} number={x.prize_Number}>
                                                {x.prize_Number}
                                        </DrawLink>                                        
                                        </Header>
                                    </List.Item>
                                );
                                
                            }
                            else{
                                return null;
                            }
                            
                        })):"N/A"
                    } 
                    </List>
                
                </Grid.Column>
                <Grid.Column width={4} textAlign="left">
                    <List>
                    {dayResult != null ? dayResult!.consolidation.map((x => {
                            j++;
                            if (j % 2 === 0){
                                return (<List.Item key={x.draw_No}>
                                        <Header>
                                        <DrawLink title={""} number={x.prize_Number}>
                                                {x.prize_Number}
                                        </DrawLink>                                        
                                        </Header>
                                    </List.Item>
                                );
                            }
                            else{
                                return null;
                            }
                            
                        })): "N/A"
                    } 
                    </List>
                
                </Grid.Column>

            </Grid.Row>
        </Grid>
    </div>
    );

}
)
