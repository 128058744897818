import React from "react";
import { Embed } from "semantic-ui-react";

export default function ForecastVideo(){
    return (
         <div style={{overflowX:"auto"}}>
        <Embed id="7MASypE0U4I" source='youtube' brandedUI >
        </Embed>

         </div>
    );
}