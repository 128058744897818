import React from "react";
import { Label, Table } from "semantic-ui-react";
import style from "./analysis.module.css";

interface Props{
    result : string;
    top3: number;
}

export default function YearResult(prop: Props){
    return (
        <Table.Cell  textAlign="center" className={style.lastdrawn}>{prop.result}<br/>
                    {prop.top3 !== 0 ?
                        <Label data-tooltip={"Top3 Strike : " + prop.top3} 
                                 circular size={"mini"} color={"red"}>{prop.top3}</Label>
                        : null
                    }
        </Table.Cell>
    );
}