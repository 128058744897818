import React from "react";
import {  Button, Label, Table } from "semantic-ui-react";
import { history } from "../../..";
import { _DisplayZero } from "../../../app/lib/CommonFunctions";
import { System3DAllRankPattern } from "../../../app/models/IDayResult";


interface Props{
  data: System3DAllRankPattern[],
  type3D: string;
  drawType: string;
}
export default function System3DRankResult({data, drawType} : Props){

const redirectTo3DPage=(pageNo: string) => {
  history.push("/3DAnalysisTypeAndRange/S/" + pageNo + "/"+ drawType);
}

return (
<Table celled unstackable className="freeze-head-and-col">
<Table.Header>
<Table.Row>
    <Table.HeaderCell textAlign="center" colSpan="18">
      System 3D Rank Trend (All Prizes)
    </Table.HeaderCell>
  </Table.Row>
  <Table.Row>
    <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
     <Button type="button" onClick={()=>redirectTo3DPage('1-10')} data-tooltip="Click here to get 1-10 rank 3D">1-10</Button> 
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
      <Button type="button" onClick={()=>redirectTo3DPage('11-20')} data-tooltip="Click here to get 11-20 rank 3D">11-20</Button> 
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
      <Button type="button" onClick={()=>redirectTo3DPage('21-30')} data-tooltip="Click here to get 21-30 rank 3D">21-30</Button> 
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('31-40')} data-tooltip="Click here to get 31-40 rank 3D">31-40</Button> 
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('41-50')} data-tooltip="Click here to get 41-50 rank 3D"> 41-50</Button> 
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
      <Button type="button" onClick={()=>redirectTo3DPage('51-60')} data-tooltip="Click here to get 51-60 rank 3D"> 51-60</Button> 
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
      <Button type="button" onClick={()=>redirectTo3DPage('61-70')} data-tooltip="Click here to get 61-70 rank 3D">61-70</Button> 
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
        <Button type="button" onClick={()=>redirectTo3DPage('71-80')} data-tooltip="Click here to get 71-80 rank 3D">71-80</Button> 
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
      <Button type="button" onClick={()=>redirectTo3DPage('81-999')} data-tooltip="Click here to get Over 80 rank 3D">Over 80</Button> 
    </Table.HeaderCell>

    </Table.Row>
</Table.Header>

<Table.Body>
    {data.map((x,i) => {
        if (i === 0){
             const total = x.a1_10 + x.a11_20 + x.a21_30 + x.a31_40 + x.a41_50 + x.a51_60 + x.a61_70 + x.a71_80 + x.over_80;
        
             if (total === 0){
               return (
                <Table.Row key={0}>
                  <Table.Cell colSpan={10}>
                    No Data
                  </Table.Cell>
                </Table.Row>

               );
             }
             return (
              <Table.Row key={i}>
                  <Table.Cell  textAlign="center">
                  {x.drawDate}
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a1_10}</Label>
                    <Label>{(x.a1_10 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a11_20}</Label>
                    <Label>{(x.a11_20 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                      <Label circular color="blue">{x.a21_30}</Label>
                      <Label>{(x.a21_30 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                      <Label circular color="blue">{x.a31_40}</Label>
                      <Label>{(x.a31_40 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a41_50}</Label>
                    <Label>{(x.a41_50 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a51_60}</Label>
                    <Label>{(x.a51_60 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a61_70}</Label>
                    <Label>{(x.a61_70 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a71_80}</Label>
                    <Label>{(x.a71_80 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.over_80}</Label>
                    <Label>{(x.over_80 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
              </Table.Row>
            );
          }
        return (
            <Table.Row key={i}>
                <Table.Cell  textAlign="center">
                {x.drawDate}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a1_10)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a11_20)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a21_30)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a31_40)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a41_50)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.a51_60)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.a61_70)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.a71_80)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.over_80)}
                </Table.Cell>
            </Table.Row>
          );
    })}
</Table.Body>
</Table>
)
}