import { useField } from "formik";
import React from "react";
import { Checkbox, Form,  Label } from "semantic-ui-react";

interface Props {
    children?: never[];
    name: string;
    label: string;
}
export default function MyCheckBox(props: Props){
    const [field, meta, helper] = useField(props.name);

    return (
        <Form.Field inline error={meta.touched && !!meta.error}>
            <Checkbox {...field}  {...props} onChange={(e: any, {name, checked})=>
                {
                    helper.setValue(checked);
                    helper.setTouched(true);                    
                }}/>
            {meta.touched && meta.error ? <Label basic color='red'>{meta.error}</Label> : null
            } 
        </Form.Field>
    );
}