import React from "react";
import { Link } from "react-router-dom";
import { Header, Popup } from "semantic-ui-react";

interface Props{
    number: string;
    title: string;
    children?: any
}
export default function Draw2DLink(props: Props){
    return (
        <Popup content="Click to search the number" trigger={
            <Header as="h4">
            {props.title}&nbsp;
            <Link to={"/search3dfrom2d/" + props.number}>{props.number}</Link>
            </Header>
        }>
            
        </Popup>
    );
}