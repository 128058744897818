import React from "react";
import {  Label, Table } from "semantic-ui-react";
import { DirectIntervalHistoryResult } from "../../../app/models/IDayResult";

interface Props{
    data: DirectIntervalHistoryResult[],
    title: string;
}
export default function DirectInterval({data, title}: Props){
return (
<Table celled  sortable unstackable className="freeze-head-and-col">
<Table.Header>
<Table.Row>
    <Table.HeaderCell>
    </Table.HeaderCell>
    <Table.HeaderCell colSpan="18" textAlign="center">
        {title} 
    </Table.HeaderCell>
  </Table.Row>
  <Table.Row>
    <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">1-49</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">50-99</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">100-199</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">200-299</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">300-399</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">400-499</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">500-599</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">600-699</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">700-799</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">800-899</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">900-999</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">Over 1000</Table.HeaderCell>
    </Table.Row>
</Table.Header>

<Table.Body>

  {data.map((x,i) => {

    var total = x.a0_49 + x.a50_99 + x.a100_199 + x.a200_299 + x.a300_399 + x.a400_499 +
                    x.a500_599 + x.a600_699 + x.a700_799 + x.a800_899 + 
                    x.a900_999 + x.over_1000 ;

    
    if ( i === 0){
        if (total === 0){
            return (<Table.Row key={i}>
                        <Table.Cell  textAlign="center">
                        Hit/% Summary
                        </Table.Cell>
                        <Table.Cell textAlign="center" colSpan="12">
                            No Result
                        </Table.Cell>
                    </Table.Row>
                    )
        }
    
        return (
            <Table.Row key={i}>
                <Table.Cell  textAlign="center">
                Hit/% Summary
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a0_49 > 0 ? x.a0_49 : 0 }</Label>
                    <Label  >{((x.a0_49 / total) * 100).toFixed(0)+'%' }</Label>                    
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a50_99 > 0 ? x.a50_99 : 0 }</Label>
                    <Label  >{((x.a50_99 / total) * 100).toFixed(0)+'%' }</Label>                    
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a100_199 > 0 ? x.a100_199 : 0 }</Label>
                    <Label  >{((x.a100_199 / total) * 100).toFixed(0)+'%' }</Label>                    
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a200_299 > 0 ? x.a200_299 : 0 }</Label>
                    <Label  >{((x.a200_299 / total) * 100).toFixed(0)+'%' }</Label>                    
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a300_399 > 0 ? x.a300_399 : 0 }</Label>
                    <Label  >{((x.a300_399 / total) * 100).toFixed(0)+'%' }</Label>                    
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a400_499 > 0 ? x.a400_499 : 0 }</Label>
                    <Label  >{((x.a400_499 / total) * 100).toFixed(0)+'%' }</Label>                    
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a500_599 > 0 ? x.a500_599 : 0 }</Label>
                    <Label  >{((x.a500_599 / total) * 100).toFixed(0)+'%' }</Label>                    
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a600_699 > 0 ? x.a600_699 : 0 }</Label>
                    <Label  >{((x.a600_699 / total) * 100).toFixed(0)+'%' }</Label>                    
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a700_799 > 0 ? x.a700_799 : 0 }</Label>
                    <Label  >{((x.a700_799 / total) * 100).toFixed(0)+'%' }</Label>                    
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a800_899 > 0 ? x.a800_899 : 0 }</Label>
                    <Label  >{((x.a800_899 / total) * 100).toFixed(0)+'%' }</Label>                    

                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a900_999 > 0 ? x.a900_999 : 0 }</Label>
                    <Label  >{((x.a900_999 / total) * 100).toFixed(0)+'%' }</Label>                    
                </Table.Cell>
                <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.over_1000 > 0 ? x.over_1000 : 0 }</Label>
                    <Label  >{((x.over_1000 / total) * 100).toFixed(0)+'%' }</Label>                    

                </Table.Cell>
    
            </Table.Row>
        );
    }                
    return (
        <Table.Row key={i}>
            <Table.Cell  textAlign="center">
                {x.draw_Date}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a0_49 > 0 ? x.a0_49 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a50_99 > 0 ? x.a50_99 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a100_199 > 0 ? x.a100_199 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a200_299 > 0 ? x.a200_299 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a300_399 > 0 ? x.a300_399 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a400_499 > 0 ? x.a400_499 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a500_599 > 0 ? x.a500_599 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a600_699 > 0 ? x.a600_699 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a700_799 > 0 ? x.a700_799 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a800_899 > 0 ? x.a800_899 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.a900_999 > 0 ? x.a900_999 : ""}
            </Table.Cell>
            <Table.Cell  textAlign="center">
                { x.over_1000 > 0 ? x.over_1000 : ""}
            </Table.Cell>

        </Table.Row>
    );
  }  
  )} 
   </Table.Body>
</Table>
)
}