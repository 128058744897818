import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useEffect, useState } from "react"
import { DropdownProps, Grid } from 'semantic-ui-react'
import { history } from "../../..";
import agent from "../../../app/api/agent";
import { _DrawType3DOptionsWithAll, _NoOfDrawOptions, _PrizeTypeOptions, _RankTypeOptions} from "../../../app/lib/CommonData";
import { _GetSavedDrawNo, _GetSavedDrawType3D, _GetSavedPrizeType, _GetSavedRankType3D, _SetSavedDrawNo, _SetSavedDrawType3D, _SetSavedPrizeType, _SetSavedRankType3D } from "../../../app/lib/CommonFunctions";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import { System3DAllRankPattern, System3DAllRankPerMonthPattern, System3DTop3Pattern, System3DTop3PerMonthPattern } from "../../../app/models/IDayResult";
import { useStore } from "../../../app/store/store";
import System3DAllRankPerMonthResult from "./System3DAllRankPerMonthResult";
import System3DRankResult from "./System3DRankResult";
import System3DTop3RankPerMonthResult from "./System3DTop3RankPerMonthResult";
import System3DTop3RankResult from "./System3DTop3RankResult";


export default observer(function System3DRankHistory(){
    const [drawType, setDrawType] = useState(_GetSavedDrawType3D("A"));
    const [rankType, setRankType] = useState(_GetSavedRankType3D("S"));    
    const [loading, setLoading] = useState(false);
    const [drawNo, setDrawNo] = useState(_GetSavedDrawNo("3D", 36));
    const [system3DAllRank, setSystem3DAllRank] = useState<System3DAllRankPattern[]>([]);
    const [system3DTop3, setSystem3DTop3] = useState<System3DTop3Pattern[]>([]);
    const [system3DAllRankPerMonth, setSystem3DAllRankPerMonth] = useState<System3DAllRankPerMonthPattern[]>([]);
    const [system3DTop3PerMonth, setSystem3DTop3PerMonth] = useState<System3DTop3PerMonthPattern[]>([]);

    const [prizeType, setPrizeType] = useState(_GetSavedPrizeType("A"));

    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
    }


    const prizeTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setPrizeType(option.value + '');
        _SetSavedPrizeType(option.value + '');
        setLoading(true);
    }

    const drawNoChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setDrawNo(parseInt(option.value + ''));
        _SetSavedDrawNo("3D", parseInt(option.value + ''));
        setLoading(true);
    }

    const rankTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setRankType(option.value + '');
        _SetSavedRankType3D(option.value + '');
        setLoading(true);
    }

    const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setDrawType(option.value + "");
        _SetSavedDrawType3D(option.value + "");
        setLoading(true);
    }

    useEffect(()=>{
        if (rankType === "S"){
            agent.Draw4DResult.get3DAllRankPattern(drawNo, drawType, prizeType).then((response)=>{

                setSystem3DAllRank(response);
            }).finally(()=>{
                setLoading(false);
            });
        }

        if(rankType === "T"){
            agent.Draw4DResult.get3DTop3Pattern(drawNo, drawType, prizeType).then((response)=>{

                setSystem3DTop3(response);
            }).finally(()=>{
                setLoading(false);
            });

        }

        if(rankType === "M"){
            agent.Draw4DResult.get3DAllRankPerMonthPattern(drawNo, drawType, prizeType).then((response)=>{

                setSystem3DAllRankPerMonth(response);
            }).finally(()=>{
                setLoading(false);
            });

        }

        if(rankType === "N"){
            agent.Draw4DResult.get3DTop3PerMonthPattern(drawNo, drawType, prizeType).then((response)=>{

                setSystem3DTop3PerMonth(response);
            }).finally(()=>{
                setLoading(false);
            });

        }

    },[drawNo, drawType, rankType, prizeType]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
        <>
        <Grid>
            <Grid.Row>
            <Grid.Column verticalAlign="middle" textAlign="center" mobile={8} tablet={8} computer={8}>
                    <MyDropDown onChange={rankTypeChanged} placeholder="Select Rank Type" zIndex="21"
                      toolTip="Select Rank Type"
                      options={_RankTypeOptions} defaultValue = {rankType}>
                      </MyDropDown>

            </Grid.Column>
            <Grid.Column verticalAlign="middle" textAlign="center" mobile={8} tablet={8} computer={8}>
                <MyDropDown onChange={prizeTypeChanged} placeholder="Select Prize Type" zIndex="21"
                      toolTip="Select Prize Type"
                      options={_PrizeTypeOptions} defaultValue = {prizeType}>
                      </MyDropDown>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                    <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={8} computer={8}>
                        <MyDropDown onChange={drawNoChanged} placeholder="Select No of draw" zIndex="20"
                            toolTip="Select No Of Draw"
                            options={_NoOfDrawOptions} defaultValue = {drawNo}>
                        </MyDropDown>

                  </Grid.Column>
                  <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={8} computer={8}>
                      <MyDropDown onChange={drawTypeChanged} placeholder="Select Draw Type"  zIndex="20"
                      toolTip="Select Draw Type"
                      options={_DrawType3DOptionsWithAll} defaultValue = {drawType}>
                      </MyDropDown>
                  </Grid.Column>

                </Grid.Row>
        </Grid>
    <div style={{overflow:"auto"}}>
        {
            rankType === "S" && <System3DRankResult drawType={drawType} type3D="S" data={system3DAllRank}></System3DRankResult>
        }
        {
            rankType === "T" && <System3DTop3RankResult  drawType={drawType} data={system3DTop3}></System3DTop3RankResult>
        }
        {
            rankType === "M" && <System3DAllRankPerMonthResult  drawType={drawType} data={system3DAllRankPerMonth}></System3DAllRankPerMonthResult>
        }
        {
            rankType === "N" && <System3DTop3RankPerMonthResult   drawType={drawType} data={system3DTop3PerMonth}></System3DTop3RankPerMonthResult>
        }
      
   </div>
   </>
    );
});