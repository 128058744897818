import React, {  useState } from "react";
import { useEffect } from "react";
import { CartesianGrid, LabelList, Legend, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import { Props } from "recharts/types/component/DefaultLegendContent";
import { Button, Divider, Grid, Header, Label, Segment } from "semantic-ui-react";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { GraphicResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import ShowHelpGraph from "./ShowHelpGraph";


interface Props1{
    number: string;
}

export default function ViewGraphicsTop3(props: Props1){
  const {modalStore} = useStore();
  
  const openHelp= ()=>{
    modalStore.openModal(<ShowHelpGraph></ShowHelpGraph>,"Help","large", null,()=>{})
  }

  const CustomTooltip = ({ active, payload, label } : any) => {
    if (active && payload && payload.length) {

      let interval = 0;
      let prizeCode = "";
      let index = 0;
      let min = 0;
      let max = 0;

      data.map((x)=>{
        if (x.draw_Date === label){
          index = x.key;
          interval = x.interval;
          prizeCode = x.prize_Code;
          min = x.minInterval;
          max = x.maxInterval;
          return false;
        }
        return true;
      });

      if (index === 1){
        const colorMessage = (interval >= min && interval <= max ? "green" : "red");
        const messageStyle = {
          color: colorMessage,
          fontWeigth:"bold"
        };

        const message: string =  (interval < min ? "Not Due (Stay)" : (interval > max ? "Over Due (Hold)" : "In Range(Buy)"));
        return (
          <Segment>
            {"Date : " + label}<br/>
            {"Prize : " + prizeCode}<br/>
            {"Current Interval : " + interval}<br/><br/>
            <span style={messageStyle}>{message}</span>
          </Segment>
        );
  


      }
      return (
        <Segment>
          {"Date : " + label}<br/>
          {"Prize : " + prizeCode}<br/>
          {"Hit Interval : " + interval}
        </Segment>
      );
    }
  
    return null;
  };

/*const CustomizeLabel = (props: any) =>{
  const { x, y, stroke, value } = props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    ); 
}*/

  const CustomizedDot = (props: any) => {
    const { cx, cy,  payload } = props;
    
    if (payload.prize_Code === '1' || payload.prize_Code === '2' || payload.prize_Code === '3') {
      return (
        <circle cx={cx} r="6" cy={cy} fill="red">
          { payload.key === 1 ?
            <animate
            attributeName="r"
            from="5"
            to="15"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          /> : null
          }
        </circle>
      );
    }
    if (payload.key === 1){
      return (<circle cx={cx} r="6" cy={cy} fill="blue">
        <animate
        attributeName="r"
        from="5"
        to="15"
        dur="1.5s"
        begin="0s"
        repeatCount="indefinite" />
        </circle>
      );
    }

    return (
        
        <circle cx={cx} r="6" cy={cy} fill="black">
        </circle>
    );
  };

  const ActiveDot = (props: any) => {
    const {cx, cy} = props;
    
    return (
        <circle cx={cx} r="4" cy={cy} fill="blue">
        </circle>
    );
  };

  const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value } = props;
  
    return (
      <text x={x} y={y} dy={-10} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    );
  };
  
  
  const renderLegend = (prop: Props) => {
    return (
      <ul>
      </ul>
    );
  }
    const [data, setData] = useState<GraphicResult[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(()=>{
        agent.Draw4DResult.getGraphicsResultTop3(props.number).then((response)=>{

            setData(response);
            setLoading(false);
            //setLoaded(true);
            //isLoading = false;
        });

    }, [props.number])

    const maxInterval = data.length > 0 ? Math.max.apply(Math, data.map(function(o) { return o.interval; })):0;


    const RenderDefaultLabel  = ({ viewBox } : any) => {

      const state = data[0].current_Interval < data[0].minInterval ? "Not Due(Stay)" :
                  data[1].current_Interval > data[0].maxInterval ? "Overdue(Hold)": "InRange(Buy)";
      const colorSpan = data[0].current_Interval < data[0].minInterval ? "orange" :
      data[1].current_Interval > data[0].maxInterval ? "red": "green";
      return (

      <g>
        
          <foreignObject x={viewBox.x} y={0} width={viewBox.width} height={100}>
              <div>Graph - 4D Interval Hits - Top3 Prizes<br/>
                    Number Analysed: <b>{props.number}</b><br/>Current Interval:{data[0].current_Interval}[<span style={{color:colorSpan}}>State: {state}</span>]
                    <br/><span style={{color:"orange"}}>ND: 1 - {data[0].minInterval - 1}</span>
                    <span style={{color:"green", marginLeft: "20px", marginRight:"20px"}}>IR: {data[0].minInterval} - {data[0].maxInterval}</span>
                    <span style={{color:"red"}}>OD: &gt; {data[0].maxInterval}</span>
              </div>
          </foreignObject>
      </g>);
    }

    if (isLoading) return (<LoadingComponent></LoadingComponent>);
    if (data.length <= 6) return (<Header>No Data</Header>);
    return (
        <div style={{overflowX:"auto", textAlign:"center", height:"100%", width:"100%"}}>
        <Grid>
          <Grid.Column width={16}>
          Top3 Interval Lines &amp; Icons&nbsp;<Button data-tooltip="Show Help" positive icon="help" onClick={()=>{openHelp()}}></Button>
          </Grid.Column>
        </Grid>
        <Divider hidden />

        <LineChart
          width={800}
          height={600}
          data={data}
          margin={{
            top: 100,
            right: 10,
            left: 10,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="draw_Date">
          <Label value="Draw Date" offset={-10} position="insideBottom" />
          </XAxis>
          <YAxis label={{value: "Interval",angle: -90, position: 'insideLeft', textAnchor: 'middle' }}/>
          <Tooltip content={<CustomTooltip/>}  />
          <Legend content={renderLegend}/>
          

          {data && data.length > 0 &&
            <ReferenceLine y={data[0].maxInterval} label={"Max [" + data[0].maxInterval+"]"} stroke="red" strokeDasharray="3 3" />
          }

          {data && data.length > 0 &&
            <ReferenceLine y={data[0].minInterval} label={"Min [" + data[0].minInterval +"]"} stroke="green" strokeDasharray="3 3">
            </ReferenceLine>
          }
        
          <Line  legendType="circle"  type="monotone" dataKey="interval" stroke="#964B00"  dot={<CustomizedDot />} activeDot={<ActiveDot/>} >
          <LabelList content={<CustomizedLabel />} />
          </Line>
          
          {data && data.length > 0 &&
            <ReferenceLine y={data[0].average} label={"Average [" + data[0].average+"]"} stroke="blue" strokeDasharray="3 3" />
          }

          {data && data.length > 0 &&
           <ReferenceLine y={maxInterval} label={<RenderDefaultLabel/>} />                           }{

          }

        </LineChart>
        </div>
    )
}