import { observer } from "mobx-react-lite"
import React from "react"
import { Grid, Header } from "semantic-ui-react"
import DrawLink from "../../app/lib/drawlink"
import { DrawDetailResult } from "../../app/models/IDayResult"


interface Props{
    drawResult: DrawDetailResult[];
}

export default observer(function DetailResult0({drawResult} : Props){

return (
    <Grid centered>
            <Grid.Row>
            <Grid.Column width={16} textAlign="center">
                <DrawLink title={"1st Prize:"} number={drawResult[0].prize_Number}></DrawLink>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width={16} textAlign="center">
                <DrawLink title={"2nd Prize:"} number={drawResult[1].prize_Number}></DrawLink>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width={16} textAlign="center">
                <DrawLink title={"3rd Prize:"} number={drawResult[2].prize_Number}></DrawLink>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width={8} textAlign="center">
            <Header as="h3"><u>Starter Prizes</u></Header>
            </Grid.Column>
            <Grid.Column width={8} textAlign="center">
                <Header as="h3"><u>Consolation Prizes</u></Header>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width={4} textAlign="right">
                <DrawLink title={""} number={drawResult[3].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="left">
                <DrawLink title={""} number={drawResult[4].prize_Number}></DrawLink>
            </Grid.Column>

            <Grid.Column width={4} textAlign="right">
                <DrawLink title={""} number={drawResult[13].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="left">
                <DrawLink title={""} number={drawResult[14].prize_Number}></DrawLink>
            </Grid.Column>

            </Grid.Row>
            <Grid.Row>
            <Grid.Column width={4} textAlign="right">
                <DrawLink title={""} number={drawResult[5].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="left">
                <DrawLink title={""} number={drawResult[6].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
                <DrawLink title={""} number={drawResult[15].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="left">
                <DrawLink title={""} number={drawResult[16].prize_Number}></DrawLink>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width={4} textAlign="right">
                <DrawLink title={""} number={drawResult[7].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="left">
                <DrawLink title={""} number={drawResult[8].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
                <DrawLink title={""} number={drawResult[17].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="left">
                <DrawLink title={""} number={drawResult[18].prize_Number}></DrawLink>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width={4} textAlign="right">
                <DrawLink title={""} number={drawResult[9].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="left">
                <DrawLink title={""} number={drawResult[10].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
                <DrawLink title={""} number={drawResult[19].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="left">
                <DrawLink title={""} number={drawResult[20].prize_Number}></DrawLink>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width={4} textAlign="right">
                <DrawLink title={""} number={drawResult[11].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="left">
                <DrawLink title={""} number={drawResult[12].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
                <DrawLink title={""} number={drawResult[21].prize_Number}></DrawLink>
            </Grid.Column>
            <Grid.Column width={4} textAlign="left">
                <DrawLink title={""} number={drawResult[22].prize_Number}></DrawLink>
            </Grid.Column>
            </Grid.Row>
    </Grid>
  )
})