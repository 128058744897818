import React, { SyntheticEvent} from 'react'
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { useStore } from '../store/store';


export default function DrawDateDropDown(){
  const {drawResultStore} = useStore();
  let {selectedDate} = drawResultStore;
  let {dateResult} = drawResultStore;

  const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
    //drawResultStore.setLoadingInitial(true);

    drawResultStore.setSelectedDate(option.value + "");

    //drawResultStore.loadDayResult();
    //drawResultStore.setLoadingInitial(false);

  }

  return (
        <Dropdown style={{marginLeft: '10px'}}
                onChange={handleDropDownSelect}
                options={dateResult.map(x=> ({key: x.draw_Date, text: x.draw_Date, value: x.draw_Date}))}
               defaultValue = {selectedDate}
            
        />
        
    );


}