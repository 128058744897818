import React from "react";
import {  Header, Image } from 'semantic-ui-react';


import {  Segment } from "semantic-ui-react";

interface Props{
    inverted?: boolean;
    content?: string;
}

export default function SplashScreen({inverted = true, content = "Loading. Please wait..."}: Props){
    return (
        <Segment
        textAlign='center' 
        style={{ minHeight: window.innerHeight, padding: '1em 0em' }}
        vertical>
                <Header as="h1">{content}</Header>
                <Image size="huge" className="centered" src="/assets/4DGM-graphic-2.jpg" circular></Image>
        </Segment>
        
    )
}