import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Image, DropdownProps,  Grid,  Header, Input, InputOnChangeData, Label, Pagination,  PaginationProps, Table, Popup } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import DrawLink from "../../app/lib/drawlink";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import NumberStatus from "../../app/lib/NumberStatus";
import {  AnalysisResultNew } from "../../app/models/IDayResult";
import { _GetRating, _GetRatingContent, _GetRowPerPage, _SetRowPerPage } from "../../app/lib/CommonFunctions";
import { _DrawTypeOptionsWithAll, _RatingOptions, _RowPerPageOptions, _StatusOptions } from "../../app/lib/CommonData";
import MyDropDown from "../../app/lib/MyDropDown";
import configData from "../../app/config.json";
import { useParams } from "react-router";
import YearResult from "../analysis/yearresult";
import System3DData from "../../app/lib/System3DData";

export default observer(function Analysis4DWithParams(){
    let {statusNumber, statusType, iDrawType, position3D} = useParams<{statusNumber: string, statusType: string, iDrawType: string, position3D: string}>();

    const curYear = configData.CurrentYear;
    const [loading, setLoading] = useState(true);
    const [drawType, setDrawType] = useState(iDrawType);
    const [analysis, setAnalysis] = useState<AnalysisResultNew[]>([]);
    const [column, setColumn] = useState("total");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("0");
    const [rowPerPage, setRowPerPage] = useState(_GetRowPerPage());
    const [rating, setRating] = useState(0);

    const periodDisplay = configData.PeriodDisplay;
    const positionDesc = (position3D === "0" ? "All Positions" : "Position " + position3D);

    const statusTypeDesc = (statusType === "2" ? " System" : " Top3");

    const rowPerPageChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setRowPerPage(parseInt(option.value + ""));
      _SetRowPerPage(parseInt(option.value + ""));
      setLoading(true);
     
    }
    const ratingChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setRating(parseInt(option.value + ""));
      setLoading(true);
     
    }

    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setPage(1);
         setDrawType(option.value + "");
         setLoading(true);
        
    }

    const statusDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setStatus(option.value + "");
      setLoading(true);
     
     }

    const handleColumnClick = (columnName : string)=>{
      if (column !== columnName){
        setDirection("ascending");
      }
      else{
        if (direction === undefined){
          setDirection('ascending');
        }
        else{
          setDirection(direction === "ascending" ? "descending" : "ascending");
        }
      }
      setColumn(columnName);
      setPage(1);
      setLoading(true);

    }

    const handlePageChanged = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps)=>{
      setPage(parseInt(data.activePage!.toString()));
      setLoading(true);

    }

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData)=>{
      if(data.value.length === 4){
          if (!isNaN(parseFloat(data.value))){
            let newDirection:string;

            newDirection = "";
            if (direction === "ascending"){
              newDirection = "asc";
            }
            if (direction === "descending"){
              newDirection = "desc";
            }

            
            agent.Draw4DResult.getPageBy4DNumberBySystem3DStatus(data.value, column, newDirection, drawType, status, rowPerPage, rating, statusNumber, statusType, position3D).then(response=>{
              if(response.pageNo === 0){
                toast.error("Number not found ! Check the Draw Type.");
                return;
              }  
              toast.info("Found number in row " + response.row_No);

              if (page !== response.pageNo){
                setPage(response.pageNo);
                setLoading(true);
              }

            });
          }
      }

    }

    useEffect(()=>{

        let newDirection = "none";
        if (direction !== undefined){
          if (direction === "ascending"){
            newDirection = "asc";
          }
          else{
            newDirection = "desc";
          }
        }

        agent.Draw4DResult.getAnalysisBySystem3DStatus(drawType, column, newDirection, page, status, rowPerPage, rating, statusNumber, statusType, position3D).then((response)=>{

            setAnalysis(response);
            setLoading(false);
            
        });

        
    },[status, drawType, column, direction, page, rowPerPage, rating, statusNumber, statusType, position3D]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
        <Table celled unstackable selectable sortable singleLine>
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell colSpan="21" textAlign="center">
              {(statusType === "2" || statusType === "3") &&
                <Header>{`4D Analytic Overview for status [`}
                      <NumberStatus status={statusNumber}></NumberStatus>
                      {`-${statusTypeDesc} - ${positionDesc} ] ${periodDisplay}`}
                </Header>
              }
              {(statusType === "1") &&
                <Header>{`4D Analytic Overview for status [`}
                      <NumberStatus status={statusNumber.split('-')[0]}></NumberStatus>
                      <NumberStatus status={statusNumber.split('-')[1]}></NumberStatus>
                      {` - ${positionDesc}] ${periodDisplay}`}
                </Header>
              }
            </Table.HeaderCell>

        </Table.Row>  
          <Table.Row>
            <Table.HeaderCell colSpan="21">
              <Grid centered >
                <Grid.Column verticalAlign="middle" textAlign="left" width={2}>
                    <MyDropDown zIndex="20" placeholder='Select Draw Type' toolTip="Select Draw Type" toolTipDirection="top center" 
                        onChange={handleDropDownSelect} options={_DrawTypeOptionsWithAll} defaultValue={drawType}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column verticalAlign="middle"  width={3}>
                  <Input 
                      data-tooltip="Enter 4D Number to find the row number in the list"
                      data-position="bottom center"
                      fluid  maxLength={4} focus placeholder='Search 4D position in the list'  onChange={handleInputChanged}></Input>
                </Grid.Column>
                <Grid.Column  width={4}>
                    <MyDropDown zIndex="20" placeholder='Select rating to filter' toolTip="Select rating to filter" toolTipDirection="top center" 
                        onChange={ratingChanged} options={_RatingOptions} defaultValue={rating}>
                    </MyDropDown>

                </Grid.Column>  

                <Grid.Column  width={3}>
                     <MyDropDown zIndex="20" placeholder='Select State' toolTip="Select Number State" toolTipDirection="top center" 
                        onChange={statusDropDownSelect} options={_StatusOptions} defaultValue={status}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column verticalAlign="middle"  width={4}>
                  <MyDropDown zIndex="20" placeholder='Select Item Per Page' toolTip="Select Item Per Page" toolTipDirection="top center" 
                        onChange={rowPerPageChanged} options={_RowPerPageOptions} defaultValue={rowPerPage}>
                    </MyDropDown>
                </Grid.Column>
              </Grid>
              </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="source_number" handleClick={handleColumnClick}>
              System<br/>Number
            </MyTableWithSort> 

            <Table.HeaderCell rowSpan={2} textAlign="center">
            Row #
            </Table.HeaderCell>

   
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="total" handleClick={handleColumnClick}>
            All
            </MyTableWithSort> 
            
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="top3" handleClick={handleColumnClick}>
            Top 3
            </MyTableWithSort> 
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="forecast" handleClick={handleColumnClick}>
            Rating
            </MyTableWithSort> 

            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="Status" handleClick={handleColumnClick}>
           
            Number<br/>State (All)
            </MyTableWithSort> 

            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="Status_Top3" handleClick={handleColumnClick}>
           
            Number<br/>State (Top3)
            </MyTableWithSort> 
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="Awaken" handleClick={handleColumnClick}>
            Awaken
            </MyTableWithSort> 

            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="draw_date" handleClick={handleColumnClick}>
            Last Drawn Date<br/>(All)
            </MyTableWithSort> 
           
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="max_Top3_Date" handleClick={handleColumnClick}>
            Last Drawn Date<br/>(Top 3)
            </MyTableWithSort> 
           
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="avg_All" handleClick={handleColumnClick}>
            Yearly Average<br/>(All/Top3)
            </MyTableWithSort> 
           
            <Table.HeaderCell textAlign="center">
            Curr Year
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="10">
            No of Strike in Year
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="4">
            Position
            </Table.HeaderCell>             
          </Table.Row>
          <Table.Row>
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y00" handleClick={handleColumnClick}>
              {(curYear).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y01" handleClick={handleColumnClick}>
              {(curYear - 1).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y02" handleClick={handleColumnClick}>
              {(curYear - 2).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y03" handleClick={handleColumnClick}>
              {(curYear - 3).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y04" handleClick={handleColumnClick}>
              {(curYear - 4).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y05" handleClick={handleColumnClick}>
              {(curYear - 5).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y06" handleClick={handleColumnClick}>
              {(curYear - 6).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y07" handleClick={handleColumnClick}>
              {(curYear - 7).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y08" handleClick={handleColumnClick}>
              {(curYear - 8).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y09" handleClick={handleColumnClick}>
              {(curYear - 9).toString().substring(2)}
          </MyTableWithSort> 
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="y10" handleClick={handleColumnClick}>
              {(curYear - 10).toString().substring(2)}
          </MyTableWithSort> 

          <Table.HeaderCell textAlign="center">
            1
          </Table.HeaderCell>   
          <Table.HeaderCell textAlign="center">
            2
          </Table.HeaderCell>   
          <Table.HeaderCell textAlign="center">
            3
          </Table.HeaderCell>   
          <Table.HeaderCell textAlign="center">
            4
          </Table.HeaderCell>   

         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis.map((x => {
            return (
                <Table.Row key={x.source_Number} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">
                      <DrawLink number={x.source_Number} title=""></DrawLink>
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.row_No}
                    </Table.Cell>
                    <Table.Cell  textAlign="right">{x.total}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.top3}</Table.Cell>
                    <Table.Cell  textAlign="center">
                      <Popup content={_GetRatingContent(x.forecast)}
                       trigger ={<Image avatar src={_GetRating(x.forecast)} />}>
                      </Popup>
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      <NumberStatus status = {x.status} />
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      <NumberStatus status = {x.status_Top3} />
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      {x.awaken}
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      {x.draw_Date}<br/>
                      <Label data-tooltip={'All Interval: ' + x.current_Gap}  
                       circular size={"tiny"} color={"blue"}>{x.current_Gap}</Label>
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      {x.max_Top3_Date}<br/>
                      <Label data-tooltip={'Top3 Interval: ' + x.top3_Current_Gap}  circular size={"tiny"} color={"blue"}>{x.top3_Current_Gap}</Label>
                    </Table.Cell>

                    <Table.Cell  textAlign="center">
                      {x.avg_All}<br/>
                      <Label 
                      data-tooltip={'10 Years Avg: ' + x.avg_Top3 + " (Top3)"}
                       circular size={"tiny"} color={"red"}>{x.avg_Top3}</Label>
                    </Table.Cell>

                    <YearResult result={x.y00} top3={x.t00}></YearResult>
                    <YearResult result={x.y01} top3={x.t01}></YearResult>
                    <YearResult result={x.y02} top3={x.t02}></YearResult>
                    <YearResult result={x.y03} top3={x.t03}></YearResult>
                    <YearResult result={x.y04} top3={x.t04}></YearResult>
                    <YearResult result={x.y05} top3={x.t05}></YearResult>
                    <YearResult result={x.y06} top3={x.t06}></YearResult>
                    <YearResult result={x.y07} top3={x.t07}></YearResult>
                    <YearResult result={x.y08} top3={x.t08}></YearResult>
                    <YearResult result={x.y09} top3={x.t09}></YearResult>
                    <YearResult result={x.y10} top3={x.t10}></YearResult>
                    <Table.Cell textAlign="center">
                    <System3DData data={x.system3D_1}></System3DData>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                    <System3DData data={x.system3D_2}></System3DData>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                    <System3DData data={x.system3D_3}></System3DData>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                    <System3DData data={x.system3D_4}></System3DData>
                    </Table.Cell>

           </Table.Row>
            );
          }))
          }   
           </Table.Body>
      </Table>
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        {analysis && analysis.length > 0 &&
          <Pagination activePage={page} totalPages={analysis[0].totalPage} onPageChange={handlePageChanged}/>
        }
      </div>
   
      </div>
    );
});