import React from "react";
import {  Button, Label, Table } from "semantic-ui-react";
import { history } from "../../..";
import { _DisplayZero } from "../../../app/lib/CommonFunctions";
import { System3DTop3Pattern } from "../../../app/models/IDayResult";


interface Props{
  data: System3DTop3Pattern[],
  drawType: string
}
export default function System3DTop3RankResult({data, drawType} : Props){
  const redirectTo3DPage=(pageNo: string) => {
    history.push("/3DAnalysisTypeAndRange/T/" + pageNo + "/"+ drawType);
  }
  
return (
<Table celled  sortable unstackable className="freeze-head-and-col">
<Table.Header>
<Table.Row>
    <Table.HeaderCell textAlign="center" colSpan="18">
      System 3D Rank Trend (Top3)
    </Table.HeaderCell>
  </Table.Row>
  <Table.Row>
    <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('1-6')} data-tooltip="Click here to get 1-6 rank 3D">1-6</Button> 
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('7-12')} data-tooltip="Click here to get 7-12 rank 3D">7-12</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('13-18')} data-tooltip="Click here to get 13-18 rank 3D">13-18</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('19-24')} data-tooltip="Click here to get 19-24 rank 3D">19-24</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('25-30')} data-tooltip="Click here to get 25-30 rank 3D">25-30</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
      <Button type="button" onClick={()=>redirectTo3DPage('31-36')} data-tooltip="Click here to get 31-36 rank 3D">31-36</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('37-99')} data-tooltip="Click here to get Over 36 rank 3D">Over 36</Button>
    </Table.HeaderCell>

    </Table.Row>
</Table.Header>

<Table.Body>
    {data.map((x,i) => {
        if (i === 0){
             const total = x.a1_6 + x.a7_12 + x.a13_18 + x.a19_24 + x.a25_30 + x.a31_36 + x.over_36;
        
             if (total === 0){
               return (
                <Table.Row key={0}>
                  <Table.Cell colSpan={10}>
                    No Data
                  </Table.Cell>
                </Table.Row>

               );
             }
             return (
              <Table.Row key={i}>
                  <Table.Cell  textAlign="center">
                  {x.drawDate}
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a1_6}</Label>
                    <Label>{(x.a1_6 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a7_12}</Label>
                    <Label>{(x.a7_12 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                      <Label circular color="blue">{x.a13_18}</Label>
                      <Label>{(x.a13_18 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                      <Label circular color="blue">{x.a19_24}</Label>
                      <Label>{(x.a19_24 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a25_30}</Label>
                    <Label>{(x.a25_30 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a31_36}</Label>
                    <Label>{(x.a31_36 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.over_36}</Label>
                    <Label>{(x.over_36 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
              </Table.Row>
            );
          }
        return (
            <Table.Row key={i}>
                <Table.Cell  textAlign="center">
                {x.drawDate}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a1_6)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a7_12)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a13_18)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a19_24)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a25_30)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.a31_36)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.over_36)}
                </Table.Cell>
            </Table.Row>
          );
    })}
</Table.Body>
</Table>
)
}