import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { DropdownProps, Grid,    Header,    Label,   Table } from 'semantic-ui-react'
import agent from "../../../app/api/agent";
import { _DrawTypeOptionsWithAll, _PrizeTypeOptions } from "../../../app/lib/CommonData";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import { Top3RankHistory} from "../../../app/models/IDayResult";


export default observer(function Top3RankHistory(){
    const [data, setData] = useState<Top3RankHistory[]>([]);
    const [prizeCode, setPrizeCode] = useState('A');
    const [drawType, setDrawType] = useState("A");
    const [noOfDraw, setNoOfDraw] = useState(50);
    const [loading, setLoading] = useState(true);

    const noOfDrawOptions = [
        {
            key: '25',
            text: '25 Draws',
            value: 25
        },
          
        {
          key: '50',
          text: '50 Draws',
          value: 50
        },
        {
            key: '100',
            text: '100 Draws',
            value: 100
        },
        {
            key: '200',
            text: '200 Draws',
            value: 200
        },
    ]

    const noOfDrawChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setNoOfDraw(parseInt(option.value+""));
        setLoading(true);
    }

    const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setDrawType(option.value + "");
         setLoading(true);
        
    }

    const prizeCodeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setPrizeCode(option.value + "");
        setLoading(true);
   }

    useEffect(()=>{

        agent.Draw4DResult.getTop3RankHistory(drawType, noOfDraw, prizeCode).then((response)=>{

            setData(response);
        }).finally(()=>{
            setLoading(false);
        });

        
    },[drawType, noOfDraw, prizeCode]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <>
            <Grid>
            <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={8} computer={4}>
              <MyDropDown onChange={prizeCodeChanged} placeholder="Select Prize Type" 
                toolTip="Select Prize Type" zIndex="21"
                options={_PrizeTypeOptions} defaultValue = {prizeCode}>
              </MyDropDown>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" mobile={8} tablet={8} computer={4}>
                <MyDropDown onChange={noOfDrawChanged} placeholder="Select # of Draw" 
                toolTip="Select # Draws" zIndex="21"
                options={noOfDrawOptions} defaultValue = {noOfDraw}>
                </MyDropDown>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={8} computer={4}>
              <MyDropDown onChange={drawTypeChanged} placeholder="Select Draw Type"  zIndex="20"
                toolTip="Select Draw Type"
                options={_DrawTypeOptionsWithAll} defaultValue = {drawType}>
              </MyDropDown>
            </Grid.Column>

            <Grid.Column verticalAlign="middle" mobile={8} tablet={8} computer={4}>
                    4D Top3 Order History
            </Grid.Column>
            </Grid>
        <div style={{overflowX:"auto"}}>
        <Table celled  sortable unstackable className="freeze-head-and-col">
        <Table.Row>
          <Table.HeaderCell textAlign="center"></Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan="20">Hit# Order for 10 Years (Top3)</Table.HeaderCell>
         </Table.Row>

        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">1</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">2</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">3</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">4</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">5</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">6</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">7</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">8</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">9</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">10</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">11</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">12</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">13</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">14</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">15</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">16</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">17</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">18</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">19</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">20</Table.HeaderCell>
            </Table.Row>
        </Table.Header>

        <Table.Body>

          {data.map((x,i) => {
             if( i === 0 ){
                 var total = x.a1+x.a2+x.a3+x.a4+x.a5+x.a6 + x.a7 + x.a8 + x.a9 + x.a10 +
                            x.a11+ x.a12 + x.a13 + x.a14 + x.a15 + x.a16 + x.a17 + x.a18 + x.a19 + x.a20;

                 var a1 =( (x.a1 / total) * 100.0).toFixed(0) + ' %';
                 var a2 = ((x.a2 / total) * 100.0 ).toFixed(0) +' %';
                 var a3 = ((x.a3 / total) * 100.0).toFixed(0) + '%';
                 var a4 = ((x.a4 / total) * 100.0).toFixed(0) +' %';
                 var a5 = ((x.a5 / total) * 100.0).toFixed(0) + '%';
                 var a6 = ((x.a6 / total) * 100.0).toFixed(0) + '%';
                 var a7 = ((x.a7 / total) * 100.0).toFixed(0) + '%';
                 var a8 = ((x.a8 / total) * 100.0).toFixed(0) + '%';
                 var a9 = ((x.a9 / total) * 100.0).toFixed(0) + '%';
                 var a10 = ((x.a10 / total) * 100.0).toFixed(0) + '%';
                 var a11 = ((x.a11 / total) * 100.0).toFixed(0) + '%';
                 var a12 = ((x.a12 / total) * 100.0).toFixed(0) + '%';
                 var a13 = ((x.a13 / total) * 100.0).toFixed(0) + '%';
                 var a14 = ((x.a14 / total) * 100.0).toFixed(0) + '%';
                 var a15 = ((x.a15 / total) * 100.0).toFixed(0) + '%';
                 var a16 = ((x.a16 / total) * 100.0).toFixed(0) + '%';
                 var a17 = ((x.a17 / total) * 100.0).toFixed(0) + '%';
                 var a18 = ((x.a18 / total) * 100.0).toFixed(0) + '%';
                 var a19 = ((x.a19 / total) * 100.0).toFixed(0) + '%';
                 var a20 = ((x.a20 / total) * 100.0).toFixed(0) + '%';
                 
                 if (total === 0){
                    return <Header textAlign="center">No Result</Header>
                }

                return (
                    <Table.Row key={i}>
                        <Table.Cell  textAlign="right">Hit/% Summary</Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a1}</Label>
                        <Label >{a1}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a2}</Label>
                        <Label >{a2}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a3}</Label>
                        <Label >{a3}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a4}</Label>
                        <Label >{a4}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a5}</Label>
                        <Label >{a5}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a6}</Label>
                        <Label >{a6}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a7}</Label>
                        <Label >{a7}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a8}</Label>
                        <Label >{a8}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a9}</Label>
                        <Label >{a9}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a10}</Label>
                        <Label >{a10}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a11}</Label>
                        <Label >{a11}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a12}</Label>
                        <Label >{a12}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a13}</Label>
                        <Label >{a13}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a14}</Label>
                        <Label >{a14}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a15}</Label>
                        <Label >{a15}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a16}</Label>
                        <Label >{a16}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a17}</Label>
                        <Label >{a17}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a18}</Label>
                        <Label >{a18}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a19}</Label>
                        <Label >{a19}</Label>
                        </Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{x.a20}</Label>
                        <Label >{a20}</Label>
                        </Table.Cell>

                    </Table.Row>
                );
             }
            
            return (
                    <Table.Row key={i}>
                        <Table.Cell  textAlign="right">{x.draw_Date}</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a1 > 0 ? x.a1 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a2 > 0 ? x.a2 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a3 > 0 ? x.a3 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a4 > 0 ? x.a4 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a5 > 0 ? x.a5 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a6 > 0 ? x.a6 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a7 > 0 ? x.a7 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a8 > 0 ? x.a8 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a9 > 0 ? x.a9 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a10 > 0 ? x.a10 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a11 > 0 ? x.a11 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a12 > 0 ? x.a12 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a13 > 0 ? x.a13 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a14 > 0 ? x.a14 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a15 > 0 ? x.a15 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a16 > 0 ? x.a16 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a17 > 0 ? x.a17 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a18 > 0 ? x.a18 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a19 > 0 ? x.a19 : "" }</Table.Cell>
                        <Table.Cell  textAlign="right">{x.a20 > 0 ? x.a20 : "" }</Table.Cell>

                    </Table.Row>
            );
          }  
          )} 
           </Table.Body>
      </Table>
      </div>
      </>
    );
});