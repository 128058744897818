import React  from "react";
import { Embed } from "semantic-ui-react";

export default function HelpWatchList(){
    return (
         <div style={{overflowX:"auto"}}>
        <Embed url="https://winforever.net/webinar/Watch-AlertList-Tutorial.mp4" placeholder="/assets/watchlist_placeholder.png">
        </Embed>

         </div>
    );
}