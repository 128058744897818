import { observer } from "mobx-react-lite";
import React, {   useEffect, useState } from "react";
import { Button, Image, Popup, Table } from "semantic-ui-react";
import agent from "../../../app/api/agent";
import {  QueryConditionResult, QueryScoreResult } from "../../../app/models/IDayResult";

import DrawLink from "../../../app/lib/drawlink";
import NumberStatus from "../../../app/lib/NumberStatus";
import MyTableWithSort from "../../../app/lib/MyTableWithSort";
import { useStore } from "../../../app/store/store";
import { _GetRating, _GetRatingContent, _NewGetDataFromLocalStorage} from "../../../app/lib/CommonFunctions";
import ScoringCondition from "./ScoringCondition";



export default observer(function ScoringNumbers(){
    const {modalStore} = useStore();
    const [refresh, setRefresh] = useState(false);
    const [queryData, setQueryData] = useState(_NewGetDataFromLocalStorage("ScoringNumberCondition",""));
    


    const handleRefresh = ()=>{
        setQueryData(_NewGetDataFromLocalStorage("ScoringNumberCondition", ""));

        setRefresh(!refresh);
    };



    const queryClick = () => {
        modalStore.openModal(<ScoringCondition></ScoringCondition>, "Scoring Condition","fullscreen", null,()=>{handleRefresh()})
    }

  
    const handleColumnClick = (columnName : string)=>{
        if (column !== columnName){
          setDirection("ascending");
        }
        else{
          if (direction === undefined){
            setDirection('ascending');
          }
          else{
            setDirection(direction === "ascending" ? "descending" : "ascending");
          }
        }
        setColumn(columnName);
      }
  

    const [data, setData] = useState<QueryScoreResult[]>([]);
    const [column, setColumn] = useState("Score");
    const [direction, setDirection] = useState<any>("descending");

    useEffect(()=>{
        
        if (!queryData || queryData.length === 0){
            return;
        }

        const queryCondition: QueryConditionResult = JSON.parse(queryData);

        const newDirection = direction === "ascending" ? " ASC " : direction === "descending" ? " DESC " : "";
        queryCondition.direction = newDirection;
        queryCondition.columnName = column;
        
        agent.Draw4DResult.postScoringNumber(queryCondition).then((response)=>{
            setData(response);
        }).finally(()=>{
        
        });

    }, [queryData, column, direction]);

    return(
        <div style={{"overflow":"auto"}}>
    <Table celled  sortable unstackable className="freeze-head-and-col">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan="25">
                        <Button icon="search" positive content="Query" type="button" onClick={()=>queryClick()} fluid></Button>

                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Source_Number" handleClick={handleColumnClick}>
                            System<br/>Number
                    </MyTableWithSort>
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="score" handleClick={handleColumnClick}>
                            Score
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="rating" handleClick={handleColumnClick}>
                            Rating
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="status" handleClick={handleColumnClick}>
                            System<br/>State
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Status_Top3" handleClick={handleColumnClick}>
                            Top3<br/>State
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="System_Interval_Min" handleClick={handleColumnClick}>
                            System<br/>Interval (Min)
                    </MyTableWithSort> 

                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="System_Interval" handleClick={handleColumnClick}>
                            System<br/>Interval
                    </MyTableWithSort> 

                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="System_Interval_Max" handleClick={handleColumnClick}>
                            System<br/>Interval (Max)
                    </MyTableWithSort> 

                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="System_Interval_Min" handleClick={handleColumnClick}>
                            Top3<br/>Interval (Min)
                    </MyTableWithSort> 

                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="System_Top3_Interval" handleClick={handleColumnClick}>
                            Top3<br/>Interval
                    </MyTableWithSort> 

                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="System_Interval_Max" handleClick={handleColumnClick}>
                            Top3<br/>Interval (Max)
                    </MyTableWithSort> 

                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Rank_All" handleClick={handleColumnClick}>
                            All<br/>Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Rank_Top3" handleClick={handleColumnClick}>
                            Top3<br/>Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Short_Term_System" handleClick={handleColumnClick}>
                            Short Term<br/>System Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Middle_Term_System" handleClick={handleColumnClick}>
                            Medium Term<br/>System Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Long_Term_System" handleClick={handleColumnClick}>
                            Long Term<br/>System Rank
                    </MyTableWithSort> 
                    
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Short_Term_Top3" handleClick={handleColumnClick}>
                            Short Term<br/>Top3 Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Middle_Term_Top3" handleClick={handleColumnClick}>
                            Medium Term<br/>Top3 Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Long_Term_Top3" handleClick={handleColumnClick}>
                            Long Term<br/>Top3 Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Month_Rank" handleClick={handleColumnClick}>
                            Month<br/>Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Month_Rank_Top3" handleClick={handleColumnClick}>
                            Top3 Month<br/>Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Sum3D" handleClick={handleColumnClick}>
                            Sum<br/>3D All
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Sum3D_Top3" handleClick={handleColumnClick}>
                            Sum<br/>3D Top3
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Sum3D" handleClick={handleColumnClick}>
                            Sum<br/>2D All
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Sum3D_Top3" handleClick={handleColumnClick}>
                            Sum<br/>2D Top3
                    </MyTableWithSort> 

                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data && data.length > 0 &&
                    data.map((x, index)=>{
                        return(
                            <Table.Row key={index}>
                                <Table.Cell textAlign="center">
                                    <DrawLink number = {x.source_Number}>
                                    </DrawLink>
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.score}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    <Popup content={_GetRatingContent(x.rating)}
                                    trigger ={<Image avatar src={_GetRating(x.rating)} />}>
                                    </Popup>
                                </Table.Cell>

                                <Table.Cell  textAlign="center">
                                    <NumberStatus status = {x.status} />
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    <NumberStatus status = {x.status_Top3} />
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.system_Interval_Min}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.system_Interval}<br/>
                                    [{ (((x.system_Interval - x.system_Interval_Min) / (x.system_Interval_Max - x.system_Interval_Min)) * 100.0).toFixed(0) + '%]'  }
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.system_Interval_Max}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.top3_Interval_Min}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.system_Top3_Interval}
                                    <br/>
                                    [{(((x.system_Top3_Interval - x.top3_Interval_Min) / (x.top3_Interval_Max - x.top3_Interval_Min)) * 100.0).toFixed(0) + '%]'}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.top3_Interval_Max}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.rank_All}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.rank_Top3}
                                </Table.Cell>

                                <Table.Cell  textAlign="center">
                                    {x.short_Term_System} %
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.middle_Term_System} %
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.long_Term_System}%
                                </Table.Cell>

                                <Table.Cell  textAlign="center">
                                    {x.short_Term_Top3} %
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.middle_Term_Top3} %
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.long_Term_Top3} %
                                </Table.Cell>

                                <Table.Cell  textAlign="center">
                                    {x.month_Rank}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.month_Rank_Top3}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.sum3D}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.sum3D_Top3}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.sum2D}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.sum2D_Top3}
                                </Table.Cell>

                            </Table.Row>
                        )
                    })
                }
            </Table.Body>
    </Table>
    </div>
    
    );    
   
}
)