import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Container } from "semantic-ui-react";
import { Helmet } from 'react-helmet';

const Testimonial = () => {
    return (
        <div>
            <Helmet>
                <title>Members Testimonials</title>
                <meta name="description" content="4DGM has garnered high praise from its users for its advanced algorithm-based predictions and user-friendly interface. Members highlight how the platform's detailed analysis and accurate forecasts have significantly enhanced their lottery strategies and outcomes. Many appreciate the innovative approach and valuable insights provided by 4DGrandMaster, making it an essential tool for serious lottery enthusiasts looking to improve their chances of winning. The positive feedback underscores the platform's effectiveness and impact on users' lottery experiences." />
            </Helmet>
            <Container text>
                <Carousel interval={3000} showThumbs={false} showIndicators={false} autoPlay={true} infiniteLoop={true} showArrows={true}>
                    <div>
                        <img src="assets/testimonial/testimonial1.png" alt="testimonial1" />
                        <p className="legend">
                            <h1>I strike 2nd Prize on 5171 won $10,000.00!</h1>
                        </p>
                    </div>
                    <div>
                        <img src="assets/testimonial/testimonial2.png" alt="testimonial2" />
                        <p className="legend">
                            <h1>I strike 1st Prize on 8198 won $4,000.00!</h1>
                        </p>
                    </div>
                    <div>
                        <img src="assets/testimonial/testimonial3.png" alt="testimonial3" />
                        <p className="legend">
                            <h1>I strike 1st Prize on 0149 won $2,000.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial4.png" alt="testimonial4" />
                        <p className="legend">
                            <h1>I strike 1st Prize on 3895 won $2,000.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial5.png" alt="testimonial5" />
                        <p className="legend">
                            <h1>I strike 2nd Prize on 4135 won $8,000.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial6.png" alt="testimonial6" />
                        <p className="legend">
                            <h1>I strike Starter Prize on 5566 won $2,746.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial7.png" alt="testimonial7" />
                        <p className="legend">
                            <h1>I strike 3rd Prize on 5479 won $1,960.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial8.png" alt="testimonial8" />
                        <p className="legend">
                            <h1>I strike 1st Prize on 3895 won $2,000.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial9.png" alt="testimonial9" />
                        <p className="legend">
                            <h1>I strike 1st Prize on 2922 won $4,000.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial10.png" alt="testimonial10" />
                        <p className="legend">
                            <h1>I strike 1st Prize on 2312 won $4,000.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial11.png" alt="testimonial11" />
                        <p className="legend">
                            <h1>I strike 3rd Prize on 5525 won $1,470.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial12.png" alt="testimonial12" />
                        <p className="legend">
                            <h1>I strike 1st Prize on 1634 won $10,000.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial13.png" alt="testimonial13" />
                        <p className="legend">
                            <h1>I strike a total of $57,750.00! (2022-2023)</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial14.png" alt="testimonial14" />
                        <p className="legend">
                            <h1>I strike 3rd prize on 8318 won $16,250.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial15.png" alt="testimonial15" />
                        <p className="legend">
                            <h1>I strike Starter on 1010 won $4,000.00!</h1>
                        </p>
                    </div>

                    <div>
                        <img src="assets/testimonial/testimonial16.png" alt="testimonial16" />
                        <p className="legend">
                            <h1>I strike 1st prize on 9335 won $2,000.00!</h1>
                        </p>
                    </div>

                </Carousel>
            </Container>
        </div>
    );
};

export default Testimonial;
