import { useField } from "formik";
import React from "react";
import { Form,  Input,  Label } from "semantic-ui-react";

interface Props {
    children?: never[];
    name: string;
    placeholder: string;
    label?: string;
    type?: string;
    maxLength?: number;
}
export default function MyTextInputNew(props: Props){
    const [field, meta] = useField(props.name);

    return (
        <Form.Field inline error={meta.touched && !!meta.error}>
            <Input fluid {...field} {...props}  maxLength={props.maxLength} />
            {meta.touched && meta.error ? <Label basic color='red'>{meta.error}</Label> : null
            } 
        </Form.Field>
    );
}