import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useRef, useState } from "react"
import { useEffect } from "react";
import {  DropdownProps,  Header, Icon, Input,  Pagination,  PaginationProps,  Table } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { RAnalysisResult } from "../../app/models/IDayResult";
import {  _DrawType3DOptions, _PrizeTypeOptions, _RowPerPageOptions} from "../../app/lib/CommonData";
import MyDropDown from "../../app/lib/MyDropDown";
import { useStore } from "../../app/store/store";
import MyTableWithSort from "../../app/lib/MyTableWithSort";

export default observer(function AnalysisWithR(){
  const [loading, setLoading] = useState(true);
  const [drawType, setDrawType] = useState("ABC");
  const [system3D, setSystem3D] = useState("");
  const [prizeType, setPrizeType] = useState("A");
  const {userStore} = useStore();
  const [column, setColumn] = useState("hit_Count");
  const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const [data, setData] = useState<RAnalysisResult[]>([]);
  const system3DRef = useRef<any>();

  const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
    setPage(1);
    setDrawType(option.value + "");
    setLoading(true);
  }

  const handlePrizeTypeSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
    setPage(1);
    setPrizeType(option.value + "");
    setLoading(true);
  }

  const handlePageSizeDropDown = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
    setPage(1);
    setPageSize(parseInt(option.value + ""));
    setLoading(true);
  }

  const handlePageChanged = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps)=>{
    setPage(parseInt(data.activePage!.toString()));
    setLoading(true);

  }

  
  const handleColumnClick = (columnName : string)=>{
    if (column !== columnName){
      setDirection("ascending");
    }
    else{
      if (direction === undefined){
        setDirection('ascending');
      }
      else{
        setDirection(direction === "ascending" ? "descending" : "ascending");
      }
    }
    setColumn(columnName);
    setPage(1);
    setLoading(true);

  }

  const handleSearch = () => {
    if(system3DRef.current){
      const system3DInput = system3DRef.current.inputRef.current.value;

      if (system3DInput.length === 3 && parseInt(system3DInput) >= 0){
        setSystem3D(system3DInput);
      }
      else{
        setSystem3D("NA");
      }
      setLoading(true);
      setPage(1);
    }
  }


  useEffect(()=>{

    if (system3D.length >=1 && system3D.length <= 2){
      setLoading(false);
      setData([]);
    }
    else{
      let newDirection = "none";
      if (direction !== undefined){
        if (direction === "ascending"){
          newDirection = "asc";
        }
        else{
          newDirection = "desc";
        }
      }

      agent.Draw4DResult.getRAnalysisResult(drawType, (system3D.length === 0 ? "NA":system3D), prizeType, page, column, newDirection, pageSize).then((response)=>{

          setData(response);
          setLoading(false);
          
      });
    }

  },[drawType, system3D, prizeType, direction, column, page, pageSize]);



  if(loading)
  {
    return (<LoadingComponent></LoadingComponent>)
  }

  if(!userStore.isLogged){
    return(<Header>Not Authorized !</Header>)
  }

  return (
    <div style={{overflowX:"auto"}}>
      <Table celled  sortable unstackable className="freeze-head-and-col">
      <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan="5" textAlign="center">
        <Header>4D Analysis with Roll</Header>
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>
          <MyDropDown zIndex="20" placeholder='Select Prize Type' toolTip="Select Prize Type" toolTipDirection="top center" 
                          onChange={handlePrizeTypeSelect} options={_PrizeTypeOptions} defaultValue={prizeType}>
          </MyDropDown>
        </Table.HeaderCell>
        <Table.HeaderCell colSpan="2">
          <MyDropDown zIndex="20" placeholder='Select Draw Type' toolTip="Select Draw Type" toolTipDirection="top center" 
                          onChange={handleDropDownSelect} options={_DrawType3DOptions} defaultValue={drawType}>
          </MyDropDown>

        </Table.HeaderCell>
        <Table.HeaderCell >
          <MyDropDown zIndex="20" placeholder='Select Page Size' toolTip="Select Page Size" toolTipDirection="top center" 
                          onChange={handlePageSizeDropDown} options={_RowPerPageOptions} defaultValue={pageSize}>
          </MyDropDown>

        </Table.HeaderCell>
        <Table.HeaderCell>
          <Input ref={system3DRef}  defaultValue={system3D}
                icon={<Icon name='search' link onClick={handleSearch}/>}
                        data-tooltip="Enter System 3D Number to filter the list"
                        data-position="bottom center"
                        fluid  maxLength={3} focus placeholder='Limit the list base on System 3D'></Input>
        </Table.HeaderCell>
                
      </Table.Row>
      <Table.Row>
        <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="r_Number" handleClick={handleColumnClick}>
            System Roll 4D
        </MyTableWithSort> 

        <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="hit_Count" handleClick={handleColumnClick}>
            # Hit
        </MyTableWithSort> 
        <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="system_3D" handleClick={handleColumnClick}>
            System 3D
        </MyTableWithSort> 

        <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="last_Date" handleClick={handleColumnClick}>
            Last Drawn<br/>Date
        </MyTableWithSort> 
        
        <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="e_WinBox" handleClick={handleColumnClick}>
            eWinBox
        </MyTableWithSort> 
        
      </Table.Row>
         
      </Table.Header>
      <Table.Body>
        {
           data.length > 0 && data.map((x, idx)=>{
            return(<Table.Row key={x.r_Number}>
                <Table.Cell textAlign="center">
                  #{(idx + 1) + (page - 1) * pageSize}&nbsp;:&nbsp; 
                  {x.r_Number}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {x.hit_Count}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {x.system_3D}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {x.last_Date}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {x.e_WinBox}
                </Table.Cell>
            </Table.Row>);

           })
        }
      </Table.Body>
      </Table>
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        {data && data.length > 0 &&
          <Pagination activePage={page} totalPages={data[0].totalPage} onPageChange={handlePageChanged}/>
        }
      </div>
    </div>
  );

});