import React from "react";
import { Embed } from "semantic-ui-react";

export default function HelpWatchList(){
    return (
         <div style={{overflowX:"auto"}}>
        <Embed url="Demo 2D3D4D video.mp4" placeholder="/assets/watchlist_placeholder.png">
        </Embed>

         </div>
    );
}