import {  Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button } from "semantic-ui-react";
import * as Yup from 'yup';
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { useStore } from "../../app/store/store";

export default observer(function DeleteMember(){
    const {userStore} = useStore();

    const validationSchema = Yup.object({
        email: Yup.string().email().required("Email is required or Invalid Email"),
    });

    return (
        <Formik initialValues={{ email: "",  deviceID: "", error: null}}
                onSubmit={
                    (values,{setErrors}) => userStore.deleteMember(values).catch(err => setErrors({error: 'Unable to register to the System'}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid name="email" placeholder="Email"></MyTextInputNonFluid>
                        <Button color="red" style={{marginTop:"10px"}} icon="delete" loading={isSubmitting}  content="Delete" type="submit" fluid></Button>

                    </Form>
                
            )}
        </Formik>
    )
})