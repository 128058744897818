import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import {DropdownProps, Grid,    Header,    Label,      Table } from 'semantic-ui-react'
import { history } from "../../..";
import agent from "../../../app/api/agent";
import {  _DrawTypeOptionsWithAll, _NoOfDrawOptions, _PrizeTypeOptions, _StatusType } from "../../../app/lib/CommonData";
import { _DisplayPercentage, _DisplayZero} from "../../../app/lib/CommonFunctions";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import NumberStatus from "../../../app/lib/NumberStatus";
import { StatusHistory } from "../../../app/models/IDayResult";
import { useStore } from "../../../app/store/store";


export default observer(function StatusHistory(){
    const [data, setData] = useState<StatusHistory[]>([]);
    const [drawType, setDrawType] = useState("A");
    const [prizeType, setPrizeType] = useState("T");
    const [noOfDraw, setNoOfDraw] = useState(36);
    const [loading, setLoading] = useState(true);
    const [statusType, setStatusType] = useState("1");

    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
    }

    const statusTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setStatusType(option.value+"");
        setLoading(true);
    }

    const noOfDrawChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setNoOfDraw(parseInt(option.value+""));
        setLoading(true);
    }

    const handlePrizeTypeSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setPrizeType(option.value + "");
        setLoading(true);
       
   }

    const handleDrawTypeSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setDrawType(option.value + "");
         setLoading(true);
        
    }

   
    useEffect(()=>{

        agent.Draw4DResult.getStatusHistory(prizeType, noOfDraw, statusType, drawType).then((response)=>{

            setData(response);
        }).finally(()=>{
            setLoading(false);
        });

        
    },[drawType, noOfDraw,  prizeType, statusType]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
            <Grid>
            <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={5} computer={5}>
              <MyDropDown zIndex="21" onChange={handlePrizeTypeSelect} placeholder="Select Prize Type" 
                toolTip="Select Prize Type"
                options={_PrizeTypeOptions} defaultValue = {prizeType}>
              </MyDropDown>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" mobile={8} tablet={5} computer={5}>
                <MyDropDown zIndex="21" onChange={noOfDrawChanged} placeholder="Select # of Draw" 
                toolTip="Select # Draws"
                options={_NoOfDrawOptions} defaultValue = {noOfDraw}>
                </MyDropDown>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={6} computer={6}>
              <MyDropDown zIndex="21" onChange={handleDrawTypeSelect} placeholder="Select Draw Type" 
                toolTip="Select Draw Type"
                options={_DrawTypeOptionsWithAll} defaultValue = {drawType}>
              </MyDropDown>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" mobile={8} tablet={10} computer={10}>
                <MyDropDown zIndex="20" onChange={statusTypeChanged} placeholder="Select Forecast Type" 
                toolTip="Select Forecast Type"
                options={_StatusType} defaultValue = {statusType}>
                </MyDropDown>
            </Grid.Column>            
            <Grid.Column verticalAlign="middle" textAlign="center" mobile={8} tablet={6} computer={6}>
                    Status History
            </Grid.Column>
            </Grid>
       

        <Table celled  sortable unstackable className="freeze-head-and-col">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
            {(statusType === "2" || statusType==="3") && <NumberStatus status={"1"}></NumberStatus>
            }   
            {(statusType === "1") && 
            <><NumberStatus status={"1"}></NumberStatus> 
            <NumberStatus status={"1"}></NumberStatus>
            </>
            }   
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
            {(statusType === "2" || statusType==="3") && <NumberStatus status={"2"}></NumberStatus>
            }   
            {(statusType === "1") && 
            <><NumberStatus status={"1"}></NumberStatus> 
            <NumberStatus status={"2"}></NumberStatus>
            </>
            }   
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
            {(statusType === "2" || statusType==="3") && <NumberStatus status={"3"}></NumberStatus>
            }   
            {(statusType === "1") && 
            <><NumberStatus status={"1"}></NumberStatus> 
            <NumberStatus status={"3"}></NumberStatus>
            </>
            }   

            </Table.HeaderCell>
            {statusType === "1" &&
            <Table.HeaderCell textAlign="center">
            <><NumberStatus status={"2"}></NumberStatus> 
            <NumberStatus status={"1"}></NumberStatus>
            </>
            </Table.HeaderCell>
            }
            {statusType === "1" &&
            <Table.HeaderCell textAlign="center">
            <><NumberStatus status={"2"}></NumberStatus> 
            <NumberStatus status={"2"}></NumberStatus>
            </>
            </Table.HeaderCell>
            }
            {statusType === "1" &&
            <Table.HeaderCell textAlign="center">
            <><NumberStatus status={"2"}></NumberStatus> 
            <NumberStatus status={"3"}></NumberStatus>
            </>
            </Table.HeaderCell>
            }
            {statusType === "1" &&
            <Table.HeaderCell textAlign="center">
            <><NumberStatus status={"3"}></NumberStatus> 
            <NumberStatus status={"1"}></NumberStatus>
            </>
            </Table.HeaderCell>
            }
            {statusType === "1" &&
            <Table.HeaderCell textAlign="center">
            <><NumberStatus status={"3"}></NumberStatus> 
            <NumberStatus status={"2"}></NumberStatus>
            </>
            </Table.HeaderCell>
            }
            {statusType === "1" &&
            <Table.HeaderCell textAlign="center">
            <><NumberStatus status={"3"}></NumberStatus> 
            <NumberStatus status={"3"}></NumberStatus>
            </>
            </Table.HeaderCell>
            }
            </Table.Row>
        </Table.Header>

        <Table.Body>

          {data.map((x,i) => {
             if( i === 0 ){
                 var total = x.status1 + x.status2 + x.status3 + x.status4+ x.status5 +
                                x.status6 + x.status7 + x.status8 + x.status9;

                 if (total === 0){
                    return <Header textAlign="center">No Result</Header>
                }

                return (
                    <Table.Row key={i}>
                        <Table.Cell  textAlign="right">{i === 0 ? "Hit/% Summary" : x.draw_Date}</Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{_DisplayZero(x.status1)}</Label>
                        <Label >{_DisplayPercentage(x.status1, total, 0)}</Label></Table.Cell>
                        
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.status2)}</Label>
                        <Label >{_DisplayPercentage(x.status2, total, 0)}
                        </Label></Table.Cell>
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.status3)}</Label>
                        <Label >{_DisplayPercentage(x.status3, total, 0)}
                        </Label></Table.Cell>
                        {statusType === "1" &&
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.status4)}</Label>
                        <Label >{_DisplayPercentage(x.status4, total, 0)}
                        </Label></Table.Cell>
                        }
                        {statusType === "1" &&
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.status5)}</Label>
                        <Label >{_DisplayPercentage(x.status5, total, 0)}
                        </Label></Table.Cell>
                        }
                        {statusType === "1" &&
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.status6)}</Label>
                        <Label >{_DisplayPercentage(x.status6, total, 0)}
                        </Label></Table.Cell>
                        }
                        {statusType === "1" &&
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.status7)}</Label>
                        <Label >{_DisplayPercentage(x.status7, total, 0)}
                        </Label></Table.Cell>
                        }
                        {statusType === "1" &&
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.status8)}</Label>
                        <Label >{_DisplayPercentage(x.status8, total, 0)}
                        </Label></Table.Cell>
                        }
                        {statusType === "1" &&
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.status9)}</Label>
                        <Label >{_DisplayPercentage(x.status9, total, 0)}
                        </Label></Table.Cell>
                        }
                    </Table.Row>
                );
             }
            
            return (
                    <Table.Row key={i}>
                        <Table.Cell  textAlign="right">{x.draw_Date}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.status1) }</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.status2)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.status3)}</Table.Cell>
                        {statusType === "1" &&
                        <>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.status4)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.status5)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.status6)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.status7)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.status8)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.status9)}</Table.Cell>
                        </>
                        }
                    </Table.Row>
            );
          }  
          )} 
           </Table.Body>
      </Table>
      </div>
    );
});
