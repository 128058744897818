import React, { SyntheticEvent, useEffect, useState } from "react";
import {  Table, Header, DropdownProps } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _DrawType2DOptionsWithAll, _DrawTypeOptions, _StatusOptions } from "../../app/lib/CommonData";
import Draw2DLink from "../../app/lib/draw2dlink";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyDropDown from "../../app/lib/MyDropDown";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import NumberStatus from "../../app/lib/NumberStatus";
import { ForecastSystem2D } from "../../app/models/IDayResult";


export default function WinningBoxSystem2DDialog(){
    const [data, setData] = useState<ForecastSystem2D[]>([]);
    const [drawType, setDrawType] = useState("ABCD");
    const [status, setStatus] = useState("0");
    const [column, setColumn] = useState("Strike_Top3");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [drawType2D, setDrawType2D] = useState("A");
    const [isLoading, setIsLoading] = useState(true);

    const handleColumnClick = (columnName : string)=>{
        if (column !== columnName){
          setDirection("ascending");
        }
        else{
          if (direction === undefined){
            setDirection('ascending');
          }
          else{
            setDirection(direction === "ascending" ? "descending" : "ascending");
          }
        }
        setColumn(columnName);
        setIsLoading(true);
    }
  
    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setDrawType(option.value + "");
        setIsLoading(true);

    }
 
   const handleDropDownSelect2D = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setDrawType2D(option.value + "");
       setIsLoading(true);
   }
   const handleStatusSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
       setStatus(option.value + "");
       setDrawType2D("A");
       setIsLoading(true);
    }

    useEffect(()=>{
        const selectedDate = localStorage.getItem("SelectedDate");
        var tempDirection = "";
        
        if (tempDirection !== undefined){
            tempDirection = direction === "ascending" ? "asc" : "desc";
        }

        const eWinBox = localStorage.getItem("BoxNo");


        agent.Draw4DResult.getWinningBox2D(selectedDate!, drawType, status, column, tempDirection, drawType2D, eWinBox + "").then((response)=>{
            setData(response);
            setIsLoading(false);
        }).catch((err) => setIsLoading(false));

        
    },[status, drawType, column, direction, drawType2D]);

    if (isLoading) {
        return <LoadingComponent content="Loading data. Please wait ..." ></LoadingComponent>
    }
   
    return (
        <div style={{overflow:"auto"}}>
        <Table unstackable celled sortable>

            <Table.Header>
                <Table.Row key="headerCondition">
                    <Table.Cell textAlign="center" colSpan="3">
                        <MyDropDown zIndex="21" placeholder='Select Forecast Draw Type' toolTip="Select Forecast Draw Type" toolTipDirection="top center" 
                            onChange={handleDropDownSelect} options={_DrawTypeOptions} defaultValue={drawType}>
                        </MyDropDown>
                    </Table.Cell>
                    <Table.Cell textAlign="center" colSpan="3">
                        <MyDropDown zIndex="21" placeholder='Select 2D Type' toolTip="Select 2D Type" toolTipDirection="top center" 
                            onChange={handleDropDownSelect2D} options={_DrawType2DOptionsWithAll} defaultValue={drawType2D}>
                        </MyDropDown>
                    </Table.Cell>                    
                    <Table.Cell textAlign="center" colSpan="4">
                        <MyDropDown zIndex="20" placeholder='Select Status' toolTip="Select Status" toolTipDirection="top center" 
                            onChange={handleStatusSelect} options={_StatusOptions} defaultValue={status}>
                        </MyDropDown>
                    </Table.Cell>
                </Table.Row>
                <Table.Row key="header">
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="System_2D" handleClick={handleColumnClick}>
                        System 2D
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Strike" handleClick={handleColumnClick}>
                        Hit All
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Strike_Top3" handleClick={handleColumnClick}>
                        Hit Top3
                    </MyTableWithSort> 

                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="System_Status" handleClick={handleColumnClick}>
                        System Status
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Top3_Status" handleClick={handleColumnClick}>
                        Top3 Status
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="System_Interval" handleClick={handleColumnClick}>
                        System Interval
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Top3_Interval" handleClick={handleColumnClick}>
                        Top3 Interval
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Rank_All" handleClick={handleColumnClick}>
                        All Rank
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Rank_Top3" handleClick={handleColumnClick}>
                        Top3 Rank 
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Month_Rank_All" handleClick={handleColumnClick}>
                    All Rank Monthly
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Month_Rank_Top3" handleClick={handleColumnClick}>
                    Top3 Rank Monthly
                    </MyTableWithSort> 

                    <Table.Cell textAlign="center"><Header as="h4">Forecast 4D</Header></Table.Cell>

                </Table.Row>
            </Table.Header>
            <Table.Body>
            {   data && data.length > 0 &&
                data.map((x,i)=>{
                return (
                    <Table.Row key={i}>
                        <Table.Cell textAlign="center">
                            <Draw2DLink title="" number={x.system_2D}>

                            </Draw2DLink>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.strike}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.strike_Top3}
                        </Table.Cell>                                      
                        <Table.Cell textAlign="center">
                            <NumberStatus status = {x.system_Status} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <NumberStatus status = {x.top3_Status} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.system_Interval}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.top3_Interval}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.rank_All}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.rank_Top3}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.month_Rank_All}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.month_Rank_Top3}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.forecast_System4D}
                        </Table.Cell>

                    </Table.Row>
                )})
            }
            </Table.Body>
        </Table>
        </div>
    );
}