import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {   Grid, Table, TableBody } from "semantic-ui-react";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { Analysis3DResult} from "../../app/models/IDayResult";
import * as Yup from 'yup';
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import NumberStatus from "../../app/lib/NumberStatus";
import MyTextInputButton from "../../app/lib/MyTextInputButton";
import { useStore } from "../../app/store/store";
import { history } from "../..";
import Draw3DLink from "../../app/lib/draw3dlink";


  
export default observer(function View3DFrom2D(){
    let {id} = useParams<{id: string}>();
    const [data, setData] = useState<Analysis3DResult[]>([]);
    const [numberToSearch, setNumberToSearch] = useState(id === null || id === undefined ? "" : id);
    const [loaded, setLoaded] = useState(id === null ||  id === undefined  || id.length === 0 ? true : false);
    const [column, setColumn] = useState("permutated_Number");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("ascending");
    const {userStore, modalStore} = useStore();

    modalStore.closeModal();

    if(!userStore.isLogged){
        history.push("/");
    }

    let formData = {
        numberToInput: numberToSearch
    }

    const handleColumnClick = (columnName : string)=>{
        if (column !== columnName){
          setDirection("ascending");
        }
        else{
          if (direction === undefined){
            setDirection('ascending');
          }
          else{
            setDirection(direction === "ascending" ? "descending" : "ascending");
          }
        }
        setColumn(columnName);
      }

    const validationSchema = Yup.object({
        numberToInput: Yup.string().required("2D Number is required").matches(/^[0-9]{2}$/, 'Must be exactly 2 digits')
    });
    
    useEffect(()=>{
        if (!numberToSearch) return;
        if (numberToSearch.length !== 2) return;

        let newDirection = "";

        if (direction === "ascending"){
            newDirection = "asc";
        }
        if(direction === "descending"){
            newDirection = "desc";
        }


        agent.Draw4DResult.getAnalysis3DFrom2D(numberToSearch, column, newDirection).then((response)=>{
            setData(response);
            setLoaded(true);
        });
    },[column,direction, numberToSearch]);

    if (!loaded){
        return (
            <LoadingComponent></LoadingComponent>
        )
    }
    return (
        <Grid textAlign="center" verticalAlign='top'>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <u>System 2D to System 3D</u>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={14}>
                <Formik 
                    enableReinitialize
                    initialValues = {formData}
                    validationSchema = {validationSchema}
                    validate = {()=>{
                        setNumberToSearch("");
                    }}
                    onSubmit = {(o) => {
                        setLoaded(false);
                        setNumberToSearch(o.numberToInput);
                    }}>
                        {({handleSubmit}) => (
                            <Form className="ui form" onSubmit={handleSubmit} autoComplete="off">
                                <Grid verticalAlign="middle">
                                    <Grid.Row>
                                    <Grid.Column width={16} textAlign="center">
                                        <MyTextInputButton loading={false}
                                                 label="Enter 2D" maxLength={2} name="numberToInput" placeholder="Enter 2D Number to Search" />
                                    </Grid.Column >
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        )}
                </Formik>
                </Grid.Column>
                <Grid.Column width={1}>

                </Grid.Column>
            </Grid.Row> 
            <Grid.Row>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={14} textAlign="center">
                { loaded && data && data.length > 0 &&
                    <div style={{overflowX:"auto"}}>
                        <Table unstackable celled sortable className="freeze-head-and-col">
                        <Table.Header>
                        <Table.Row>
                            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="permutated_number" handleClick={handleColumnClick}>
                                System<br/>3D
                            </MyTableWithSort> 
                            <Table.HeaderCell rowSpan={2} textAlign="center">#</Table.HeaderCell>
                            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="NoOfHit" handleClick={handleColumnClick}>
                                Strike<br/>(ALL)
                            </MyTableWithSort>
                            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="hitCount123" handleClick={handleColumnClick}>
                                Strike<br/>(TOP3)
                            </MyTableWithSort> 
                            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="interval" handleClick={handleColumnClick}>
                                System<br/>Interval
                            </MyTableWithSort> 
                            <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="interval123" handleClick={handleColumnClick}>
                                Top3<br/>Interval
                            </MyTableWithSort> 
                            <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="systemInRange" handleClick={handleColumnClick}>
                                System<br/>In Range
                            </MyTableWithSort>
                            <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="top3Inrange" handleClick={handleColumnClick}>
                                Top3<br/>In Range
                            </MyTableWithSort>
                            <Table.HeaderCell colSpan={4} textAlign="center">
                                Rank (10 Years 3D)
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="All_Rank" handleClick={handleColumnClick}>
                                All<br/>Rank
                            </MyTableWithSort>
                            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Top3_Rank" handleClick={handleColumnClick}>
                                Top3<br/>Rank
                            </MyTableWithSort>
                            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="all_Rank_Month" handleClick={handleColumnClick}>
                                All Rank<br/>By Month
                            </MyTableWithSort>
                            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Top3_Rank_Month" handleClick={handleColumnClick}>
                                Top3<br/>By Month
                            </MyTableWithSort>

                        </Table.Row>
                        </Table.Header>
                        <TableBody>
                        {data && data.length > 0 && data.map((x => {
                            return (
                                <Table.Row key={x.draw_ID}>
                                    <Table.Cell textAlign="center">
                                    <Draw3DLink title = ""  number={x.permutated_Number}></Draw3DLink>
                                    </Table.Cell>

                                    <Table.Cell textAlign="center">{x.draw_ID}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.noOfHit}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.hitCount123}</Table.Cell>

                                    <Table.Cell  textAlign="right">{x.interval}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.interval123}</Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <NumberStatus status={x.systemInRange} />
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <NumberStatus status={x.top3InRange} />
                                    </Table.Cell>
                                    <Table.Cell  textAlign="right">{x.all_Rank}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.top3_Rank}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.all_Rank_Month}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.top3_Rank_Month}</Table.Cell>
                                </Table.Row>
                            )
                        }))}
                        </TableBody>
                        </Table>
                    </div>                        
                }   
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
            </Grid.Row> 
        </Grid>

    );
}
);
