import React from "react";
import { Button } from "semantic-ui-react";
import { history } from "../..";

interface Props{
    range: string;
    code: string;
    drawType: string;
}


export default function RedirectTo4D({range, code, drawType} : Props){
    const redirectTo4DPage=() => {
        if (code === "BS" || code === "BT" || code === "BM" ||  code === "BN"){
            history.push("/4DAnalysisRankTypeAndRange/" + code + "/" + range + "/"+ (drawType ==='A' ? 'ABCD' : drawType) );
            return;
        }
        if(code === "AS" || code === "AT" || code === "CS" || code === "CT" ){
            history.push("/4DAnalysisTypeAndRange/" + code + "/" + range + "/"+ (drawType ==='A' ? 'ABCD' : drawType) );
        }
        else{
            history.push("/4DAnalysisDirectTypeAndRange/" + code + "/" + range + "/"+ (drawType ==='A' ? 'ABCD' : drawType) );
        }    
      }
    
    return (
        <Button type="button" onClick={()=>redirectTo4DPage()} data-tooltip={"Click here to view " + range + " System 4D"}>{range}</Button> 
    );
}