import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { Image, DropdownProps, Grid,    Header,    Label,      Table } from 'semantic-ui-react'
import { history } from "../../..";
import agent from "../../../app/api/agent";
import {  _DrawTypeOptionsWithAll,  _ForecastType, _NoOfDrawOptions, _PrizeTypeOptions } from "../../../app/lib/CommonData";
import { _DisplayPercentage, _DisplayZero, _GetRating, _GetRatingContent } from "../../../app/lib/CommonFunctions";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import {RatingHistory } from "../../../app/models/IDayResult";
import { useStore } from "../../../app/store/store";


export default observer(function RatingHistory(){
    const [data, setData] = useState<RatingHistory[]>([]);
    const [drawType, setDrawType] = useState("A");
    const [prizeType, setPrizeType] = useState("T");
    const [noOfDraw, setNoOfDraw] = useState(36);
    const [loading, setLoading] = useState(true);
    const [forecastTye, setForecastType] = useState("1");

    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
    }

    const forecastTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setForecastType(option.value+"");
        setLoading(true);
    }

    const noOfDrawChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setNoOfDraw(parseInt(option.value+""));
        setLoading(true);
    }

    const handlePrizeTypeSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setPrizeType(option.value + "");
        setLoading(true);
       
   }

    const handleDrawTypeSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setDrawType(option.value + "");
         setLoading(true);
        
    }


    useEffect(()=>{

        agent.Draw4DResult.getRatingHistory(prizeType, noOfDraw, 0, forecastTye, drawType).then((response)=>{

            setData(response);
        }).finally(()=>{
            setLoading(false);
        });

        
    },[drawType, noOfDraw, prizeType, forecastTye]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
            <Grid>
            <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={5} computer={5}>
              <MyDropDown zIndex="21" onChange={handlePrizeTypeSelect} placeholder="Select Prize Type" 
                toolTip="Select Prize Type"
                options={_PrizeTypeOptions} defaultValue = {prizeType}>
              </MyDropDown>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" mobile={8} tablet={5} computer={5}>
                <MyDropDown zIndex="21" onChange={noOfDrawChanged} placeholder="Select # of Draw" 
                toolTip="Select # Draws"
                options={_NoOfDrawOptions} defaultValue = {noOfDraw}>
                </MyDropDown>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={6} computer={6}>
              <MyDropDown zIndex="20" onChange={handleDrawTypeSelect} placeholder="Select Draw Type" 
                toolTip="Select Draw Type"
                options={_DrawTypeOptionsWithAll} defaultValue = {drawType}>
              </MyDropDown>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" mobile={8} tablet={10} computer={10}>
                <MyDropDown zIndex="20" onChange={forecastTypeChanged} placeholder="Select Forecast Type" 
                toolTip="Select Forecast Type"
                options={_ForecastType} defaultValue = {forecastTye}>
                </MyDropDown>
            </Grid.Column>            
            <Grid.Column verticalAlign="middle" textAlign="center" mobile={8} tablet={6} computer={6}>
                    Rating History
            </Grid.Column>
            </Grid>

        <Table celled  sortable unstackable className="freeze-head-and-col">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
                <Image size="mini" centered src={_GetRating(1)}></Image>
                {_GetRatingContent(1)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
                <Image size="mini" centered src={_GetRating(2)}></Image>
                {_GetRatingContent(2)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
                    <Image size="mini" centered src={_GetRating(3)}></Image>
                    {_GetRatingContent(3)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
                <Image size="mini" centered src={_GetRating(4)}></Image>
                {_GetRatingContent(4)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
                    <Image size="mini" centered src={_GetRating(5)}></Image>
                    {_GetRatingContent(5)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
                    <Image size="mini" centered src={_GetRating(6)}></Image>
                    {_GetRatingContent(6)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
                    <Image size="mini" centered src={_GetRating(7)}></Image>
                    {_GetRatingContent(7)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
                <Image size="mini" centered src={_GetRating(8)}></Image>

                    {_GetRatingContent(8)}
            </Table.HeaderCell>
         
            </Table.Row>
        </Table.Header>

        <Table.Body>

          {data.map((x,i) => {
             if( i === 0 ){
                 var total = x.rating1 + x.rating2 + x.rating3 + x.rating4+ x.rating5 +
                                x.rating6 + x.rating7 + x.rating8;

                 if (total === 0){
                    return <Header textAlign="center">No Result</Header>
                }

                return (
                    <Table.Row key={i}>
                        <Table.Cell  textAlign="right">{i === 0 ? "Hit/% Summary" : x.draw_Date}</Table.Cell>
                        <Table.Cell  textAlign="center">
                        <Label circular color="blue">{_DisplayZero(x.rating1)}</Label>
                        <Label >{_DisplayPercentage(x.rating1, total, 0)}</Label></Table.Cell>
                        
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.rating2)}</Label>
                        <Label >{_DisplayPercentage(x.rating2, total, 0)}
                        </Label></Table.Cell>
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.rating3)}</Label>
                        <Label >{_DisplayPercentage(x.rating3, total, 0)}
                        </Label></Table.Cell>
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.rating4)}</Label>
                        <Label >{_DisplayPercentage(x.rating4, total, 0)}
                        </Label></Table.Cell>
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.rating5)}</Label>
                        <Label >{_DisplayPercentage(x.rating5, total, 0)}
                        </Label></Table.Cell>
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.rating6)}</Label>
                        <Label >{_DisplayPercentage(x.rating6, total, 0)}
                        </Label></Table.Cell>
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.rating7)}</Label>
                        <Label >{_DisplayPercentage(x.rating7, total, 0)}
                        </Label></Table.Cell>
                        <Table.Cell  textAlign="center"><Label circular color="blue">{_DisplayZero(x.rating8)}</Label>
                        <Label >{_DisplayPercentage(x.rating8, total, 0)}
                        </Label></Table.Cell>

                    </Table.Row>
                );
             }
            
            return (
                    <Table.Row key={i}>
                        <Table.Cell  textAlign="right">{x.draw_Date}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.rating1) }</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.rating2)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.rating3)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.rating4)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.rating5)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.rating6)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.rating7)}</Table.Cell>
                        <Table.Cell  textAlign="right">{_DisplayZero(x.rating8)}</Table.Cell>
                    </Table.Row>
            );
          }  
          )} 
           </Table.Body>
      </Table>
      </div>
    );
});
