import { observer } from "mobx-react-lite";
import React, { useEffect, useState }  from "react";
import { Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import { UserList } from "../../app/models/IDayResult";

export default observer(function ListUser(){
    const [column, setColumn] = useState<string>("Email");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [data, setData] = useState<UserList[]>([]);
    const [loading, setLoading] = useState(true);

    const handleColumnClick = (columnName : string)=>{
        if(columnName === column){
            if(direction === "ascending"){
                setDirection("descending");
            }
            else{
                setDirection("ascending");
            }
        }
        else{
            setDirection("ascending");
        }
        setColumn(columnName);
    };


    useEffect(()=>{
        var newDirection: string;

        newDirection = "";

        if (direction === "ascending"){
            newDirection = "ASC";
        }
       
        if (direction ==="descending"){
            newDirection = "DESC";
        }
       
        agent.Account.getUserList(column, newDirection).then((response)=>{

            setData(response);
        }).finally(()=>{
            setLoading(false);
        });

        
    },[column, direction]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
        <div style={{overflowX:"auto"}}>
        <Table unstackable celled sortable className="freeze-head-and-col">
             <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell colSpan="6" textAlign="center">
                        List of Users
                    </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                    <Table.Cell textAlign="center">
                                S/N
                    </Table.Cell>   
                    <MyTableWithSort textAlign="center" column={column} columnName="Email" handleClick={handleColumnClick} direction={direction}>
                                Email
                    </MyTableWithSort>
                    <MyTableWithSort textAlign="center" column={column} columnName="DisplayName" handleClick={handleColumnClick} direction={direction}>
                                Display Name
                    </MyTableWithSort>
                    <MyTableWithSort textAlign="center" column={column} columnName="GroupID" handleClick={handleColumnClick} direction={direction}>
                                Subscription Type
                    </MyTableWithSort>

                    <MyTableWithSort textAlign="center" column={column} columnName="PhoneNumber" handleClick={handleColumnClick} direction={direction}>
                               Phone Number
                    </MyTableWithSort>

                    <MyTableWithSort textAlign="center" column={column} columnName="ExpiredDate" handleClick={handleColumnClick} direction={direction}>
                                Expired Date
                    </MyTableWithSort>
                    <MyTableWithSort textAlign="center" column={column} columnName="Price" handleClick={handleColumnClick} direction={direction}>
                                Price
                    </MyTableWithSort>

                    <MyTableWithSort textAlign="center" column={column} columnName="Remark" handleClick={handleColumnClick} direction={direction}>
                                Remark
                    </MyTableWithSort>

                    </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    data.map( 
                    (x, i)=>{
                            return(
                        <Table.Row key={i}>
                            <Table.Cell textAlign="center"> {i + 1}
                            </Table.Cell>

                            <Table.Cell textAlign="center"> {x.email}
                            </Table.Cell>
                            <Table.Cell textAlign="center"> {x.displayName}
                            </Table.Cell>

                            <Table.Cell textAlign="center"> {x.userType}
                            </Table.Cell>

                            <Table.Cell textAlign="center"> {x.phoneNumber}
                            </Table.Cell>
                            <Table.Cell textAlign="center"> {x.expiredDate}
                            </Table.Cell>
                            <Table.Cell textAlign="center"> {x.price}
                            </Table.Cell>
    
                            <Table.Cell textAlign="center"> {x.remark}
                            </Table.Cell>
   
                        </Table.Row>
                    )
                })
            }
            </Table.Body>         
        </Table>
        </div>
  
    );
});