import React, { SyntheticEvent, useEffect, useState } from "react";
import { DropdownItemProps, DropdownProps, Grid, Table, Image } from "semantic-ui-react";
import agent from "../../../app/api/agent";
import {_DrawTypeOptionsWithAll, _StatusOptions } from "../../../app/lib/CommonData";
import { _GetImage } from "../../../app/lib/CommonFunctions";
import DrawLink from "../../../app/lib/drawlink";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import MyTableWithSort from "../../../app/lib/MyTableWithSort";
import NumberStatus from "../../../app/lib/NumberStatus";
import { MonthDayResult } from "../../../app/models/IDayResult";


export default function QueryByMonthDay(){
    const curDate = new Date();

    const [month, setMonth] = useState(curDate.getMonth() + 1);
    const [loading, setLoading] = useState(true);
    const [day, setDay] = useState(curDate.getDay() === 0 ? 1 : curDate.getDay() > 3 ? 7 : 4);
    const [dayPart, setDayPart] = useState(curDate.getDate());
   
    const [prize, setPrize] = useState("T");
    const [data, setData] = useState<MonthDayResult[]>([]);
    const [column, setColumn] = useState("System_Interval");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("0");
    const [drawType, setDrawType] = useState("ABCD");



    const monthOptions = [
        {
            key: '1',
            text: 'Jan',
            value: 1
        },
          
        {
          key: '2',
          text: 'Feb',
          value: 2
        },
        {
            key: '3',
            text: 'Mar',
            value: 3
        },
        {
            key: '4',
            text: 'Apr',
            value: 4
        },
        {
            key: '5',
            text: 'May',
            value: 5
        },
        {
            key: '6',
            text: 'Jun',
            value: 6
        },
        {
            key: '7',
            text: 'Jal',
            value: 7
        },
          
        {
          key: '8',
          text: 'Aug',
          value: 8
        },
        {
            key: '9',
            text: 'Sep',
            value: 9
        },
        {
            key: '10',
            text: 'Oct',
            value: 10
        },
        {
            key: '11',
            text: 'Nov',
            value: 11
        },
        {
            key: '12',
            text: 'Dec',
            value: 12
        }
    ]
    const monthChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setMonth(parseInt(option.value+""));
        setLoading(true);
    }

    const dayOptions = [
        {
            key: '4',
            text: 'Wed',
            value: 4
        },
          
        {
          key: '7',
          text: 'Sat',
          value: 7
        },
        {
            key: '1',
            text: 'Sun',
            value: 1
        }
    ]



    const dayChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setDay(parseInt(option.value+""));
        setLoading(true);
    }
    const prizeTypeOptions = [
        {
            key: 'T',
            text: 'Top3',
            value: 'T'
        },
        {
          key: 'TS',
          text: 'Top3 + Starter',
          value: 'TS'
        }
    ]

    const prizeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setPrize(option.value+"");
        setLoading(true);
    }

    const dayPartChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setDayPart(parseInt(option.value+""));
        setLoading(true);
    }

    const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setDrawType(option.value+"");
        setLoading(true);
    }

    const statusChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setStatus(option.value+"");
        setLoading(true);
    }

    let dayPartOptions:DropdownItemProps[] = [];

    for(var i = 1; i<=31;i++){
        dayPartOptions.push({
            key: i.toString(),
            text: i.toString(),
            value: i,
        })
    }


    const handleColumnClick = (columnName : string)=>{
        if (column !== columnName){
          setDirection("ascending");
        }
        else{
          if (direction === undefined){
            setDirection('ascending');
          }
          else{
            setDirection(direction === "ascending" ? "descending" : "ascending");
          }
        }
        setColumn(columnName);
        setPage(1);
    }

  
  
    useEffect(()=>{
        let newDirection = "";

        if (direction === "ascending"){
            newDirection = "asc";
        }

        if (direction === "descending"){
            newDirection = "desc";
        }

        agent.Draw4DResult.getMonthDayResult(month, day, prize, dayPart, drawType, status, column, newDirection).then((response)=>{
            setData(response);
        }).finally(()=>{
            setLoading(false);
        });

        
    },[column, direction, page, month, day, prize, dayPart, drawType, status]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }

    return (
        <div style={{overflowX:"auto"}}>
            <Grid centered columns={3} padded={false}>
                <Grid.Row>
                <Grid.Column>
                    <MyDropDown onChange={monthChanged} placeholder="Select Month"  zIndex="21"
                        toolTip="Select Month"
                        options={monthOptions} defaultValue = {month}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column>
                    <MyDropDown onChange={dayChanged} placeholder="Select Day of Week" zIndex="21"
                        toolTip="Select Day of Week"
                        options={dayOptions} defaultValue = {day}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column>
                    <MyDropDown onChange={prizeChanged} placeholder="Select Prize Type" zIndex="21"
                        toolTip="Select Prize Type"
                        options={prizeTypeOptions} defaultValue = {prize}>
                    </MyDropDown>
                </Grid.Column>
                </Grid.Row> 
                <Grid.Row>
                <Grid.Column>
                    <MyDropDown onChange={dayPartChanged} placeholder="Select Day"  zIndex="20"
                        toolTip="Select Day"
                        options={dayPartOptions} defaultValue = {dayPart}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column>
                    <MyDropDown onChange={drawTypeChanged} placeholder="Select Draw Type" zIndex="20"
                        toolTip="Select Draw Type"
                        options={_DrawTypeOptionsWithAll} defaultValue = {drawType}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column>
                    <MyDropDown onChange={statusChanged} placeholder="Select Number State"  zIndex="20"
                        toolTip="Select Number State"
                        options={_StatusOptions} defaultValue = {status}>
                    </MyDropDown>
                </Grid.Column>
                </Grid.Row>
            </Grid>

        <Table celled  sortable unstackable className="freeze-head-and-col" style={{minHeight: 600}}>
            <Table.Header>
            <Table.Row>
            <MyTableWithSort   textAlign="center" direction={direction} column={column} columnName="system_number" handleClick={handleColumnClick}>
              System<br/>Number
            </MyTableWithSort> 
            <MyTableWithSort   textAlign="center" direction={direction} column={column} columnName="prize_type" handleClick={handleColumnClick}>
              Prize<br/>Type
            </MyTableWithSort> 

            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Status" handleClick={handleColumnClick}>
              Status
            </MyTableWithSort> 
            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="System_Interval" handleClick={handleColumnClick}>
              System Interval All
            </MyTableWithSort> 

            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="System_Interval_Top3" handleClick={handleColumnClick}>
              System Interval Top3
            </MyTableWithSort> 

            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Month_Rank" handleClick={handleColumnClick}>
              Month Rank All
            </MyTableWithSort> 
            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Month_Top3_Rank" handleClick={handleColumnClick}>
              Month Rank Top3
            </MyTableWithSort> 
            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="All_Rank" handleClick={handleColumnClick}>
              All Prize Rank
            </MyTableWithSort> 
            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Top3_Rank" handleClick={handleColumnClick}>
              Top3 Prize Rank
            </MyTableWithSort> 
            <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="System_3DInterval" handleClick={handleColumnClick}>
              System3D Top3 Interval
            </MyTableWithSort> 
            </Table.Row>
            </Table.Header>
            <Table.Body>
            {data && data.length > 0 &&
                data.map((x)=>{
                    return(
                        <Table.Row>
                            <Table.Cell textAlign="center">
                              <DrawLink number = {x.system_Number}>
                              </DrawLink>
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Image centered  src={_GetImage(x.prize_Code)} size="mini"></Image>
                            </Table.Cell>

                            <Table.Cell textAlign="center">
                                <NumberStatus status = {x.status} />
                            </Table.Cell >
                            <Table.Cell textAlign="center">
                                {x.system_Interval}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {x.system_Interval_Top3}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {x.month_Rank}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {x.month_Top3_Rank}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {x.all_Rank}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {x.top3_Rank}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {x.system_3DInterval}
                            </Table.Cell>
                        </Table.Row>
                    );
                })
            }
  
            </Table.Body>
        </Table>
        </div>
    )
}