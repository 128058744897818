import React from "react";
import { Button, List } from "semantic-ui-react";
import { useStore } from "../../app/store/store";

export default function ShowHelp(){
    const {modalStore} = useStore();

    return(
        <div>
        <List>
            <List.Header>Interval Icons</List.Header>
             <List.Item><span style={{ color:'green' }}>Green</span> = Min Interval</List.Item>
            <List.Item><span style={{ color:'yellow' }}>Yellow</span> = Avg Interval</List.Item>
            <List.Item><span style={{ color:'red' }}>Red</span> = Max Interval</List.Item>
            <List.Item><span style={{  color:'blue' }}>Blue</span> = Current Interval</List.Item>
        </List>
        <p>3 Possible states:</p>
        <List ordered>
             <List.Item><span style={{ color:'green' }}>&quot;InRange&quot;</span> - Once the blue icon (Current Interval) is within the range of the Min &amp;
Max Interval (Green icon &amp; Red icon), it is <span style={{ color:'green' }}>&quot;InRange&quot;</span> means recommended &quot;Buy&quot;.</List.Item>
            <List.Item><span style={{ color:'orange' }}>&quot;NotDue&quot;</span> - If Blue icon is before the Green icon, it is <span style={{ color:'orange' }}>&quot;NotDue&quot;</span>, means &quot;Stay&quot;
(Don&#39;t buy yet).</List.Item>
            <List.Item><span style={{ color:'red' }}>&quot;OverDue&quot;</span> - If the Blue icon interval is beyond the Red icon, it is <span style={{ color:'red' }}>&quot;OverDue&quot;</span>. It
means &quot;Hold&quot; (Don&#39;t buy yet. Wait until it is &quot;awaken&quot; by a hit). Then consider Buy.</List.Item>
        </List>
        <Button fluid icon="close" content="Close" positive onClick={()=>{
            modalStore.closeModal();
        }}/>
        </div>
    );
}