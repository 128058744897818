import React from "react";
import { Link } from "react-router-dom";
import { Header, } from "semantic-ui-react";
import { useStore } from "../store/store";

interface Props{
    number: string;
    title?: string;
    children?: any
}
export default function DrawLink(props: Props){
    
    const {userStore} = useStore();
  
    return (
        
        <Header as="h3" data-tooltip={userStore.isLogged ? "Click to Search the number" : "Login to use the functions !"}>
        {props.title}&nbsp;
        <Link to= {(!userStore.isLogged ? '#' :
                            "/search/" + props.number)}>
                {props.number}
        </Link>
        </Header>
    );
}