import { useEffect, useState } from "react";
import { eWinningBoxResult } from "../../app/models/IDayResult";
import agent from "../../app/api/agent";
import { Header, Table } from "semantic-ui-react";
import { _GetPrizeName } from "../../app/lib/CommonFunctions";



export default function EWinningBoxResultDialog(){
    const [data, setData] = useState<eWinningBoxResult[]>([]);
    const [startBox, setStartBox] = useState<number>(1);

    
    useEffect(()=>{
        const selectedDate = localStorage.getItem("SelectedDate");
        const resultType = localStorage.getItem("ResultType");

        if (resultType === "Daily") {
            setStartBox(5);
            agent.Draw4DResult.getDailyEWinBoxDrawResult(selectedDate!).then((response) => {
                setData(response);
            });
        }
        else {
            setStartBox(1);
            agent.Draw4DResult.getMonthlyEWinBoxDrawResult(selectedDate!).then((response) => {
                setData(response);
            });
        }
    },[]);
    
    if (data.length === 0){
        return (<Header>No Data</Header>)
    }
    return (
        <div style={{overflow:"auto", fontSize:"18px"}}>
        <Table unstackable celled>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell textAlign="center">
                    #
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                    Direct Number
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                    Date
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                    Prize
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                    Recommended?
                </Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>
        <Table.Row>
            <Table.Cell colSpan="5"  textAlign="center">
                            <Header as="h3">e-WinBox Detail Result for Box: {startBox}</Header>
            </Table.Cell>    
        </Table.Row>

        {
            data.filter(y => y.box === startBox).map((x,i) => (
                <Table.Row key={i}>
                    <Table.Cell textAlign="center">{i + 1}</Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.directNumber}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.drawDate}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {_GetPrizeName(x.prizeCode)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.isDirect === "Y" ? "Yes" : "No"}
                    </Table.Cell>
                </Table.Row>
            ))
        }

        <Table.Row>
            <Table.Cell colSpan="5"  textAlign="center">
                            <Header as="h3">e-WinBox Detail Result for Box: {startBox + 1}</Header>
            </Table.Cell>    
        </Table.Row>

        {
                        data.filter(y => y.box === startBox + 1).map((x,i) => (
                <Table.Row key={i}>
                    <Table.Cell textAlign="center">{i + 1}</Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.directNumber}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.drawDate}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {_GetPrizeName(x.prizeCode)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.isDirect === "Y" ? "Yes" : "No"}
                    </Table.Cell>
                </Table.Row>
            ))
        }

        <Table.Row>
            <Table.Cell colSpan="5"  textAlign="center">
                            <Header as="h3">e-WinBox Detail Result for Box: {startBox+2}</Header>
            </Table.Cell>    
        </Table.Row>

        {
                        data.filter(y => y.box === startBox + 2).map((x,i) => (
                <Table.Row key={i}>
                    <Table.Cell textAlign="center">{i + 1}</Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.directNumber}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.drawDate}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {_GetPrizeName(x.prizeCode)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.isDirect === "Y" ? "Yes" : "No"}
                    </Table.Cell>
                </Table.Row>
            ))
        }
        <Table.Row>
            <Table.Cell colSpan="5"  textAlign="center">
                            <Header as="h3">e-WinBox Detail Result for Box: {startBox + 3}</Header>
            </Table.Cell>    
        </Table.Row>

        {
                        data.filter(y => y.box === startBox + 3).map((x,i) => (
                <Table.Row key={i}>
                    <Table.Cell textAlign="center">{i + 1}</Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.directNumber}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.drawDate}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {_GetPrizeName(x.prizeCode)}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {x.isDirect === "Y" ? "Yes" : "No"}
                    </Table.Cell>
                </Table.Row>
            ))
        }

        </Table.Body>

        </Table>

        </div>
    );
}