import { useField } from "formik";
import React from "react";
import {  Dropdown, DropdownItemProps, DropdownProps, Label, Popup } from "semantic-ui-react";


interface Props{
    onAdd:  (e: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => void;
    children: never[];
    placeholder: string;
    options: DropdownItemProps[];
    zIndex?: string;
    toolTip?: string;
    toolTipDirection?:"top center" | undefined ;
    name: string;
}

export default function MyDropDownSearchMultipleFormik(props: Props){
    const [field, meta, helper] = useField(props.name);

    return(
        <>
            <Popup content={props.toolTip} position={props.toolTipDirection} trigger={
                <Dropdown  fluid   search allowAdditions  scrolling
                    multiple selection  
                    style={{zIndex:props.zIndex}}
                    onChange  = {(e: any, {name, value})=>
                    {
                        helper.setValue(value);
                        helper.setTouched(true);                    
                    }}

                    onAddItem={props.onAdd}
                    placeholder={props.placeholder}
                    options={props.options}
                    value = {field.value}
                    direction = "right"
                />     
            }>
             {meta.touched && meta.error ? <Label basic color='red'>{meta.error}</Label> : null
                } 
               
            </Popup>
           
        </>
    )
}