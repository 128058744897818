import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Button,    Label } from "semantic-ui-react";
import * as Yup from 'yup';
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { useStore } from "../../app/store/store";
import {Form as reactForm} from "semantic-ui-react";
import MyCheckBox from "../../app/lib/MyCheckBox";

export default observer(function Registration(){
    const {userStore} = useStore();

    const validationSchema = Yup.object({
        userName: Yup.string().required("User Name is required"), 
        email: Yup.string().email().required("Email is required or Invalid Email"),
        password: Yup.string().required("Password is required"),
        displayName:  Yup.string().required("Display Name is required"),
        expiryDate: Yup.string().required("Expired Date is required"),
        price: Yup.number().required("Price is required !").positive(),
        noOfDevice: Yup.number().required("Number Of Device is required").min(1, "Minimum is 1 device !")
    });

    const defDate = moment().add(1,"y").format("DD/MM/yyyy")
    return (
        <Formik initialValues={{price: 0, group: false, remark: "", noOfDevice:2 , phoneNo: "", userName: "", email: "", password:"", displayName: "",  expiryDate: defDate, deviceID: "", error: null}}
                onSubmit={
                    (values,{setErrors}) => userStore.register(values).catch(err => setErrors({error: 'Unable to register to the System'}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid name="userName" placeholder="User Name"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="displayName" placeholder="Display Name"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="email" placeholder="Email"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="phoneNo" placeholder="Phone Number of Member"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="password" placeholder="Password"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="expiryDate" placeholder="Expiry Date. Format: dd/MM/yyyy"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="price" placeholder="Enter price that member paid"></MyTextInputNonFluid>
                        <MyTextInputNonFluid maxLength={2} type="number" name="noOfDevice" placeholder="Limit to Number ot Device"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="remark" placeholder="Enter Remark"></MyTextInputNonFluid>
                        <reactForm.Field>
                        <MyCheckBox name="group" label="Basic Membership?"></MyCheckBox>
                        </reactForm.Field>
                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button style={{marginTop:"10px"}} icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                        
                    </Form>
                
            )}
        </Formik>
    )
})