import React, { useEffect, useState } from "react";
import { Accordion, Card,   CardHeader,  Header, Label } from "semantic-ui-react";
import agent from "../api/agent";
import { PersonalForecastNumber } from "../models/IDayResult";
import { useStore } from "../store/store";


export default function PersonalForecast(){
    const {userStore} = useStore();    
    const [data, setData] = useState<PersonalForecastNumber[]>([]);
    const [isActive, setActive] = useState(false);
    
    const toggleShow = () =>{
        setActive(!isActive);
    }

    useEffect(()=>{
        agent.Draw4DResult.getForecastByUser(userStore.user?.username!).then((response)=>{
            setData(response);
            //isLoading = false;
        });

    },[userStore.user?.username]);
  
    if(!userStore.isLogged || data.length === 0){
        return(
            <>
            </>
        );    
        
    }

    return (
        <Accordion fluid styled key="mainAccordPersonalForecast">
        <Accordion.Title onClick={()=>toggleShow()} key="personalForecastTitle">
        <Header><u>{isActive ? "": "Click here for "}
            Weekly Forecast Numbers</u></Header>
        </Accordion.Title>
        <Accordion.Content active={isActive}>
        <Card.Group  itemsPerRow={window.innerWidth <= 768 ? 2: 4} key="mainGroup">
            {
                data.map((x,i) =>{
                    return(
                        <div key={i}>
                        <Card raised color="blue" key={data[i].system_ABCD+"_" + i}>
                            <CardHeader>
                            <div style={{paddingLeft:10}}>
                            <Label color="blue"  ribbon>
                                {data[i].system_ABCD}
                            </Label>
                            </div>
                            </CardHeader>
                            <Card.Content>
                                <p>{data[i].direct_ABCD}</p>
                            </Card.Content>
                        </Card>

                        <Card raised color="green" key={data[i].system_AABC +"_" + i}>
                            <CardHeader>
                            <div style={{paddingLeft:10}}>
                            <Label color="green"  ribbon>
                                {data[i].system_AABC}
                            </Label>
                            </div>
                            </CardHeader>
                            <Card.Content>
                                <p>{data[i].direct_AABC}</p>
                            </Card.Content>
                        </Card>

                        <Card raised color="yellow" key={data[i].system_AABB + "_" + i}>
                            <CardHeader>
                            <div style={{paddingLeft:10}}>
                            <Label color="yellow"  ribbon>
                                {data[i].system_AABB}
                            </Label>
                            </div>
                            </CardHeader>
                            <Card.Content>
                                <p>{data[i].direct_AABB}</p>
                            </Card.Content>
                        </Card>

                        <Card raised color="red" key={data[i].system_AAAB + "_" + i}>
                            <CardHeader>
                            <div style={{paddingLeft:10}}>
                            <Label color="red"  ribbon>
                                {data[i].system_AAAB}
                            </Label>
                            </div>
                            </CardHeader>
                            <Card.Content>
                                <p>{data[i].direct_AAAB}</p>
                            </Card.Content>
                        </Card>

                        </div>
                    )
                })
            }
        </Card.Group>
        </Accordion.Content>
        </Accordion>
    )
}