import { ErrorMessage, Form, Formik} from "formik";
import React, {useEffect,  useRef,  useState } from "react";
import { Button,  DropdownProps, Grid, Label } from "semantic-ui-react";
import * as Yup from 'yup';
import {     _DrawType3DOptionsWithAll, _DrawTypeOptionsWithAll, _RatingOptionStrings,  _StatusOptions } from "../../../app/lib/CommonData";
import {  _NewGetDataFromLocalStorage, _NewSetDataToLocalStorage } from "../../../app/lib/CommonFunctions";
import { MyDropDownValueString, QueryConditionResult } from "../../../app/models/IDayResult";
import { useStore } from "../../../app/store/store";
import agent from "../../../app/api/agent";
import MyDropDownFormik from "../../../app/lib/MyDropDownFormik";
import MyDropDownSearchMultipleFormik from "../../../app/lib/MyDropDownSearchMultipleFormik";
import MyDropDownSearchMultipleFormikNoAdd from "../../../app/lib/MyDropDownSearchMultipleFormikNoAdd";
import MyInputSwitchNew from "../../../app/lib/MyInputSwitchNew";

function checkFromToIsValid(data: string){

    if (!data || data.length === 0){
        return false;
    }


    const splitData = data.split(',');
    let ok = true;

    splitData.map((x)=>{

        if (!ok) return false;


        const fromTo = x.split('-');
        if (fromTo.length === 1){
            if (isNaN(parseInt(fromTo[0]))){
                ok = false;
                return true;
            }
        }
        if (fromTo.length === 2){
            if (isNaN(parseInt(fromTo[0]))){
                ok = false;
                return true;
            }

            if (isNaN(parseInt(fromTo[1]))){
                ok = false;
                return true;
            }

            const from = parseInt(fromTo[0]);
            const to = parseInt(fromTo[1]);

            if (from > to){
                ok = false;
                return true;
            }
        }
        if (fromTo.length > 2) ok = false;
        return true;
    });
    
    return ok;

}
export default function QueryCondition(){
    function parseNumbers(data: string){
        var tempData: MyDropDownValueString[] = [];
        
        if (!data || data.length === 0) return tempData;

        data.split(',').map((x) =>{
            tempData.push({
                value: x,
                text: x
            });    

            return true;
        });

        return tempData;
    }

    function getData(data: string){
        var tempData:string[] = [];
        
        if (!data || data.length === 0) return tempData;

        data.split(',').map((x) =>{
            tempData.push(x);    

            return true;
        });

        return tempData;
    }
    const {modalStore} = useStore();

    const formRef = useRef<any>();

    const defaultValues: QueryConditionResult = {
        queryResultConditionID: 0,
        drawType: 'A',
        drawType3D: 'A',
        numbersToFilter: '',
        error: null,
        tempNumberFilter: [],
        is_System: false,
        sum3D: '',
        useSum3D: false,

        sum3DTop3: '',
        useSum3DTop3: false,

        useRankAll: false,
        rankAll: '',
        useRankTop3: false,
        rankTop3: '',

        useSystemInterval: false,
        systemInterval: '',
        useTop3Interval: false,
        top3Interval: '',

        useMonthRank: false,
        monthRank: '',

        fromMonthRank: 0,
        toMonthRank: 9999,

        useMonthRankTop3: false,
        monthRankTop3: '',

        fromMonthRankTop3: 0,
        toMonthRankTop3:0,

        rating: 0,

        fromRankAll: 0,
        toRankAll: 9999,

        fromRankTop3: 0,
        toRankTop3: 9999,

        fromSystemInterval: 0,
        toSystemInterval: 9999,

        fromTop3Interval: 0,
        toTop3Interval: 9999,

        system3DStatus: '0',
        system4DStatus: '0',

        columnName : "",
        direction: "",

        useLongTerm: true,
        longTermSystem: "50-100",

        useLongTermTop3: false,
        longTermTop3: "",

        useMiddleTerm: true,
        middleTermSystem: "50-100",

        useMiddleTermTop3: false,
        middleTermTop3: "",

        useShortTerm: true,
        shortTermSystem:"50-100",
        useShortTermTop3: false,
        shortTermTop3:"",

        fromLongTerm:0,
        fromLongTermTop3: 0,
        fromMiddleTerm: 0,
        fromMiddleTermTop3: 0,
        fromShortTerm: 0,
        fromShortTermTop3: 0,

        toLongTerm: 100,
        toLongTermTop3: 100,
        toMiddleTermTop3: 100,
        toMiddleTerm: 100,
        toShortTerm: 100,
        toShortTermTop3: 100,

        ratings: '5,6,7,8',

        tempRating: [],

        useSystem3DInterval: false,
        system3DInterval: "",

        useTop33DInterval:  false,
        top33DInterval: "",

        monthRank3D: '',
        monthRankTop33D: '',
        
        useMonthRankTop33D: false,
        useMonthRank3D: false,

        rankAll3D: '',
        rankTop33D: '',

        useRankAll3D: false,
        useRankTop33D: false,

        useSum2D: false,
        useSum2DTop3: false,

        sum2D: '',
        sum2DTop3: '',

        useSum4DNumbers: false,
        sum4DNumbers: ''

    };
    

    const handleNumbersAdd = (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) =>{
        setNumbers( [{text: data.value as string, value: data.value as string},...numbers as MyDropDownValueString[]]);
    }

    const queryData = _NewGetDataFromLocalStorage("QueryNumberCondition","");
    
    const initValues: QueryConditionResult = queryData && queryData.length > 0 ? JSON.parse(queryData) : defaultValues;
    
    const [numbers, setNumbers] =  useState<MyDropDownValueString[]>(parseNumbers(initValues.numbersToFilter));
    initValues.tempNumberFilter = getData(initValues.numbersToFilter);
    initValues.tempRating = getData(initValues.ratings);

    const [refresh, setRefresh] = useState(false);

    const setParameters = () =>{
        agent.Draw4DResult.getQueryNumberParameter(formRef.current.values["drawType"]).then((response)=>{
            const data = JSON.parse(JSON.stringify(response));
            Object.keys(data).map((key) =>{
                formRef.current.values[key] = data[key];
                return true;
            });

            formRef.current.values["tempNumberFilter"] = getData(response.numbersToFilter);
            formRef.current.values["tempRating"] = getData(response.ratings);
                 
            setNumbers(parseNumbers(response.numbersToFilter));
            setRefresh(true);

        });
        
    };

    const validationSchema = Yup.object({
        tempRating: Yup.array().test(function(value: any){
            const data = value as string[];

            if (!value || !data || data.length === 0){
                return this.createError({
                    message: `Rating is required !`,
                    path: 'tempRating', // Fieldname
                  });
            }
            return true;
        }),
        sum3D: Yup.string().test(function(value:any){
            const {useSum3D} = this.parent;

            if (!value && useSum3D){
                return this.createError({
                    message: "Sum 3D is required (format: 9-9 or 9,9) !",
                    path: "sum3D"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Sum 3D is invalid (format: 9-9 or 9,9) !",
                path: "sum3D"
            });

        }),

        sum3DTop3: Yup.string().test(function(value:any){
            const {useSum3DTop3} = this.parent;

            if (!value && useSum3DTop3){
                return this.createError({
                    message: "Sum 3D Top3 is required (format: 9-9 or 9,9) !",
                    path: "sum3DTop3"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Sum 3D Top3 is invalid (format: 9-9 or 9,9) !",
                path: "sum3DTop3"
            });

        }),
        systemInterval: Yup.string().test(function(value:any){
            const {useSystemInterval} = this.parent;

            if (!value && useSystemInterval){
                return this.createError({
                    message: "System Interval is required (format: 9-9 or 9,9) !",
                    path: "systemInterval"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "System Interval is invalid (format: 9-9 or 9,9) !",
                path: "systemInterval"
            });

        }),
        top3Interval: Yup.string().test(function(value:any){
            const {useTop3Interval} = this.parent;

            if (!value && useTop3Interval){
                return this.createError({
                    message: "Top3 Interval is required (format: 9-9 or 9,9) !",
                    path: "top3Interval"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Top3 Interval is invalid (format: 9-9 or 9,9) !",
                path: "top3Interval"
            });

        }),
        rankAll:Yup.string().test(function(value:any){
            const {useRankAll} = this.parent;

            if (!value && useRankAll){
                return this.createError({
                    message: "Rank All is required (format: 9-9 or 9,9) !",
                    path: "rankAll"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Rank All is invalid (format: 9-9 or 9,9) !",
                path: "rankAll"
            });

        }),
        rankTop3:Yup.string().test(function(value:any){
            const {useRankTop3} = this.parent;

            if (!value && useRankTop3){
                return this.createError({
                    message: "Rank Top3 is required (format: 9-9 or 9,9) !",
                    path: "rankTop3"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Rank Top3 is invalid (format: 9-9 or 9,9) !",
                path: "rankTop3"
            });

        }),
        monthRank:Yup.string().test(function(value:any){
            const {useMonthRank} = this.parent;

            if (!value && useMonthRank){
                return this.createError({
                    message: "Month Rank All is required (format: 9-9 or 9,9) !",
                    path: "monthRank"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Month Rank All is invalid (format: 9-9 or 9,9) !",
                path: "monthRank"
            });

        }),
        monthRankTop3:Yup.string().test(function(value:any){
            const {useMonthRankTop3} = this.parent;

            if (!value && useMonthRankTop3){
                return this.createError({
                    message: "Month Rank Top3 is required (format: 9-9 or 9,9) !",
                    path: "monthRankTop3"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Month Rank Top3 is invalid (format: 9-9 or 9,9) !",
                path: "monthRankTop3"
            });

        }),
        shortTermSystem:Yup.string().test(function(value:any){
            const {useShortTerm} = this.parent;

            if (!value && useShortTerm){
                return this.createError({
                    message: "Shor Term System is required (format: 0-100) !",
                    path: "shortTermSystem"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Short Term System is invalid (format: 0-100) !",
                path: "shortTermSystem"
            });

        }),
        middleTermSystem:Yup.string().test(function(value:any){
            const {useMiddleTerm} = this.parent;

            if (!value && useMiddleTerm){
                return this.createError({
                    message: "Middle Term System is required (format: 0-100) !",
                    path: "middleTermSystem"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Middle Term System is invalid (format: 0-100) !",
                path: "middleTermSystem"
            });

        }),
        longTermSystem:Yup.string().test(function(value:any){
            const {useLongTerm} = this.parent;

            if (!value && useLongTerm){
                return this.createError({
                    message: "Long Term System is required (format: 0-100) !",
                    path: "longTermSystem"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Long Term System is invalid (format: 0-100) !",
                path: "longTermSystem"
            });

        }),
        
        shortTermTop3:Yup.string().test(function(value:any){
            const {useShortTermTop3} = this.parent;

            if (!value && useShortTermTop3){
                return this.createError({
                    message: "Shor Term Top3 is required (format: 0-100) !",
                    path: "shortTermTop3"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Short Term Top3 is invalid (format: 0-100) !",
                path: "shortTermTop3"
            });

        }),

        middleTermTop3:Yup.string().test(function(value:any){
            const {useMiddleTermTop3} = this.parent;

            if (!value && useMiddleTermTop3){
                return this.createError({
                    message: "Middle Term Top3 is required (format: 0-100) !",
                    path: "middleTermTop3"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Middle Term Top3 is invalid (format: 0-100) !",
                path: "middleTermTop3"
            });

        }),
        longTermTop3:Yup.string().test(function(value:any){
            const {useLongTermTop3} = this.parent;

            if (!value && useLongTermTop3){
                return this.createError({
                    message: "Long Term Top3 is required (format: 0-100) !",
                    path: "longTermTop3"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Long Term Top3 is invalid (format: 0-100) !",
                path: "longTermTop3"
            });

        }),
        system3DInterval: Yup.string().test(function(value:any){
            const {useSystem3DInterval} = this.parent;

            if (!value && useSystem3DInterval){
                return this.createError({
                    message: "System Interval 3D is required (format: 9-9 or 9,9) !",
                    path: "system3DInterval"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "System Interval 3D is invalid (format: 9-9 or 9,9) !",
                path: "system3DInterval"
            });

        }),
        top33DInterval: Yup.string().test(function(value:any){
            const {useTop33DInterval} = this.parent;

            if (!value && useTop33DInterval){
                return this.createError({
                    message: "Top3 Interval 3D is required (format: 9-9 or 9,9) !",
                    path: "top33DInterval"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Top3 Interval 3D is invalid (format: 9-9 or 9,9) !",
                path: "top33DInterval"
            });

        }),
        rankAll3D:Yup.string().test(function(value:any){
            const {useRankAll3D} = this.parent;

            if (!value && useRankAll3D){
                return this.createError({
                    message: "Rank All 3D is required (format: 9-9 or 9,9) !",
                    path: "rankAll3D"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Rank All 3D is invalid (format: 9-9 or 9,9) !",
                path: "rankAll3D"
            });

        }),
        rankTop33D:Yup.string().test(function(value:any){
            const {useRankTop33D} = this.parent;

            if (!value && useRankTop33D){
                return this.createError({
                    message: "Rank Top3 3D is required (format: 9-9 or 9,9) !",
                    path: "rankTop33D"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Rank Top3 3D is invalid (format: 9-9 or 9,9) !",
                path: "rankTop33D"
            });

        }),
        monthRank3D:Yup.string().test(function(value:any){
            const {useMonthRank3D} = this.parent;

            if (!value && useMonthRank3D){
                return this.createError({
                    message: "Month Rank All 3D is required (format: 9-9 or 9,9) !",
                    path: "monthRank3D"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Month Rank All 3D is invalid (format: 9-9 or 9,9) !",
                path: "monthRank3D"
            });

        }),
        monthRankTop33D:Yup.string().test(function(value:any){
            const {useMonthRankTop33D} = this.parent;

            if (!value && useMonthRankTop33D){
                return this.createError({
                    message: "Month Rank Top3 3D is required (format: 9-9 or 9,9) !",
                    path: "monthRankTop33D"
                });
    
            }
            if (!value){
                return true;
            }

            const valid =  checkFromToIsValid(value as string);

            if (valid) return true;

            return this.createError({
                message: "Month Rank Top3 3D is invalid (format: 9-9 or 9,9) !",
                path: "monthRankTop33D"
            });

        }),



    });

    useEffect(()=>{
    }, [refresh, initValues]);

    return (
        <Formik innerRef={formRef}  validate={()=>{
            setRefresh(false);
        }}
            validationSchema={validationSchema}
            initialValues={
                initValues
            }
            onSubmit={
                (values,{setErrors}) => 
                {
                    const selectedNumbers = values["tempNumberFilter"] as string[];
                    if (selectedNumbers && selectedNumbers.length > 0){
                        values["numbersToFilter"] = selectedNumbers.join(','); 
                    }
                    else{
                        values["numbersToFilter"] = "";
                    }

                    const selectedRatings = values["tempRating"] as string[];
                    if (selectedRatings && selectedRatings.length > 0){
                        values["ratings"] = selectedRatings.join(','); 
                    }
                    else{
                        values["ratings"] = "";
                    }

                    const data = JSON.stringify(values);
                    _NewSetDataToLocalStorage("QueryNumberCondition",data);
                    modalStore.closeModal();
                }
            }
        >
        {(
            {handleSubmit, isSubmitting, errors}) => (
            <Form className="ui form" onSubmit={handleSubmit}>
                <div>
                <Grid>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use System Interval?"  checkBoxName="useSystemInterval" maxLength={50} textBoxName="systemInterval" placeHolder="Enter System Interval" ></MyInputSwitchNew>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Top3 Interval?" checkBoxName="useTop3Interval" textBoxName="top3Interval"  maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use All Rank?" checkBoxName="useRankAll" textBoxName="rankAll"  maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Top3 Rank?" checkBoxName="useRankTop3" textBoxName="rankTop3"  maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Month Rank?" checkBoxName="useMonthRank" textBoxName="monthRank"  maxLength={50} />
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Month Rank Top3?" checkBoxName="useMonthRankTop3" textBoxName="monthRankTop3" maxLength={50} />
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Short Term (0-100%)?" checkBoxName="useShortTerm" textBoxName="shortTermSystem" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Short Term Top3 (0-100%)?" checkBoxName="useShortTermTop3" textBoxName="shortTermTop3" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Middle Term?(0-100%)" checkBoxName="useMiddleTerm" textBoxName="middleTermSystem" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Middle Term Top3?(0-100%)" checkBoxName="useMiddleTermTop3" textBoxName="middleTermTop3"  maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Long Term?(0-100%)" checkBoxName="useLongTerm" textBoxName="longTermSystem" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Long Term Top3?(0-100%)" checkBoxName="useLongTermTop3" textBoxName="longTermTop3" maxLength={50} />
                    </Grid.Column>

                    <Grid.Column width={8}>
                    <MyDropDownFormik name="drawType" placeholder="Select Draw Type" 
                        toolTip="Select Draw Type"
                        options={_DrawTypeOptionsWithAll}>
                    </MyDropDownFormik>

                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyDropDownFormik name="system4DStatus"  placeholder="Select Number State" 
                                toolTip=""
                                options={_StatusOptions}>
                        </MyDropDownFormik>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyDropDownSearchMultipleFormikNoAdd placeholder="Enter or select one or more ratings" 
                                    toolTip="" name="tempRating" options={_RatingOptionStrings}>
                        </MyDropDownSearchMultipleFormikNoAdd>

                    </Grid.Column>

                    <Grid.Column width={8}>
                        <MyDropDownSearchMultipleFormik onAdd={handleNumbersAdd} placeholder="Enter 2D/3D/4D"
                                    toolTip="" name="tempNumberFilter" options={numbers}>
                        </MyDropDownSearchMultipleFormik>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use System 3D Interval?" checkBoxName="useSystem3DInterval" textBoxName="system3DInterval" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Top3 3D Interval?" checkBoxName="useTop33DInterval" textBoxName="top33DInterval" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use System 3D Rank?" checkBoxName="useRankAll3D" textBoxName="rankAll3D" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Top3 3D Rank?" checkBoxName="useRankTop33D" textBoxName="rankTop33D" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Month System 3D Rank?" checkBoxName="useMonthRank3D" textBoxName="monthRank3D" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Month Top3 3D Rank?" checkBoxName="useMonthRankTop33D" textBoxName="monthRankTop33D" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Sum 3D ?" checkBoxName="useSum3D" textBoxName="sum3D" maxLength={50} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyInputSwitchNew label="Use Sum 3D Top3 ?" checkBoxName="useSum3DTop3" textBoxName="sum3DTop3" maxLength={50} />
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <MyDropDownFormik name="drawType3D" placeholder="Select Draw Type 3D" 
                            toolTip="Select Draw Type 3D"
                            options={_DrawType3DOptionsWithAll}>
                        </MyDropDownFormik>

                    </Grid.Column>
                    <Grid.Column width={8}>
                        <MyDropDownFormik name="system3DStatus"  placeholder="Select Number State" 
                                toolTip=""
                                options={_StatusOptions}>
                        </MyDropDownFormik>
                    </Grid.Column>
                    
                </Grid>    
    
    
    
                <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
            
                <Grid>
                    <Grid.Column width={8}>
                        <Button icon="sync" positive content="Set Parameter" type="button" fluid  onClick={setParameters}></Button>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Button icon="search" primary content="Search" type="submit" fluid></Button>
                    </Grid.Column>
                </Grid>
                </div>
            </Form>
        
        )}
           </Formik>
    
    );
}

