import { useField } from "formik";
import React, { useState } from "react";
import { CheckboxProps, Form,  Label, Radio } from "semantic-ui-react";

interface Props {
    children: never[],
    name: string;
    placeholder: string;
    label?: string;
}
export default function SystemOrDirectRadioButton(props: Props){
    const [field, meta, helper] = useField(props.name);
    const [label, setLabel] = useState("Sys");
    
    const handleChanged = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps)=>{
        helper.setValue(data.checked);
        helper.setTouched(true);
        if (data.checked){
            if (window.innerWidth <= 768){
                setLabel("Sys");
            }
            else{
                setLabel("System");
            }
        }
        else{
            if(window.innerWidth <= 768){
                setLabel("Dir");
            }else{
            setLabel("Direct");
            }
        }
        
    }
    return (
        <Form.Field error={meta.touched && !!meta.error}>

            <Radio {...props} {...field} toggle onChange={handleChanged} checked={field.value} label={ label }></Radio>
            {meta.touched && meta.error ? <Label basic color='red'>{meta.error}</Label> : null
            } 
        </Form.Field>
    );
}