import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { DropdownProps, Grid, Header, Label, Table } from 'semantic-ui-react'
import agent from "../../../app/api/agent";
import { _DrawTypeOptionsWithAll, _NoOfDrawOptions, _PrizeTypeOptions } from "../../../app/lib/CommonData";
import {  _DisplayPercentage, _DisplayZero, _GetSavedDrawNo, _GetSavedDrawType4D, _GetSavedPrizeType, _SetSavedDrawNo, _SetSavedDrawType4D,  _SetSavedPrizeType} from "../../../app/lib/CommonFunctions";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import RedirectTo4DSumRoot from "../../../app/lib/RedirectTo4DSumRoot";
import {  Sum1D } from "../../../app/models/IDayResult";
import { useStore } from "../../../app/store/store";


export default observer(function Sum4DHistory(){
    const [data, setData] = useState<Sum1D[]>([]);
    const [drawType, setDrawType] = useState(_GetSavedDrawType4D("A"));
    const [prizeType, setPrizeType] = useState(_GetSavedPrizeType("T"));
    
    const [noOfDraw, setNoOfDraw] = useState(_GetSavedDrawNo("NoOfDraw",36));

    const [loading, setLoading] = useState(true);
    const {userStore} = useStore();

  
    
    const noOfDrawChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setNoOfDraw(parseInt(option.value+""));
        _SetSavedDrawNo("NoOfDraw", parseInt(option.value+""));
        setLoading(true);
    }

    const prizeTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setPrizeType(option.value + "");
         _SetSavedPrizeType(option.value + "");
         setLoading(true);
    }

   const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setDrawType(option.value + "");
        _SetSavedDrawType4D(option.value + "");
        setLoading(true);
    }

    useEffect(()=>{
        
        agent.Draw4DResult.getSum4DNumbersHistory(prizeType, noOfDraw, drawType).then((response)=>{
            setData(response);
        }).finally(()=>{
            setLoading(false);
        });

    },[drawType, noOfDraw, prizeType]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }

    if (!userStore.isLogged){
        return (<Header>Not Authorized</Header>);
    }

    return (
      <div style={{overflowX:"auto"}}>
                  <Grid>
                    <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={4} computer={4}>
                        <MyDropDown onChange={prizeTypeChanged} placeholder="Select Prize Type" 
                        toolTip="Select Prize Type" zIndex="21"
                        options={_PrizeTypeOptions} defaultValue = {prizeType}>
                        </MyDropDown>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" mobile={8} tablet={4} computer={4}>
                        <MyDropDown onChange={noOfDrawChanged} placeholder="Select No of Draw" 
                        toolTip="Select No Of Draw" zIndex="21"
                        options={_NoOfDrawOptions} defaultValue = {noOfDraw}>
                        </MyDropDown>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" mobile={8} textAlign="center" tablet={4} computer={4}>
                      <MyDropDown onChange={drawTypeChanged} placeholder="Select Draw Type" 
                      toolTip="Select Draw Type" zIndex="20"
                      options={_DrawTypeOptionsWithAll} defaultValue = {drawType}>
                      </MyDropDown>
                    </Grid.Column>
                  </Grid>
          
                  <Table celled  sortable unstackable className="freeze-head-and-col">
                  <Table.Header>
                  <Table.Row>
                  <Table.HeaderCell colSpan="18">
                      Sum 4D Number (Root)
                  </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                  <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <RedirectTo4DSumRoot range="0" code={"S4"} drawType={drawType}></RedirectTo4DSumRoot>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <RedirectTo4DSumRoot range="1" code={"S4"} drawType={drawType}></RedirectTo4DSumRoot>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <RedirectTo4DSumRoot range="2" code={"S4"} drawType={drawType}></RedirectTo4DSumRoot>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                  <RedirectTo4DSumRoot range="3" code={"S4"} drawType={drawType}></RedirectTo4DSumRoot>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <RedirectTo4DSumRoot range="4" code={"S4"} drawType={drawType}></RedirectTo4DSumRoot>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <RedirectTo4DSumRoot range="5" code={"S4"} drawType={drawType}></RedirectTo4DSumRoot>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <RedirectTo4DSumRoot range="6" code={"S4"} drawType={drawType}></RedirectTo4DSumRoot>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <RedirectTo4DSumRoot range="7" code={"S4"} drawType={drawType}></RedirectTo4DSumRoot>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <RedirectTo4DSumRoot range="8" code={"S4"} drawType={drawType}></RedirectTo4DSumRoot>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <RedirectTo4DSumRoot range="9" code={"S4"} drawType={drawType}></RedirectTo4DSumRoot>
                  </Table.HeaderCell>
                  </Table.Row>
                  </Table.Header>
                  <Table.Body>
                      {data.map((x,i) => {
                      
                      if (i === 0){
                          var total = x.col1 + x.col2  + x.col3  + x.col4 + x.col5 +
                                      x.col6 + x.col7  + x.col8  + x.col9 + x.col0;

                          if (total === 0){
                              return (
                                  <Table.Row key={i}>
                                      <Table.Cell textAlign="center">
                                          Summary
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                          No Result
                                      </Table.Cell>
                                  </Table.Row>
                              )
                          }
                          return (
                              <Table.Row key={i}>
                                  <Table.Cell  textAlign="center">
                                  Hit/% Summary
                                  </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col0 }</Label>
                                      <Label >{_DisplayPercentage(x.col0, total, 0)}</Label>                    
                                  </Table.Cell>
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col1 }</Label>
                                      <Label >{_DisplayPercentage(x.col1, total, 0)}</Label>                    
                                  </Table.Cell>
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col2 }</Label>
                                      <Label >{_DisplayPercentage(x.col2, total, 0)}</Label>                    
                                  </Table.Cell>
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col3 }</Label>
                                      <Label >{_DisplayPercentage(x.col3, total, 0)}</Label>                    
                                  </Table.Cell>
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col4 }</Label>
                                      <Label >{_DisplayPercentage(x.col4, total, 0)}</Label>                    
                                  </Table.Cell>
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col5 }</Label>
                                      <Label >{_DisplayPercentage(x.col5, total, 0)}</Label>                    
                                  </Table.Cell>
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col6 }</Label>
                                      <Label >{_DisplayPercentage(x.col6, total, 0)}</Label>                    
                                  </Table.Cell>
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col7 }</Label>
                                      <Label >{_DisplayPercentage(x.col7, total, 0)}</Label>                    
                                  </Table.Cell>
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col8 }</Label>
                                      <Label >{_DisplayPercentage(x.col8, total, 0)}</Label>                    
                                  </Table.Cell>
                                  <Table.Cell  textAlign="center">
                                      <Label circular color="blue">{x.col9 }</Label>
                                      <Label >{_DisplayPercentage(x.col9, total, 0)}</Label>                    
                                  </Table.Cell>

                              </Table.Row>
                          )
                      }
                      
                      
                      return (
                      <Table.Row key={i}>
                          <Table.Cell  textAlign="center">
                              {x.draw_Date}
                          </Table.Cell>
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col0)}
                          </Table.Cell>
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col1)}
                          </Table.Cell>
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col2)}
                          </Table.Cell>
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col3)}
                          </Table.Cell>
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col4)}
                          </Table.Cell>
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col5)}
                          </Table.Cell>
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col6)}
                          </Table.Cell>
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col7)}
                          </Table.Cell>
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col8)}
                          </Table.Cell>
                          <Table.Cell  textAlign="center">
                              { _DisplayZero(x.col9)}
                          </Table.Cell>
                      </Table.Row>
                      );
                    }  
                    )} 
                    </Table.Body>
                </Table>                 
   </div>
    );
});