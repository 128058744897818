import React from "react";
import { Label } from "semantic-ui-react";

interface Props{
    status: string | number;
}

function getStatus(status: string){
    if (status === "2") return "Not Due";
    if (status ==="1") return "In Range";
    return "Overdue";
  }
  

export default function NumberStatus({status} : Props){
    return (
    <Label color={status === "1" ? "green" : (status === "2" ? "orange" : "red")}>
    {getStatus(status + "")}
    </Label>
    );
}