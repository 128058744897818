import React  from "react";
import { Table } from "semantic-ui-react";

interface Props{
    column: string;
    columnName: string;
    handleClick: (columnName:string) => void;
    textAlign: "center" | "left" | "right" | undefined;
    direction: "ascending" | "descending" | undefined;
    rowSpan?: number;
    children? : any;
    colSpan?: number;
    filtered?: boolean;
}

export default function MyTableWithSort(props: Props){
    return (
        <Table.HeaderCell 
    
        rowSpan={props.rowSpan}
        colSpan={props.colSpan}
        onClick = {() => props.handleClick(props.columnName)}
        sorted={props.column === props.columnName ? props.direction : undefined}
        textAlign={props.textAlign}>
            {props.children}
        </Table.HeaderCell>
    );
}

