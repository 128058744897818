import React, { useCallback, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Image, Button, Header,  Label } from "semantic-ui-react";
import { useStore } from "../../app/store/store";
import MyTextInput from "../../app/lib/MyTextInput";
import {useDropzone} from 'react-dropzone'
import * as Yup from 'yup';

export default observer(function EditDisplayName(){
    const {userStore : {user, getPhotoURL, changeDisplayName}} = useStore();
    const [files, setFiles] = useState<any>([]);
    const [currentURL, setCurrentURL] = useState(getPhotoURL());
    
    const validationSchema = Yup.object({
        password: Yup.string().required("Password is required"),
        displayName: Yup.string().required("Display Name is required")

    });
    
    const dzStyle = {
        border: "dashed 3px #eee",
        borderColor: "#eee",
        borderRadius: "5px",
        paddingTop: "30px",
        textAlign: "center" as "center",
        height: 200
    }

    const dzActive = {
        border: "green"
    }

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        setFiles(acceptedFiles.map((file: any)=>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
        })));
        setCurrentURL(acceptedFiles[0].preview);

    }, [setFiles])
    
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    
    return (
        <Formik validationSchema = {validationSchema}
         initialValues={{ email: "", password:"", deviceID: "", displayName: user?.displayName, error: null}}
                onSubmit={
                    (values,{setErrors}) => changeDisplayName(values, files[0]).catch(err => setErrors({error: 'Unable to change display name !'}))
                }
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <Header as="h3" content="Edit Display Name and Photo" textAlign="center" color="green"></Header>
                        <MyTextInput name="password" placeholder="Current Password" type="password"></MyTextInput>

                        <MyTextInput name="displayName" placeholder="Display Name"></MyTextInput>
                        <div {...getRootProps()}   style={isDragActive ? {...dzStyle, ...dzActive} : dzActive}>
                            <input {...getInputProps()} />
                            {currentURL &&  (
                                <Image src={currentURL} size="small" className="centered"/>
                            )}
                            <Header content="Drop Image here" textAlign="center"></Header>
                        </div>

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})
