import React from "react";
import { Button } from "semantic-ui-react";
import { history } from "../..";

interface Props{
    range: string;
    code: string;
    drawType: string;
}


export default function RedirectTo3D({range, code, drawType} : Props){
    const redirectTo3DPage=() => {
        history.push("/3DAnalysisTypeAndRange/" + code + "/" + range + "/"+ drawType);
      }
    
    return (
        <Button type="button" onClick={()=>redirectTo3DPage()} data-tooltip={"Click here to view " + range + " System 3D"}>{range}</Button> 
    );
}