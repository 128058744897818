import { observer } from "mobx-react-lite"
import React from "react"
import {  Header, Segment, Statistic } from "semantic-ui-react"
import { _SortObjectByKeys } from "../../app/lib/CommonFunctions"
import { DrawDetailResult } from "../../app/models/IDayResult"


interface Props{
    drawResult: DrawDetailResult[];
}

export default observer(function DetailResult6({drawResult} : Props){
    const rankAllDict : any = {};
    const rankTop3Dict: any = {};
    const rankAllByMonthDict: any = {};
    const rankTop3ByMonthDict: any = {};

    for(var i = 0; i < drawResult.length; i++){
      var existingRank =rankAllDict[drawResult[i].rank_All];
      if (existingRank === undefined){
        rankAllDict[drawResult[i].rank_All] = 1;
      } 
      else{
        rankAllDict[drawResult[i].rank_All] = existingRank + 1;
      }

      if(i <= 2){
        existingRank =rankTop3Dict[drawResult[i].rank_Top3];
        if (existingRank === undefined){
          rankTop3Dict[drawResult[i].rank_Top3] = 1;
        } 
        else{
          rankTop3Dict[drawResult[i].rank_Top3] = existingRank + 1;
        }
      }
      existingRank =rankAllByMonthDict[drawResult[i].month_Rank_All];
      if (existingRank === undefined){
        rankAllByMonthDict[drawResult[i].month_Rank_All] = 1;
      } 
      else{
        rankAllByMonthDict[drawResult[i].month_Rank_All] = existingRank + 1;
      }

      if (i <= 2){
        existingRank =rankTop3ByMonthDict[drawResult[i].month_Rank_Top3];
        if (existingRank === undefined){
          rankTop3ByMonthDict[drawResult[i].month_Rank_Top3] = 1;
        } 
        else{
          rankTop3ByMonthDict[drawResult[i].month_Rank_Top3] = existingRank + 1;
        }
      }
    }

    var sortedRankAllDict = _SortObjectByKeys(rankAllDict);

    var sortedTop3AllDict = _SortObjectByKeys(rankTop3Dict);

    var sortedRankAllByMonthDict = _SortObjectByKeys(rankAllByMonthDict);

    var sortedRankTop3ByMonthDict = _SortObjectByKeys(rankTop3ByMonthDict);

return (
    <div>
       <Segment.Group compact>
      <Segment compact>
        <Header as="h2" textAlign="left"> Rank(All)</Header>
      <Statistic.Group>
      {
        Object.keys(sortedRankAllDict).map((key, index) => {
          return (
            <Statistic key={index}>
            <Statistic.Value>{sortedRankAllDict[key]}</Statistic.Value>
            <Statistic.Label>
              {key}
            </Statistic.Label>
          </Statistic>
          );
        })
      }
    </Statistic.Group>
    </Segment>
    <Segment compact>
        <Header as="h2" textAlign="left"> Rank(Top3)</Header>
      <Statistic.Group>
      {
        Object.keys(sortedTop3AllDict).map((key, index) => {
          return (
            <Statistic key={index}>
            <Statistic.Value>{sortedTop3AllDict[key]}</Statistic.Value>
            <Statistic.Label>
              {key}
            </Statistic.Label>
          </Statistic>
          );
        })
      }
    </Statistic.Group>
    </Segment>
    <Segment compact>
        <Header as="h2" textAlign="left"> Rank By Month(All)</Header>
      <Statistic.Group>
      {
        Object.keys(sortedRankAllByMonthDict).map((key, index) => {
          return (
            <Statistic key={index}>
            <Statistic.Value>{sortedRankAllByMonthDict[key]}</Statistic.Value>
            <Statistic.Label>
              {key}
            </Statistic.Label>
          </Statistic>
          );
        })
      }
    </Statistic.Group>
    </Segment>
    <Segment compact>
        <Header as="h2" textAlign="left"> Rank By Month(Top3)</Header>
      <Statistic.Group>
      {
        Object.keys(sortedRankTop3ByMonthDict).map((key, index) => {
          return (
            <Statistic key={index}>
            <Statistic.Value>{sortedRankTop3ByMonthDict[key]}</Statistic.Value>
            <Statistic.Label>
              {key}
            </Statistic.Label>
          </Statistic>
          );
        })
      }
    </Statistic.Group>
    </Segment>

    </Segment.Group>
  </div>
  )
})