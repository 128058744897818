import React from "react";
import { Label } from "semantic-ui-react";

interface Props{
    status: string | number;
}

function getStatus(status: string){
    if (status === "2") return "ND";
    if (status ==="1") return "IR";
    return "OD";
  }
  

export default function NumberStatusShort({status} : Props){
    return (
    <Label   color={status === "1" ? "green" : (status === "2" ? "orange" : "red")} size="small">
    {getStatus(status + "")}
    </Label>
    );
}