import { observer } from "mobx-react-lite";
import React from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router";
import { Header, Icon, Message, Segment } from "semantic-ui-react";
import { useStore } from "../../app/store/store";
import LatestDraw from "../drawresult/LatestDraw";

export default observer(function HomePage() {
    const { userStore } = useStore();

    if (userStore.isLogged) {
        return (
            <Redirect to='/forecast' />
        )
        //history.push("/forecast");

    }
    //if(drawResultStore.loadingInitial) return (<LoadingComponent></LoadingComponent>);   

    return (
        <>
            <Helmet>
                <title>Welcome to the best Singapore 4D Analysis & Forecasts software using Algorithm & AI</title>
                <meta name="description" content="Unlock deeper insights with 4DGrandMaster Analysis for Singapore 4D, leveraging cutting-edge algorithms and artificial intelligence to transform data into high probability chance. Enhance decision-making with our innovative AI-powered solutions" />
            </Helmet>
            <Message warning style={{marginTop: "20px"} }>
                <Header>Unlock Lottery Insights and Secrets: Professional Analysis and AI-Powered Predictions for Singapore 4D.
                </Header>
                <p style={{ marginTop: "20px" }}>
                    Tired of relying on luck? 4DGrandmaster.com empowers you with advanced professional analysis and AI-driven predictions for Singapore's 4D lottery.
                </p>
                <p >
                    The Power of AI and Human Expertise:
                    Our predictions are powered by a unique blend of cutting-edge AI algorithms and the insights of seasoned professionals. With over 20 years of experience, our team of analysts and programmers have developed sophisticated models that analyze vast amounts of historical data to identify hidden patterns and trends.
                </p>
                <p>
                    Unlock Hidden Patterns: Our AI algorithms delve deep into the data, uncovering statistical anomalies and probabilistic trends that can significantly improve your chances of winning.
                </p>
                <p>
                    Make Smarter Bets, Not Guesses: Transform raw data into actionable insights. We provide high-probability predictions to help you make informed decisions and enhance your lottery strategy.
                </p>
                <p>
                Gain a Competitive Edge: Don't leave your success to chance. Leverage the power of AI and human expertise to gain an edge over other players and unlock the secrets of the 4D world.
                </p>
                <p style={{ marginTop: "20px" }}>Please exit this website if you are under 18 years,click <a href='/about'>here</a> to contact us. Testimonials from Satisfied Users click <a href='/testimonial'>here.</a></p>
            </Message>
            <LatestDraw />
            <Segment style={{ marginTop: "100px" }}>
                <Header>
                    Disclaimer:
                </Header>
                <p>
                    The information provided on 4dgrandmaster.com (the “Site”) is for general informational &amp; education purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
                </p>
                <p>
                    Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site [or our mobile application] or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.
                </p>
                <p>
                    The publisher does not claim to provide its readers with a foolproof method of forecasting winning lottery numbers. The 4D Grand Master software is designed to serve merely as a guide for 4D   players.  All efforts and care have been taken to ensure the accuracy of the information and data presented in this software. The Publisher, the staff, partners or associates, however, assume no responsibility and shall not have any liability for any kind arising from the use of this software.
                </p>
                <p>
                    Lottery results are ramdom and unpredictable. Past performance is not indicative of future results.
                </p>
                <p>
                    Ready to take control of your lottery journey? Visit 4DGrandmaster.com today and start unlocking the potential of AI-powered and professional analysts predictions.
                </p>
                <p>
                    We improve our products by using Microsoft Clarity to see how you use our website. By using our site, you agree that we and Microsoft can collect and use this data.Our privacy statement <a href='/privacy'>here</a> has more details.
                </p>
                
            </Segment>

            
            <Segment>
                <u>Advisory.</u><br /><br />
                This website and software are designed to provide lottery enthusiasts with 4D results, gaming data, information, lottery aids, etc.

                All Users are encouraged to play the legalised lottery games as a form of entertainment and for sheer fun of it.

                This site is NOT involved in or linked with any form of illegal lotteries or organised gambling which contravenes the Gaming Acts or any Laws of Singapore.
            </Segment>
            
        </>
    )
})