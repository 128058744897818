import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { Image, Button, Card, Dropdown, DropdownItemProps, Grid,  Header,    Label, List, Menu, Popup } from "semantic-ui-react";
import * as Yup from 'yup';
import agent from "../../app/api/agent";
import { _GetRating, _GetRatingContent, _SortNumber } from "../../app/lib/CommonFunctions";
import MyProgressBar from "../../app/lib/MyProgressBar";
import SystemOrDirectRadioButton from "../../app/lib/MyRadioButton";
import MyTextInput from "../../app/lib/MyTextInputNew";
import { NumberSearchResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import ViewGraphics from "./viewgraphics";
import ViewGraphicsTop3 from "./viewgraphicstop3";
import ViewLastDraw from "./viewlastdraw";
import ViewDetailNumber from "./viewnumberlist";
import ViewRecommendedDirect from "./viewrecommendeddirect";
import configData from "../../app/config.json";

export default function ViewEditNumber(){
    const thisYear = configData.CurrentYear;
    const [detailType, setDetailType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    let {id} = useParams<{id: string}>();
    const [data, setData] = useState<NumberSearchResult[]>([]);

    const [newIsSystem, setNewIsSystem] = useState<boolean>(true);
    const [isDetail, setDetail] = useState(false);
    const [isRecommended, setRecommended] = useState(false);
    const [detailNumber, setDetailNumber] = useState("");
    const [isSyemDetail, setSystemDetail]= useState(true);
    const [isGraphics, setIsGraphics] = useState(false);
    const [isGraphicsTop3, setIsGraphicsTop3] = useState(false);
    const {userStore} = useStore();    


    const validationSchema = Yup.object({
        numberToInput: Yup.string().required("4D Number is required").matches(/^[0-9]{4}$/, 'Must be exactly 4 digits')
    });


    id = _SortNumber(newIsSystem, id);
    const [newID, setNewID] = useState<string>(id);
 
    let formData = {
        isSystem: true,
        numberToInput: id
    }

    const handleDetailClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data:DropdownItemProps )=>{
        setDetail(false);
        setRecommended(false);
        setSystemDetail(false);
        setIsGraphics(false);
        setIsGraphicsTop3(false);
  
        setDetailNumber(newID);
        
        setSystemDetail(newIsSystem);
        setDetailType(data.value+"");

        if(data.value! === "1" || data.value === "2")
        {
            setDetail(true);
        }
        if(data.value! === "3")
        {
            setRecommended(true);
        }
        if(data.value! === "4")
        {
            setIsGraphics(true);
        }

        
        if(data.value! === "5")
        {

            setIsGraphicsTop3(true);
        }

   }

    useEffect(()=>{
        if (!newID) return;
        if (newID.length !== 4) return;


        agent.Draw4DResult.searchNumber(newIsSystem, newID).then((response)=>{
            setData(response);
            setLoaded(true);
            setIsLoading(false);
            //isLoading = false;
        });
        
    },[ newID,newIsSystem, formData.isSystem, formData.numberToInput]);

    if(!userStore.isLogged){
        return (
            <Header>Not Login</Header>
        )
    }
    
    return (
        <Grid textAlign="center" verticalAlign='top'>
           <Grid.Row>
               <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14}>
               <Formik 
                    enableReinitialize
                    initialValues = {formData}
                    validationSchema = {validationSchema}
                    validate = {()=>{
                        setIsLoading(false);
                        setLoaded(false);
                        setNewID("");
                        setDetail(false);
                        setRecommended(false);
                        setIsGraphics(false);
                        setIsGraphicsTop3(false);

                    }}
                    onSubmit = {(o) => {
                        const sortedNumber = _SortNumber(o.isSystem, o.numberToInput);

                        setNewIsSystem(o.isSystem);
                        setNewID(sortedNumber);
                    }}>
                        {({handleSubmit}) => (
                            <Form className="ui form" onSubmit={handleSubmit}>
                                <Grid verticalAlign="middle">
                                    <Grid.Row>
                                    <Grid.Column width={5} textAlign="right"><SystemOrDirectRadioButton  name="isSystem" placeholder="" label={formData.isSystem ? (window.innerWidth <= 768 ? "Sys":"System") : (window.innerWidth<=768 ? "Dir" : "Direct")}>
                                </SystemOrDirectRadioButton></Grid.Column>
                                    <Grid.Column width={6} textAlign="center" verticalAlign="middle">
                                       <MyTextInput name="numberToInput" maxLength={4} placeholder="Input 4D Number"/>
                                    </Grid.Column >
                                    <Grid.Column width={5} textAlign="left">
                                    {window.innerWidth <= 768 ?                             
                                    <Button  fluid loading={isLoading}  type="submit" positive icon="search" >
                                   
                                   </Button>
                                   :
                                   <Button  fluid loading={isLoading}  type="submit" positive icon="search" content="Search" labelPosition="right">
                                   
                                   </Button>
                                    }
                                    </Grid.Column >
                                    </Grid.Row>
                                </Grid>
                                
                             
                                
                            </Form>
                        )}
                </Formik>
               </Grid.Column>
               <Grid.Column width={1}>

               </Grid.Column>
           </Grid.Row> 
           <Grid.Row>
                <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14} textAlign="center">
                { isLoaded && data && data.length > 0 &&                
                    <Card fluid>
                         <Card.Content>
                            <Card.Header as="h3">History &amp; Analysis for {newID}&nbsp;{newIsSystem ? "[system number]": "[direct number]"}
                            </Card.Header>
                        </Card.Content>
                        <Card.Content>
                        <List horizontal>
                        <List.Item key="Hdr1">
                        <List.Content>
                                <List.Header>1st</List.Header>
                                <div style={{alignItems:'center', width:"100%"}}>
                                <Label data-tooltip = {"No of Strike for 1st Prize: " + data[0].first}  circular color='orange'>{data[0].first}</Label>
                                </div>
                                </List.Content>
                                </List.Item>
                                <List.Item key="Hdr2">
                                <List.Content>
                                    <List.Header>2nd</List.Header>
                                    <Label data-tooltip = {"No of Strike for 2nd Prize: " + data[0].second} circular color='grey'>{data[0].second}</Label>
                                </List.Content>
                                </List.Item>
                                <List.Item key="Hdr3">
                                <List.Content>
                                    <List.Header>3rd</List.Header>
                                    <Label data-tooltip = {"No of Strike for 3rd Prize: " + data[0].third} circular color='brown'>{data[0].third}</Label>                                </List.Content>
                                </List.Item>
                                <List.Item>
                                <List.Content>
                                    <List.Header>S</List.Header>
                                    <Label data-tooltip = {"No of Strike for Starter: " + data[0].starter} circular color='green'>{data[0].starter}</Label>                                </List.Content>
                                </List.Item>
                                <List.Item key="Hdr4">
                                <List.Content>
                                    <List.Header>C</List.Header>
                                    <Label data-tooltip = {"No of Strike for Consolation: " + data[0].consolidation} circular color='teal'>{data[0].consolidation}</Label>                                </List.Content>
                                </List.Item>
                                <List.Item key="Hdr5">
                                <List.Content>
                                    <List.Header><Header as={"h3"}>TOTAL</Header></List.Header>
                                    <Label data-tooltip={"Total Strike (Top3 + Starter+ Conso): " + data[0].noOfHit} circular color='orange' size={"large"}>{data[0].noOfHit}</Label>                                </List.Content>
                                </List.Item>
                                <List.Item key="Hdr6">
                                <List.Content>
                                    <List.Header>{thisYear}</List.Header>
                                    <Label data-tooltip={ thisYear + " Strike: " + data[0].currentYear} circular color='violet'>{data[0].currentYear}</Label>                                </List.Content>
                                </List.Item>
                                <List.Item key="Hdr7">
                                <List.Content>
                                    <List.Header>{thisYear - 1}</List.Header>
                                    <Label data-tooltip={ (thisYear - 1) + " Strike: " + data[0].year_1} circular color='violet'>{data[0].year_1}</Label>                                </List.Content>
                                </List.Item>
                                <List.Item key="Hdr8">
                                <List.Content>
                                    <List.Header>{thisYear - 2}</List.Header>
                                    <Label data-tooltip={ (thisYear - 2) + " Strike: " + data[0].year_2} circular color='violet'>{data[0].year_2}</Label>                                </List.Content>
                                </List.Item>
                                <List.Item key="Hdr9">
                                <List.Content>
                                    <List.Header>{thisYear - 3}</List.Header>
                                    <Label data-tooltip={ (thisYear - 3) + " Strike: " + data[0].year_3} circular color='violet'>{data[0].year_3}</Label>                                </List.Content>
                                </List.Item>
                                <List.Item key="Hdr10">
                                <List.Content>
                                    <List.Header>{thisYear - 4 }</List.Header>
                                    <Label data-tooltip={ (thisYear - 4) + " Strike: " + data[0].year_4} circular color='violet'>{data[0].year_4}</Label>                                </List.Content>
                                </List.Item>
                                <List.Item key="Hdr11">
                                <List.Content>
                                    <List.Header>{thisYear - 5 }</List.Header>
                                    <Label data-tooltip={ (thisYear - 5) + " Strike: " + data[0].year_5} circular color='violet'>{data[0].year_5}</Label>                                </List.Content>
                                </List.Item>
                                {newIsSystem && 
                                <List.Item key="Hdr12">
                                <List.Content>
                                    <List.Header as="h3">Rating</List.Header>
                                    <Popup content={_GetRatingContent(data[0].rank)}
                                    trigger ={<Image avatar src={_GetRating(data[0].rank)} />}>
                                    </Popup>
                
                                </List.Content>
                                </List.Item>
                                }
                                {newIsSystem &&
                                <List.Item key="Hdr13">
                                <List.Content>
                                    <List.Header as="h3">Awaken</List.Header>
                                    <Label circular color="blue">{data[0].awaken}</Label>
                                    </List.Content>
                                </List.Item>                                    
                                }
                            </List> 
                        </Card.Content>
                        <Card.Meta>
                            <MyProgressBar useIcon={true} title={"All Interval"} minValue={data[0].minGap}
                                            maxValue={data[0].maxGap}
                                            average={data[0].avgGap} 
                                            minColor={"green"}
                                            avgColor={"yellow"}
                                            maxColor={"red"}   
                                            interval = {data[0].interval}
                                            intervalColor={"blue"}
                            ></MyProgressBar>

                            <MyProgressBar useIcon={false} title={"Top3 Interval"} minValue={data[0].minGap123}
                                            maxValue={data[0].maxGap123}
                                            average={data[0].avgGap123} 
                                            minColor={"green"}
                                            avgColor={"yellow"}
                                            maxColor={"red"}   
                                            interval = {data[0].interval123}
                                            intervalColor={"blue"}
                            ></MyProgressBar>

                        </Card.Meta>
                        <Card.Content extra textAlign="center">
                        <Menu vertical compact inverted>
                            <Dropdown  item text='View' icon="arrow right" direction={window.innerWidth<=768 ? "left": "right"}>
                            <Dropdown.Menu>
                                <Dropdown.Item value="1" onClick={handleDetailClick}>Summary by Interval</Dropdown.Item>
                                <Dropdown.Item value="2" onClick={handleDetailClick}>Summary by Strike</Dropdown.Item>
                                <Dropdown.Item value="3" onClick={handleDetailClick}>Recommended Direct Number</Dropdown.Item>
                                <Dropdown.Item value="4" onClick={handleDetailClick}>Graph</Dropdown.Item>
                                {userStore.user && userStore.user?.groupID > 1 && <Dropdown.Item value="5" onClick={handleDetailClick}>Graph Top3</Dropdown.Item>}

                            </Dropdown.Menu>
                            </Dropdown>
                        </Menu>
                        </Card.Content>
                    </Card>
                }
               </Grid.Column>
               <Grid.Column width={1}></Grid.Column>
           </Grid.Row> 
           <Grid.Row>
                <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14}>
                    {!isGraphicsTop3 && !isGraphics && !isRecommended && !isDetail && isLoaded && data && data.length > 0 &&
                        <ViewLastDraw numberToCheck = {newID} isSystem={newIsSystem}></ViewLastDraw>
                    }
               </Grid.Column>
               <Grid.Column width={1}></Grid.Column>
           </Grid.Row>
           <Grid.Row>
           <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14} textAlign="center">
                    { isDetail && 
                        <ViewDetailNumber detailType={detailType} mainNumber={detailNumber} isSystem={isSyemDetail}></ViewDetailNumber>
                    }
               </Grid.Column>
               <Grid.Column width={1}></Grid.Column>
           </Grid.Row>
           <Grid.Row>
                <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14} textAlign="center">
                    { isRecommended && 
                        <ViewRecommendedDirect numberToCheck={detailNumber}></ViewRecommendedDirect>
                    }
               </Grid.Column>
               <Grid.Column width={1}></Grid.Column>
           </Grid.Row>
           <Grid.Row>
                <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14} textAlign="center">
                    { isGraphics && 
                        <ViewGraphics number={detailNumber} rating={data[0].rank}></ViewGraphics>
                    }
               </Grid.Column>
               <Grid.Column width={1}></Grid.Column>
           </Grid.Row>
           <Grid.Row>
                <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14} textAlign="center">
                    { isGraphicsTop3 && 
                        <ViewGraphicsTop3 number={detailNumber}></ViewGraphicsTop3>
                    }
               </Grid.Column>
               <Grid.Column width={1}></Grid.Column>
           </Grid.Row>
  
        </Grid>
    );

}