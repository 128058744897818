import React from 'react';
import { Helmet } from 'react-helmet';
import { Message, MessageHeader } from 'semantic-ui-react';

function Privacy() {
    return (
        <div style={{ "paddingTop": "20px" }} >
            <Helmet>
                <title>Privacy</title>
                <meta name="description" content="4DGM Privacy Statements" />
            </Helmet>
        <Message info>
            <MessageHeader>Privacy Statement</MessageHeader>
      <p>We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the
          <a href='https://privacy.microsoft.com/privacystatement'><i>Microsoft Privacy Statement</i></a></p>
            </Message>
        </div>
            );
}

export default Privacy;