import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Grid, Tab } from "semantic-ui-react";
import * as Yup from 'yup';
import { history } from "../..";
import agent from "../../app/api/agent";
import { _SortNumber } from "../../app/lib/CommonFunctions";
import MyTextInputButton from "../../app/lib/MyTextInputButton";
import {  ExpressDirectResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import ViewQuickDirectPart1 from "./ViewQuickDirectPart1";
import ViewQuickDirectPart2 from "./ViewQuickDirectPart2";
import ViewQuickDirectPart3 from "./ViewQuickDirectPart3";
import ViewQuickDirectPart4 from "./ViewQuickDirectPart4";
import ViewQuickDirectPart5 from "./ViewQuickDirectPart5";

export default observer(function QuickDirect(){
    const [id, setID] = useState("");
    const [data, setData] = useState<ExpressDirectResult | null>(null);
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
    }

    const validationSchema = Yup.object({
        numberToInput: Yup.string().required("4D Number is required").matches(/^[0-9]{4}$/, 'Must be exactly 4 digits')
    });

    let formData = {
        numberToInput: ""
    }
    const panes = [
        { menuItem: 'Recommended Direct Numbers', render: () => {
            return (<Tab.Pane> <ViewQuickDirectPart1 data={data!.expressDirectPart1} /></Tab.Pane>)}
        },
        { menuItem: 'All Prizes', render: () => {
            return (<Tab.Pane> <ViewQuickDirectPart2 data={data!.expressDirectPart2} /></Tab.Pane>)}
        },
        { menuItem: 'Top3', render: () => {
            return (<Tab.Pane> <ViewQuickDirectPart3 data={data!.expressDirectPart3} /></Tab.Pane>)}
        },
        { menuItem: 'All Directs', render: () => {
            return (<Tab.Pane> <ViewQuickDirectPart4 data={data!.expressDirectPart4} /></Tab.Pane>)}
        },
        { menuItem: 'By Draw Day', render: () => {
            return (<Tab.Pane> <ViewQuickDirectPart5 data={data!.expressDirectPart5} /></Tab.Pane>)}
        }
    ]

    const handleTabChange = (e:any, tabData: any) => setActiveIndex(tabData.activeIndex)

    useEffect(()=>{
        if (!id) return;
        if (id.length !== 4) return;


        agent.Draw4DResult.getExpressDirect(id).then((response)=>{
            setData(response);
            setLoading(false);
        });
        
        
    },[ id, formData.numberToInput]);

    return (
        <Grid textAlign="center" verticalAlign='top'>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <u>Express Direct</u>
                </Grid.Column>
            </Grid.Row>

           <Grid.Row>
               <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14}>
               <Formik 
                    enableReinitialize
                    initialValues = {formData}
                    validationSchema = {validationSchema}
                    validate = {()=>{
                        setID("");
                        
                    }}
                    onSubmit = {(o) => {
                        const sortedNumber = _SortNumber(true,o.numberToInput);
                        setLoading(true);
                        setID(sortedNumber);
                        setActiveIndex(0);
                        
                    }}>
                        {({handleSubmit}) => (
                            <Form  className="ui form" onSubmit={handleSubmit}>
                                <MyTextInputButton loading={loading} label="Enter 4D:" maxLength={4} name="numberToInput" placeholder="Enter 4D Number to Search">
                                </MyTextInputButton>
                            </Form>
                        )}
                </Formik>
               </Grid.Column>
               <Grid.Column width={1}>

               </Grid.Column>
           </Grid.Row>
           <Grid.Row>
           <Grid.Column width={1}></Grid.Column>
           <Grid.Column width={14}>Number Analysed: {id}</Grid.Column>
           <Grid.Column width={1}></Grid.Column>
               
           </Grid.Row>
           <Grid.Row>
           <Grid.Column width={1}></Grid.Column>
           <Grid.Column width={14}>Period : Last 10+ years (01.01.11 to date)</Grid.Column>
           <Grid.Column width={1}></Grid.Column>
               
           </Grid.Row>
           <Grid.Row>
               <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14}>
                   {data &&
                        <div>
                        <Tab onTabChange={handleTabChange} activeIndex={activeIndex} menu={{pointing: true, className: "tab_wrapped" }} panes={panes}></Tab>
                        </div>
                   }
               </Grid.Column>
               <Grid.Column width={1}></Grid.Column>
           </Grid.Row>
        </Grid>
    );

})