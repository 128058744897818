import React from "react";
import {  Image, List } from "semantic-ui-react";
import DrawLink from "../../app/lib/drawlink";
import { AlertData } from "../../app/models/IDayResult";
import { _GetRating} from "../../app/lib/CommonFunctions";
import NumberStatusShort from "../../app/lib/NumberStatusShort";

export default function DirectSquareBox(){
    
    const data : AlertData[] = JSON.parse(localStorage.getItem("alertDataNumbers") + "");

    return(
        <List horizontal verticalAlign="middle" celled>
            {data !== null && data!.length > 0 && data!.map((x,i)=>{
                return(
                    <List.Item key={x.system4D}>
                    <Image centered avatar src={_GetRating(x.rating)}></Image>
                    <List.Content>
                        <div style={{textAlign:"center", width:"70px"}}>
                        <DrawLink number = {x.system4D}>
                        </DrawLink>
                        <NumberStatusShort status = {x.system_status} />                        
                        <NumberStatusShort status = {x.top3_status} />
                        </div>                        
                    </List.Content>
                    </List.Item>
                );
            })}
            </List>
    )
};