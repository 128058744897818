import React from "react";
import { Link } from "react-router-dom";
import { useStore } from "../store/store";
import NumberStatus from "./NumberStatus";

interface Props{
    status: string;
    status_top3: string;
    status_type: string;
    status_only: string;
    draw_type: string;
    position: number;
    children?: any
}
export default function DrawLinkStatus(props: Props){
    if (props.status_type === "2" || 
        props.status_type === "3"){
            return (
                <Link to={"/get4DByStatus/" + props.status_only + "/" + props.status_type + "/" + props.draw_type + "/" + props.position} data-tooltip="Click to view 4D numbers" data-position="bottom left">
                    <NumberStatus status={props.status_only}></NumberStatus><br/>
                </Link>
            )
    }
  
    return (
        
        <Link to={"/get4DByStatus/" + props.status + "-" + props.status_top3 + "/" + props.status_type + "/" + props.draw_type + "/" + props.position} data-tooltip="Click to view 4D numbers" data-position="bottom left">
                    <NumberStatus status={props.status}></NumberStatus>
                    <NumberStatus status={props.status_top3}></NumberStatus>
        </Link>
    );
}