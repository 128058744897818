import { ErrorMessage, Form, Formik} from "formik";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import { Button,   Label } from "semantic-ui-react";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import moment from "moment";


export default observer(function AddEWinbox(){
    const formRef = useRef<any>();

    const validationSchema = Yup.object({
        winBoxDate: Yup.string().required("Date is required"),
        winBoxNo: Yup.number().required("Box No is required"),
        box1: Yup.number().required("Line 1 is required"),
        box2: Yup.number().required("Line 2 is required"),
        box3: Yup.number().required("Line 3 is required"),
        box4: Yup.number().required("Line 4 is required"),        
    });

    const getDate = ()=>{
        //var curDate = Date.now();
        var curDate = moment().startOf("month").add(1,"M");

        while (curDate.weekday() !== 3 && curDate.weekday() !== 6 && curDate.weekday() !== 7) {
            curDate = curDate.add(1, "day");
        }
        return curDate.format("DD/MM/yyyy");
    }
    return (
        <Formik innerRef={formRef} initialValues={{winBoxDate: getDate(), winBoxNo:1, box1: "", box2:"", box3:"", box4:"", error: null}} 
                onSubmit={
                    (values,{setErrors}) => agent.Account.submitWinBox(values).then(()=>{ values.winBoxNo++; values.box1=""; values.box2=""; values.box3=""; values.box4=""; toast.success("Submitted !"); }).catch(err => setErrors({error: 'Unable to submit:'+err}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid name="winBoxDate" placeholder="Date: (dd/MM/yyyy)"></MyTextInputNonFluid>
                        <MyTextInputNonFluid maxLength={1} name="winBoxNo" placeholder="eWinBox No:"></MyTextInputNonFluid>
                        
                        <MyTextInputNonFluid maxLength={4} name="box1" placeholder="Line 1:"></MyTextInputNonFluid>
                        <MyTextInputNonFluid maxLength={4} name="box2" placeholder="Line 2:"></MyTextInputNonFluid>
                        <MyTextInputNonFluid maxLength={4} name="box3" placeholder="Line 3:"></MyTextInputNonFluid>
                        <MyTextInputNonFluid maxLength={4} name="box4" placeholder="Line 4:"></MyTextInputNonFluid>

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>

                    </Form>
                
            )}
        </Formik>
    )
})