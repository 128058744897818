import React, { SyntheticEvent, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { DropdownProps,  Grid,  Input,  InputOnChangeData,  Pagination, PaginationProps, Table } from "semantic-ui-react";
import { history } from "../..";
import agent from "../../app/api/agent";
import { _DrawType3DOptionsWithAll, _RowPerPageOptions } from "../../app/lib/CommonData";
import { _GetRowPerPage, _SetRowPerPage } from "../../app/lib/CommonFunctions";
import Draw3DLink from "../../app/lib/draw3dlink";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyDropDown from "../../app/lib/MyDropDown";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import NumberStatus from "../../app/lib/NumberStatus";
import {  AnalysisSystem3DResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";


export default function Analysis3dWithParams(){
    let {type3D, range3D, iDrawType} = useParams<{type3D: string, range3D: string, iDrawType: string}>();

    const [column, setColumn] = useState("System_Top3_Interval");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [page, setPage] = useState(1);

    const [loading, setLoading] = useState(true);
    const [drawType, setDrawType] = useState(iDrawType);
    const [analysis, setAnalysis] = useState<AnalysisSystem3DResult[]>([]);
    const [rowPerPage, setRowPerPage] = useState(_GetRowPerPage());
    const {userStore} = useStore();

    if(!userStore.isLogged){
      history.push("/");
    }

    const rowPerPageChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setRowPerPage(parseInt(option.value + ""));
      _SetRowPerPage(parseInt(option.value + ""));
      //setLoading(true);
     
    }

   const handleColumnClick = (columnName : string)=>{

     if (column !== columnName){
       setDirection("ascending");
     }
     else{
       if (direction === undefined){
         setDirection('ascending');
       }
       else{
         setDirection(direction === "ascending" ? "descending" : "ascending");
       }
     }
     setColumn(columnName);
   }

   const handlePageChanged = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps)=>{
     setPage(parseInt(data.activePage!.toString()));
   }

   const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
    //setPage(1);
        setDrawType(option.value + "");
    
    }

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData)=>{
      if(data.value.length === 3){
          if (!isNaN(parseFloat(data.value))){
            let newDirection:string;

            newDirection = "";
            if (direction === "ascending"){
              newDirection = "asc";
            }
            if (direction === "descending"){
              newDirection = "desc";
            }
            agent.Draw4DResult.getPageBy3DNumberCommon(data.value, column, newDirection, rowPerPage, type3D, range3D, drawType).then(response=>{
              if(response.pageNo === 0){
                toast.error("Number not found ! Check the Draw Type.");
                return;
              }  
              toast.info("Found number in row " + response.row_No);
              setPage(response.pageNo);
            });
          }
      }

    }

    const getType3D = (type3D: string, range3D: string) =>{
      switch(type3D){
        case "ÄS":
          return "System Interval 3D for Range " + range3D;
         case "ÄT":
            return "System Top3 Interval 3D for Range " + range3D;
        case "S":
          return "All Rank 3D for Range " + range3D;
        case "T":
          return "Top3 Rank 3D for Range " + range3D;
        case "M":
          return "All Rank 3D Per Month for Range " + range3D;
        case "N":
            return "Top3 Rank 3D Per Month for Range " + range3D;
      }
    }
    useEffect(()=>{

        let newDirection = "";
        if (direction === "ascending"){
            newDirection = "asc";
        }
        if (direction === "descending"){
            newDirection = "desc";
        }

        
        agent.Draw4DResult.getAnalysis3DFromTypeAndRange(type3D, range3D, drawType, column, newDirection , page, rowPerPage).then((response)=>{

            setAnalysis(response);
            setLoading(false);
            
        });


    },[type3D, range3D, drawType, column, direction, page, rowPerPage]);


    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
          <div style={{overflowX:"auto"}}>
            <Table celled  sortable unstackable className="freeze-head-and-col">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="12" textAlign="left">
                <Grid centered divided columns={4}>
                  <Grid.Column textAlign="left" verticalAlign="middle">
                    <MyDropDown zIndex="20"
                        toolTip = "Select Draw Type"
                        onChange = {handleDropDownSelect}
                        placeholder='Select Draw Type'
                        options={_DrawType3DOptionsWithAll}
                        defaultValue = {drawType}>
                    </MyDropDown>
                  </Grid.Column>

                  <Grid.Column verticalAlign="middle">
                    <Input data-position="bottom center" fluid maxLength={3} focus placeholder='Search 3D position in the list' data-tooltip="Search 3D position in the list"  onChange={handleInputChanged}></Input>
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle">
                  <MyDropDown zIndex="20"
                      placeholder="Select Item Per Page"
                      toolTip="Select Item Per Page" 
                      onChange = {rowPerPageChanged}
                      options={_RowPerPageOptions}
                      defaultValue={rowPerPage} >
                  </MyDropDown>
                </Grid.Column>

                  <Grid.Column verticalAlign="middle">
                  {getType3D(type3D, range3D)}
                  </Grid.Column>
                </Grid>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="System3D" handleClick={handleColumnClick}>
                  System 3D
                </MyTableWithSort> 
                <Table.HeaderCell textAlign="center">
                  Row #
                </Table.HeaderCell>
                <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="SystemInRange" handleClick={handleColumnClick}>
                  System Status
                </MyTableWithSort> 
                <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Top3InRange" handleClick={handleColumnClick}>
                  Top3 Status
                </MyTableWithSort> 
                <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="System_Interval" handleClick={handleColumnClick}>
                  System Interval
                </MyTableWithSort> 
                <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="System_Top3_Interval" handleClick={handleColumnClick}>
                  Top3 Interval
                </MyTableWithSort> 

             </Table.Row>
            </Table.Header>
            <Table.Body>
                {analysis && analysis.length > 0 && analysis.map((x => {
                    return (
                        <Table.Row key={x.system3D}>
                            <Table.Cell textAlign="center">
                              <Draw3DLink title = ""  number={x.system3D}></Draw3DLink>
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {x.draw_ID}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <NumberStatus status = {x.systemInRange} />
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <NumberStatus status = {x.top3InRange} />
                            </Table.Cell>

                            <Table.Cell  textAlign="right">{x.systemInterval}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.top3Interval}</Table.Cell>
                        </Table.Row>
                    )
                }))}
            </Table.Body>
            </Table>
            <Pagination activePage={page} totalPages={analysis && analysis.length > 0 ? analysis[0].totalPage : 10} onPageChange={handlePageChanged}/>
        </div>              
    );
}