import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { DropdownProps, Grid,  Label, Table } from 'semantic-ui-react'
import { history } from "../../..";
import agent from "../../../app/api/agent";
import { _DrawType3DOptionsWithAll,  _NoOfDrawOptions,  _PrizeTypeOptions } from "../../../app/lib/CommonData";
import { _DisplayHeader, _DisplayPercentage, _DisplayZero, _GetSavedDrawNo, _GetSavedDrawType3D, _GetSavedIntervalType3D, _GetSavedPrizeType, _SetSavedDrawNo, _SetSavedDrawType3D, _SetSavedIntervalType3D, _SetSavedPrizeType } from "../../../app/lib/CommonFunctions";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import RedirectTo3D from "../../../app/lib/RedirectTo3D";
import { PatternResult } from "../../../app/models/IDayResult";
import { useStore } from "../../../app/store/store";


export default observer(function IntervalTop3History(){
    const [data, setData] = useState<PatternResult[]>([]);
    const [noOfDraw, setNoOfDraw] = useState(_GetSavedDrawNo("3D", 36));
    const [drawType, setDrawType] = useState(_GetSavedDrawType3D("A"));
    const [systemType, setSystemType] = useState(_GetSavedIntervalType3D("S"));
    const [prizeType, setPrizeType] = useState(_GetSavedPrizeType("T"));
    const [loading, setLoading] = useState(true);
    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
    }

    const systemTypeOptions = [
        {
            key: 'S',
            text: 'System Interval',
            value: 'S'
        },
        {
            key: 'T',
            text: 'Top3 Interval',
            value: 'T'
        }
    ]
  


    const noOfDrawChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setNoOfDraw(parseInt(option.value+""));
        _SetSavedDrawNo("3D", parseInt(option.value+""));
        setLoading(true);
    }

    const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setDrawType(option.value + "");
        _SetSavedDrawType3D(option.value + "");
        setLoading(true);
    }

    const prizeTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setPrizeType(option.value + "");
        _SetSavedPrizeType(option.value + "");
        setLoading(true);
    }

    const systemTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setSystemType(option.value + "");
        _SetSavedIntervalType3D(option.value + "");
        if (option.value === "T"){
            setPrizeType("T");
            _SetSavedPrizeType("T");
        }
        setLoading(true);
    }

    useEffect(()=>{
        agent.Draw4DResult.getIntervalCombination3Top3History(noOfDraw, drawType, systemType, prizeType).then((response)=>{
            setData(response);
        }).finally(()=>{
            setLoading(false);
        });
    
    },[noOfDraw, drawType, systemType, prizeType]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }

    const allColumns = (data === null || data.length === 0) ? [] : data[0].columnNames.split('|');

    return (
      <div style={{overflowX:"auto"}}>
                  <Grid>
                  <Grid.Row>
                  <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={8} computer={8}>
                        <MyDropDown onChange={systemTypeChanged} placeholder="Select Type"  zIndex="21"
                            toolTip="Select Type"
                            options={systemTypeOptions} defaultValue = {systemType}>
                        </MyDropDown>

                  </Grid.Column>
                  <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={8} computer={8}>
                      <MyDropDown onChange={drawTypeChanged} placeholder="Select Draw Type"  zIndex="21"
                      toolTip="Select Draw Type"
                      options={_DrawType3DOptionsWithAll} defaultValue = {drawType}>
                      </MyDropDown>
                  </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                  <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={8} computer={8}>
                      <MyDropDown onChange={prizeTypeChanged} placeholder="Select Prize Type" zIndex="20"
                      toolTip="Prize Type"
                      options={_PrizeTypeOptions} defaultValue = {prizeType}>
                      </MyDropDown>
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={8} computer={8}>
                        <MyDropDown onChange={noOfDrawChanged} placeholder="Select No of Draw" zIndex="20"
                            toolTip="Select # of Draw"
                            options={_NoOfDrawOptions} defaultValue = {noOfDraw}>
                      </MyDropDown>

                  </Grid.Column>
                  </Grid.Row>
                </Grid>
                  <Table celled  sortable unstackable className="freeze-head-and-col">
                    <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell colSpan="18">
                        3D Interval Range
                    </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                    <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        <RedirectTo3D range={_DisplayHeader(allColumns,1)} code={"A" + systemType} drawType={drawType}></RedirectTo3D>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        <RedirectTo3D range={_DisplayHeader(allColumns,2)} code={"A" + systemType} drawType={drawType}></RedirectTo3D>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        <RedirectTo3D range={_DisplayHeader(allColumns,3)} code={"A" + systemType} drawType={drawType}></RedirectTo3D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo3D range={_DisplayHeader(allColumns,4)} code={"A" + systemType} drawType={drawType}></RedirectTo3D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo3D range={_DisplayHeader(allColumns,5)} code={"A" + systemType} drawType={drawType}></RedirectTo3D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo3D range={_DisplayHeader(allColumns,6)} code={"A" + systemType} drawType={drawType}></RedirectTo3D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo3D range={_DisplayHeader(allColumns,7)} code={"A" + systemType} drawType={drawType}></RedirectTo3D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo3D range={_DisplayHeader(allColumns,8)} code={"A" + systemType} drawType={drawType}></RedirectTo3D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo3D range={_DisplayHeader(allColumns,9)} code={"A" + systemType} drawType={drawType}></RedirectTo3D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo3D range={_DisplayHeader(allColumns,10)} code={"A" + systemType} drawType={drawType}></RedirectTo3D>
                    </Table.HeaderCell>


                    </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.map((x,i) => {
                        
                        if (i === 0){
                            var total = x.col1 + x.col2  + x.col3  + x.col4 + x.col5 +
                                        x.col6 + x.col7  + x.col8  + x.col9 + x.col10;

                            if (total === 0){
                                return (
                                    <Table.Row key={i}>
                                        <Table.Cell textAlign="center">
                                            Summary
                                        </Table.Cell>
                                        <Table.Cell textAlign="center" colSpan="10">
                                            No Result
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                            return (
                                <Table.Row key={i}>
                                    <Table.Cell  textAlign="center">
                                    Hit/% Summary
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col1 }</Label>
                                        <Label >{_DisplayPercentage(x.col1, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col2 }</Label>
                                        <Label >{_DisplayPercentage(x.col2, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col3}</Label>
                                        <Label >{_DisplayPercentage(x.col3, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col4}</Label>
                                        <Label >{_DisplayPercentage(x.col4, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col5}</Label>
                                        <Label >{_DisplayPercentage(x.col5, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col6}</Label>
                                        <Label >{_DisplayPercentage(x.col6, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col7}</Label>
                                        <Label >{_DisplayPercentage(x.col7, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col8}</Label>
                                        <Label >{_DisplayPercentage(x.col8, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col9}</Label>
                                        <Label >{_DisplayPercentage(x.col9, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col10 }</Label>
                                        <Label >{_DisplayPercentage(x.col10, total, 0)}</Label>                    
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                        
                        
                        return (
                        <Table.Row key={i}>
                            <Table.Cell  textAlign="center">
                                {x.draw_Date}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                { _DisplayZero(x.col1)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col2)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col3)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col4)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col5)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col6)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col7)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col8)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col9)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col10)}
                            </Table.Cell>
                        </Table.Row>
            );
        }  
        )} 
        </Table.Body>
        </Table>                  
   </div>
    );
});