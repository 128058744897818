import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Grid, Header, Label } from "semantic-ui-react";
import MyDropDownSearchMultipleFormikNoAdd from "../../app/lib/MyDropDownSearchMultipleFormikNoAdd";
import {  _RatingOptionStringsForAlert, _StatusOptionsOnlyForAlert } from "../../app/lib/CommonData";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import { useStore } from "../../app/store/store";
import LoadingComponent from "../../app/lib/LoadingComponent";

export default function AlertSetting(){
    const categoryID = parseInt(localStorage.getItem("alertSetting")!.split('|')[0]);
    const system4D = localStorage.getItem("alertSetting")!.split('|')[1];
    const {modalStore} = useStore();
    const [dbStatus, setDBStatus] = useState<string[]>([]);    
    const [dbRating, setDBRating] = useState<string[]>([]);    
    const [isLoaded, setIsLoaded] = useState(false);

    
    const turnOffAlert = () =>{
        agent.Draw4DResult.updateAlert(categoryID, system4D, false).then((response)=>{
            modalStore.closeModal();  
        }).catch((err)=>{
            toast.error(err);
        });
    }

    useEffect(()=>{
                
        agent.Draw4DResult.getAlertSetting(categoryID, system4D).then((response) =>{
            setDBStatus(response.status);
            setDBRating(response.rating);
            setIsLoaded(true);        
        });


    }, [categoryID, system4D]);

    
    if (!isLoaded) return (<LoadingComponent></LoadingComponent>);

    return (

    <Formik initialValues={{ status:dbStatus, rating: dbRating, error: null}}
        onSubmit={
        (values,{setErrors}) => {
                var status = values["status"];
                var rating = values["rating"];

                if(status.length === 0 && rating.length === 0){
                    setErrors({error:"Must select one of alert conditions !"});
                    return;
                }

                if(status.length === 1 && rating.length === 1 && status[0].trim() === "" && rating[0].trim() === ""){
                    setErrors({error:"Must select one of alert conditions !"});
                    return;
                }

                agent.Draw4DResult.updateAlertWithData(categoryID, system4D, status.join(','), rating.join(',')).then((response) => {
                    modalStore.closeModal();
                }).catch((err) =>{

                    setErrors({error: "Update Failed " + err});
                    //data.checked = !data.checked;
                });                
        }
        }>
    {(
        {handleSubmit, isSubmitting, errors}) => (
            <Form className="ui form" onSubmit={handleSubmit}>
                <Grid>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column width={2}>
                            <Header>Status</Header>
                        </Grid.Column>
                        <Grid.Column width={14}>
                            <MyDropDownSearchMultipleFormikNoAdd placeholder="Alert for Status" toolTip="Alert for Status"
                                options={_StatusOptionsOnlyForAlert} name="status">
                            </MyDropDownSearchMultipleFormikNoAdd>        
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row  verticalAlign="middle">
                    <Grid.Column width={2} >
                            <Header>Rating</Header>
                        </Grid.Column>
                        <Grid.Column width={14}>
                            <MyDropDownSearchMultipleFormikNoAdd placeholder="Alert for Rating" toolTip="Alert for Rating"
                                options={_RatingOptionStringsForAlert} name="rating">
                            </MyDropDownSearchMultipleFormikNoAdd>                                 
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                                <Divider></Divider>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        </Grid.Column>
                        <Grid.Column width={8} textAlign="right">
                                <Button secondary content="Turn Off" icon="toggle off" type="button" onClick={()=>turnOffAlert()}></Button>
                                <Button primary content="Save" icon="save" type="submit"></Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Form>
        
    )}
    </Formik>
)}