import React, {  useState } from "react";
import { useEffect } from "react";
import { CartesianGrid, LabelList, Legend, Line, LineChart,  ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import { Props } from "recharts/types/component/DefaultLegendContent";
import {Divider, Grid, Header, Label, Segment } from "semantic-ui-react";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { GraphicResult } from "../../app/models/IDayResult";



export default function ViewGraphics3D(){
  let system3D : string = localStorage.getItem("System3DGraph") + "";

  const CustomTooltip = ({ active, payload, label } : any) => {
    if (active && payload && payload.length) {

      let interval = 0;
      let prizeCode = "";
      let index = 0;
      let min = 0;
      let max = 0;

      data.map((x)=>{
        if (x.draw_Date === label){
          index = x.key;
          interval = x.interval;
          prizeCode = x.prize_Code;
          min = x.minInterval;
          max = x.maxInterval;
          return false;
        }
        return true;
      });

      if (index === 1){
        const colorMessage = (interval >= min && interval <= max ? "green" : "red");
        const messageStyle = {
          color: colorMessage,
          fontWeigth:"bold"
        };

        const message: string =  (interval < min ? "Not Due (Stay)" : (interval > max ? "Over Due (Hold)" : "In Range(Buy)"));
        return (
          <Segment>
            {"Date : " + label}<br/>
            {"Prize : ?" }<br/>
            {"Current Interval : " + interval}<br/><br/>
            <span style={messageStyle}>{message}</span>
          </Segment>
        );
  


      }
      return (
        <Segment>
          {"Date : " + label}<br/>
          {"Prize : " + prizeCode}<br/>
          {"Hit Interval : " + interval}
        </Segment>
      );
    }
  
    return null;
  };

/*const CustomizeLabel = (props: any) =>{
  const { x, y, stroke, value } = props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    ); 
}*/

  const CustomizedDot = (props: any) => {
    const { cx, cy,  payload } = props;
    
    if (payload.prize_Code === '1' || payload.prize_Code === '2' || payload.prize_Code === '3') {
      return (
        <circle cx={cx} r="6" cy={cy} fill="red">
          { payload.key === 1 ?
            <animate
            attributeName="r"
            from="5"
            to="15"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          /> : null
          }
        </circle>
      );
    }
    if (payload.key === 1){
      return (<circle cx={cx} r="6" cy={cy} fill="blue">
        <animate
        attributeName="r"
        from="5"
        to="15"
        dur="1.5s"
        begin="0s"
        repeatCount="indefinite" />
        </circle>
      );
    }

    return (
        
        <circle cx={cx} r="6" cy={cy} fill="black">
        </circle>
    );
  };

  const ActiveDot = (props: any) => {
    const {cx, cy} = props;
    
    return (
        <circle cx={cx} r="4" cy={cy} fill="blue">
        </circle>
    );
  };

  const CustomizedLabel = (props: any) => {
    const { x, y, stroke, value } = props;
  
    return (
      <text x={x} y={y} dy={-10} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    );
  };
  
  
  const renderLegend = (prop: Props) => {
    return (
      <ul>
      </ul>
    );
  }
    const [data, setData] = useState<GraphicResult[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [isLower, setIsLower] = useState(0);
    const [isBelowAvg, setBelowAvg] = useState(0);
    const [isAboveAvg, setAboveAvg] = useState(0);
    const [isAboveMax, setAboveMax] = useState(0);
    
    useEffect(()=>{
        agent.Draw4DResult.getGraphicsTop33DResult(system3D).then((response)=>{
            setData(response);
            var lowerCount = 0;
            var belowAvg = 0;
            var aboveAvg = 0;
            var aboveMax = 0;
            if (response.length > 0){
                var minInterval = response[0].minInterval;
                var maxInterval = response[0].maxInterval;
                var avgInterval = response[0].average;

                response.map((x) => {
                    if (x.interval < minInterval){
                      lowerCount++;
                    }
                    else{
                      if (x.interval < avgInterval){
                        belowAvg++;
                      }
                      else{
                        if (x.interval <= maxInterval){
                            aboveAvg++;
                        }
                        else{
                          aboveMax++;
                        }
                      }
                    }
                    return true;
                })
            }
            setIsLower(lowerCount);
            setBelowAvg(belowAvg);
            setAboveAvg(aboveAvg);
            setAboveMax(aboveMax);

            setLoading(false);
            //setLoaded(true);
            //isLoading = false;
        });

    }, [system3D])

    const maxInterval = data.length > 0 ? Math.max.apply(Math, data.map(function(o) { return o.interval; })):0;


    const RenderDefaultLabel  = ({ viewBox } : any) => {

      const state = data[0].current_Interval < data[0].minInterval ? "Not Due(Stay)" :
                  data[1].current_Interval > data[0].maxInterval ? "Overdue(Hold)": "InRange(Buy)";
      const colorSpan = data[0].current_Interval < data[0].minInterval ? "orange" :
      data[1].current_Interval > data[0].maxInterval ? "red": "green";
      return (

      <g>
        
        <foreignObject x={viewBox.x} y={0} width={viewBox.width} height={100}>
              <div>Graph - 3D Interval Hits - Top3 Prizes<br/>
                    Number Analysed: <b>{system3D}</b><br/>Current Interval : {data[0].current_Interval}&nbsp;&nbsp;[<span style={{color:colorSpan}}>State: {state}</span>]
                    <br/>No. Hits - <span style={{color:"orange"}}>ND: &lt;{data[0].minInterval}={isLower}</span>
                    <span style={{color:"green", marginLeft: "20px", marginRight:"5px"}}>IR1: {data[0].minInterval} - {data[0].average - 1} = {isBelowAvg}
                    ,</span>
                    <span style={{color:"green", marginLeft: "5px", marginRight:"20px"}}>IR2: {data[0].average} - {data[0].maxInterval} = {isAboveAvg}
                    </span>
                    <span style={{color:"red", marginLeft: "20px", marginRight:"20px"}}>OD: &gt; {data[0].maxInterval} = {isAboveMax}</span><br/>
              </div>
          </foreignObject>
      </g>);
    }

    if (isLoading) return (<LoadingComponent></LoadingComponent>);
    if (data.length <=6) return (<Header>No Data</Header>);
    return (
        <div style={{overflowX:"auto", textAlign:"center", height:"100%", width:"100%"}}>
        <Grid>
          <Grid.Column width={16}>
          Number Analyzed: {system3D} &nbsp;
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <LineChart
          width={2000}
          height={600}
          data={data}
          margin={{
            top: 110,
            right: 10,
            left: 10,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="draw_Date">
          <Label value="Draw Date" offset={-10} position="insideBottom" />
          </XAxis>
          <YAxis label={{value: "Interval",angle: -90, position: 'insideLeft', textAnchor: 'middle' }}/>
          <Tooltip content={<CustomTooltip/>}  />
          <Legend content={renderLegend}/>
          
          {data && data.length > 0 &&
            <ReferenceLine y={data[0].maxInterval} label={"Max [" + data[0].maxInterval+"]"} stroke="red" strokeDasharray="3 3" />
          }

          {data && data.length > 0 &&
            <ReferenceLine y={data[0].minInterval} label={"Min [" + data[0].minInterval +"]"} stroke="green" strokeDasharray="3 3">
            </ReferenceLine>
          }

          <Line  legendType="circle"  type="monotone" dataKey="interval" stroke="#964B00"  dot={<CustomizedDot />} activeDot={<ActiveDot/>} >
          <LabelList content={<CustomizedLabel />} />
          </Line>

           {data && data.length > 0 &&
            <ReferenceLine y={data[0].average} label={"Average [" + data[0].average+"]"} stroke="blue" strokeDasharray="3 3" />
          }
         
          {data && data.length > 0 &&
           <ReferenceLine y={maxInterval} label={<RenderDefaultLabel/>} />                           }{
          }

        </LineChart>
        </div>
    )
}