import React from "react";
import { Button } from "semantic-ui-react";
import { history } from "../..";

interface Props{
    range: string;
    code: string;
    drawType: string;
}


export default function RedirectTo4DSumRoot({range, code, drawType} : Props){
    const redirectTo4DPage=() => {
        if (code === "S4" || code === "U4"){
            history.push("/4DAnalysisSumRoot/" + code + "/" + range + "/"+ (drawType ==='A' ? 'ABCD' : drawType) );
            return;
        }
      }

    if (code === "U4"){
        return (
            <Button type="button" onClick={()=>redirectTo4DPage()} data-tooltip={"Click here to view Sum 4D: " + range}>{range}</Button> 
        );
    }
    
    return (
        <Button type="button" onClick={()=>redirectTo4DPage()} data-tooltip={"Click here to view Sum Root: " + range}>{range}</Button> 
    );
}