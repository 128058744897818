import { ErrorMessage, Form, Formik} from "formik";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { Button,    Grid,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import { useStore } from "../../app/store/store";
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import agent from "../../app/api/agent";
import { _SortNumber } from "../../app/lib/CommonFunctions";
import { toast } from "react-toastify";

export default observer(function WeeklyForecast(){
    const {userStore} = useStore();
    const formRef = useRef<any>();
    const [isChanged, setChanged] = useState(false);
    const [isNew, setIsNew] = useState(true);

    const userID: string = userStore.user!.username;

    const getExisting = ()=>{
        agent.Account.getExistingForecast(userID).then((response)=>{
            formRef.current.values["system_AAAB"] = response.system_AAAB;
            formRef.current.values["system_AAAB_Direct"] = response.system_AAAB_Direct;
            formRef.current.values["system_AABB"] = response.system_AABB;
            formRef.current.values["system_AABB_Direct"] = response.system_AABB_Direct;
            formRef.current.values["system_AABC"] = response.system_AABC;
            formRef.current.values["system_AABC_Direct"] = response.system_AABC_Direct;
            formRef.current.values["system_ABCD"] = response.system_ABCD;
            formRef.current.values["system_ABCD_Direct"] = response.system_ABCD_Direct;
            setIsNew(false);            
            setChanged(true);
        })
    }

    const deleteExisting = ()=>{
        if (window.confirm("Are you sure to delete ?")){
            agent.Account.deleteExistingForecast(userID).then((response)=>{
                if (response === "OK"){
                    formRef.current.values["system_AAAB"] = "";
                    formRef.current.values["system_AAAB_Direct"] = "";
                    formRef.current.values["system_AABB"] = "";
                    formRef.current.values["system_AABB_Direct"] = "";
                    formRef.current.values["system_AABC"] = "";
                    formRef.current.values["system_AABC_Direct"] = "";
                    formRef.current.values["system_ABCD"] = "";
                    formRef.current.values["system_ABCD_Direct"] = "";

                    setIsNew(true);            
                    setChanged(true);
                }
                else{
                    toast.error(response);
                }
            });
        }
    }
    const handleRetrieve = (field: string, direct: string) =>{
        const data = formRef.current.values[field];
        setChanged(false);
        if ((data + "").length === 4){
            const newData = _SortNumber(true, data);

            agent.Draw4DResult.getRecommendedDirectNumber(newData).then((response)=>{
                var arr:string[] = [];
                
                response.map(x=>{
                    arr.push(x.number);
                    return true;
                });
                formRef.current.values[field] = newData;
                formRef.current.values[direct] = arr.join(" ");
                setChanged(true);
            });
        }
    }

    const validationSchema = Yup.object({
        system_ABCD: Yup.number().required("ABCD is required"),
        system_AABC: Yup.number().required("AABC is required"),
        system_AABB: Yup.number().required("AABB is required"),
        system_AAAB: Yup.number().required("AAAB is required"),

        system_ABCD_Direct: Yup.string().required("ABCD Direct is required"),
        system_AABC_Direct: Yup.string().required("AABC Direct is required"),
        system_AABB_Direct: Yup.string().required("AABB Direct is required"),
        system_AAAB_Direct: Yup.string().required("AAAB Direct is required")

    });

    return (
        <Formik innerRef={formRef} initialValues={{userID: "", system_ABCD: "",system_ABCD_Direct: "", system_AABC:"", system_AABC_Direct:"",system_AABB: "", system_AABB_Direct:"", system_AAAB:"", system_AAAB_Direct:"",  error: null}} 
        onSubmit={
                    (values,{setErrors}) => {
                        userStore.submitForecast(values, isNew, isChanged).catch(err => setErrors({error: 'Unable to submit the weekly forecast'}))
                        setIsNew(true);
                        setChanged(false);
                    }
            } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <Grid>
                        <Grid.Row>
                        <Grid.Column width={4}>
                            <MyTextInputNonFluid clickAction={{icon: "sync alternate", type: "button",
                                onClick: () => handleRetrieve("system_ABCD","system_ABCD_Direct")}} 
                             name="system_ABCD" placeholder="System ABCD" maxLength={4}></MyTextInputNonFluid>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <MyTextInputNonFluid  name="system_ABCD_Direct" placeholder="System ABCD Direct" maxLength={50}></MyTextInputNonFluid>
                        </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                        <Grid.Column width={4}>
                            <MyTextInputNonFluid  clickAction={{icon: "sync alternate", type: "button",
                                onClick: () => handleRetrieve("system_AABC","system_AABC_Direct")}}
                            name="system_AABC" placeholder="System AABC" maxLength={4}></MyTextInputNonFluid>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <MyTextInputNonFluid  name="system_AABC_Direct" placeholder="System AABC Direct" maxLength={50}></MyTextInputNonFluid>
                        </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                        <Grid.Column width={4}>
                            <MyTextInputNonFluid  clickAction={{icon: "sync alternate", type: "button",
                                onClick: () => handleRetrieve("system_AABB","system_AABB_Direct")}}
                            name="system_AABB" placeholder="System AABB" maxLength={4}></MyTextInputNonFluid>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <MyTextInputNonFluid  name="system_AABB_Direct" placeholder="System AABB Direct" maxLength={50}></MyTextInputNonFluid>
                        </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                        <Grid.Column width={4}>
                            <MyTextInputNonFluid  clickAction={{icon: "sync alternate", type: "button",
                                onClick: () => handleRetrieve("system_AAAB","system_AAAB_Direct")}} name="system_AAAB" placeholder="System AAAB" maxLength={4}></MyTextInputNonFluid>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <MyTextInputNonFluid  name="system_AAAB_Direct" placeholder="System AAAB Direct" maxLength={50}></MyTextInputNonFluid>
                        </Grid.Column>
                        </Grid.Row>

                        </Grid>

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button style={{marginTop:"20px"}} icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                        <Button onClick={()=>getExisting()} style={{marginTop:"10px"}} icon="sync alternate"  content="Retrieve" type="button" fluid></Button>
                        <Button onClick={()=>deleteExisting()} style={{marginTop:"10px"}} icon="delete"  content="Delete" type="button" fluid></Button>

                    </Form>
            )}
        </Formik>
    )
})