import React, { SyntheticEvent } from "react";
import {  Dropdown, DropdownItemProps, DropdownProps, Popup } from "semantic-ui-react";


interface Props{
    children: never[];
    onChange: (e: SyntheticEvent<any, Event>, option: DropdownProps)=>void;
    placeholder: string;
    defaultValue: string | number;
    options?: DropdownItemProps[];
    zIndex: string;
    toolTip?: string;
    toolTipDirection?:"top center" | undefined ;
}

export default function MyDropDown(props: Props){
    

    return(
        <>
            <Popup content={props.toolTip} position={props.toolTipDirection} trigger={
                <Dropdown  fluid 
                    selection 
                    style={{zIndex:props.zIndex}}
                    onChange = {props.onChange}
                    placeholder={props.placeholder}
                    options={props.options}
                    defaultValue = {props.defaultValue}
                    direction = "right"
                />     
            }>
                
            </Popup>
           
        </>
    )
}