import { observer } from "mobx-react-lite";
import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Image, Menu, Input, InputOnChangeData, Dropdown, Button } from "semantic-ui-react";
import ChangeDevice from "../../feature/admin/ChangeDevice";
import Registration from "../../feature/admin/Registration";
import RenewSubscription from "../../feature/admin/RenewSubscription";
import ChangePasswrord from "../../feature/ChangePasswrord";
import WeeklyForecast from "../../feature/Forecast/WeeklyForecast";
import LoginForm from "../../feature/LoginForm";
import ListUser from "../../feature/Users/ListUser";
import agent from "../api/agent";
import { useStore } from "../store/store";
import DeleteMember from "../../feature/admin/DeleteMember";
import { toast } from "react-toastify";
import AddEWinbox from "../../feature/admin/AddEWinbox";



function FourDNavBar(){
    var history = useHistory();
    const {userStore: {user, isLogged, systemLogout}, modalStore} = useStore();

    const redirectTo4DGM = () => {
        history.push("https://ai4dchat.4dgrandmaster.com/")
    }
    const listUser = () =>{
        modalStore.openModal(<ListUser></ListUser>,"User List","large", null, ()=>{})
    }

    const forecastMonthClick = ()=>{
        if (window.confirm("Are you sure to change daily to monthly forecast ? Make sure do this function for next draw is new month of the draw !")){
            agent.Account.changeToMonthlyForecast().then (()=>{
                toast.success("Change to Monthly success. Wait for the processing to complete and check again !");
            });
        }
    }

    const switchRole = () =>{
        agent.Account.changeRole().then(() => {
            systemLogout();
        });
        
    }


    const submitForecast = () =>{
        modalStore.openModal(<WeeklyForecast></WeeklyForecast>,"Enter Weekly Forecast","large", null, () => {})
    }
    const changePasword = () =>{
        modalStore.openModal(<ChangePasswrord></ChangePasswrord>,"Change Password","mini", null, ()=>{})
    }

    const registerUser = () =>{
        modalStore.openModal(<Registration></Registration>,"Register Member","mini", null, ()=>{})
    }

    const changeDevice = () =>{
        modalStore.openModal(<ChangeDevice></ChangeDevice>,"Change/Reset Device","mini", null, ()=>{})
    }

    const renewSubscription = () =>{
        modalStore.openModal(<RenewSubscription></RenewSubscription>,"Renew Subscription","mini", null, ()=>{})
    }

    const submitEWinBox = () =>{
        modalStore.openModal(<AddEWinbox></AddEWinbox>,"Submit eWinBox Numbers","mini", null, ()=>{})
    }

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData)=>{
       

        if (data && data.value.length === 4 && !isNaN(parseFloat(data.value))){
            history.push("/search/" + data.value);
        }
    };

    const deleteUser = () =>{
        modalStore.openModal(<DeleteMember></DeleteMember>,"Delete Member","mini", null,()=>{})
    }
    
    const photoPath = user == null || user.image == null ? "/assets/user.png": agent.Photo.getURL(user?.image!);

    const isBasic = isLogged && user?.groupID !== undefined && user.groupID > 1 ? false: true;

    const displayVersion = !isLogged ? "(Not Login)" : (isBasic ? "Basic" : "Professional");
    return (
        <Menu inverted fixed="top" fluid stackable>
                <Menu.Item header as={NavLink} to="/" name="home">
                    <img src="/assets/4DGM-logo.jpg" alt="Logo" style={{marginRight:"10px"}} />
                    4D Grandmaster<br/>
                    {displayVersion}
                </Menu.Item>
                {isLogged ? (
                    <>
                        <Dropdown scrolling  item text="Analysis" name="analysis" style={{zIndex:999999}}>
                        <Dropdown.Menu>
                            <Dropdown.Item target="_blank" href="https://ai4dchat.4dgrandmaster.com/" text='4DGM AIChat' />

                                <Dropdown.Item as={NavLink} to="/analysis" text='4D Analytics Overview' />
                                <Dropdown.Item as={NavLink} to="/overviewbymonth" text='4D By Cumulative Month' disabled = {isBasic}/>
                                <Dropdown.Item as={NavLink} to="/analysisbymonth" text='4D By Cur Month' />
                                <Dropdown.Item as={NavLink} to="/system4DWithR" text='4D with Roll' disabled = {isBasic} />
                                
                                <Dropdown.Item as={NavLink} to="/quickdirect" text='Express Direct' />
                                <Dropdown.Item as={NavLink} to="/quickgraph" text='Quick Graph' />
                                <Dropdown.Item as={NavLink} to="/quickgraphTop3" text='Quick Graph Top3' disabled = {isBasic} />
                                <Dropdown.Item as={NavLink} to="/forecast" text='Monthly 4D Forecasts' />
                                <Dropdown.Item as={NavLink} to="/ewinbox" text='Per-Draw 4D e-WinBox' disabled = {isBasic}/>
                                <Dropdown.Item as={NavLink} to="/ewinbox2" text='Monthly 4D e-WinBox' disabled = {isBasic}/>

                                <Dropdown.Item as={NavLink} to="/watchlist" text='Watch List' disabled = {isBasic}/>

                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown scrolling  item text="1D/2D/3D" name="D1_2_3" style={{zIndex:999999}}>
                        <Dropdown.Menu>
                            <Dropdown.Item as={NavLink} to="/digitposition" text='1-Digit Position' />

                                <Dropdown.Item as={NavLink} to="/analysis2D" text='2D Analysis' />
                                <Dropdown.Item as={NavLink} to="/best2d" text='2D Analysis 10 Years' disabled = {isBasic}/>
                                <Dropdown.Item as={NavLink} to="/view3dfrom2d" text='2D To 3D'  disabled = {isBasic}/>
                                <Dropdown.Item as={NavLink} to="/analysis3D" text='3D Analysis' />
                                <Dropdown.Item as={NavLink} to="/best3d" text='3D Analysis 10 Years'  disabled = {isBasic}/>
                                <Dropdown.Item as={NavLink} to="/view4dfrom3d" text='3D to 4D' />

                                {user?.username === "WalterTang" &&
                                    <Dropdown.Item as={NavLink} to="/querymonthday" text='Month and DOW Result' />
                                }
                                <Dropdown.Item as={NavLink} to="/querynumber" text='Filter Numbers' disabled = {isBasic}/>

                                <Dropdown.Item as={NavLink} to="/scorenumber" text='Score Numbers' disabled = {isBasic}/>

                            </Dropdown.Menu>
                        </Dropdown>
                      
                        
                        <Dropdown scrolling item text="Trends" name="Trends" style={{zIndex:999999}}> 
                            <Dropdown.Menu>
                                <Dropdown.Item as={NavLink} to="/drawresult" text='Draw Results' />
                                <Dropdown.Item as={NavLink} to="/patternhistory" text='Draw Pattern' />

                                <Dropdown.Item as={NavLink} to="/intervalhistory" text='4D Intervals' />
                                <Dropdown.Item as={NavLink} to="/rankallhistory" text='4D Rank/Hit'  disabled = {isBasic} />
                                <Dropdown.Item as={NavLink} to="/combination3top3intervalhistory" text='3D Interval' />
                                <Dropdown.Item as={NavLink} to="/result3DRank" text='3D By Rank'  disabled = {isBasic} /> 
                                <Dropdown.Item as={NavLink} to="/sum3d" text='Sum 3D Interval'  disabled = {isBasic}/> 
                                <Dropdown.Item as={NavLink} to="/sum2d" text='Sum 2D Interval'  disabled = {isBasic}/> 
                                <Dropdown.Item as={NavLink} to="/sum4dNumber" text='Sum 4D (Root)'  disabled = {isBasic}/> 
                                <Dropdown.Item as={NavLink} to="/sum4dNumber2D" text='Sum 4D'  disabled = {isBasic}/> 

                                <Dropdown.Item as={NavLink} to="/ratinghistory" text='Rating History' disabled = {isBasic}  /> 
                                <Dropdown.Item as={NavLink} to="/statushistory" text='Status History' disabled = {isBasic}  /> 
                                <Dropdown.Item as={NavLink} to="/statushistory3D" text='Status History System 3D' disabled = {isBasic}  /> 

                            </Dropdown.Menu>
                        </Dropdown>

                        {
                            (user?.username === "Charles" || user?.username==="SteveXie") &&
                            <Dropdown scrolling item text="Admin" name="Admin" style={{zIndex:999999}}>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={listUser} text='User List'/>
                                <Dropdown.Item onClick={switchRole} text='Switch Role' />

                                <Dropdown.Item onClick={registerUser} text='Register Member' />
                                <Dropdown.Item onClick={renewSubscription} text='Renew Subscription' />
                                <Dropdown.Item onClick={deleteUser} text='Delete Member' />
                                <Dropdown.Item onClick={changeDevice} text='Change/Reset Device' />
                                <Dropdown.Item  as={NavLink} to="/viewForecastMethod" text='View Forecast Result' />
                                <Dropdown.Item onClick={submitForecast} text='Submit Weekly Forecast'/>
                                <Dropdown.Item onClick={forecastMonthClick} text='Trigger Monthly Forecast' />
                                <Dropdown.Item onClick={submitEWinBox} text='Submit EWinBox' />

                            </Dropdown.Menu>
                            </Dropdown>
                        }
                    </>)
                : null
                }
                {isLogged ? (
                    <Menu.Item name="search" position="right" >
                        <Input autoComplete="on" icon='search' placeholder='Search 4D Numbers' onChange={handleChanged}  />
                    </Menu.Item>
                    ) : (
                        <Menu.Item name="login" position="right">
                            <Button icon="lock" fluid  onClick={ () =>{
                                modalStore.openModal(<LoginForm></LoginForm>,"Login","mini", null,()=>{})
                            }} positive content="Login"></Button>
                        </Menu.Item>

                    )}

                    {isLogged ? (
                        <Menu.Item name="user" position="right" style={{marginRight: "20px"}}>
                        <Image src={photoPath} avatar spaced="right"></Image>

                        <Dropdown pointing="top right" text={user?.displayName} item style={{zIndex:999999}}>
                            <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={'/profile'} text="My Profile" icon="user"></Dropdown.Item>
                        <Dropdown.Item  onClick={changePasword} text="Change Password" icon="key"></Dropdown.Item>
                        <Dropdown.Item  as={NavLink} to="/about" text='Contact Us' icon="envelope" />
                 
                        <Dropdown.Item onClick={systemLogout} text="Logout" icon="power"></Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                        </Menu.Item>
                    ) : (
                            null
                    )}
        </Menu>
    );
}

export default  observer(FourDNavBar);
    
