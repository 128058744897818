import React from "react";
import { Embed } from "semantic-ui-react";

export default function ShowAwakenVideo(){
    return (
         <div style={{overflowX:"auto"}}>
        <Embed id="SMlWgo8mk1c"  source='youtube' autoplay={true} brandedUI>
        </Embed>

         </div>
    );
}