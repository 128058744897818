import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button,  Label, Message } from "semantic-ui-react";
import { useStore } from "../app/store/store";
import * as Yup from 'yup';
import { history } from "..";
import MyTextInputNonFluidPassword from "../app/lib/MyTextInputNonFluidPassword";


export default observer(function ChangePassword(){
    const {userStore} = useStore();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const togglePassword = ()=> {
        setShowPassword(!showPassword);
    };

    const togglePassword1 = ()=> {
        setShowPassword1(!showPassword1);
    };

    const togglePassword2 = ()=> {
        setShowPassword2(!showPassword2);
    };

    if(!userStore.isLogged){
        history.push("/");
    }

    const validationSchema = Yup.object({
        password: Yup.string().required("Existing Password is required"),
        newPassword: Yup.string().required("New Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required").oneOf([Yup.ref('newPassword'), null], 'New & Confirm Passwords must match')
    });

    return (
        <>
        <Formik initialValues={{confirmPassword: "", password:"", newPassword:"", error: null}}
                onSubmit={
                    (values,{setErrors}) => userStore.changePassword(values).catch(err => setErrors({error: 'Unable to Login to the System'}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluidPassword togglePassword={togglePassword} name="password" placeholder="Password" type={!showPassword ? "password" : ""} showPassword={showPassword}></MyTextInputNonFluidPassword>
                        <MyTextInputNonFluidPassword togglePassword={togglePassword1} name="newPassword" placeholder="New Password" type={!showPassword1 ? "password" : ""} showPassword={showPassword1}></MyTextInputNonFluidPassword>
                        <MyTextInputNonFluidPassword togglePassword={togglePassword2} name="confirmPassword" placeholder="Confirm Password" type={!showPassword2 ? "password" : ""} showPassword={showPassword2}></MyTextInputNonFluidPassword>
                        

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="key" loading={isSubmitting} positive content="Change Password" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
        <Message>
            <p>
            Password must be:
            - at least 1 uppercase
            - at least 1 lowercase
            - at least 1 digit
            - at 1 symbol 
            - minimum length 8        
            </p>
        </Message>
        </>
    )
})