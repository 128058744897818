import React from "react";
import {  Button, Label, Table } from "semantic-ui-react";
import { history } from "../../..";
import { _DisplayZero } from "../../../app/lib/CommonFunctions";
import { System3DAllRankPerMonthPattern } from "../../../app/models/IDayResult";


interface Props{
  data: System3DAllRankPerMonthPattern[],
  drawType: string
}
export default function System3DAllRankPerMonthResult({data, drawType} : Props){

  const redirectTo3DPage=(pageNo: string) => {
    history.push("/3DAnalysisTypeAndRange/M/" + pageNo+"/"+drawType);
  }
return (
<Table celled  sortable unstackable className="freeze-head-and-col">
<Table.Header>
<Table.Row>
    <Table.HeaderCell textAlign="center" colSpan="18">
      System 3D Rank Per Month Trend (All Prizes)
    </Table.HeaderCell>
  </Table.Row>
  <Table.Row>
    <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button data-tooltip="Click here to get 1-5 rank 3D" type="button" onClick={()=>redirectTo3DPage('1-5')}>1-5</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('6-10')} data-tooltip="Click here to get 6-10 rank 3D">6-10</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('11-15')} data-tooltip="Click here to get 11-15 rank 3D">11-15</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('16-20')} data-tooltip="Click here to get 16-20 rank 3D">16-20</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('21-25')} data-tooltip="Click here to get 21-25 rank 3D">21-25</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('26-30')} data-tooltip="Click here to get 26-30 rank 3D">26-30</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('31-35')} data-tooltip="Click here to get 31-35 rank 3D">31-35</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('35-99')} data-tooltip="Click here to get Over 35 rank 3D">Over 35</Button>
    </Table.HeaderCell>

    </Table.Row>
</Table.Header>

<Table.Body>
    {data.map((x,i) => {
        if (i === 0){
             const total = x.a1_5 + x.a6_10 + x.a11_15 + x.a16_20 + x.a21_25 + x.a26_30 + x.a31_35 + x.over_35;
        
             if (total === 0){
               return (
                <Table.Row key={0}>
                  <Table.Cell colSpan={9}>
                    No Data
                  </Table.Cell>
                </Table.Row>

               );
             }
             return (
              <Table.Row key={i}>
                  <Table.Cell  textAlign="center">
                  {x.drawDate}
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a1_5}</Label>
                    <Label>{(x.a1_5 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a6_10}</Label>
                    <Label>{(x.a6_10 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                      <Label circular color="blue">{x.a11_15}</Label>
                      <Label>{(x.a11_15 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                      <Label circular color="blue">{x.a16_20}</Label>
                      <Label>{(x.a16_20 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a21_25}</Label>
                    <Label>{(x.a21_25 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a26_30}</Label>
                    <Label>{(x.a26_30 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a31_35}</Label>
                    <Label>{(x.a31_35 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.over_35}</Label>
                    <Label>{(x.over_35 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
              </Table.Row>
            );
          }
        return (
            <Table.Row key={i}>
                <Table.Cell  textAlign="center">
                {x.drawDate}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a1_5)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a6_10)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a11_15)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a16_20)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a21_25)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.a26_30)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.a31_35)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.over_35)}
                </Table.Cell>
            </Table.Row>
          );
    })}
</Table.Body>
</Table>
)
}