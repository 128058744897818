import { ErrorMessage, Formik } from "formik"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { toast } from "react-toastify";
import { Button, Form,  Grid,  Item,  Label, Segment } from "semantic-ui-react"
import * as Yup from 'yup';
import agent from "../../app/api/agent";
import MyTextInput from "../../app/lib/MyTextInput"
import { RecommendedDirectNumber } from "../../app/models/IDayResult";

interface Props{
    selectedDate: string;
}

export default observer(function RecommendedDirect({selectedDate} : Props){
    const [data, setData] = useState<RecommendedDirectNumber[]>([]);
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object({
        system4D: Yup.number().required("System 4D is required")
    });

    const search4D = (system4D: string) => {
        setLoading(false);
        if (system4D.length < 4) {
            toast.error("System4D must be 4 digit numbers !");
            return;
        }
    
        agent.Draw4DResult.getHistoryRecommendedNumber(system4D, selectedDate).then((response)=>{
            setData(response);
            
        });      
    }
return (
    <>
    <Formik initialValues={{system4D: "", error: null}}
        onSubmit={
        (values,{setErrors}) => {
                setLoading(true); 
                search4D(values["system4D"]);
        }
        } validationSchema={validationSchema}>
    {(
        {handleSubmit, isSubmitting, errors}) => (
            <Form className="ui form" onSubmit={handleSubmit}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} textAlign="right">
                            <MyTextInput name={"system4D"} placeholder={"System 4D"} maxLength={4}></MyTextInput>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign="left">
                        <Button icon="search" loading={loading} positive content="Search" type="submit" fluid></Button>
                        </Grid.Column>
                        <Grid.Column width={4}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Form>
        
    )}
    </Formik>
    {data && data.length > 0 &&
    <Segment raised textAlign="left">
             <Label as='a' color='green' ribbon>
                    4DGM Recommended Direct Numbers on {selectedDate}
            </Label>
            <Item.Group relaxed horizontal>
             {
                 data && data.length > 0 &&
                 data.map(x=>{
                    return (
                    <Item>
                    <Item.Content verticalAlign='middle'>
                        <Item.Header>{x.number}</Item.Header>
                    </Item.Content>
                    </Item>
                    )
                 })
             }   
             </Item.Group>
        </Segment>
        }
        </>        
)})
