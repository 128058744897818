import { makeAutoObservable, runInAction } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import { changePasswordFormValues, resetPasswordFormValues, User, userFormValues, userRegistrationValues,  userRenewSubscription,  WeeklyForecastValues } from "../models/user";
import { store } from "./store";
import DeviceUUID from "device-uuid";
import { toast } from "react-toastify";

export default class UserStore{
   
    deleteMember= async(values: { email: string; deviceID: string; error: null; })=> {
        if(window.confirm("Are you sure to delete member with email "+values.email)){
            values.deviceID = new DeviceUUID.DeviceUUID().get();

            await agent.Account.deleteMember(values);
            values.email = "";
            toast.success("Member deleted !");
        }


    }
    resetPassword = async (email:string) => {
        const creds = {email: email, password: "", deviceID:  new DeviceUUID.DeviceUUID().get() }
        await agent.Account.resetPassword(creds);
    }

    user: User | null = null;
    refreshTokenTimeOut: any;

    constructor(){
        makeAutoObservable(this);
    }

    get isLogged(){
        return !!this.user;
    }

    get isAdmin(){
        if (this.isLogged){
            if(this.user?.username === "Charles" || this.user?.username === "SteveXie"){
                return true;
            }
        }
        return false;
    }

    changePassword = async(values: changePasswordFormValues)=>{
        await agent.Account.changePassword(values);

        //store.commonStore.setToken(user.token);
        //this.startRefreshTokenTimer(user);

        store.modalStore.closeModal();

        this.logout();

        toast.success("Change Password Succesfully. Please login again");

    }

    resetActualPassword = async(values: resetPasswordFormValues)=>{
        await agent.Account.resetActualPassword(values);


        history.push("/");

        store.modalStore.closeModal();

    }

    submitForecast = async (values: WeeklyForecastValues, isNew: boolean, isChanged: boolean) =>{
        values.userID = this.user?.username!;

        agent.Account.submitForecast(values, isNew, isChanged);
        store.modalStore.closeModal();

    }

    login = async (creds: userFormValues) =>{
        try{

            creds.deviceID = new DeviceUUID.DeviceUUID().get();
            const deviceInfo = new DeviceUUID.DeviceUUID().parse();
            
            creds.displayName = JSON.stringify(deviceInfo);

            const user = await agent.Account.login(creds);

            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);

            runInAction(()=> this.user = user);


            store.modalStore.closeModal();
            history.push("/forecast");

        }catch(error){
            throw error;
        }
    }

    getPhotoURL = () => {
        const photoPath = this.user == null || this.user.image == null ? "/assets/user.png": agent.Photo.getURL(this.user?.image!);

        return photoPath;
    }

    systemLogout =  async() => {
        this.logout();
        await agent.Account.logout().catch((err) => {
            console.log("Logout Error" , err);
        });

    }

    logout =  () => {

        store.commonStore.setToken(null);


        window.localStorage.removeItem("4dgm_jwt_new");
        this.user = null;
        history.push("/");
    }

    getUser = async() =>{
        try{
            const user = await agent.Account.current();
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);

            runInAction(()=>{
                this.user = user;
            });
        }catch(error){
            console.log(error);
        }
    }

    changeDisplayName = async (userValue: userFormValues, file: any) =>{
        try{


            let formData = new FormData();
            formData.append('file', file);
            formData.append('password', userValue.password);
            formData.append('email', userValue.email);
            formData.append('displayName', userValue.displayName!);

            
            const returnUser = await agent.Account.changeDisplayName(formData);


            store.modalStore.closeModal();

            store.commonStore.setToken(returnUser.token);
            this.startRefreshTokenTimer(returnUser);

            runInAction(()=>{
                this.user = returnUser;

                history.push("/profile");
            });
            
        }catch(error){
            throw error;
        }
    }

    register = async (creds: userRegistrationValues) =>{
        try{
            creds.deviceID = new DeviceUUID.DeviceUUID().get();
            await agent.Account.register(creds);

            store.modalStore.closeModal();
        }catch(error){
            throw error;
        }
    }

    renewSubscription = async (creds: userRenewSubscription) =>{
        try{
            creds.deviceID = new DeviceUUID.DeviceUUID().get();
            await agent.Account.renewSubscription(creds);

            store.modalStore.closeModal();
        }catch(error){
            throw error;
        }
    }

    changeDevice = async (creds: userRegistrationValues) =>{
        try{
            creds.deviceID = new DeviceUUID.DeviceUUID().get();
            await agent.Account.changeDevice(creds);

            store.modalStore.closeModal();
        }catch(error){
            throw error;
        }
    }

    refreshToken = async () => {
        try{
            
            this.stopRefreshTokenTimer();
            const user = await agent.Account.refreshToken();

            runInAction(()=> this.user = user);
            store.commonStore.setToken(user.token);

            this.startRefreshTokenTimer(user);

        }catch(error){
            console.log(error);
        }
    }

    private startRefreshTokenTimer(user: User){
        const jwtToken = JSON.parse(atob(user.token.split('.')[1]));


        const expires = new Date(jwtToken.exp * 1000);
        
        const timeout = expires.getTime() - new Date().getTime() - (30 * 1000);
        
        this.refreshTokenTimeOut = setTimeout(this.refreshToken, timeout);
    }

    private stopRefreshTokenTimer(){
        clearTimeout(this.refreshTokenTimeOut);
    }
}