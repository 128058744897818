import { observer } from "mobx-react-lite";
import { Container, Header, Segment } from "semantic-ui-react";
import { useStore } from "../store/store";

export default observer(function ServerError(){
        const {commonStore} = useStore();

        return (
            <Container>
                <Header as="h1" content="Server Error"></Header>
                <Header sub as="h5" color="red" content={commonStore._error?.message}></Header>
                {commonStore._error?.details &&
                    <Segment>
                        <Header as="h4" content="Stack Trace" color="teal">
                            <code style={{marginTop:"10px"}}>{commonStore._error?.details}</code>
                        </Header>
                    </Segment>
                }
            </Container>
        )
        
})