import React from "react";
import {  Button, Label, Table } from "semantic-ui-react";
import { history } from "../../..";
import { _DisplayZero } from "../../../app/lib/CommonFunctions";
import { System3DTop3PerMonthPattern } from "../../../app/models/IDayResult";


interface Props{
  data: System3DTop3PerMonthPattern[],
  drawType: string
}
export default function System3DTop3RankPerMonthResult({data, drawType} : Props){
  const redirectTo3DPage=(pageNo: string) => {
    history.push("/3DAnalysisTypeAndRange/N/" + pageNo + "/"+ drawType);
  }
  
return (
<Table celled  sortable unstackable className="freeze-head-and-col">
<Table.Header>
<Table.Row>
    <Table.HeaderCell textAlign="center" colSpan="18">
      System 3D Rank Trend (Top3)
    </Table.HeaderCell>
  </Table.Row>
  <Table.Row>
    <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
      <Button type="button" onClick={()=>redirectTo3DPage('1-2')} data-tooltip="Click here to get 1-2 rank 3D">1-2</Button> 
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('3-4')} data-tooltip="Click here to get 3-4 rank 3D">3-4</Button> 
   </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
      <Button type="button" onClick={()=>redirectTo3DPage('5-6')} data-tooltip="Click here to get 5-6 rank 3D">5-6</Button>
     </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('7-8')} data-tooltip="Click here to get 7-8 rank 3D">7-8</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('9-10')} data-tooltip="Click here to get 9-10 rank 3D">9-10</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
      <Button type="button" onClick={()=>redirectTo3DPage('11-12')} data-tooltip="Click here to get 11-12 rank 3D">11-12</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center">
      <Button type="button" onClick={()=>redirectTo3DPage('13-14')} data-tooltip="Click here to get 6-10 rank 3D">13-14</Button>
    </Table.HeaderCell>
    <Table.HeaderCell textAlign="center"> 
      <Button type="button" onClick={()=>redirectTo3DPage('15-16')} data-tooltip="Click here to get 15-16 rank 3D">15-16</Button>
    </Table.HeaderCell>
    
    <Table.HeaderCell textAlign="center">
    <Button type="button" onClick={()=>redirectTo3DPage('17-99')} data-tooltip="Click here to get over 16 rank 3D">Over 16</Button>
    </Table.HeaderCell>

    </Table.Row>
</Table.Header>

<Table.Body>
    {data.map((x,i) => {
        if (i === 0){
             const total = x.a1_2 + x.a3_4 + x.a5_6 + x.a7_8 + x.a9_10 + x.a11_12 + x.a13_14 + x.a15_16 + x.over_16;
        
             if (total === 0){
               return (
                <Table.Row key={0}>
                  <Table.Cell colSpan={10}>
                    No Data
                  </Table.Cell>
                </Table.Row>

               );
             }
             return (
              <Table.Row key={i}>
                  <Table.Cell  textAlign="center">
                  {x.drawDate}
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a1_2}</Label>
                    <Label>{(x.a1_2 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a3_4}</Label>
                    <Label>{(x.a3_4 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                      <Label circular color="blue">{x.a5_6}</Label>
                      <Label>{(x.a5_6 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                      <Label circular color="blue">{x.a7_8}</Label>
                      <Label>{(x.a7_8 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a9_10}</Label>
                    <Label>{(x.a9_10 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a11_12}</Label>
                    <Label>{(x.a11_12 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a13_14}</Label>
                    <Label>{(x.a13_14 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a15_16}</Label>
                    <Label>{(x.a15_16 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>

                  <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.over_16}</Label>
                    <Label>{(x.over_16 / total * 100.0).toFixed(0)}%</Label>
                  </Table.Cell>
              </Table.Row>
            );
          }
        return (
            <Table.Row key={i}>
                <Table.Cell  textAlign="center">
                {x.drawDate}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a1_2)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a3_4)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a5_6)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a7_8)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                  {_DisplayZero(x.a9_10)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.a11_12)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.a13_14)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.a15_16)}
                </Table.Cell>
                <Table.Cell  textAlign="center">
                {_DisplayZero(x.over_16)}
                </Table.Cell>
            </Table.Row>
          );
    })}
</Table.Body>
</Table>
)
}