import React, { SyntheticEvent, useState } from "react";
import { useEffect } from "react";
import { DropdownProps, Label, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _PrizeTypeOptions } from "../../app/lib/CommonData";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyDropDown from "../../app/lib/MyDropDown";
import { DirectNumberResult } from "../../app/models/IDayResult";

interface Props {
    mainNumber: string,
    isSystem: boolean,
    detailType: string;
}

export default function ViewDetailNumber(prop: Props){
    const [result, setResult] = useState<DirectNumberResult[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [prizeType, setPrizeType] = useState("A");


    const prizeTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setPrizeType(option.value + "");
        setLoading(true);
   }

    useEffect(()=>{
        setLoading(true);
        if (prop.detailType === "1"){
            agent.Draw4DResult.getSummaryByInterval(prop.mainNumber, prop.isSystem, prizeType).then((response)=>{

                setResult(response);
                setLoading(false);
            });
        }

        if (prop.detailType === "2"){
            agent.Draw4DResult.getSummaryByStrike(prop.mainNumber, prop.isSystem, prizeType).then((response)=>{

                setResult(response);
                setLoading(false);
            });
        }
        //if (prop.detailType === "3"){
            //agent.Draw4DResult.get3DAnalysisByStrike(prop.mainNumber, prop.isSystem).then((response)=>{

                //setResult(response);
                //setLoading(false);
            //});
        //}

    }, [prop.mainNumber, prop.isSystem, prop.detailType, prizeType]);

    if (isLoading){
        return (<LoadingComponent></LoadingComponent>); 
    }

    if( prop.detailType === "1"){
        return (
            <div style={{overflowX:"auto"}}>
            <Table celled unstackable>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell colSpan="2">
                    <MyDropDown zIndex="20" onChange={prizeTypeChanged} placeholder="Select Prize Type" 
                      toolTip="Select Prize Type" 
                      options={_PrizeTypeOptions} defaultValue = {prizeType}>
                    </MyDropDown>

                </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
                <Table.HeaderCell textAlign="center">Interval</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Strike #</Table.HeaderCell>
            </Table.Row>
            </Table.Header>
        
            <Table.Body>

            
            {result.map((x => {
                return (
                    <Table.Row key={x.row}>
                        <Table.Cell  textAlign="right">{x.interval}
                        {x.currentGap === x.interval &&
                        <Label as='a' color='green' ribbon>
                                {"Matched : " + x.interval}
                        </Label>}
                        </Table.Cell>
                        <Table.Cell  textAlign="right">{x.noOfHit}</Table.Cell>
                    </Table.Row>
                );
            }))
            }   
            </Table.Body>
            </Table>
            </div>
        );
    }

    if( prop.detailType === "2"){
        return (
            <div style={{overflowX:"auto"}}>
            <Table celled unstackable>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell colSpan="4">
                    <MyDropDown  zIndex="20" onChange={prizeTypeChanged} placeholder="Select Prize Type" 
                      toolTip="Select Prize Type"
                      options={_PrizeTypeOptions} defaultValue = {prizeType}>
                    </MyDropDown>

                </Table.HeaderCell>
            </Table.Row>

            <Table.Row>
                <Table.HeaderCell textAlign="center">Direct Number</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Strike #</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Current Interval</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Average</Table.HeaderCell>
            </Table.Row>
            </Table.Header>
        
            <Table.Body>

            {result.map((x => {
                return (
                    <Table.Row key={x.row}>
                        <Table.Cell textAlign="center">{x.numberToDisplay}</Table.Cell>
                        <Table.Cell  textAlign="right">{x.noOfHit}</Table.Cell>
                        <Table.Cell  textAlign="right">{x.interval}</Table.Cell>
                        <Table.Cell  textAlign="right">{x.average}</Table.Cell>
                    </Table.Row>
                );
            }))
            }   
            </Table.Body>
            </Table>
            </div>
        );
    }


    return (
        <div>Not Implemented</div>
    )
}