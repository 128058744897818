import React from "react";
import { Embed } from "semantic-ui-react";

export default function AnalysisVideo(){
    return (
         <div style={{overflowX:"auto"}}>
        <Embed id="nfl05pfixQs" source='youtube' brandedUI >
        </Embed>

         </div>
    );
}