import React, { SyntheticEvent, useEffect, useState } from "react";
import {  Image, Dropdown, DropdownProps,  Grid, Header, PaginationProps, Table, Pagination } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _PrizeTypeOptions } from "../../app/lib/CommonData";
import { _GetImage } from "../../app/lib/CommonFunctions";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import { DrawList } from "../../app/models/IDayResult";

interface Props{
    numberToCheck: string;
    isSystem: boolean;
}

export default function ViewLastDraw(props: Props){
    const [data, setData] = useState<DrawList[]>([]);
    const [column, setColumn] = useState("draw_Date");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");

    
    const [page, setPage] = useState(1);
    const [drawType, setDrawType] = useState("A");




    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setPage(1);
        setDrawType(option.value + "");
        //setLoading(true);
       
    }

    const handleColumnClick = (columnName : string)=>{
        if (column !== columnName){
          setDirection("ascending");
        }
        else{
          if (direction === undefined){
            setDirection('ascending');
          }
          else{
            setDirection(direction === "ascending" ? "descending" : "ascending");
          }
        }
        setColumn(columnName);
        setPage(1);
      }
   
   const handlePageChanged = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps)=>{
     setPage(parseInt(data.activePage!.toString()));
   }
   
    useEffect(()=>{
        if (!props.numberToCheck) return;
        if (props.numberToCheck.length !== 4) return;

        let newDirection = "";

        if (direction === "ascending"){
            newDirection = "asc";
        }

        if (direction === "descending"){
            newDirection = "desc";
        }
        agent.Draw4DResult.getDrawResult(props.isSystem, props.numberToCheck, page, drawType, column, newDirection).then((response)=>{

            setData(response);
            //setLoaded(true);
            //isLoading = false;
        });
        
    },[ drawType,column, direction, props.numberToCheck, props.isSystem, page]); 

    return (
        <Grid verticalAlign="middle">
            <Grid.Row>
                <Grid.Column width={6} textAlign="left">
                    Prize Type:&nbsp;&nbsp;
                    <Dropdown fluid
                    onChange = {handleDropDownSelect}
                    placeholder='Select Prize Type'
                    selection
                    options={_PrizeTypeOptions}
                    defaultValue = {drawType}
                />
                </Grid.Column>
                <Grid.Column width={10} textAlign="right">
                    {data && data.length > 0 &&
                    <Header>From: {data[0].from_Date} To {data[0].to_Date}&nbsp;&nbsp;</Header>
                    }
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <div style={{overflowX:"auto"}}>
                    <Table unstackable celled sortable>
                        <Table.Header>
                            <Table.Row>
                                <MyTableWithSort textAlign="center" column={column} columnName="prize_Code" handleClick={handleColumnClick} direction={direction}>
                                Prize Type
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="draw_Date" handleClick={handleColumnClick} direction={direction}>
                                Draw Date
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="prize_Number" handleClick={handleColumnClick} direction={direction}>
                                Number
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="gap" handleClick={handleColumnClick} direction={direction}>
                                Interval
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="month_interval" handleClick={handleColumnClick} direction={direction}>
                                # of Month
                                </MyTableWithSort>
     
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {   data && data.length > 0 &&
                            data.map(x=>{
                            return (
                                <Table.Row key={x.draw_No}>
                                    <Table.Cell textAlign="center">
                                        <Image centered  src={_GetImage(x.prize_Code)} size="mini"></Image>
                                    </Table.Cell>
                                    <Table.Cell textAlign="center"> {x.draw_Date}</Table.Cell>
                                    <Table.Cell textAlign="center">{x.prize_Number}</Table.Cell>
                                    <Table.Cell textAlign="right">{x.gap}</Table.Cell>
                                    <Table.Cell textAlign="right">{x.month_Interval.toFixed(2)}</Table.Cell>
                                </Table.Row>
                            )})
                        }
                        <Table.Row>
                            <Table.Cell colSpan={4}> 
                                <Pagination activePage={page} totalPages={data && data.length > 0 ? data[0].total_Page : 1} onPageChange={handlePageChanged}/>
                            </Table.Cell>
                        </Table.Row>
                        </Table.Body>
                    </Table>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}