import React, { useEffect, useState } from "react";
import {  Button, Divider, Grid, Label, Popup, Transition } from "semantic-ui-react";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import ShowHelp from "../../feature/viewnumber/ShowHelp";
import { useStore } from "../store/store";

interface Props{
  minValue: number;
  maxValue: number;
  average: number;
  minColor: SemanticCOLORS;
  maxColor : SemanticCOLORS;
  avgColor : SemanticCOLORS;
  title : string;
  interval: number;
  intervalColor: SemanticCOLORS;
  useIcon: boolean;
}

export default function MyProgressBar(props: Props){
  const {modalStore} = useStore();
  const [showAnmimation, setShowAnimation] = useState(false);
  
  let columnToUse = 1;
  
  if (props.interval < props.minValue){
     columnToUse = 1;
  }
  else{
    if (props.interval <= props.average){
      columnToUse = 2;
    }
    else{
      if (props.interval <= props.maxValue){
        columnToUse = 3;
      }
      else{
        columnToUse = 4;
      }
    }
  }

  const openHelp= ()=>{
    modalStore.openModal(<ShowHelp></ShowHelp>,"Help","large", null,()=>{})
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimation(!showAnmimation);
    }, 500);
    return () => clearTimeout(timer);
  }, [showAnmimation]);

  return (
    <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            {props.title} &nbsp;
            {props.useIcon && <Divider horizontal/> && <Button data-tooltip="Show Help" positive icon="help" onClick={()=>{openHelp()}}></Button>
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column width={4}>
            {columnToUse === 1 ? 
              <Popup trigger={
                <div>
                <Transition visible={showAnmimation} animation="shake" duration={15000}>
                  <Label circular color={props.intervalColor}>{props.interval}</Label> 
                </Transition>
                </div>
              } flowing hoverable>
              {
                "Current Interval " + (props.interval) 
              }
              <br/>
              <span style={{color:"orange"}}>State: Not Due </span>
              <br/>
              Recommend: Stay
            </Popup>
              
              
              :
              <Popup trigger={
                  <Label circular color={props.minColor}>{props.minValue}</Label>
              }flowing hoverable>
              {
                  "Min Interval: " + props.minValue
              }
              </Popup>
            }
        </Grid.Column>
        <Grid.Column width={4}>
            {columnToUse === 1 ? <Label data-tooltip = {"Min Interval: " + props.minValue} circular color={props.minColor}>{props.minValue}</Label>
            :
              columnToUse === 2 ? 
              <Popup trigger={
                <div>
                <Transition visible={showAnmimation} animation="bounce" duration={15000}>
                <Label   circular color={props.intervalColor}>{props.interval}</Label> 
                </Transition>
                </div>
              } flowing hoverable>
              {
                "Current Interval " + (props.interval) 
              }
              <br/>
              <span style={{color:"green"}}>State: In Range </span>
              <br/>
              Recommend: Buy
            </Popup>
            :  
            <Popup trigger={
              <Label  circular color={props.avgColor}>{props.average}</Label>
            }flowing hoverable>
            {
              "Average Interval: " + props.average
            }
            </Popup>
            }
        </Grid.Column>  
        <Grid.Column width={3}>
            {columnToUse === 2 || columnToUse === 1 ? <Label  data-tooltip = {"Average Interval: " + props.average} circular color={props.avgColor}>{props.average}</Label>
              :
            columnToUse === 3 ? 

            <Popup trigger={
              <div>
              <Transition visible={showAnmimation} animation="bounce" duration={15000}>
              <Label  circular color={props.intervalColor}>{props.interval}</Label> 
              </Transition>
              </div>
            } flowing hoverable>
            {
              "Current Interval " + (props.interval) 
            }
            <br/>
            <span style={{color:"green"}}>State: In Range </span>
            <br/>
            Recommend: Buy
          </Popup>
            :
            <Popup trigger={
            
              <Label circular color={props.maxColor}>{props.maxValue}</Label>
            }flowing hoverable>
            {
              "Max Interval: " + props.maxValue
            }
            </Popup>
          }
        </Grid.Column>
        <Grid.Column width={3}>
            {columnToUse === 3  || columnToUse === 1 || columnToUse === 2? 
              <Popup trigger={
                <Label circular color={props.maxColor}>{props.maxValue}</Label>
              }flowing hoverable>
              {
                "Max Interval: " + props.maxValue
              }
              </Popup>
              :
              <Popup trigger={
                <div>
                <Transition visible={showAnmimation} animation="shake" duration={15000}>
                <Label  circular color={props.intervalColor}>{props.interval}</Label> 
                </Transition>
                </div>
              } flowing hoverable>
              {
                "Current Interval " + (props.interval) 
              }
              <br/>
              <span style={{color:"red"}}>State: Overdue</span>
              <br/>
              Recommend: Hold
            </Popup>
            }
        </Grid.Column>
        <Grid.Column width={2}>

        </Grid.Column>
        </Grid.Row>
    </Grid>
  );
};
