import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { DropdownProps, Grid, Header, Label, Table } from 'semantic-ui-react'
import { history } from "../../..";
import agent from "../../../app/api/agent";
import { _DigitPositionOptions, _PrizeTypeOptions } from "../../../app/lib/CommonData";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import { DigitPositionHistoryResult} from "../../../app/models/IDayResult";
import { useStore } from "../../../app/store/store";


export default observer(function DigitPositionHistory(){
    const [data, setData] = useState<DigitPositionHistoryResult[]>([]);
    const [prizeType, setPrizeType] = useState("T");
    const [digitPosition, setDigitPosition] = useState(1);
    const [loading, setLoading] = useState(true);
    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
    }
  


    const digitPositionChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setDigitPosition(parseInt(option.value+""));
        setLoading(true);
    }

    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setPrizeType(option.value + "");
         setLoading(true);
    }


    useEffect(()=>{

        agent.Draw4DResult.getDigitPatternHistory(prizeType, digitPosition).then((response)=>{

            setData(response);
        }).finally(()=>{
            setLoading(false);
        });

        
    },[prizeType, digitPosition]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
        <Table celled  sortable unstackable className="freeze-head-and-col">
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell colSpan="19">
            <Grid centered>
            <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={5} computer={5}>
              <MyDropDown onChange={handleDropDownSelect} placeholder="Select Prize Type" zIndex="20"
                 toolTip="Select Prize Type"
                options={_PrizeTypeOptions} defaultValue = {prizeType}>
              </MyDropDown>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" mobile={8} tablet={5} computer={5}>
                <MyDropDown onChange={digitPositionChanged} placeholder="Select Digit Position" zIndex="20"
                toolTip="Select Digit Position"
                options={_DigitPositionOptions} defaultValue = {digitPosition}>
                </MyDropDown>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" mobile={16} textAlign="center" tablet={6} computer={6}>
                    Digit Pattern History
            </Grid.Column>
            </Grid>
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">0</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">1</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">2</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">3</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">4</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">5</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">6</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">7</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">8</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">9</Table.HeaderCell>

            </Table.Row>
        </Table.Header>

        <Table.Body>

          {data.map((x,i) => {
            if (i === 0){
                var total = x.a0 + x.a1 + x.a2 + x.a3 + x.a4 + x.a5 + x.a6 + x.a7 + x.a8 + x.a9;
                if (total === 0) return (<Header>Not Found</Header>)
                return(
                    <Table.Row key={i}>
                    <Table.Cell  textAlign="center">
                        Hit/% Summary</Table.Cell>
                    <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a0 }</Label>
                    <Label  >{((x.a0 / total) * 100).toFixed(0)+'%' }</Label>                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a1 }</Label>
                    <Label  >{((x.a1 / total) * 100).toFixed(0)+'%' }</Label>                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a2 }</Label>
                    <Label  >{((x.a2 / total) * 100).toFixed(0)+'%' }</Label>                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a3 }</Label>
                    <Label  >{((x.a3 / total) * 100).toFixed(0)+'%' }</Label>                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a4 }</Label>
                    <Label  >{((x.a4 / total) * 100).toFixed(0)+'%' }</Label>                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a5 }</Label>
                    <Label  >{((x.a5 / total) * 100).toFixed(0)+'%' }</Label>                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a6 }</Label>
                    <Label  >{((x.a6 / total) * 100).toFixed(0)+'%' }</Label>                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a7 }</Label>
                    <Label  >{((x.a7 / total) * 100).toFixed(0)+'%' }</Label>                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a8 }</Label>
                    <Label  >{((x.a8 / total) * 100).toFixed(0)+'%' }</Label>                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                    <Label circular color="blue">{x.a9 }</Label>
                    <Label  >{((x.a9 / total) * 100).toFixed(0)+'%' }</Label>                    
                    </Table.Cell>

                </Table.Row>
                        
                )
            }

            return (
                <Table.Row key={i}>
                    <Table.Cell  textAlign="center">
                        {x.draw_Date}</Table.Cell>
                    <Table.Cell  textAlign="center">
                        {i === 1 ? <Label circular={x.a0  >= x.a0_Avg  ? true: false}>{x.a0}</Label> :
                            (x.a0_Open ?
                            <Label color="red">{x.a0}</Label> :
                            <Label >{x.a0}</Label>)
                        }
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                        {i === 1 ? <Label circular={x.a1  >= x.a1_Avg  ? true: false}>{x.a1}</Label> :
                            (x.a1_Open ?
                            <Label color="red">{x.a1}</Label> :
                            <Label >{x.a1}</Label>)
                        }                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                        {i === 1 ? <Label circular={x.a2  >= x.a2_Avg  ? true: false}>{x.a2}</Label> :
                            (x.a2_Open ?
                            <Label color="red">{x.a2}</Label> :
                            <Label >{x.a2}</Label>)
                        }                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                        {i === 1 ? <Label circular={x.a3  >= x.a3_Avg  ? true: false}>{x.a3}</Label> :
                            (x.a3_Open ?
                            <Label color="red">{x.a3}</Label> :
                            <Label >{x.a3}</Label>)
                        }                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                        {i === 1 ? <Label circular={x.a4  >= x.a4_Avg  ? true: false}>{x.a4}</Label> :
                            (x.a4_Open ?
                            <Label color="red">{x.a4}</Label> :
                            <Label >{x.a4}</Label>)
                        }                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                        {i === 1 ? <Label circular={x.a5  >= x.a5_Avg  ? true: false}>{x.a5}</Label> :
                            (x.a5_Open ?
                            <Label color="red">{x.a5}</Label> :
                            <Label >{x.a5}</Label>)
                        }                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                        {i === 1 ? <Label circular={x.a6  >= x.a6_Avg  ? true: false}>{x.a6}</Label> :
                            (x.a6_Open ?
                            <Label color="red">{x.a6}</Label> :
                            <Label >{x.a6}</Label>)
                        }                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                        {i === 1 ? <Label circular={x.a7  >= x.a7_Avg  ? true: false}>{x.a7}</Label> :
                            (x.a7_Open ?
                            <Label color="red">{x.a7}</Label> :
                            <Label >{x.a7}</Label>)
                        }                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                        {i === 1 ? <Label circular={x.a8  >= x.a8_Avg  ? true: false}>{x.a8}</Label> :
                            (x.a8_Open ?
                            <Label color="red">{x.a8}</Label> :
                            <Label >{x.a8}</Label>)
                        }                    
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                        {i === 1 ? <Label circular={x.a9  >= x.a9_Avg  ? true: false}>{x.a9}</Label> :
                            (x.a9_Open ?
                            <Label color="red">{x.a9}</Label> :
                            <Label >{x.a9}</Label>)
                        }                    
                    </Table.Cell>

                </Table.Row>
            );
          }  
          )} 
           </Table.Body>
      </Table>
      </div>
    );
});