import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button,  Label } from "semantic-ui-react";
import * as Yup from 'yup';
import MyTextInputNonFluid from "../../app/lib/MyTextInputNonFluid";
import { useStore } from "../../app/store/store";


export default observer(function RenewSubscription(){
    const {userStore} = useStore();

    const validationSchema = Yup.object({
        email: Yup.string().email().required("Email is required or Invalid Email"),
        noOfMonth: Yup.number().required("No Of Month is required").min(1,'Minimum is 1 month').max(24,"Maximum is 24 months"),
        price: Yup.number().required("Price is required"),
        remark: Yup.string().required("Remark is required"),
    });

    return (
        <Formik initialValues={{email: "", noOfMonth: 12, price: 0, remark: "", error: "", deviceID: ''}}
                onSubmit={
                    (values,{setErrors}) => userStore.renewSubscription(values).catch(err => setErrors({error: 'Unable to renew the subscription ! Error: ' + err}))
                } validationSchema={validationSchema}
        >
            {(
                {handleSubmit, isSubmitting, errors}) => (
                    <Form className="ui form" onSubmit={handleSubmit}>
                        <MyTextInputNonFluid name="email" placeholder="Email"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="noOfMonth" placeholder="No Of Month is required"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="price" placeholder="Price is required"></MyTextInputNonFluid>
                        <MyTextInputNonFluid name="remark" placeholder="Enter any remark"></MyTextInputNonFluid>

                        <ErrorMessage name="error" render={ ()=> <Label style={{marginBottom: 10}} basic color ="red" content={errors.error}></Label>}/>
                        <Button icon="save" loading={isSubmitting} positive content="Save" type="submit" fluid></Button>
                    </Form>
                
            )}
        </Formik>
    )
})