import { observer } from "mobx-react-lite";
import React, {   SyntheticEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Label, Button, ButtonProps, Checkbox, CheckboxProps, Confirm, Dropdown, DropdownProps,Image, Input,  InputOnChangeData,  Popup,  Table } from "semantic-ui-react";
import { history } from "../..";
import agent from "../../app/api/agent";
import { DisplayAlertSettingRating, DisplayAlertSettingStatus, _GetRating, _GetRatingContent, _SortNumber } from "../../app/lib/CommonFunctions";
import DrawLink from "../../app/lib/drawlink";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import NumberStatus from "../../app/lib/NumberStatus";
import {  MyDropDownValue, WatchListResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import AlertData from "../Forecast/AlertData";
import AlertSetting from "./AlertSetting";
import HelpPDFWatchList from "./HelpPDFWatchList";
import HelpWatchList from "./HelpWatchList";



export default observer(function WatchListEntry(){
    const {userStore, modalStore} = useStore();
    const [categoryID, setCategoryID] = useState(0);
    const [column, setColumn] = useState("Source_Number");
    const [direction, setDirection] = useState<any>("ascending");
    const [data, setData] = useState<WatchListResult[]>([]);
    const [watchListData, setWatchListData] = useState<MyDropDownValue[]>();
    const [drawResult, setDrawResult]= useState<any>(null);
    const [noOfDraw, setNoOfDraw] = useState(3);
    
    const system4DRef = useRef<any>();
    const noOfDrawRef = useRef<any>();

    const categoryNameRef = useRef<any>();

    const [refresh, setRefresh] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const watchCatRef = useRef<any>();

    const handleCancel = () => {
        setIsOpen(false);
    }

    const handleDelete = () => {
        setIsOpen(false);
        agent.Draw4DResult.deleteCategory(categoryID).then((response) => 
        {
            setWatchListData(response);
            if (response.length === 0){
                setCategoryID(0);
            }
            else{
                setCategoryID(response[0].value);
            }
            setRefresh(true);                    
        }).catch((err)=>{
            toast.error(err);
        })

    }

    const deleteWatchNumber = (system4D: string) => {
        agent.Draw4DResult.deleteFromCategory(categoryID, system4D).then((response) => 
        {
            setRefresh(true);                    
        }).catch((err)=>{
          toast.error(err);
        });
    }

    const addSystem4D = ()=>{
        if(categoryID === 0 || categoryID === null){
            toast.error("Select or Create Watchlist Name first !");
            return;
        }
        if (system4DRef && system4DRef.current){
            const system4D = _SortNumber(true, system4DRef.current.inputRef.current.value);

            if(system4D.length !== 4){
                toast.error("System 4D must be 4 numbers !");
                return;
            }
    
            agent.Draw4DResult.addToCategory(categoryID, system4D).then((response) => 
            {
                system4DRef.current.inputRef.current.value = "";
                system4DRef.current.focus();
                setRefresh(true);                    
            }).catch((err)=>{
              toast.error(err);
            })
        }
    };

    const addCategory = () => {
        if (categoryNameRef.current){
            var categoryName = categoryNameRef.current.inputRef.current.value;

            if (!categoryName || categoryName.length === 0){
                toast.error("Watchlist Name is required !");
                categoryNameRef.current.focus();
                return;
            }
            const categoryValue : MyDropDownValue = {
                value : 0,
                text: categoryName
            };

            agent.Draw4DResult.insertWatchListCategory(categoryValue).then((response) => {
                setWatchListData([...watchListData!, response]);
                setCategoryID(response.value);
                categoryNameRef.current.inputRef.current.value = "";
                categoryNameRef.current.focus();
            }).catch((err) =>{
                toast.error(err);
            })
        }
    };

    const editCategory = () => {

        if(categoryID === 0) {
            toast.error("Watchlist Name is required !");
            return;
        }

        if (categoryNameRef.current){
            var categoryName = categoryNameRef.current.inputRef.current.value;

            if (!categoryName || categoryName.length === 0){
                toast.error("Watchlist Name is required !");
                categoryNameRef.current.focus();
                return;
            }

            const categoryValue : MyDropDownValue = {
                value : categoryID,
                text: categoryName
            };

            agent.Draw4DResult.editWatchListCategory(categoryValue).then((response: boolean) => {
                
                watchListData?.map(x=>{
                    if (x.value === categoryID){
                        x.text = categoryName;
                        return false;
                    }
                    return true;
                });

                const newData = [...watchListData!]

                setWatchListData(newData);
                //watchCatRef.current.ref.current.innerText = categoryName;
                categoryNameRef.current.inputRef.current.value = "";
                categoryNameRef.current.focus();
                //setWatchListData([...watchListData!, response]);
                //setCategoryID(response.value);
                //categoryNameRef.current.inputRef.current.value = "";
                //categoryNameRef.current.focus();
            }).catch((err) =>{
                toast.error(err);
            })
        }
    };


    const deleteCategory = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps)=>{
        setMessage("Are you sure to delete " + watchCatRef.current.ref.current.innerText + '?');
        if(categoryID === 0 || categoryID === null){
            toast.error("Select Watchlist Name to delete !");
            return;
        }

        setIsOpen(true);
    };


    const onChange = (e: SyntheticEvent<any, Event>, option: DropdownProps) => {
        setCategoryID(parseInt(option.value + ""));
    }


    useEffect(() => {
        agent.Draw4DResult.getExistingWatchListCategory().then((response) =>{
            setWatchListData(response);
            setDrawResult(null);
            if(response.length > 0){

                setCategoryID(response[0].value);
            }
        });
    }
  ,[]);

    if(!userStore.isLogged){
        history.push("/");
    }

  
    const handleColumnClick = (columnName : string)=>{
        if (column !== columnName){
          setDirection("ascending");
        }
        else{
          if (direction === undefined){
            setDirection('ascending');
          }
          else{
            setDirection(direction === "ascending" ? "descending" : "ascending");
          }
        }
        setColumn(columnName);
      }
  

    
    useEffect(()=>{
        
        if (categoryID === 0){
            return;
        }

        const tempDirection = (direction === "ascending" ? "asc":"desc");

        agent.Draw4DResult.getWatchListNumbers(categoryID, column, tempDirection).then((response) =>{
            setData(response);
            setRefresh(false);
            setDrawResult(null);
        });


    }, [refresh, categoryID, column, direction]);

    const openHelp = () =>{
        modalStore.openModal(<HelpWatchList></HelpWatchList>, "Watchlist Training","fullscreen", null,()=>{})
    }

    const openPDFHelp = () =>{
        modalStore.openModal(<HelpPDFWatchList></HelpPDFWatchList>, "Watchlist Training","fullscreen", null,()=>{})
    }

    const handleKeypress = (e:React.KeyboardEvent):any => {
        //it triggers by pressing the enter key
      if (e.key === "Enter") {
        addSystem4D();
      }
    };

    const noOfDrawChange = (e:React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData):any => {
        //it triggers by pressing the enter key

        setNoOfDraw(parseInt(data.value));
    };


    const onAlertChanged = (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        localStorage.setItem("alertSetting", categoryID + "|" + data.id +"");
        modalStore.openModal(<AlertSetting></AlertSetting>,"Alert Setting","small",null,()=>{ 
            setRefresh(true);
            sessionStorage.setItem("lastNotifyDate", "");
        });
    }

    const showAlert = ()=>{
        agent.Draw4DResult.getAlertData().then((response)=>{
            localStorage.setItem("alertDataNumbers", JSON.stringify(response));
            modalStore.openModal(<AlertData></AlertData>, "Alert","small", null, ()=>{

                });
        });

    }

    const checkResult = ()=>{
        var noOfDraw = noOfDrawRef.current.inputRef.current.value;

        if (noOfDraw >= 10){
            toast.error("No of Draw must be lower than 10");
            return;
        }
        agent.Draw4DResult.checkWatchlistResult(categoryID, noOfDraw).then((response)=>{
            setDrawResult(response);
        });

    }

    const isAdmin = (userStore.user?.username === "JasonLim" || userStore.user?.username === "SteveXie" || userStore.user?.username ==="XCharles"); 
    
    return(
        <div style={{"overflow":"auto"}}>
        <Confirm 
          content={message}
          open={isOpen}
          cancelButton='Cancel'
          confirmButton="Delete ?"
          onCancel={handleCancel}
          onConfirm={handleDelete}
        />
        <Table celled  sortable unstackable className="freeze-head-and-col">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan="3">
                        <Input fluid placeholder="New Watchlist name" maxLength={50} ref={categoryNameRef}></Input>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="left" colSpan="2">
                        <Button data-tooltip="New Watchlist Name" data-position="bottom left" color='green' icon="plus" onClick={addCategory}/>
                        <Button data-tooltip="Edit Watchlist Name"  data-position="bottom left" color='green' icon="pencil" onClick={editCategory}/>
                        <Button data-tooltip="Delete Selected Watchlist" data-position="bottom left" color='green' icon="trash" onClick={deleteCategory}/>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="18" textAlign="center" verticalAlign="middle">
                    <Button size="small" style={{marginRight: "10px"}} data-tooltip="Training Video" data-position="bottom center" positive icon="video" onClick={()=>{openHelp()}}></Button>
                    <span style={{fontSize:"22px", fontWeight:"bold", verticalAlign:"middle"}}>WATCH LIST</span>
                    <Button size="small" style={{marginLeft: "10px"}} data-tooltip="Training PDF" data-position="bottom center" positive icon="help" onClick={()=>{openPDFHelp()}}></Button>

                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell colSpan="3">
                        <Dropdown ref={watchCatRef}  style={{zIndex:21}} fluid   search  scrolling
                                 data-moveup="false" 
                            onChange = {onChange}
                            placeholder="Create Watchlist/Accesslist"
                            options={watchListData}
                            value = {categoryID}
                            direction = "right"
                        />     

                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="1">
                        <Input onKeyPress={handleKeypress} type="number" maxLength={4} fluid  ref={system4DRef} placeholder="4D"></Input>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="1" textAlign="center">
                    <Button primary  icon="plus" fluid content="Add" onClick={addSystem4D}></Button>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="1" textAlign="center">
                        <Button data-tooltip="Show Alert" data-position="bottom left" color='green' icon="bell" onClick={()=>showAlert()}/>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="1" textAlign="center">
                        <Input type="number" onChange={noOfDrawChange} value={noOfDraw}  maxLength={2} fluid  ref={noOfDrawRef} placeholder="# of draws"></Input>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="1" textAlign="center">
                        <Button data-tooltip="Check Result" data-position="bottom left" color='blue' icon="checkmark" onClick={()=>checkResult()}/>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="15" textAlign="center">
                    </Table.HeaderCell>                    
                </Table.Row>
                <Table.Row>
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Source_Number" handleClick={handleColumnClick}>
                            System<br/>Number
                    </MyTableWithSort>
 
                    {data.length > 0 && !data[0].is_System &&
                    <Table.HeaderCell>
                            Delete?
                    </Table.HeaderCell>
                    }
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="rating" handleClick={handleColumnClick}>
                            Rating
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="status" handleClick={handleColumnClick}>
                            System<br/>Status
                    </MyTableWithSort> 
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Status_Top3" handleClick={handleColumnClick}>
                            Top3<br/>Status
                    </MyTableWithSort>
                    <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Awaken" handleClick={handleColumnClick}>
                            Awaken
                    </MyTableWithSort>

                    {data.length > 0 && !data[0].is_System &&
                    <>
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Is_Alert" handleClick={handleColumnClick}>
                            Alert?
                    </MyTableWithSort>
                    <Table.HeaderCell>Alert Settings</Table.HeaderCell>
                    </>
                    }
                    {drawResult != null &&
                    <Table.HeaderCell>
                            Result
                    </Table.HeaderCell>
                    }

                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="System_Interval" handleClick={handleColumnClick}>
                            System<br/>Interval
                    </MyTableWithSort>

                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="System_Top3_Interval" handleClick={handleColumnClick}>
                            System<br/>Top3 Interval
                    </MyTableWithSort>
                    {isAdmin &&                    
                    <>
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Rank_All" handleClick={handleColumnClick}>
                            All<br/>Rank
                    </MyTableWithSort> 
                   
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Rank_Top3" handleClick={handleColumnClick}>
                            Top3<br/>Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Short_Term_System" handleClick={handleColumnClick}>
                            Short Term<br/>System Freq
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Middle_Term_System" handleClick={handleColumnClick}>
                            Medium Term<br/>System Freq
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Long_Term_System" handleClick={handleColumnClick}>
                            Long Term<br/>System Freq
                    </MyTableWithSort> 
                    
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Short_Term_Top3" handleClick={handleColumnClick}>
                            Short Term<br/>Top3 Freq
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Middle_Term_Top3" handleClick={handleColumnClick}>
                            Medium Term<br/>Top3 Freq
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Long_Term_Top3" handleClick={handleColumnClick}>
                            Long Term<br/>Top3 Freq
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Month_Rank" handleClick={handleColumnClick}>
                            Month<br/>Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Month_Rank_Top3" handleClick={handleColumnClick}>
                            Top3 Month<br/>Rank
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Sum3D" handleClick={handleColumnClick}>
                            Sum<br/>3D All
                    </MyTableWithSort> 
                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Sum3D_Top3" handleClick={handleColumnClick}>
                            Sum<br/>3D Top3
                    </MyTableWithSort> 
                    </>                    
}                    

                    <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Created_Date" handleClick={handleColumnClick}>
                            Created<br/>Date
                    </MyTableWithSort> 

                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data && data.length > 0 &&
                    data.map((x, index)=>{
                        return(
                            <Table.Row key={index}>
                                <Table.Cell textAlign="center">
                                    <DrawLink number = {x.source_Number}>
                                    </DrawLink>
                                </Table.Cell>
                                {!x.is_System &&
                                    <Table.Cell textAlign="center">
                                        <Button icon="delete" size="tiny" primary onClick={()=>deleteWatchNumber(x.source_Number)}></Button>
                                    </Table.Cell>
                                }
                                <Table.Cell  textAlign="center">
                                    <Popup content={_GetRatingContent(x.rating)}
                                    trigger ={<Image avatar src={_GetRating(x.rating)} />}>
                                    </Popup>
                                </Table.Cell>

                                <Table.Cell  textAlign="center">
                                    <NumberStatus status = {x.status} />
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    <NumberStatus status = {x.status_Top3} />
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.awaken}
                                </Table.Cell>

                                {data.length > 0 && !x.is_System &&
                                <>
                                <Table.Cell  textAlign="center">
                                    <Checkbox toggle checked={x.is_Alert}  onClick={ onAlertChanged } id={x.source_Number}
                                    />
                                </Table.Cell>
                                <Table.Cell  textAlign="center" verticalAlign="middle">
                                    <>
                                    {DisplayAlertSettingStatus(x.status).length > 0 && <span>{DisplayAlertSettingStatus(x.statuses)}<br/></span>}
                                    {DisplayAlertSettingRating(x.ratings).length > 0 && <span>{DisplayAlertSettingRating(x.ratings)}</span>}                                    
                                    </>
                                </Table.Cell>
                                </>
                                }
                                {drawResult != null &&
                                    <Table.HeaderCell textAlign="center">
                                        <p style={{textAlign:"center"}}>
                                        {
                                                (drawResult[x.source_Number] === null || drawResult[x.source_Number] === undefined ? [] : drawResult[x.source_Number].split(',')).map((k:string)=>{
                                                    if (k.indexOf("[1]") >=0 || k.indexOf("[2]") >=0 || k.indexOf("[3]") >=0){
                                                        return (<><Label color="red">{k}</Label><br/></>);
                                                    }    
                                                    return (<><Label>{k}</Label><br/></>);
                                                })
                                        }
                                        </p>
                                    </Table.HeaderCell>
                                }

                                <Table.Cell  textAlign="center">
                                    {x.system_Interval}<br/>
                                    [
                                    {x.system_Interval_Min === 0 || x.system_Interval_Max === 0 ? "N/A" : 
                                         (((x.system_Interval - x.system_Interval_Min) / (x.system_Interval_Max - x.system_Interval_Min)) * 100.0).toFixed(0) + '%'  
                                    }
                                    ]
                                    <br/>
                                    [Min]{x.system_Interval_Min}-{x.system_Interval_Max}[Max]
                                    </Table.Cell>


                                <Table.Cell  textAlign="center">
                                    {x.system_Top3_Interval}
                                    <br/>[
                                    {x.top3_Interval_Min === 0 || x.top3_Interval_Max === 0 ? "N/A" : (((x.system_Top3_Interval - x.top3_Interval_Min) / (x.top3_Interval_Max - x.top3_Interval_Min)) * 100.0).toFixed(0) + '%'}
                                    ]
                                    <br/>
                                    [Min]{x.top3_Interval_Min}-{x.top3_Interval_Max}[Max]
                                </Table.Cell>
                                {isAdmin &&                    
                                <>
                                <Table.Cell  textAlign="center">
                                    {x.rank_All}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.rank_Top3}
                                </Table.Cell>

                                <Table.Cell  textAlign="center">
                                    {x.short_Term_System} %
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.middle_Term_System} %
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.long_Term_System}%
                                </Table.Cell>

                                <Table.Cell  textAlign="center">
                                    {x.short_Term_Top3} %
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.middle_Term_Top3} %
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.long_Term_Top3} %
                                </Table.Cell>

                                <Table.Cell  textAlign="center">
                                    {x.month_Rank}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.month_Rank_Top3}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.sum3D}
                                </Table.Cell>
                                <Table.Cell  textAlign="center">
                                    {x.sum3D_Top3}
                                </Table.Cell>
                                </>
                                }
                                <Table.Cell  textAlign="center">
                                    {x.created_Date}
                                </Table.Cell>

                            </Table.Row>
                        )
                    })
                }
            </Table.Body>
    </Table>
    </div>
    
    );    
   
}
)