import { observer } from "mobx-react-lite"
import React from "react"
import {  Statistic } from "semantic-ui-react"
import { DrawDetailResult } from "../../app/models/IDayResult"


interface Props{
    drawResult: DrawDetailResult[];
}

export default observer(function DetailResult0({drawResult} : Props){
    let top3 = 0
    let starter = 0
    let conso = 0

    for(var i = 0; i < drawResult.length; i++){
        if (drawResult[i].is_Direct_Hit === "Yes"){
            if (i >= 0 && i <= 2){
                top3++;
            }
            else{
                if (i >= 3 && i <= 12){
                    starter++;
                }
                else{
                    conso++;
                }
            }
        }
    }

return (
    <div>
    <Statistic.Group>
      <Statistic>
        <Statistic.Value>{top3}</Statistic.Value>
        <Statistic.Label>Top3</Statistic.Label>
      </Statistic>
      <Statistic>
        <Statistic.Value>{starter}</Statistic.Value>
        <Statistic.Label>Starter</Statistic.Label>
      </Statistic>
      <Statistic>
        <Statistic.Value>{conso}</Statistic.Value>
        <Statistic.Label>Consolation</Statistic.Label>
      </Statistic>
    </Statistic.Group>
  </div>
  )
})