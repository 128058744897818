import React from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

interface Props{
    inverted?: boolean;
    content?: string;
}

export default function MiniLoading({inverted = true, content = "Loading ..."}: Props){
    return (
        <Segment 
        textAlign='center' 
        style={{ padding: '1em 0em' }}
        vertical>
                 <Dimmer active inverted={inverted}>
                        <Loader size='small'>{content}</Loader>
                </Dimmer>
        </Segment>
        
    )
}