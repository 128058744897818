import { useField } from "formik";
import React from "react";
import { Checkbox, Container, Form,  Input,  Label } from "semantic-ui-react";

interface Props {
    children?: never[];
    
    placeHolder?: string;

    label: string;

    maxLength: number;

    textBoxName: string;
    checkBoxName: string;
}

export default function MyInputSwitchNew(props: Props){
    const [field, meta, helper] = useField(props.checkBoxName);
    const [field1, meta1] = useField(props.textBoxName);
    
    return (
        <Form.Field inline error={ (meta1.touched  && !!meta1.error)}>
            <Container>
            <Checkbox  fitted checked={field.value} onChange={(e: any, {name, checked})=>
                {
                    helper.setValue(checked);
                    helper.setTouched(true);                    
                }} label={props.label} />
            <Input placeholder={props.placeHolder!}  {...field1} maxLength={props.maxLength} fluid  disabled={field.value ? false : true} label=""/>
            </Container>
            {meta.touched && meta.error ? <Label basic color='red'>{meta.error}</Label> : null}
            {meta1.touched && meta1.error ? <Label basic color='red'>{meta1.error}</Label> : null}
      </Form.Field>
    );
}