import { useField } from "formik";
import React from "react";
import {  Dropdown, DropdownItemProps, Popup } from "semantic-ui-react";


interface Props{
    children: never[];
    placeholder: string;
    options?: DropdownItemProps[];
    zIndex?: string;
    toolTip?: string;
    toolTipDirection?:"top center" | undefined ;
    name: string;
}

export default function MyDropDown(props: Props){
    
    const [field, meta, helper] = useField(props.name);

    return(
        <>
            <Popup content={props.toolTip} position={props.toolTipDirection} trigger={
                <Dropdown  fluid 
                    value = {field.value} 
                    selection 
                    style={{zIndex:props.zIndex}}
                    onChange = {(e: any, {name, value})=>
                    {
                        helper.setValue(value);
                        helper.setTouched(true);                    
                    }}
                    placeholder={props.placeholder}
                    options={props.options}
                    direction = "right"
                />     
            }>
                
            </Popup>
           
        </>
    )
}