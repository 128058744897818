import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { DropdownProps, Grid,  Label, Table } from 'semantic-ui-react'
import agent from "../../../app/api/agent";
import {  _DrawTypeOptionsWithAll, _NoOfDrawOptions, _PrizeTypeOptions, _RankTypeOptions } from "../../../app/lib/CommonData";
import { _DisplayHeader, _DisplayPercentage, _DisplayZero, _GetSavedDrawNo,_GetSavedDrawType4D, _GetSavedPrizeType, _GetSavedRankType4D, _SetSavedDrawNo, _SetSavedDrawType4D, _SetSavedPrizeType, _SetSavedRankType4D } from "../../../app/lib/CommonFunctions";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import RedirectTo4D from "../../../app/lib/RedirectTo4D";
import {PatternResult} from "../../../app/models/IDayResult";


export default observer(function AllRankHistory(){
    const [data, setData] = useState<PatternResult[]>([]);
    const [prizeCode, setPrizeCode] = useState(_GetSavedPrizeType("A"));
    const [drawType, setDrawType] = useState(_GetSavedDrawType4D("A"));
    const [noOfDraw, setNoOfDraw] = useState(_GetSavedDrawNo("4D", 36));
    const [loading, setLoading] = useState(true);
    const [rankType, setRankType] = useState(_GetSavedRankType4D("N"));

    const noOfDrawChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setNoOfDraw(parseInt(option.value+""));
        _SetSavedDrawNo("4D", parseInt(option.value+""));
        setLoading(true);
    }

    const rankTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setRankType(option.value+"");
        _SetSavedRankType4D(option.value+"");
        setLoading(true);
    }

    const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setDrawType(option.value + "");
         _SetSavedDrawType4D(option.value + "");
         setLoading(true);
        
    }

    const prizeCodeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setPrizeCode(option.value + "");
        _SetSavedPrizeType(option.value + "");
        setLoading(true);
   }

    useEffect(()=>{

        agent.Draw4DResult.getAllRankHistory(drawType, noOfDraw, prizeCode, rankType).then((response)=>{

            setData(response);
        }).finally(()=>{
            setLoading(false);
        });

        
    },[drawType, noOfDraw, prizeCode, rankType]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }

    const allColumns = (data === null || data.length === 0) ? [] : data[0].columnNames.split('|');

    return (
      <>
            <Grid>
                <Grid.Row>
                    <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} computer={8} tablet={8}>
                        <MyDropDown onChange={rankTypeChanged} placeholder="Select Rank Type" 
                            toolTip="Select Rank Type" zIndex="21"
                            options={_RankTypeOptions} defaultValue = {rankType}>
                        </MyDropDown>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" mobile={8} computer={8} tablet={8}>
                        <MyDropDown onChange={prizeCodeChanged} placeholder="Select Prize Type" 
                            toolTip="Select Prize Type" zIndex="21"
                            options={_PrizeTypeOptions} defaultValue = {prizeCode}>
                        </MyDropDown>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                    <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} computer={8} tablet={8}>
                        <MyDropDown onChange={noOfDrawChanged} placeholder="Select # of Draw" 
                            toolTip="Select # Draws" zIndex="20"
                            options={_NoOfDrawOptions} defaultValue = {noOfDraw}>
                        </MyDropDown>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} computer={8} tablet={8}>
                    <MyDropDown onChange={drawTypeChanged} placeholder="Select Draw Type" 
                        toolTip="Select Draw Type" zIndex="20"
                        options={_DrawTypeOptionsWithAll} defaultValue = {drawType}>
                    </MyDropDown>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        <div style={{overflowX:"auto"}}>
                <Table celled  sortable unstackable className="freeze-head-and-col">
                    <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell colSpan="18">
                        4D Rank/Hit
                    </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                    <Table.HeaderCell textAlign="center">Draw Date</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        <RedirectTo4D range={_DisplayHeader(allColumns,1)} code={"B" + rankType} drawType={drawType}></RedirectTo4D>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        <RedirectTo4D range={_DisplayHeader(allColumns,2)} code={"B" + rankType} drawType={drawType}></RedirectTo4D>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        <RedirectTo4D range={_DisplayHeader(allColumns,3)} code={"B" + rankType} drawType={drawType}></RedirectTo4D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo4D range={_DisplayHeader(allColumns,4)} code={"B" + rankType} drawType={drawType}></RedirectTo4D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo4D range={_DisplayHeader(allColumns,5)} code={"B" + rankType} drawType={drawType}></RedirectTo4D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo4D range={_DisplayHeader(allColumns,6)} code={"B" + rankType} drawType={drawType}></RedirectTo4D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo4D range={_DisplayHeader(allColumns,7)} code={"B" + rankType} drawType={drawType}></RedirectTo4D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo4D range={_DisplayHeader(allColumns,8)} code={"B" + rankType} drawType={drawType}></RedirectTo4D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo4D range={_DisplayHeader(allColumns,9)} code={"B" + rankType} drawType={drawType}></RedirectTo4D>
                    </Table.HeaderCell>

                    <Table.HeaderCell textAlign="center">
                        <RedirectTo4D range={_DisplayHeader(allColumns,10)} code={"B" + rankType} drawType={drawType}></RedirectTo4D>
                    </Table.HeaderCell>


                    </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.map((x,i) => {
                        
                        if (i === 0){
                            var total = x.col1 + x.col2  + x.col3  + x.col4 + x.col5 +
                                        x.col6 + x.col7  + x.col8  + x.col9 + x.col10;

                            if (total === 0){
                                return (
                                    <Table.Row key={i}>
                                        <Table.Cell textAlign="center">
                                            Summary
                                        </Table.Cell>
                                        <Table.Cell textAlign="center" colSpan="10">
                                            No Result
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                            return (
                                <Table.Row key={i}>
                                    <Table.Cell  textAlign="center">
                                    Hit/% Summary
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col1 }</Label>
                                        <Label >{_DisplayPercentage(x.col1, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col2 }</Label>
                                        <Label >{_DisplayPercentage(x.col2, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col3}</Label>
                                        <Label >{_DisplayPercentage(x.col3, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col4}</Label>
                                        <Label >{_DisplayPercentage(x.col4, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col5}</Label>
                                        <Label >{_DisplayPercentage(x.col5, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col6}</Label>
                                        <Label >{_DisplayPercentage(x.col6, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col7}</Label>
                                        <Label >{_DisplayPercentage(x.col7, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col8}</Label>
                                        <Label >{_DisplayPercentage(x.col8, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col9}</Label>
                                        <Label >{_DisplayPercentage(x.col9, total, 0)}</Label>                    
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <Label circular color="blue">{x.col10 }</Label>
                                        <Label >{_DisplayPercentage(x.col10, total, 0)}</Label>                    
                                    </Table.Cell>
                                </Table.Row>
                            )
                        }
                        
                        
                        return (
                        <Table.Row key={i}>
                            <Table.Cell  textAlign="center">
                                {x.draw_Date}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                { _DisplayZero(x.col1)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col2)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col3)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col4)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col5)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col6)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col7)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col8)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col9)}
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                {  _DisplayZero(x.col10)}
                            </Table.Cell>
                        </Table.Row>
            );
        }  
        )} 
        </Table.Body>
        </Table> 
   </div>
      </>
    );
});