import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { DropdownProps, Grid, Header, Table } from 'semantic-ui-react'
import agent from "../../../app/api/agent";
import { _DrawTypeOptionsWithAll, _NoOfDrawOptions, _PrizeTypeOptions } from "../../../app/lib/CommonData";
import {  _GetSavedDrawNo, _GetSavedDrawType4D, _GetSavedPrizeType, _SetSavedDrawNo, _SetSavedDrawType4D,  _SetSavedPrizeType} from "../../../app/lib/CommonFunctions";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import RedirectTo4DSumRoot from "../../../app/lib/RedirectTo4DSumRoot";
import TableCellLabel from "../../../app/lib/TabelCellLabel";
import { Sum4DAll } from "../../../app/models/IDayResult";
import { useStore } from "../../../app/store/store";


export default observer(function Sum4DHistory(){
    const [data, setData] = useState<Sum4DAll[]>([]);
    const [drawType, setDrawType] = useState(_GetSavedDrawType4D("A"));
    const [prizeType, setPrizeType] = useState(_GetSavedPrizeType("T"));
    
    const [noOfDraw, setNoOfDraw] = useState(_GetSavedDrawNo("NoOfDraw",36));

    const [loading, setLoading] = useState(true);
    const {userStore} = useStore();

    let numbers = [];

    for(var i = 0; i<=36; i++){
        numbers.push(i);
    }
    
    const noOfDrawChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setNoOfDraw(parseInt(option.value+""));
        _SetSavedDrawNo("NoOfDraw", parseInt(option.value+""));
        setLoading(true);
    }

    const prizeTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setPrizeType(option.value + "");
         _SetSavedPrizeType(option.value + "");
         setLoading(true);
    }

   const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setDrawType(option.value + "");
        _SetSavedDrawType4D(option.value + "");
        setLoading(true);
    }

    useEffect(()=>{
        
        agent.Draw4DResult.getSum4DAllNumbersHistory(prizeType, noOfDraw, drawType).then((response)=>{
            setData(response);
        }).finally(()=>{
            setLoading(false);
        });

    },[drawType, noOfDraw, prizeType]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }

    if (!userStore.isLogged){
        return (<Header>Not Authorized</Header>);
    }


    let isDisplay: boolean[] = [false, false, false, false, false, false, false, false, false, false,
                                false, false, false, false, false, false, false, false, false, false, 
                                false, false, false, false, false, false, false, false, false, false,
                                false, false, false, false, false, false, false];



    for(var index = 0; index < isDisplay.length; index++)
    {
        if (drawType === "A"){
            isDisplay[index] = true; 
        }
        else{
            if (drawType === "AAAA" && (index %  4 === 0 ) ){
                isDisplay[index] = true; 
            } 
            else{
                if (drawType === "AABB" && (index > 2 && index < 34) ){
                    isDisplay[index] = true; 
                } 
                else{
                    if (drawType === "AABC" && (index > 2 && index < 32) ){
                        isDisplay[index] = true; 
                    } 
                    else{
                        if (drawType === "ABCD" && (index > 5 && index < 31) ){
                            isDisplay[index] = true; 
                        } 
                    }
                }
            }
        }
    }
                        

    
    return (
      <div style={{overflowX:"auto"}}>
                  <Grid>
                    <Grid.Column verticalAlign="middle" textAlign="left" mobile={8} tablet={4} computer={4}>
                        <MyDropDown onChange={prizeTypeChanged} placeholder="Select Prize Type" 
                        toolTip="Select Prize Type" zIndex="21"
                        options={_PrizeTypeOptions} defaultValue = {prizeType}>
                        </MyDropDown>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" mobile={8} tablet={4} computer={4}>
                        <MyDropDown onChange={noOfDrawChanged} placeholder="Select No of Draw" 
                        toolTip="Select No Of Draw" zIndex="21"
                        options={_NoOfDrawOptions} defaultValue = {noOfDraw}>
                        </MyDropDown>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle" mobile={8} textAlign="center" tablet={4} computer={4}>
                      <MyDropDown onChange={drawTypeChanged} placeholder="Select Draw Type" 
                      toolTip="Select Draw Type" zIndex="20"
                      options={_DrawTypeOptionsWithAll} defaultValue = {drawType}>
                      </MyDropDown>
                    </Grid.Column>
                  </Grid>
          
                  <Table celled  sortable unstackable className="freeze-head-and-col">
                  <Table.Header>
                  <Table.Row>
                  <Table.HeaderCell colSpan="18">
                      Sum 4D Number
                  </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                  <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
                 {
                    numbers.map((x, i)=>{
                        if (isDisplay[i]){
                            return (
                            <Table.HeaderCell textAlign="center">
                            <RedirectTo4DSumRoot range={x + ""} code={"U4"} drawType={drawType}></RedirectTo4DSumRoot>
                            </Table.HeaderCell>
                            );
                        }
                        else{
                            return null;
                        }
                    })
                 }
                  </Table.Row>
                  </Table.Header>
                  <Table.Body>
                      {data.map((x,i) => {
                      
                      if (i === 0){
                          var total = x.col0 + x.col1 + x.col2  + x.col3  + x.col4 + x.col5 +
                                      x.col6 + x.col7  + x.col8  + x.col9 + x.col10 +
                                      x.col11 + x.col12 + x.col13 + x.col14 + x.col15 +
                                      x.col16 + x.col17 + x.col18 + x.col19 + x.col20 + 
                                      x.col21 + x.col22 + x.col23 + x.col24 + x.col25 +
                                      x.col26 + x.col27 + x.col28 + x.col29 + x.col30 +
                                      x.col31 + x.col32 + x.col33 + x.col34 + x.col35 + 
                                      x.col36;

                          if (total === 0){
                              return (
                                  <Table.Row key={i}>
                                      <Table.Cell textAlign="center">
                                          Summary
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                          No Result
                                      </Table.Cell>
                                  </Table.Row>
                              )
                          }
                          return (
                              <Table.Row key={i}>
                                  <Table.Cell  textAlign="center">
                                  Hit/% Summary
                                  </Table.Cell>
                                  <TableCellLabel isVisible={isDisplay[0]} content={x.col0} total={total} />
                                  <TableCellLabel isVisible={isDisplay[1]} content={x.col1} total={total} />
                                  <TableCellLabel isVisible={isDisplay[2]} content={x.col2} total={total} />
                                  <TableCellLabel isVisible={isDisplay[3]} content={x.col3} total={total} />
                                  <TableCellLabel isVisible={isDisplay[4]} content={x.col4} total={total} />
                                  <TableCellLabel isVisible={isDisplay[5]} content={x.col5} total={total} />
                                  <TableCellLabel isVisible={isDisplay[6]} content={x.col6} total={total} />
                                  <TableCellLabel isVisible={isDisplay[7]} content={x.col7} total={total} />
                                  <TableCellLabel isVisible={isDisplay[8]} content={x.col8} total={total} />
                                  <TableCellLabel isVisible={isDisplay[9]} content={x.col9} total={total} />
                                  <TableCellLabel isVisible={isDisplay[10]} content={x.col10} total={total} />
                                  <TableCellLabel isVisible={isDisplay[11]} content={x.col11} total={total} />
                                  <TableCellLabel isVisible={isDisplay[12]} content={x.col12} total={total} />
                                  <TableCellLabel isVisible={isDisplay[13]} content={x.col13} total={total} />
                                  <TableCellLabel isVisible={isDisplay[14]} content={x.col14} total={total} />
                                  <TableCellLabel isVisible={isDisplay[15]} content={x.col15} total={total} />
                                  <TableCellLabel isVisible={isDisplay[16]} content={x.col16} total={total} />
                                  <TableCellLabel isVisible={isDisplay[17]} content={x.col17} total={total} />
                                  <TableCellLabel isVisible={isDisplay[18]} content={x.col18} total={total} />
                                  <TableCellLabel isVisible={isDisplay[19]} content={x.col19} total={total} />
                                  <TableCellLabel isVisible={isDisplay[20]} content={x.col20} total={total} />
                                  <TableCellLabel isVisible={isDisplay[21]} content={x.col21} total={total} />
                                  <TableCellLabel isVisible={isDisplay[22]} content={x.col22} total={total} />
                                  <TableCellLabel isVisible={isDisplay[23]} content={x.col23} total={total} />
                                  <TableCellLabel isVisible={isDisplay[24]} content={x.col24} total={total} />
                                  <TableCellLabel isVisible={isDisplay[25]} content={x.col25} total={total} />
                                  <TableCellLabel isVisible={isDisplay[26]} content={x.col26} total={total} />
                                  <TableCellLabel isVisible={isDisplay[27]} content={x.col27} total={total} />
                                  <TableCellLabel isVisible={isDisplay[28]} content={x.col28} total={total} />
                                  <TableCellLabel isVisible={isDisplay[29]} content={x.col29} total={total} />
                                  <TableCellLabel isVisible={isDisplay[30]} content={x.col30} total={total} />
                                  <TableCellLabel isVisible={isDisplay[31]} content={x.col31} total={total} />
                                  <TableCellLabel isVisible={isDisplay[32]} content={x.col32} total={total} />
                                  <TableCellLabel isVisible={isDisplay[33]} content={x.col33} total={total} />
                                  <TableCellLabel isVisible={isDisplay[34]} content={x.col34} total={total} />
                                  <TableCellLabel isVisible={isDisplay[35]} content={x.col35} total={total} />
                                  <TableCellLabel isVisible={isDisplay[36]} content={x.col36} total={total} />

                              </Table.Row>
                          )
                      }
                      
                      
                      return (
                      <Table.Row key={i}>
                          <Table.Cell  textAlign="center">
                              {x.draw_Date}
                          </Table.Cell>
                          <TableCellLabel body={true} isVisible={isDisplay[0]} content={x.col0} />
                          <TableCellLabel body={true} isVisible={isDisplay[1]} content={x.col1} />
                          <TableCellLabel body={true} isVisible={isDisplay[2]} content={x.col2} />
                          <TableCellLabel body={true} isVisible={isDisplay[3]} content={x.col3} />
                          <TableCellLabel body={true} isVisible={isDisplay[4]} content={x.col4} />
                          <TableCellLabel body={true} isVisible={isDisplay[5]} content={x.col5} />
                          <TableCellLabel body={true} isVisible={isDisplay[6]} content={x.col6} />
                          <TableCellLabel body={true} isVisible={isDisplay[7]} content={x.col7} />
                          <TableCellLabel body={true} isVisible={isDisplay[8]} content={x.col8} />
                          <TableCellLabel body={true} isVisible={isDisplay[9]} content={x.col9} />

                          <TableCellLabel body={true} isVisible={isDisplay[10]} content={x.col10} />
                          <TableCellLabel body={true} isVisible={isDisplay[11]} content={x.col11} />
                          <TableCellLabel body={true} isVisible={isDisplay[12]} content={x.col12} />
                          <TableCellLabel body={true} isVisible={isDisplay[13]} content={x.col13} />
                          <TableCellLabel body={true} isVisible={isDisplay[14]} content={x.col14} />
                          <TableCellLabel body={true} isVisible={isDisplay[15]} content={x.col15} />
                          <TableCellLabel body={true} isVisible={isDisplay[16]} content={x.col16} />
                          <TableCellLabel body={true} isVisible={isDisplay[17]} content={x.col17} />
                          <TableCellLabel body={true} isVisible={isDisplay[18]} content={x.col18} />
                          <TableCellLabel body={true} isVisible={isDisplay[19]} content={x.col19} />

                          <TableCellLabel body={true} isVisible={isDisplay[20]} content={x.col20} />
                          <TableCellLabel body={true} isVisible={isDisplay[21]} content={x.col21} />
                          <TableCellLabel body={true} isVisible={isDisplay[22]} content={x.col22} />
                          <TableCellLabel body={true} isVisible={isDisplay[23]} content={x.col23} />
                          <TableCellLabel body={true} isVisible={isDisplay[24]} content={x.col24} />
                          <TableCellLabel body={true} isVisible={isDisplay[25]} content={x.col25} />
                          <TableCellLabel body={true} isVisible={isDisplay[26]} content={x.col26} />
                          <TableCellLabel body={true} isVisible={isDisplay[27]} content={x.col27} />
                          <TableCellLabel body={true} isVisible={isDisplay[28]} content={x.col28} />
                          <TableCellLabel body={true} isVisible={isDisplay[29]} content={x.col29} />

                          <TableCellLabel body={true} isVisible={isDisplay[30]} content={x.col30} />
                          <TableCellLabel body={true} isVisible={isDisplay[31]} content={x.col31} />
                          <TableCellLabel body={true} isVisible={isDisplay[32]} content={x.col32} />
                          <TableCellLabel body={true} isVisible={isDisplay[33]} content={x.col33} />
                          <TableCellLabel body={true} isVisible={isDisplay[34]} content={x.col34} />
                          <TableCellLabel body={true} isVisible={isDisplay[35]} content={x.col35} />
                          <TableCellLabel body={true} isVisible={isDisplay[36]} content={x.col36} />

                        </Table.Row>
                      );
                    }  
                    )} 
                    </Table.Body>
                </Table>                 
   </div>
    );
});