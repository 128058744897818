import React from "react";
import NumberStatusShort from "./NumberStatusShort";


interface Props{
    data: string;
}


export default function TableCellLabel({data}: Props){
    if (data === undefined || data === null || data.split('|').length === 1){
        return (<></>);
    }
    return (
        <>{data.split('|')[0]}<br/>
        <NumberStatusShort status={data.split('|')[1]}></NumberStatusShort>
        <NumberStatusShort status={data.split('|')[2]}></NumberStatusShort><br/>
        {'Sys:' + data.split('|')[3] + ', T3: ' + data.split('|')[4]}
        </>
    )

}