import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffect } from "react";
import { Table } from "semantic-ui-react";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import {  ExpressDirectNumberPart3 } from "../../app/models/IDayResult";

interface Props{
    data: ExpressDirectNumberPart3[];
}

export default observer( function ViewQuickDirectPart5(prop: Props){
    const [column, setColumn] = useState<any>("year");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [data, setData] = useState<ExpressDirectNumberPart3[]>([]);

    const handleColumnClick = (columnName : any)=>{
        let tempDirection : "ascending" | "descending" | undefined;
        if (column !== columnName){
            tempDirection = "ascending";
        }
        else{
          if (direction === undefined){
            tempDirection = "ascending";
          }
          else{
            tempDirection = (direction === "ascending" ? "descending" : "ascending");
          }
        }


        setDirection(tempDirection);
        setColumn(columnName);

      }

    useEffect(() => {
        let sortableItems = [...prop.data];

        sortableItems.sort((a:any, b:any) => {

            if (a[column] < b[column]) {
                return direction === 'ascending' ? -1 : 1;
              }
              if (a[column] > b[column]) {
                return direction === 'ascending' ? 1 : -1;
              }

            return 0;
        });
        setData(sortableItems);

        },[column, direction, prop.data]);
    
    if (!data || data.length === 0){
        return (<div>Not Data</div>);
    }

    return (
        <div style={{overflowX:"auto"}}>
        
        <Table unstackable celled sortable>
             <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell>{"Results by " + data[0].monthName} </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                    <MyTableWithSort textAlign="center" column={column} columnName="year" handleClick={handleColumnClick} direction={direction}>
                                Year
                    </MyTableWithSort>
                    <MyTableWithSort textAlign="center" column={column} columnName="wed" handleClick={handleColumnClick} direction={direction}>
                                Wed
                    </MyTableWithSort>
                    <MyTableWithSort textAlign="center" column={column} columnName="sat" handleClick={handleColumnClick} direction={direction}>
                                Sat
                    </MyTableWithSort>
                    <MyTableWithSort textAlign="center" column={column} columnName="sun" handleClick={handleColumnClick} direction={direction}>
                                Sun
                    </MyTableWithSort>

                    </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    data.map( 
                    (x, i)=>{
                            return(
                                <Table.Row key={i}>
                                    <Table.Cell textAlign="center"> {x.year}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center"> {x.wed}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center"> {x.sat}
                                    </Table.Cell>
                                    <Table.Cell textAlign="right"> {x.sun}
                                    </Table.Cell>
                                </Table.Row>
                            )
                    })
            }
            </Table.Body>         
        </Table>
        </div>
    );
})