import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { Image, DropdownProps, Grid, Header, Input, InputOnChangeData, Label, Pagination,  PaginationProps,  Popup,  Table } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { AnalysisResult } from "../../app/models/IDayResult";
import style from "./analysisbymonth.module.css";
import YearResult from "../analysis/yearresult";
import DrawLink from "../../app/lib/drawlink";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import NumberStatus from "../../app/lib/NumberStatus";
import { _GetMonthName, _GetRating, _GetRatingContent, _GetRowPerPage, _SetRowPerPage } from "../../app/lib/CommonFunctions";
import MyDropDown from "../../app/lib/MyDropDown";
import { _DrawTypeOptions, _RatingOptions, _RowPerPageOptions, _StatusOptions } from "../../app/lib/CommonData";
import { toast } from "react-toastify";
import { useStore } from "../../app/store/store";
import { history } from "../..";
import configData from "../../app/config.json";

export default observer(function AnalysisByMonth(){
    const periodDisplay = configData.PeriodDisplay;

    const curMonth = _GetMonthName(new Date().getMonth());    
    const curYear = configData.CurrentYear;
    const [loading, setLoading] = useState(true);
    const [drawType, setDrawType] = useState("ABCD");
    const [analysis, setAnalysis] = useState<AnalysisResult[]>([]);
    const [column, setColumn] = useState("total");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("0");
    const [rowPerPage, setRowPerPage] = useState(_GetRowPerPage());
    const {userStore} = useStore();
    const [rating, setRating]  = useState(0);

    if(!userStore.isLogged){
      history.push("/");
    }

    const ratingChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setRating(parseInt(option.value + ""));
      setLoading(true);
     
    }


    const rowPerPageChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setRowPerPage(parseInt(option.value + ""));
      _SetRowPerPage(parseInt(option.value + ""));
      setLoading(true);
     
    }

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData)=>{
      if(data.value.length === 4){
          if (!isNaN(parseFloat(data.value))){
            let newDirection:string;

            newDirection = "";
            if (direction === "ascending"){
              newDirection = "asc";
            }
            if (direction === "descending"){
              newDirection = "desc";
            }

            
            agent.Draw4DResult.getPageBy4DNumberMonthly(data.value, column, newDirection, drawType, status, rowPerPage, rating).then(response=>{
              if(response.pageNo === 0){
                toast.error("Number not found ! Check the Draw Type.");
                return;
              }  
              toast.info("Found number in row " + response.row_No);
              if (page !== response.pageNo){
                setPage(response.pageNo);
                setLoading(true);
              }
            });
          }
      }

    }

    const statusDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setStatus(option.value + "");
      setLoading(true);
     
    }

   
    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setPage(1);
         setDrawType(option.value + "");
         setLoading(true);
        
    }

    const handleColumnClick = (columnName : string)=>{
      if (column !== columnName){
        setDirection("ascending");
      }
      else{
        if (direction === undefined){
          setDirection('ascending');
        }
        else{
          setDirection(direction === "ascending" ? "descending" : "ascending");
        }
      }
      setColumn(columnName);
      setLoading(true);
      
    }

    const handlePageChanged = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps)=>{
      setPage(parseInt(data.activePage!.toString()));
      setLoading(true);

    }

    useEffect(()=>{

        let newDirection = "none";
        if (direction !== undefined){
          if (direction === "ascending"){
            newDirection = "asc";
          }
          else{
            newDirection = "desc";
          }
        }
       

        agent.Draw4DResult.getAnalysisByMonth(drawType, column, newDirection, page, status, rowPerPage, rating).then((response)=>{
            setAnalysis(response);
            setLoading(false);
            
        });

        
    },[drawType, column, direction, page, status, rowPerPage, rating]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
        <Table celled  sortable unstackable className="freeze-head-and-col">
        <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="19" textAlign="center">
          <Header>4D Analytic By Month {periodDisplay}</Header>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
            <Table.HeaderCell colSpan="19">
            <Grid centered>
                <Grid.Column verticalAlign="middle" textAlign="left" width={2}>
                    <MyDropDown zIndex="20" placeholder='Select Draw Type' toolTip="Select Draw Type" toolTipDirection="top center" 
                        onChange={handleDropDownSelect} options={_DrawTypeOptions} defaultValue={drawType}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column verticalAlign="middle"  width={3}>
                  <Input 
                      data-tooltip="Enter 4D Number to find the row number in the list"
                      data-position="bottom center"
                      fluid  maxLength={4} focus placeholder='Search 4D position in the list'  onChange={handleInputChanged}></Input>
                </Grid.Column>
                <Grid.Column verticalAlign="middle"  width={4}>
                <MyDropDown zIndex="20" placeholder='Select rating to filter' toolTip="Select rating to filter" toolTipDirection="top center" 
                        onChange={ratingChanged} options={_RatingOptions} defaultValue={rating}>
                </MyDropDown>
                </Grid.Column>
                <Grid.Column  width={3}>
                     <MyDropDown zIndex="20" placeholder='Select State' toolTip="Select Number State" toolTipDirection="top center" 
                        onChange={statusDropDownSelect} options={_StatusOptions} defaultValue={status}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column  width={4}>
                  <MyDropDown zIndex="20" placeholder='Select Item Per Page' toolTip="Select Item Per Page" toolTipDirection="top center" 
                        onChange={rowPerPageChanged} options={_RowPerPageOptions} defaultValue={rowPerPage}>
                    </MyDropDown>
                </Grid.Column>  
              </Grid>

            </Table.HeaderCell>
          </Table.Row>
          
          <Table.Row>
            <Table.HeaderCell 
             sorted={column === 'source_number' ? direction : undefined}
             onClick={
              () => handleColumnClick("source_number")
            }
             rowSpan='2' textAlign="center">System<br/>Number
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan={2} textAlign="center">
                    Row #
            </Table.HeaderCell>
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="forecast" handleClick={handleColumnClick}>
            Rating
            </MyTableWithSort> 

            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="Status" handleClick={handleColumnClick}>
            Number<br/>Status (All)
            </MyTableWithSort> 

            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="Status_Top3" handleClick={handleColumnClick}>
            Number<br/>Status (Top3)
            </MyTableWithSort> 

            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="Awaken" handleClick={handleColumnClick}>
            Awaken
            </MyTableWithSort> 

            <Table.HeaderCell 
             sorted={column === 'total' ? direction : undefined}
              onClick={
                () => handleColumnClick("total")
              }
            rowSpan='2' textAlign="center">All</Table.HeaderCell>
           
            <Table.HeaderCell 
             sorted={column === 'top3' ? direction : undefined}
             onClick={
              () => handleColumnClick("top3")
            }
            rowSpan='2' textAlign="center">Top3</Table.HeaderCell>
            <Table.HeaderCell 
             sorted={column === 'draw_date' ? direction : undefined}
             onClick={
              () => handleColumnClick("draw_date")
            }
                rowSpan='2' textAlign="center">Last Drawn Date<br/>(All)</Table.HeaderCell>

            <Table.HeaderCell 
             sorted={column === 'max_Top3_Date' ? direction : undefined}
             onClick={
              () => handleColumnClick("max_Top3_Date")
            }
                rowSpan='2' textAlign="center">Last Drawn Date<br/>(Top 3)</Table.HeaderCell>

            <Table.HeaderCell 
             sorted={column === 'average_All' ? direction : undefined}
             onClick={
              () => handleColumnClick("average_All")
            }
                rowSpan='2' textAlign="center">Yearly Average<br/>(All/Top3)</Table.HeaderCell>

            <Table.HeaderCell 
            colSpan='12' textAlign="center"><Header># Strike in Year for Month: {curMonth}</Header></Table.HeaderCell>
   
          </Table.Row>
          <Table.Row>
             <Table.HeaderCell 
             sorted={column === 'y00' ? direction : undefined}
             onClick={() => handleColumnClick("y00")}> {(curYear).toString().substring(2)}
             </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'y01' ? direction : undefined}
             onClick={() => handleColumnClick("y01")}>{(curYear - 1).toString().substring(2)}</Table.HeaderCell>
            <Table.HeaderCell
            sorted={column === 'y02' ? direction : undefined}
            onClick={() => handleColumnClick("y02")}>{(curYear - 2).toString().substring(2)}</Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'y03' ? direction : undefined}
            onClick={() => handleColumnClick("y03")}>{(curYear - 3).toString().substring(2)}</Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'y04' ? direction : undefined}
            onClick={() => handleColumnClick("y04")}>{(curYear - 4).toString().substring(2)}</Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'y05' ? direction : undefined}
            onClick={() => handleColumnClick("y05")}>{(curYear - 5).toString().substring(2)}</Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'y06' ? direction : undefined}
            onClick={() => handleColumnClick("y06")}>{(curYear - 6).toString().substring(2)}</Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'y07' ? direction : undefined}
            onClick={() => handleColumnClick("y07")}>{(curYear - 7).toString().substring(2)}</Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'y08' ? direction : undefined}
            onClick={() => handleColumnClick("y08")}>{(curYear - 8).toString().substring(2)}</Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'y09' ? direction : undefined}
            onClick={() => handleColumnClick("y09")}>{(curYear - 9).toString().substring(2)}</Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'y10' ? direction : undefined}
            onClick={() => handleColumnClick("y10")}>{(curYear - 10).toString().substring(2)}</Table.HeaderCell>
         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis.map((x,i) => {
            return (
                <Table.Row key={x.source_Number}>
                    <Table.Cell textAlign="center">
                    <DrawLink number={x.source_Number} title=""></DrawLink>
                    </Table.Cell>

                    <Table.Cell  textAlign="center">
                      {x.row_No}
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      <Popup content={_GetRatingContent(x.forecast)}
                       trigger ={<Image avatar src={_GetRating(x.forecast)} />}>
                      </Popup>
                    </Table.Cell>
                                        
                    <Table.Cell  textAlign="center">
                      <NumberStatus status = {x.status} />
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      <NumberStatus status = {x.status_Top3} />
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      {x.awaken}
                    </Table.Cell>
                    <Table.Cell  textAlign="center">{x.total}<br/>
                    <Label  data-tooltip={'Freq Order: ' + x.rank}
                       circular size={"tiny"} color={"blue"}>{x.rank}</Label>

                    </Table.Cell>
                    <Table.Cell  textAlign="center">{x.top3}<br/>
                    <Label  data-tooltip={'Top3 Freq Order: ' + x.rank_Top3}
                       circular size={"tiny"} color={"blue"}>{x.rank_Top3}</Label>
                    </Table.Cell>
                    <Table.Cell  textAlign="center" className={style.lastdrawn}>
                      {x.draw_Date}<br/>
                      <Label  data-tooltip={'All Interval: ' + x.current_Gap}  circular size={"tiny"} color={"blue"}>{x.current_Gap}</Label>
                    </Table.Cell>
                    <Table.Cell  textAlign="center" className={style.lastdrawn}>
                      {x.max_Top3_Date}<br/>
                      <Label data-tooltip={'Top3 Interval: ' + x.top3_Current_Gap}  circular size={"tiny"} color={"blue"}>{x.top3_Current_Gap}</Label>
                    </Table.Cell>

                    <Table.Cell  textAlign="center"  className={style.lastdrawn}>
                      {x.avg_All}<br/>
                      <Label  data-tooltip={'Top3 Average: ' + x.avg_Top3}
                       circular size={"tiny"} color={"red"}>{x.avg_Top3}</Label>
                    </Table.Cell>
                    <YearResult result={x.y00} top3={x.t00}></YearResult>
                    <YearResult result={x.y01} top3={x.t01}></YearResult>
                    <YearResult result={x.y02} top3={x.t02}></YearResult>
                    <YearResult result={x.y03} top3={x.t03}></YearResult>
                    <YearResult result={x.y04} top3={x.t04}></YearResult>
                    <YearResult result={x.y05} top3={x.t05}></YearResult>
                    <YearResult result={x.y06} top3={x.t06}></YearResult>
                    <YearResult result={x.y07} top3={x.t07}></YearResult>
                    <YearResult result={x.y08} top3={x.t08}></YearResult>
                    <YearResult result={x.y09} top3={x.t09}></YearResult>
                    <YearResult result={x.y10} top3={x.t10}></YearResult>

           </Table.Row>
            );
          })
          }   
           </Table.Body>
      </Table>
        {analysis && analysis.length > 0 &&
          <Pagination activePage={page} totalPages={analysis[0].totalPage} onPageChange={handlePageChanged}/>
        }
      </div>
    );
});