import { observer } from "mobx-react-lite";
import React, {  SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { DropdownProps, Grid } from 'semantic-ui-react'
import { history } from "../../..";
import agent from "../../../app/api/agent";
import { _DrawTypeOptionsWithAll } from "../../../app/lib/CommonData";
import LoadingComponent from "../../../app/lib/LoadingComponent";
import MyDropDown from "../../../app/lib/MyDropDown";
import { DirectIntervalHistoryResult } from "../../../app/models/IDayResult";
import { useStore } from "../../../app/store/store";
import DirectInterval from "./DirectInterval";


export default observer(function DigitPositionHistory(){
    const [directData, setDirectData] = useState<DirectIntervalHistoryResult[]>([]);
    const [noOfDraw, setNoOfDraw] = useState(36);
    const [drawType, setDrawType] = useState("A");
    const [loading, setLoading] = useState(true);
    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
    }
  
    
    const noOfDrawOptions = [
        {
            key: '1',
            text: '1 Month',
            value: 12
        },
          
        {
          key: '2',
          text: '2 Months',
          value: 24
        },
        {
            key: '3',
            text: '3 Months',
            value: 36
        },
        {
            key: '6',
            text: '6 Months',
            value: 72
        },
        {
            key: '12',
            text: '1 Year',
            value: 144
        }

    ]

    const noOfDrawChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setNoOfDraw(parseInt(option.value+""));
        setLoading(true);
    }

    const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setDrawType(option.value + "");
        setLoading(true);
    }


    useEffect(()=>{
        agent.Draw4DResult.getTop3IntervalHistory( noOfDraw, drawType).then((response)=>{
            setDirectData(response);
        }).finally(()=>{
            setLoading(false);
        });
    },[ noOfDraw, drawType]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
                  <Grid>
                  <Grid.Column verticalAlign="middle" mobile={8} tablet={5} computer={5}>
                      <MyDropDown onChange={noOfDrawChanged} placeholder="Select No of Draw" 
                      toolTip="Select No Of Draw" zIndex="20"
                      options={noOfDrawOptions} defaultValue = {noOfDraw}>
                      </MyDropDown>
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle" mobile={8} tablet={5} computer={5}>
                      <MyDropDown onChange={drawTypeChanged} placeholder="Select Draw Type" 
                      toolTip="Select Draw Type" zIndex="20"
                      options={_DrawTypeOptionsWithAll} defaultValue = {drawType}>
                      </MyDropDown>
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle" textAlign="center" mobile={16} tablet={6} computer={6}>
                          Top3 Interval History
                  </Grid.Column>
                  </Grid>
                  <DirectInterval data={directData} title="4D System Top3 Interval"/>
        
    </div>
    );
});