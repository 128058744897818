import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useStore } from "../../app/store/store";
import { Button, Checkbox, Container, Dropdown, DropdownProps, Header, Input, InputOnChangeData, Table } from "semantic-ui-react";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { _GetSessionData, _SetSessionData } from "../../app/lib/CommonFunctions";
import { DrawDateList, MonthlyEWinBox, MyDropDownValue } from "../../app/models/IDayResult";
import agent from "../../app/api/agent";
import EWinningBoxResultDialog from "./eWinningBoxResultDialog";
import DetailEWinningBox from "./DetailEWinningBox";
import MyNewDropDown from "../../app/lib/MyNewDropDown";
import { _DrawTypeOptionsWithAll, _RatingOptions, _StatusOptionsOnly, _WinboxNumberDisplay } from "../../app/lib/CommonData";
import WinningBoxSystem3DDialog from "./WinningBoxSystem3DDialog";
import WinningBoxSystem2DDialog from "./WinningBoxSystem2DDialog";

export default observer(function EWinningBox(){
    const [isLoading, setIsLoading] = useState(false);
    const [isOriginal, setOriginal] = useState(false);
    const [data, setData] = useState<MonthlyEWinBox>();
    const {modalStore, userStore} = useStore();
    const [forecastDate, setForecastDate] = useState<DrawDateList[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [rating, setRating] = useState(parseInt(_GetSessionData("EWinBox_Rating", 6) + ""));
    const [drawType, setDrawType] = useState(_GetSessionData("EWinBox_DrawType","A"));
    const [directInterval, setDirectInterval] = useState(_GetSessionData("EWinBox_DirectIntervalNew",""));
    const [systemInterval, setSystemInterval] = useState(_GetSessionData("EWinBox_SystemIntervalNew",""));
    const [top3Interval, setTop3Interval] = useState(_GetSessionData("EWinBox_Top3IntervalNew",""));
    const [systemStatus, setSystemStatus] = useState<string>(_GetSessionData("EWinBox_SystemStatus","0"));
    const [top3Status, setTop3Status] = useState<string>(_GetSessionData("EWinBox_Top3Status","0"));
    const [numberType, setNumberType] = useState<string>(_GetSessionData("EWinBox_NumberType","0"));
    
    const numberTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setNumberType(option.value + "");
        _SetSessionData("EWinBox_NumberType" , option.value + "");
    }

    const ratingChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        _SetSessionData("EWinBox_Rating" , parseInt(option.value + ""));
        setRating(parseInt(option.value + ""));
    }

    const drawTypeChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setDrawType(option.value + "");
        _SetSessionData("EWinBox_DrawType" , option.value + "");
    }

    const directIntervalChanged = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setDirectInterval(data.value);
        _SetSessionData("EWinBox_DirectIntervalNew", data.value);
    }


    const systemIntervalChanged = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setSystemInterval(data.value);
        _SetSessionData("EWinBox_SystemIntervalNew", data.value);
    }

    const top3IntervalChanged = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setTop3Interval(data.value);
        _SetSessionData("EWinBox_Top3IntervalNew", data.value);
    }
 
    const systemStatusChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setSystemStatus(option.value + "");
        _SetSessionData("EWinBox_SystemStatus" , option.value + "");
    }

    const openResultClick = () => {
        localStorage.setItem("SelectedDate", selectedDate);
        localStorage.setItem("ResultType", "Monthly");
        const title = "4DGM Monthly e-WinBox Result";
        modalStore.openModal(<EWinningBoxResultDialog></EWinningBoxResultDialog>, title,"large", null,()=>{})

    };

    const openBoxClick = (box: number) => {
        localStorage.setItem("SelectedBox", box + "");
        localStorage.setItem("SelectedDate", selectedDate + "");

        const title = "Direct Numbers for Box: " + (box === 0 ? "[Box 1-4]" : box);
        modalStore.openModal(<DetailEWinningBox></DetailEWinningBox>, title,"tiny", null,()=>{})

    };

    const openSystem3DClick = (box: number) => {
        localStorage.setItem("BoxNo", box + "");
        localStorage.setItem("SelectedDate", selectedDate + "");

        const title = "System3D Numbers for Box: " + (box === 0 ? "[Box 1-4]" : box);
        modalStore.openModal(<WinningBoxSystem3DDialog></WinningBoxSystem3DDialog>, title, "fullscreen", null, () => { })

    };

    const openSystem2DClick = (box: number) => {
        localStorage.setItem("BoxNo", box + "");
        localStorage.setItem("SelectedDate", selectedDate + "");

        const title = "System2D Numbers for Box: " + (box === 0 ? "[Box 1-4]" : box);
        modalStore.openModal(<WinningBoxSystem2DDialog></WinningBoxSystem2DDialog>, title, "fullscreen", null, () => { })

    };

    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        //drawResultStore.setLoadingInitial(true);
    
        setSelectedDate(option.value + "");
    
        //drawResultStore.loadDayResult();
        //drawResultStore.setLoadingInitial(false);
    
    }

    const isOriginalClicked = (event: React.FormEvent<HTMLInputElement>, data: any) =>{
        setOriginal(data.checked);
        //_SetSessionData("isOriginal" , data.checked ? "true" : "false");
    }

    const top3StatusChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps) =>{
        setTop3Status(option.value + "");
        _SetSessionData("EWinBox_Top3Status" , option.value + "");
    }



    useEffect(()=>{
        if (selectedDate && selectedDate.length === 0) return;

     
        agent.Draw4DResult.getMonthlyEWinbox(isOriginal, selectedDate).then((response)=>{
            setIsLoading(false);
            setData(response);
        });
    }, [isOriginal, selectedDate]);

    useEffect(()=>{

        agent.Draw4DResult.getWinningDate("Monthly").then((response)=>{
            setForecastDate(response);
            if (response.length > 0){
                setSelectedDate(response[0].draw_Date);
            }
        });
    }, []);


    
    if(!userStore.isLogged){
        return (<Header>Not Authorized</Header>)
    }

    if (isLoading){
        return (<LoadingComponent></LoadingComponent>);
    }
    return(
        <div>
        <Container fluid>
            <Table celled selectable  unstackable style={{"borderCollapse":"collapse", "borderWidth":"4px",'borderColor':'red',"width":"48em"}}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                    <Checkbox  label='Show Original?' defaultChecked={isOriginal} onClick={isOriginalClicked}/>
                    </Table.Cell>
                    <Table.Cell>Monthly Forecast Date:
                    <Dropdown style={{marginLeft: '10px', zIndex:99}} compact 
                            onChange={handleDropDownSelect}
                            options={forecastDate.map(x=> ({key: x.draw_Date, text: x.draw_Date, value: x.draw_Date}))}
                            value = {selectedDate}
                    
                        />

                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        <Button size="medium" primary type="button" onClick={()=>openResultClick()}>Result</Button>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
            </Table>
            <Table celled selectable  unstackable style={{"borderCollapse":"collapse", "borderWidth":"4px",'borderColor':'red',"width":"48em"}}>

                <Table.Body>
                    <Table.Row>
                    <Table.Cell colSpan="6" textAlign="center">
                        <MyNewDropDown onChange={ratingChanged} placeholder="Select Rating" zIndex="25"
                            toolTip="Select Rating"
                            options={_RatingOptions} defaultValue = {rating}>
                      </MyNewDropDown>
                    </Table.Cell>                    
                    <Table.Cell colSpan="5" textAlign="center">
                            <MyNewDropDown onChange={drawTypeChanged} placeholder="Select Draw Type" zIndex="23"
                            toolTip="Select Draw Type"
                            options={_DrawTypeOptionsWithAll} defaultValue = {drawType}>
                            </MyNewDropDown>
                    </Table.Cell>                    
                            <Table.Cell colSpan="5" textAlign="center">
                                <MyNewDropDown onChange={numberTypeChanged} placeholder="Select Number Type" zIndex="20"
                                    toolTip="Select Number Type"
                                    options={_WinboxNumberDisplay} defaultValue={numberType}>
                                </MyNewDropDown>                       
                        </Table.Cell>

                    </Table.Row>
                    <Table.Row>
                            <Table.Cell colSpan="6" textAlign="center">
                                <Input label="System" style={{ width: "100%" }} placeholder='System Interval' defaultValue={systemInterval} onChange={systemIntervalChanged} />

                        </Table.Cell>
                            <Table.Cell colSpan="5" textAlign="center">
                                <Input label="Top3" style={{ width: "100%" }}  placeholder='Top3 Interval' defaultValue={top3Interval} onChange={top3IntervalChanged} />
              
                        </Table.Cell>
                        <Table.Cell colSpan="5" textAlign="center">
                                <Input label="Direct" style={{ width: "100%" }} placeholder='Direct Interval' defaultValue={directInterval} onChange={directIntervalChanged} />
                        </Table.Cell>

                    </Table.Row>

                    <Table.Row>
                        <Table.Cell colSpan="6" textAlign="center">

                                <MyNewDropDown onChange={systemStatusChanged} placeholder="Select System Status" zIndex="21"
                                    toolTip="Select System Status"
                                    options={_StatusOptionsOnly} defaultValue={systemStatus}>
                                </MyNewDropDown>
                        </Table.Cell>
                        <Table.Cell colSpan="5" textAlign="center">
                            <MyNewDropDown onChange={top3StatusChanged} placeholder="Select Top3 Status" zIndex="21"
                                toolTip="Select Top3 Status"
                                options={_StatusOptionsOnly} defaultValue={top3Status}>
                                </MyNewDropDown>                        
                            </Table.Cell>
                            <Table.Cell colSpan="5" textAlign="center">

                        </Table.Cell>

                    </Table.Row>

                    <Table.Row>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem3DClick(1)} size="mini" type="button" color="teal">System3D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem3DClick(2)} size="mini" type="button" color="teal">System3D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem3DClick(3)} size="mini" type="button" color="teal">System3D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem3DClick(4)} size="mini" type="button" color="teal">System3D</Button>
                                </Header>
                            </Table.Cell>
                    </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem2DClick(1)} size="mini" type="button" color="teal">System2D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem2DClick(2)} size="mini" type="button" color="teal">System2D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem2DClick(3)} size="mini" type="button" color="teal">System2D</Button>
                                </Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue">
                                    <Button onClick={() => openSystem2DClick(4)} size="mini" type="button" color="teal">System2D</Button>
                                </Header>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openBoxClick(1)} size="mini" type="button" primary>Detail Box 1</Button></Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openBoxClick(2)} size="mini" type="button" primary>Detail Box 2</Button></Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openBoxClick(3)} size="mini" type="button" primary>Detail Box 3</Button></Header>
                            </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openBoxClick(4)} size="mini" type="button" primary>Detail Box 4</Button></Header>
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openSystem3DClick(0)} size="mini" type="button" primary color="green">System3D (1-4)</Button></Header>
                            </Table.Cell>
                        <Table.Cell colSpan="8" textAlign="center" style={{"borderWidth":"4px",'borderColor':'red'}}>
                        <Header as="h5" color="blue"><Button onClick={()=>openBoxClick(0)} size="mini" type="button" primary>All Boxes (1-4)</Button></Header>
                        </Table.Cell>
                            <Table.Cell colSpan="4" textAlign="center" style={{ "borderWidth": "4px", 'borderColor': 'red' }}>
                                <Header as="h5" color="blue"><Button onClick={() => openSystem2DClick(0)} size="mini" type="button" primary color="green">System2D (1-4)</Button></Header>
                            </Table.Cell>

                        </Table.Row>
                    {data && <Table.Row style={{"borderWidth":"4px",'borderColor':'red'}}>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                            <b>{data?.box1_1 ? data?.box1_1.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                            <b>{data?.box1_1 ?  data?.box1_1.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b>{data?.box1_1 ?  data?.box1_1.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_1 ?  data?.box1_1.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red', "borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_2 ? data?.box1_2.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_2 ?  data?.box1_2.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_2 ?  data?.box1_2.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_2 ?  data?.box1_2.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red',"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_3 ? data?.box1_3.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_3 ? data?.box1_3.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_3 ? data?.box1_3.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_3 ? data?.box1_3.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red',"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_4 ? data?.box1_4.substring(0,1):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_4 ? data?.box1_4.substring(1,2):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_4 ? data?.box1_4.substring(2,3):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box1_4 ? data?.box1_4.substring(3,4):""}</b>
                        </Table.Cell>
                    </Table.Row>
                    }
                    {data && <Table.Row style={{"borderWidth":"4px",'borderColor':'red'}}>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                            <b>{data?.box2_1 ? data?.box2_1.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b>{data?.box2_1 ?  data?.box2_1.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_1 ?  data?.box2_1.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_1 ?  data?.box2_1.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red', "borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_2 ? data?.box2_2.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_2 ?  data?.box2_2.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_2 ?  data?.box2_2.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_2 ?  data?.box2_2.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red',"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_3 ? data?.box2_3.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_3 ? data?.box2_3.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_3 ? data?.box2_3.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_3 ? data?.box2_3.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red',"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_4 ? data?.box2_4.substring(0,1):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_4 ? data?.box2_4.substring(1,2):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_4 ? data?.box2_4.substring(2,3):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box2_4 ? data?.box2_4.substring(3,4):""}</b>
                        </Table.Cell>
                    </Table.Row>
                    }
                    {data && <Table.Row style={{"borderWidth":"4px",'borderColor':'red'}}>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_1 ? data?.box3_1.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_1 ?  data?.box3_1.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_1 ?  data?.box3_1.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_1 ?  data?.box3_1.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red', "borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_2 ? data?.box3_2.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_2 ?  data?.box3_2.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_2 ?  data?.box3_2.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_2 ?  data?.box3_2.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red',"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_3 ? data?.box3_3.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_3 ? data?.box3_3.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_3 ? data?.box3_3.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_3 ? data?.box3_3.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red',"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_4 ? data?.box3_4.substring(0,1):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_4 ? data?.box3_4.substring(1,2):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_4 ? data?.box3_4.substring(2,3):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box3_4 ? data?.box3_4.substring(3,4):""}</b>
                        </Table.Cell>
                    </Table.Row>
                    }
                    {data && <Table.Row style={{"borderWidth":"4px",'borderColor':'red'}}>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_1 ? data?.box4_1.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_1 ?  data?.box4_1.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_1 ?  data?.box4_1.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_1 ?  data?.box4_1.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red', "borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_2 ? data?.box4_2.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_2 ?  data?.box4_2.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_2 ?  data?.box4_2.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_2 ?  data?.box4_2.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red',"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_3 ? data?.box4_3.substring(0,1) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_3 ? data?.box4_3.substring(1,2) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_3 ? data?.box4_3.substring(2,3) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_3 ? data?.box4_3.substring(3,4) : ""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderLeftWidth":"4px",'borderLeftColor':'red',"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_4 ? data?.box4_4.substring(0,1):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_4 ? data?.box4_4.substring(1,2):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_4 ? data?.box4_4.substring(2,3):""}</b>
                        </Table.Cell>
                        <Table.Cell textAlign="center" style={{"borderTopWidth":"4px",'borderTopColor':'red'}}>
                        <b> {data?.box4_4 ? data?.box4_4.substring(3,4):""}</b>
                        </Table.Cell>
                    </Table.Row>
                    }

              </Table.Body>
            </Table>
        </Container>
        </div>
    )
})