import React, {  useEffect, useState } from "react";
import {  Item, Label, Segment } from "semantic-ui-react";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/lib/LoadingComponent";
import {  RecommendedDirectNumber } from "../../app/models/IDayResult";

interface Props{
    numberToCheck: string
}

export default function ViewRecommendedDirect(props: Props){
    const [data, setData] = useState<RecommendedDirectNumber[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(()=>{
        if (!props.numberToCheck) return;
        if (props.numberToCheck.length !== 4) return;

        agent.Draw4DResult.getRecommendedDirectNumber(props.numberToCheck).then((response)=>{

            setData(response);
            setLoading(false);
            //setLoaded(true);
            //isLoading = false;
        });
        
    },[  props.numberToCheck]); 

    if (isLoading) return (<LoadingComponent></LoadingComponent>)
    return (
        <Segment raised textAlign="left">
             <Label as='a' color='green' ribbon>
                    4DGM Recommended Direct Numbers
            </Label>
            <Item.Group relaxed horizontal>
             {
                 data && data.length > 0 &&
                 data.map(x=>{
                    return (
                    <Item>
                    <Item.Content verticalAlign='middle'>
                        <Item.Header>{x.number}</Item.Header>
                    </Item.Content>
                    </Item>
                    )
                 })
             }   
             </Item.Group>
        </Segment>
    );
}