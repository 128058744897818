import React from "react";
import { Label, Table } from "semantic-ui-react";
import { _DisplayPercentage, _DisplayZero } from "./CommonFunctions";


interface Props{
    isVisible: boolean;
    content: number;
    total?: number;
    body?: boolean;
}


export default function TableCellLabel({body,isVisible, content, total}: Props){
    if (!isVisible) return null;
    if (body){
        return (
        <Table.Cell  textAlign="center">
            { _DisplayZero(content)}
        </Table.Cell>
        );
    }
    return (
        <Table.Cell  textAlign="center">
        <Label circular color="blue">{content }</Label>
        <Label >{_DisplayPercentage(content, total!, 0)}</Label>                    
        </Table.Cell>       
    )
}

 