import React from 'react';
import { Helmet } from 'react-helmet';
import { Header, List, ListItem, ListList, Segment } from 'semantic-ui-react';

function TermAndCondition() {
    return (
        <Segment>
            <Helmet>
                <title>Term and Condition</title>
                <meta name="description" content="4DGM Term and Condition using the AI prediction software" />
            </Helmet>
            <Header>
                Terms and Conditions
            </Header>
            <List as='ol'>
                <ListItem as='li'><u>*License and Usage*</u><br />
                    <ListList as='ol'>
                        <ListItem as='li'>
                            The 4DGM/TOTOGM/AI4D Chat software is licensed to you exclusively for personal use as a registered subscriber.
                        </ListItem>
                    </ListList>
                </ListItem>
                <ListItem style={{ marginTop: "10px" }} as='li'><u>*Device Access*</u><br />
                    <ListList as='ol'>
                        <ListItem as='li'>
                            You may access the software on a maximum of two (2) personal devices selected by you.
                        </ListItem>
                        <ListItem as='li'>
                            The first two logins on your devices will be registered as the authorized devices.
                        </ListItem>
                    </ListList>
                </ListItem>
                <ListItem as='li' style={{ marginTop: "10px" }}>
                    <u>*Unauthorized Access*</u>
                    <ListList as='ol'>
                        <ListItem as='li'>Any access by a device or user other than the two registered devices/users will be deemed unauthorized.</ListItem>
                        <ListItem as='li'>Unauthorized access may result in account suspension or restriction of access to the software.</ListItem>
                    </ListList>
                </ListItem>
                <ListItem as='li' style={{ marginTop: "10px" }}><u>*Device Reset Fee*</u>
                    <ListList as='ol'>
                        <ListItem as='li'>In the event your account is locked due to unauthorized access, a reset fee of $20 may apply.</ListItem>
                        <ListItem as='li'>This fee may be waived if the replacement of a registered device is necessary due to it being unserviceable. Proof of replacement, such as a purchase receipt, must be provided.</ListItem>
                    </ListList>
                </ListItem>
                <ListItem as='li' style={{ marginTop: "10px" }}><u>*Subscription Payment*</u>
                    <ListList as='ol'>
                        <ListItem as='li'>All subscription payments are non-refundable under any circumstances, provided the services are satisfactorily delivered by 4DGrandMaster.com</ListItem>
                    </ListList>
                </ListItem>

            </List>
        </Segment>

    );
}

export default TermAndCondition;