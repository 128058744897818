export const _ForecastType = [
    {
        key: '1',
        text: 'Monthly Forecast',
        value: '1'
    },
    {
        key: '2',
        text: 'Forecast',
        value: '2'
    }
]

export const _StatusType = [
    {
        key: '1',
        text: 'Both System and Top3',
        value: '1'
    },
    {
        key: '2',
        text: 'System',
        value: '2'
    },
    {
        key: '3',
        text: 'Top3',
        value: '3'
    },

]
export const _ForecastDrawType = [
    {
        key: 'ABCD',
        text: 'ABCD',
        value: 'ABCD'
    },
    {
        key: 'AABC',
        text: 'AABC',
        value: 'AABC'
    },
    {
        key: 'AABB',
        text: 'AABB',
        value: 'AABB'
    },
    {
        key: 'AAAB',
        text: 'AAAB',
        value: 'AAAB'
    }    
]

export const _RatingOptionStrings = [
    {   text: "Any",
        value: "0",
    },
    {
        text: 'Very Weak (8)',
        value: "8",
        image: {
            src: "/assets/point8.png",
            avatar: true
        }
    },
    {
        text: 'Weak (7)',
        value: "7",
        image: {
            src: "/assets/point7.png",
            avatar: true
        }
    },
    {
        text: 'Good (6)',
        value: "6",
        image : {
            src: "/assets/point6.png",
            avatar: true
        }
    },
    {
        text: 'Very Good (5)',
        value: "5",
        image: {
            src: "/assets/point5.png",
            avatar: true
        }
    },
    {
        text: 'Average (4)',
        value: "4",
        image:{ 
            src: "/assets/point4.png",
            avatar: true
        }
    },
    {
        text: 'Average (3)',
        value: "3",
        image: {
            avatar: true,
            src: "/assets/point3.png"
        }
    },
    {
        text: 'Below Average (2)',
        value: "2",
        image: {
            src: "/assets/point2.png",
            avatar: true
        }
    },
    {
        text: 'Below Average (1)',
        value: "1",
        image: {
            src: "/assets/point1.png",
            avatar: true
        }
    },
]

export const _RatingOptionStringsForAlert = [
    {
        text: 'Very Weak (8)',
        value: "8",
        image: {
            src: "/assets/point8.png",
            avatar: true
        }
    },
    {
        text: 'Weak (7)',
        value: "7",
        image: {
            src: "/assets/point7.png",
            avatar: true
        }
    },
    {
        text: 'Good (6)',
        value: "6",
        image : {
            src: "/assets/point6.png",
            avatar: true
        }
    },
    {
        text: 'Very Good (5)',
        value: "5",
        image: {
            src: "/assets/point5.png",
            avatar: true
        }
    },
    {
        text: 'Average (4)',
        value: "4",
        image:{ 
            src: "/assets/point4.png",
            avatar: true
        }
    }
]

export const _ColorsToChoose  = ["green", "red","brown","silver","blue"];
export const _QimentDunJia = ["S","SW","W","NW","N","NE","E","SE"];
    

export const _RatingOptions = [
    {   text: "Any",
        value: 0,
    },
    {
        text: 'Very Weak (8)',
        value: 8,
        image: {
            src: "/assets/point8.png",
            avatar: true
        }
    },
    {
        text: 'Weak (7)',
        value: 7,
        image: {
            src: "/assets/point7.png",
            avatar: true
        }
    },
    {
        text: 'Good (6)',
        value: 6,
        image : {
            src: "/assets/point6.png",
            avatar: true
        }
    },
    {
        text: 'Very Good (5)',
        value: 5,
        image: {
            src: "/assets/point5.png",
            avatar: true
        }
    },
    {
        text: 'Average (4)',
        value: 4,
        image:{ 
            src: "/assets/point4.png",
            avatar: true
        }
    },
    {
        text: 'Average (3)',
        value: 3,
        image: {
            avatar: true,
            src: "/assets/point3.png"
        }
    },
    {
        text: 'Below Average (2)',
        value: 2,
        image: {
            src: "/assets/point2.png",
            avatar: true
        }
    },
    {
        text: 'Below Average (1)',
        value: 1,
        image: {
            src: "/assets/point1.png",
            avatar: true
        }
    },
] 

export const _DrawTypeOptions = [
    {
        key: 'ABCD',
        text: 'ABCD',
        value: 'ABCD'
    },
    {
        key: 'AABC',
        text: 'AABC',
        value: 'AABC'
    },
    {
        key: 'AABB',
        text: 'AABB',
        value: 'AABB'
    },
    {
        key: 'AAAB',
        text: 'AAAB',
        value: 'AAAB'
    },
    {
        key: 'AAAA',
        text: 'AAAA',
        value: 'AAAA'
    }
]

export const _DrawTypeOptionsWithAll = [
    {
        key: 'A',
        text: 'All',
        value: 'A'
    },
    {
        key: 'ABCD',
        text: 'ABCD',
        value: 'ABCD'
    },
    {
        key: 'AABC',
        text: 'AABC',
        value: 'AABC'
    },
    {
        key: 'AABB',
        text: 'AABB',
        value: 'AABB'
    },
    {
        key: 'AAAB',
        text: 'AAAB',
        value: 'AAAB'
    },
    {
        key: 'AAAA',
        text: 'AAAA',
        value: 'AAAA'
    }
]

export const  _WinboxNumberDisplay = [
    {
        key: '0',
        text: 'All',
        value: '0'
    },
    {
        key: '1',
        text: 'Recommended Number Only',
        value: '1'
    },
    {
        key: '2',
        text: 'Other Direct Number Only',
        value: '2'
    }
]

export const  _StatusOptionsOnlyForAlert = [
    {
      key: '1',
      text: 'System In Range',
      value: '1'
    },
    {
      key: '2',
      text: 'System Not Due',
      value: '2'
    },
    {
      key: '3',
      text: 'System Over Due',
      value: '3'
    },
    {
        key: '4',
        text: 'Top3 In Range',
        value: '4'
      },
      {
        key: '5',
        text: 'Top3 Not Due',
        value: '5'
      },
      {
        key: '6',
        text: 'Top3 Over Due',
        value: '6'
      }
  
]

export const  _StatusOptionsOnly = [
    {
      key: '0',
      text: 'All',
      value: '0'
    },
    {
      key: '1',
      text: 'System In Range',
      value: '1'
    },
    {
      key: '2',
      text: 'System Not Due',
      value: '2'
    },
    {
      key: '3',
      text: 'System Over Due',
      value: '3'
    }
]

export const  _StatusOptions = [
    {
      key: '0',
      text: 'All',
      value: '0'
    },
    {
      key: '1',
      text: 'System In Range',
      value: '1'
    },
    {
      key: '2',
      text: 'System Not Due',
      value: '2'
    },
    {
      key: '3',
      text: 'System Over Due',
      value: '3'
    },
    {
        key: '4',
        text: 'Top3 In Range',
        value: '4'
    },
    {
        key: '5',
        text: 'Top3 Not Due',
        value: '5'
    },
    {
        key: '6',
        text: 'Top3 Over Due',
        value: '6'
    },
    {
        key: '7',
        text: 'System and Top3 In Range',
        value: '7'
    },
    {
        key: '8',
        text: 'System and Top3 Not Due',
        value: '8'
    },
    {
        key: '9',
        text: 'System and Top3 Over Due',
        value: '9'
    },
    {
        key: '10',
        text: 'System In Range and Top3 Not Due',
        value: '10'
    },
    {
        key: '11',
        text: 'System In Range and Top3 Over Due',
        value: '11'
    },
    {
        key: '12',
        text: 'System Not Due and Top3 In Range',
        value: '12'
    },
    {
        key: '13',
        text: 'System Not Due and Top3 Over Due',
        value: '13'
    },

    {
        key: '14',
        text: 'System Over Due and Top3 In Range',
        value: '14'
    },
    {
        key: '15',
        text: 'System Over Due and Top3 Not Due',
        value: '15'
    }
  ]

  
  export const _RowPerPageOptions = [
    { value: 5,  text: "5"  },
    { value: 10, text: "10" },
    { value: 20, text: "20" },
    { value: 50, text: "50" },
    { value: 999, text: "All" }
 ]


export const _PrizeTypeOptions = [
    {   key: "A",
        text: 'All Prizes',
        value: 'A'
    },
    {
        key: 'T',
        text: 'Top3',
        value: 'T'
    },
      
    {
      key: '1',
      text: '1st',
      value: '1'
    },
    {
        key: '2',
        text: '2nd',
        value: '2'
    },
    {
        key: '3',
        text: '3rd',
        value: '3'
    },
    {
        key: 'S',
        text: 'Starter',
        value: 'S'
    },
    {
        key: 'C',
        text: 'Consolation',
        value: 'C'
    }
]

export const _DrawType3DOptions = [
    {
        key: 'ABC',
        text: 'ABC',
        value: 'ABC'
    },
    {
        key: 'AAB',
        text: 'AAB',
        value: 'AAB'
    },
    {
        key: 'AAA',
        text: 'AAA',
        value: 'AAA'
    }
]

export const _DrawType3DOptionsWithAll = [
    {
        key: 'All',
        text: 'All',
        value: 'A'
    },
    {
        key: 'ABC',
        text: 'ABC',
        value: 'ABC'
    },
    {
        key: 'AAB',
        text: 'AAB',
        value: 'AAB'
    },
    {
        key: 'AAA',
        text: 'AAA',
        value: 'AAA'
    }
]

export const _DrawType2DOptions = [
    {
        key: 'AB',
        text: 'AB',
        value: 'AB'
    },
    {
        key: 'AA',
        text: 'AA',
        value: 'AA'
    }
]

export const _DrawType2DOptionsWithAll = [
    {
        key: 'All',
        text: 'All',
        value: 'A'
    },
    {
        key: 'AB',
        text: 'AB',
        value: 'AB'
    },
    {
        key: 'AA',
        text: 'AA',
        value: 'AA'
    }
]

export const _eWinBoxNumer = [
    {
        key: '1',
        text: '1',
        value: 1
    },
    {
        key: '2',
        text: '2',
        value: 2
    },
    {
        key: '3',
        text: '3',
        value: 3
    },
    {
        key: '4',
        text: '4',
        value: 4
    },
    {
        key: '5',
        text: '5',
        value: 5
    },
    {
        key: '6',
        text: '6',
        value: 6
    },
    
]

export const _eDailyWinBoxNumer = [
    {
        key: '5',
        text: '5',
        value: 5
    },
    {
        key: '6',
        text: '6',
        value: 6
    },
    {
        key: '7',
        text: '7',
        value: 7
    },
    {
        key: '8',
        text: '8',
        value: 8
    },

]

export const _DigitPositionOptions = [
    {
        key: '1',
        text: '1st',
        value: 1
    },
      
    {
      key: '2',
      text: '2nd',
      value: 2
    },
    {
        key: '3',
        text: '3rd',
        value: 3
    },
    {
        key: '4',
        text: '4th',
        value: 4
    }
]

export const _3DPattern = [
    {
        text: 'ABC',
        value: "ABC"
    },
    {
        text: 'AAB',
        value: "AAB"
    },
    {
        text: 'AAA',
        value: "AAA"
    }
] 

export const _2DPattern = [
    {
        text: 'AB',
        value: "AB"
    },
    {
        text: 'AA',
        value: "AA"
    }
] 

export const _PositionType = [
    {
        text: 'All Position',
        value: 0
    },
    {
        text: 'Position 1',
        value: 1
    },
    {
        text: 'Position 2',
        value: 2
    },
    {
        text: 'Position 3',
        value: 3
    },
    {
        text: 'Position 4',
        value: 4
    }
] 

export const _RankTypeOptions = [
    {
        key: 'S',
        text: 'All Rank',
        value: 'S'
    },
    {
      key: 'T',
      text: 'Top3 Rank',
      value: 'T'
    },
    {
        key: 'M',
        text: 'By Month - All Rank',
        value: 'M'
    },
    {
        key: 'N',
        text: 'By Month - Top3',
        value: 'N'
    }

]


export const _NoOfDrawOptions = [
    {
        key: '1',
        text: '1 Month',
        value: 12
    },
      
    {
      key: '2',
      text: '2 Months',
      value: 24
    },
    {
        key: '3',
        text: '3 Months',
        value: 36
    },
    {
        key: '6',
        text: '6 Months',
        value: 72
    },
    {
        key: '12',
        text: '1 Year',
        value: 144
    },
    {
        key: '720',
        text: '5 Years',
        value: 720
    },
    {
        key: '1440`',
        text: '10 Years',
        value: 1440
    }
]