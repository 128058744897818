import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, {  useState } from "react";
import {  Grid} from "semantic-ui-react";
import * as Yup from 'yup';
import { history } from "../..";
import { _SortNumber } from "../../app/lib/CommonFunctions";
import MyTextInputButton from "../../app/lib/MyTextInputButton";
import { useStore } from "../../app/store/store";
import ViewGraphicsTop3 from "../viewnumber/viewgraphicstop3";


export default observer(function QuickGraph(){
    const [id, setID] = useState("");
    const [loading, setLoading] = useState(false);
    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
    }

    const validationSchema = Yup.object({
        numberToInput: Yup.string().required("4D Number is required").matches(/^[0-9]{4}$/, 'Must be exactly 4 digits')
    });

    let formData = {
        numberToInput: ""
    }
    return (
        <Grid textAlign="center" verticalAlign='top'>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <u>Quick Graph Top3</u>
                </Grid.Column>
            </Grid.Row>
           <Grid.Row>
               <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14}>
               <Formik 
                    enableReinitialize
                    initialValues = {formData}
                    validationSchema = {validationSchema}
                    validate = {()=>{
                        setID("");
                        setLoading(false);
                    }}
                    onSubmit = {(o) => {
                        const sortedNumber = _SortNumber(true,o.numberToInput);
                        setID(sortedNumber);
                        setLoading(false);
                    }}>
                        {({handleSubmit}) => (
                            <Form  className="ui form" onSubmit={handleSubmit}>
                                <MyTextInputButton loading={loading} label="Enter 4D Top3:" maxLength={4} name="numberToInput" placeholder="Enter 4D Number to Search">
                                </MyTextInputButton>
                            </Form>
                        )}
                </Formik>
               </Grid.Column>
               <Grid.Column width={1}>

               </Grid.Column>
           </Grid.Row>
           <Grid.Row>
               <Grid.Column width={1}></Grid.Column>
               <Grid.Column width={14}>
                   { id.length ===  4 && (
                       <ViewGraphicsTop3 number = {id} />
                   )
                   }
               </Grid.Column>
               <Grid.Column width={1}></Grid.Column>
           </Grid.Row>
        </Grid>
    );

})