import React, { useEffect, useState } from "react";
import {Popup,  Table, Image, Header } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _GetDirectHit, _GetImage } from "../../app/lib/CommonFunctions";
import { ForecastDrawResult } from "../../app/models/IDayResult";


export default function ForecastResultDialog(){
    const [data, setData] = useState<ForecastDrawResult[]>([]);
    let noOfTop3 = 0;
    let noOfStarter = 0;
    let noOfConso = 0;
    let totalStrike = 0;

    useEffect(()=>{
        const selectedDate = localStorage.getItem("SelectedDate");
        

        agent.Draw4DResult.getForecastDrawResult(selectedDate!).then((response)=>{
            setData(response);

           
        });

        
    },[]);

    if (data && data.length > 0){
        data.map(x=>{
            totalStrike++;
            if (x.prizeCode === "1" || x.prizeCode === "2" || x.prizeCode === "3"){
                noOfTop3++;
            } 
            if (x.prizeCode === "S"){
                    noOfStarter++;
            } 

            if (x.prizeCode === "C"){
                    noOfConso++;
            } 
            return true;
        });
    }
    return (
        <div style={{overflow:"auto"}}>
        <Table unstackable celled>

            <Table.Header>
                <Table.Row>
                    <Table.Cell textAlign="center"><Header as="h4">Top3</Header></Table.Cell>
                    <Table.Cell textAlign="center"><Header as="h4">Starter</Header></Table.Cell>
                    <Table.Cell textAlign="center"><Header as="h4">Consolation</Header></Table.Cell>
                    <Table.Cell textAlign="center"><Header as="h4">Total</Header></Table.Cell>
                    <Table.Cell textAlign="center"><Header as="h4">% Matched</Header></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell textAlign="center">{noOfTop3}</Table.Cell>
                    <Table.Cell textAlign="center">{noOfStarter}</Table.Cell>
                    <Table.Cell textAlign="center">{noOfConso}</Table.Cell>
                    <Table.Cell textAlign="center">{totalStrike}</Table.Cell>
                    <Table.Cell textAlign="center">{ ((totalStrike/48.0) * 100).toFixed(2)}%</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.HeaderCell textAlign="center">
                        #
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        Hit No.
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        Date
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        Prize
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        Direct Hit?
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {   data && data.length > 0 &&
                data.map((x,i)=>{
                return (
                    <Table.Row key={i}>
                        <Table.Cell textAlign="center">{i + 1}</Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.directNumber}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {x.drawDate}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                                <Image centered  src={_GetImage(x.prizeCode)} size="mini"></Image>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                                <Popup content={x.isDirect === "Yes" ? "Direct Hit" : "No Direct Hit"} 
                                    trigger={<Image centered  src={_GetDirectHit(x.isDirect)} size="mini"></Image>}>
                                </Popup>

                        </Table.Cell>
                    </Table.Row>
                )})
            }
            </Table.Body>
        </Table>
        </div>
    );
}