import { observer } from "mobx-react-lite";
import React from "react";
import { Modal } from "semantic-ui-react";
import { useStore } from "../store/store";


export default observer( function ModalContainer(){
    const {modalStore} = useStore();
    return (
        <Modal  closeIcon open={modalStore.modal.open} onClose={modalStore.closeModal} size={modalStore.modal.size}>
            <Modal.Header>
                {modalStore.modal.header}
            </Modal.Header>
            <Modal.Content>
                {modalStore.modal.body}
            </Modal.Content>
            <Modal.Actions>
                {modalStore.modal.action}
            </Modal.Actions>
        </Modal>
    )
}
)