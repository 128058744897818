import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Image, DropdownProps,  Grid,  Header, Input, InputOnChangeData, Pagination,  PaginationProps, Popup, Table, TableHeaderCell } from 'semantic-ui-react'
import agent from "../../app/api/agent";
import DrawLink from "../../app/lib/drawlink";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import {ForecastMethodResult } from "../../app/models/IDayResult";
import {  _GetRating, _GetRatingContent, _GetRowPerPage, _SetRowPerPage } from "../../app/lib/CommonFunctions";
import {  _ForecastDrawType, _RowPerPageOptions } from "../../app/lib/CommonData";
import MyDropDown from "../../app/lib/MyDropDown";
import { useStore } from "../../app/store/store";
import { history } from "../..";

export default observer(function ViewForecastMethod(){
    
    const [loading, setLoading] = useState(true);
    const [drawType, setDrawType] = useState("ABCD");
    const [analysis, setAnalysis] = useState<ForecastMethodResult[]>([]);
    const [column, setColumn] = useState("totalMethod");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [page, setPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(_GetRowPerPage());
    const {userStore} = useStore();
    

    if(!userStore.isAdmin){
      history.push("/");
    }

    const rowPerPageChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setRowPerPage(parseInt(option.value + ""));
      _SetRowPerPage(parseInt(option.value + ""));
      //setLoading(true);
     
    }

    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setPage(1);
         setDrawType(option.value + "");
         //setLoading(true);
        
    }


    const handleColumnClick = (columnName : string)=>{
      if (column !== columnName){
        setDirection("ascending");
      }
      else{
        if (direction === undefined){
          setDirection('ascending');
        }
        else{
          setDirection(direction === "ascending" ? "descending" : "ascending");
        }
      }
      setColumn(columnName);
      setPage(1);
    }

    const handlePageChanged = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps)=>{
      setPage(parseInt(data.activePage!.toString()));
    }

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData)=>{
      if(data.value.length === 4){
          if (!isNaN(parseFloat(data.value))){
            let newDirection:string;

            newDirection = "";
            if (direction === "ascending"){
              newDirection = "asc";
            }
            if (direction === "descending"){
              newDirection = "desc";
            }

            
            agent.Draw4DResult.getPageForForecastMethod(data.value, column, newDirection, drawType, rowPerPage).then(response=>{
              if(response.pageNo === 0){
                toast.error("Number not found ! Check the Draw Type.");
                return;
              }  
              toast.info("Found number in row " + response.row_No);

              setPage(response.pageNo);
            });
          }
      }

    }

    useEffect(()=>{

        let newDirection = "none";
        if (direction !== undefined){
          if (direction === "ascending"){
            newDirection = "asc";
          }
          else{
            newDirection = "desc";
          }
        }

        agent.Draw4DResult.getForecastMethodResult(drawType, column, newDirection, page, rowPerPage).then((response)=>{

            setAnalysis(response);
            setLoading(false);
            
        });

        
    },[drawType, column, direction, page, rowPerPage]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
        <Table celled unstackable selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="21">
              <Grid centered >
                <Grid.Column verticalAlign="middle" textAlign="left" width={2}>
                    <MyDropDown zIndex="20" placeholder='Select Draw Type' toolTip="Select Draw Type" toolTipDirection="top center" 
                        onChange={handleDropDownSelect} options={_ForecastDrawType} defaultValue={drawType}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column verticalAlign="middle"  width={3}>
                  <Input 
                      data-tooltip="Enter 4D Number to find the row number in the list"
                      data-position="bottom center"
                      fluid  maxLength={4} focus placeholder='Search 4D position in the list'  onChange={handleInputChanged}></Input>
                </Grid.Column>
                <Grid.Column  width={3}>
                </Grid.Column>
                <Grid.Column  width={2}>
                  <MyDropDown zIndex="20" placeholder='Select Item Per Page' toolTip="Select Item Per Page" toolTipDirection="top center" 
                        onChange={rowPerPageChanged} options={_RowPerPageOptions} defaultValue={rowPerPage}>
                    </MyDropDown>
                </Grid.Column>  
                <Grid.Column verticalAlign="middle"  width={6}>
                <Header>Forecast Method Result View</Header>
                </Grid.Column>
              </Grid>
              </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="System_4D" handleClick={handleColumnClick}>
              System<br/>Number
            </MyTableWithSort> 
            <TableHeaderCell textAlign="center">#</TableHeaderCell>
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="TotalMethod" handleClick={handleColumnClick}>
              Total Point 
            </MyTableWithSort> 
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="TotalMethod" handleClick={handleColumnClick}>
              Rating 
            </MyTableWithSort> 
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="TotalMethod" handleClick={handleColumnClick}>
              Icon 
            </MyTableWithSort> 

            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Method1" handleClick={handleColumnClick}>
              System Interval<br/>Top3
            </MyTableWithSort> 
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Method2" handleClick={handleColumnClick}>
              System Interval<br/>All Prizes
            </MyTableWithSort> 
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Method3" handleClick={handleColumnClick}>
              Top3<br/>Rank
            </MyTableWithSort> 
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Method4" handleClick={handleColumnClick}>
              All<br/>Rank
            </MyTableWithSort> 
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Method9" handleClick={handleColumnClick}>
              Top3<br/>In Range
            </MyTableWithSort> 
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Method10" handleClick={handleColumnClick}>
              All<br/>In Range
            </MyTableWithSort> 
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Method7" handleClick={handleColumnClick}>
              Awaken Numbers 
            </MyTableWithSort> 
            <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="Method8" handleClick={handleColumnClick}>
              Top3 Due 
            </MyTableWithSort> 

         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis.map((x => {
            return (
                <Table.Row key={x.system_4D} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">
                      <DrawLink number={x.system_4D} title=""></DrawLink>
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.row_No}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.totalMethod}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.rating}
                    </Table.Cell>

                    <Table.Cell  textAlign="center">
                      <Popup content={_GetRatingContent(x.rating)}
                       trigger ={<Image avatar src={_GetRating(x.rating)} />}>
                      </Popup>
                    </Table.Cell>

                    <Table.Cell textAlign="center">{x.method1}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.method2}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.method3}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.method4}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.method9}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.method10}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.method7}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.method8}
                    </Table.Cell>
           </Table.Row>
            );
          }))
          }   
           </Table.Body>
      </Table>
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <Pagination activePage={page} totalPages={analysis && analysis.length > 0 ? analysis[0].totalPage : 10} onPageChange={handlePageChanged}/>
      </div>
   
      </div>
    );
});