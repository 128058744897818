import { useField } from "formik";
import React from "react";
import { Button, Form,  Label } from "semantic-ui-react";

interface Props {
    children?: never[],
    name: string;
    placeholder: string;
    label?: string;
    maxLength: number;
    loading: boolean;
}
export default function MyTextInputButton(props: Props){
    const [field, meta] = useField(props.name);

    return (
        <Form.Field error={meta.touched && !!meta.error} inline={true}>
            {(window.innerWidth > 768) ?
                <label>{props.label}</label> : null
            }
            <input autoComplete="on" {...field} {...props}  maxLength={props.maxLength} style={{marginRight:"10px"}} />
            <Button fluid loading={props.loading} type="submit" positive icon="search" content="Search"  
                labelPosition="right" />
            {meta.touched && meta.error ? <Label basic color='red'>{meta.error}</Label> : null
            } 
        </Form.Field>
    );
}