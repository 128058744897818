import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect, useState } from "react";
import {  Grid, Dropdown, DropdownProps, Tab } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { DrawDateList, DrawDetailResult } from "../../app/models/IDayResult";
import DetailResult0 from "./DetailResult0";
import DetailResult1 from "./DetailResult1";
import DetailResult2 from "./DetailResult2";
import DetailResult3 from "./DetailResult3";
import DetailResult5 from "./DetailResult5";
import DetailResult6 from "./DetailResult6";
import RecommendedDirect from "./RecommendedDirect";


export default observer(function Forecast(){
    const [selectedDate, setSelectedDate] = useState("");
    const [drawDates, setDrawDates] = useState<DrawDateList[]>([]);    
    const [drawResult, setDrawResult] = useState<DrawDetailResult[]>([]);    
    const [activeIndex, setActiveIndex] = useState(0);

    const panes = [
        { menuItem: 'Overall', render: () => {
            return (<Tab.Pane> <DetailResult0 drawResult={drawResult} /></Tab.Pane>)}
        },
        { menuItem: 'Detail', render: () => {
            return (<Tab.Pane> <DetailResult1 drawResult={drawResult} /></Tab.Pane>)}
        },
        { menuItem: 'Direct Hits', render: () => {
            return (<Tab.Pane> <DetailResult2 drawResult={drawResult} /></Tab.Pane>)}
        },
        { menuItem: 'Rating', render: () => {
            return (<Tab.Pane> <DetailResult3 drawResult={drawResult} /></Tab.Pane>)}
        },
        { menuItem: 'Status', render: () => {
            return (<Tab.Pane> <DetailResult5 drawResult={drawResult} /></Tab.Pane>)}
        },
        { menuItem: 'Rank', render: () => {
            return (<Tab.Pane> <DetailResult6 drawResult={drawResult} /></Tab.Pane>)}
        },
        { menuItem: 'Result Recommended Number', render: () => {
            return (<Tab.Pane> <RecommendedDirect selectedDate={selectedDate} /></Tab.Pane>)}
        },
    ]

    const handleTabChange = (e:any, tabData: any) => setActiveIndex(tabData.activeIndex)

    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        //drawResultStore.setLoadingInitial(true);
    
        setSelectedDate(option.value + "");
    
        //drawResultStore.loadDayResult();
        //drawResultStore.setLoadingInitial(false);
    
      }
    
    useEffect(()=>{
        agent.Draw4DResult.getDetailResultDate().then(response=>{
            setDrawDates(response);
            if (response.length > 0){
                setSelectedDate(response[0].draw_Date);
            }
        });
    }, []);
    
    useEffect(()=>{
        if (selectedDate === "") return;

        agent.Draw4DResult.getDetailResult(selectedDate).then((response)=>{

            setDrawResult(response);

        });

        
    },[selectedDate]);

    return (
        <div style={{overflowX:"auto"}}>
        <Grid centered>
        <Grid.Column width={16} textAlign="center">
            Draw Date: 
            <Dropdown compact style={{marginLeft:"10"}}
                    onChange={handleDropDownSelect}
                    options={drawDates.map(x=> ({key: x.draw_Date, text: x.draw_Date, value: x.draw_Date}))}
                value = {selectedDate}
                
            />
        </Grid.Column>
        </Grid>
        {drawResult && drawResult.length > 0 &&
                        <div>
                        <Tab onTabChange={handleTabChange} activeIndex={activeIndex} menu={{pointing: true, className: "tab_wrapped" }} panes={panes}></Tab>
                        </div>
        }

        </div>

    )
})