import { observer } from "mobx-react-lite"
import React from "react"
import {  Header,  Statistic, Table } from "semantic-ui-react"
import NumberStatus from "../../app/lib/NumberStatus"
import { DrawDetailResult } from "../../app/models/IDayResult"


interface Props{
    drawResult: DrawDetailResult[];
}

export default observer(function DetailResult3({drawResult} : Props){
    let rating = [0,0,0]
    let top3Rating = [0,0,0]
 
    let ratingTop3 = [0,0,0]
    let ratingTop3System = [0,0,0]
    let systemCombine : { [key: string]: number } = {};
    let top3Combine : { [key: string]: number } = {};

    for(var i = 1; i<=3; i++){
      for(var j = 1; j <= 3; j++){
        systemCombine[i.toString() + "-" + j.toString()] = 0;
        top3Combine[i.toString() + "-" + j.toString()] = 0;
        
      }
    }

    for(var k = 0; k < drawResult.length; k++){
      var keyAll = drawResult[k].systemInRange + "-" + drawResult[k].top3InRange;

      if (systemCombine[keyAll] !== undefined){
        systemCombine[keyAll] ++;
      }
      else{
        systemCombine[keyAll] = 1;
      }


      rating[ parseInt(drawResult[k].systemInRange) - 1] ++;
      top3Rating[ parseInt(drawResult[k].top3InRange) - 1] ++;

      if (k <= 2){
        if (top3Combine[keyAll] !== undefined){
          top3Combine[keyAll]++;
        }
        else{
          top3Combine[keyAll] = 1;
        }
        ratingTop3System[ parseInt(drawResult[k].systemInRange) - 1] ++;
  
        ratingTop3[ parseInt(drawResult[k].top3InRange) - 1] ++;
      }
    }
    const top3Keys = Object.keys(top3Combine).sort();
    const allKeys = Object.keys(systemCombine).sort();
return (
    <div>
      <Table celled size="large" unstackable color="red">
        <Table.Body>
          <Table.Row>
          <Table.Cell colSpan="3" textAlign="center">
            <Header as="h1">Top3 Prizes</Header>
          </Table.Cell>
          </Table.Row>
          <Table.Row>
              <Table.Cell textAlign="center">
                System
              </Table.Cell>
              <Table.Cell textAlign="center">
                Top3
              </Table.Cell>
              <Table.Cell textAlign="center">
                #
              </Table.Cell>

          </Table.Row>
          <Table.Row>
            <Table.Cell>
                <Statistic.Group widths='three' size="small">
                <Statistic>

                <Statistic.Value>{ratingTop3System[0]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="1"></NumberStatus>
                    </Statistic.Label>
                  </Statistic>
                  <Statistic>
                  <Statistic.Value>{ratingTop3System[1]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="2"></NumberStatus>
                    </Statistic.Label>
                  </Statistic>
                  <Statistic>
                  <Statistic.Value>{ratingTop3System[2]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="3"></NumberStatus>
                    </Statistic.Label>
                    </Statistic>
              </Statistic.Group>
                </Table.Cell>              
                <Table.Cell textAlign="center">
                <Statistic.Group widths='three' size="small">
                <Statistic>
                  <Statistic.Value>{ratingTop3[0]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="1"></NumberStatus>
                    </Statistic.Label>
                  </Statistic>
                  <Statistic>
                  <Statistic.Value>{ratingTop3[1]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="2"></NumberStatus>
                    </Statistic.Label>
                  </Statistic>
                  <Statistic>
                  <Statistic.Value>{ratingTop3[2]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="3"></NumberStatus>
                    </Statistic.Label>
                  </Statistic>
              </Statistic.Group>
                </Table.Cell>

                <Table.Cell textAlign="center">
                  3
                </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                <Header as="h2">Details</Header>
              </Table.Cell>
            </Table.Row>
            {top3Keys.map( (x, index) => {
                return (
                  <Table.Row key = {x + index.toString()}>
                      <Table.Cell textAlign="center">
                          <NumberStatus status={x.split('-')[0]}></NumberStatus>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                          <NumberStatus status={x.split('-')[1]}></NumberStatus>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                          {top3Combine[x]}
                      </Table.Cell>
                  </Table.Row>
                )
              })
            }
       </Table.Body>
        
      </Table>
      
      <Table celled size="large" selectable  unstackable color="green">
        <Table.Body>
          <Table.Row>
          <Table.Cell colSpan="3" textAlign="center" >
            <Header as="h1">All Prizes</Header>
          </Table.Cell>
          </Table.Row> 
          <Table.Row>
              <Table.Cell textAlign="center">
                System
              </Table.Cell>
              <Table.Cell textAlign="center">
                Top3
              </Table.Cell>
              <Table.Cell textAlign="center">
                #
              </Table.Cell>

          </Table.Row>
          <Table.Row>
            <Table.Cell>
                <Statistic.Group widths='three' size="small">
                <Statistic>

                <Statistic.Value>{rating[0]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="1"></NumberStatus>
                    </Statistic.Label>
                  </Statistic>
                  <Statistic>
                  <Statistic.Value>{rating[1]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="2"></NumberStatus>
                    </Statistic.Label>
                  </Statistic>
                  <Statistic>
                  <Statistic.Value>{rating[2]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="3"></NumberStatus>
                    </Statistic.Label>
                    </Statistic>
              </Statistic.Group>
                </Table.Cell>              
                <Table.Cell textAlign="center">
                <Statistic.Group widths='three' size="small">
                <Statistic>
                  <Statistic.Value>{top3Rating[0]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="1"></NumberStatus>
                    </Statistic.Label>
                  </Statistic>
                  <Statistic>
                  <Statistic.Value>{top3Rating[1]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="2"></NumberStatus>
                    </Statistic.Label>
                  </Statistic>
                  <Statistic>
                  <Statistic.Value>{top3Rating[2]}</Statistic.Value>
                    <Statistic.Label>
                      <NumberStatus status="3"></NumberStatus>
                    </Statistic.Label>
                  </Statistic>
              </Statistic.Group>
                </Table.Cell>

                <Table.Cell textAlign="center">
                  23
                </Table.Cell>
            </Table.Row>     
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                <Header as="h2">Details</Header>
              </Table.Cell>
            </Table.Row>                 
            {allKeys.map((x, index) => {
                return (
                  <Table.Row key={x + (index + 4).toString()}>
                      <Table.Cell textAlign="center">
                          <NumberStatus status={x.split('-')[0]}></NumberStatus>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                          <NumberStatus status={x.split('-')[1]}></NumberStatus>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                          {systemCombine[x]}
                      </Table.Cell>
                  </Table.Row>
                )
              })
            }


       </Table.Body>
        
      </Table>

  
  </div>
  )
})