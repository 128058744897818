import React, { SyntheticEvent, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Button, DropdownProps,  Grid,  Header,  Input,  InputOnChangeData,  Pagination, PaginationProps, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _DrawType3DOptions, _RowPerPageOptions } from "../../app/lib/CommonData";
import { _GetRowPerPage, _SetRowPerPage } from "../../app/lib/CommonFunctions";
import Draw3DLink from "../../app/lib/draw3dlink";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyDropDown from "../../app/lib/MyDropDown";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import NumberStatus from "../../app/lib/NumberStatus";
import { Analysis3DResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import ViewGraphics3D from "./ViewGraphics3D";
import ViewGraphics3DTop3 from "./ViewGraphics3DTop3";


export default function Analysis3D(){
    const [column, setColumn] = useState("NoOfHit");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [page, setPage] = useState(1);

    const [loading, setLoading] = useState(true);
    const [drawType, setDrawType] = useState("ABC");
    const [analysis, setAnalysis] = useState<Analysis3DResult[]>([]);
    const [rowPerPage, setRowPerPage] = useState(_GetRowPerPage());
    const {userStore, modalStore} = useStore();

   

    const rowPerPageChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setRowPerPage(parseInt(option.value + ""));
      _SetRowPerPage(parseInt(option.value + ""));
      //setLoading(true);
     
    }

    
   const handleColumnClick = (columnName : string)=>{

     if (column !== columnName){
       setDirection("ascending");
     }
     else{
       if (direction === undefined){
         setDirection('ascending');
       }
       else{
         setDirection(direction === "ascending" ? "descending" : "ascending");
       }
     }
     setColumn(columnName);
   }

   const handlePageChanged = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps)=>{
     setPage(parseInt(data.activePage!.toString()));
   }

   const showSystemGraph = (system3Number: string) => {
      localStorage.setItem("System3DGraph", system3Number);
      modalStore.openModal(<ViewGraphics3D></ViewGraphics3D>, "System 3D Graph","fullscreen", null, ()=>{})
   
   }

   const showTop3Graph = (system3Number: string) => {
    localStorage.setItem("System3DGraph", system3Number);
    modalStore.openModal(<ViewGraphics3DTop3></ViewGraphics3DTop3>, "Top3 3D Graph","fullscreen", null, ()=>{})
  
  }

   const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
    //setPage(1);
        setDrawType(option.value + "");
    }

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData)=>{
      if(data.value.length === 3){
          if (!isNaN(parseFloat(data.value))){
            let newDirection:string;

            newDirection = "";
            if (direction === "ascending"){
              newDirection = "asc";
            }
            if (direction === "descending"){
              newDirection = "desc";
            }
            agent.Draw4DResult.getPageBy3DNumber(data.value, column, newDirection, drawType, rowPerPage).then(response=>{
              if(response.pageNo === 0){
                toast.error("Number not found ! Check the Draw Type.");
                return;
              }  
              toast.info("Found number in row " + response.row_No);

              setPage(response.pageNo);
            });
          }
      }

    }

    useEffect(()=>{

        let newDirection = "";
        if (direction === "ascending"){
            newDirection = "asc";
        }
        if (direction === "descending"){
            newDirection = "desc";
        }
        
        agent.Draw4DResult.getAnalysis3D(drawType, column, newDirection , page, rowPerPage).then((response)=>{

            setAnalysis(response);
            setLoading(false);
            
        });


    },[drawType, column, direction, page, rowPerPage]);

    if(!userStore.isLogged){
      return (<Header>Not Authorized</Header>); 
    }
    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
          <div style={{overflowX:"auto"}}>
            <Table celled  sortable unstackable className="freeze-head-and-col">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="12" textAlign="left">
                <Grid centered divided columns={4}>
                  <Grid.Column textAlign="left" verticalAlign="middle">
                    <MyDropDown zIndex="20"
                        toolTip = "Select Draw Type"
                        onChange = {handleDropDownSelect}
                        placeholder='Select Draw Type'
                        options={_DrawType3DOptions}
                        defaultValue = {drawType}>
                    </MyDropDown>
                  </Grid.Column>

                  <Grid.Column verticalAlign="middle">
                    <Input data-position="bottom center" fluid maxLength={3} focus placeholder='Search 3D position in the list' data-tooltip="Search 3D position in the list"  onChange={handleInputChanged}></Input>
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle">
                  <MyDropDown zIndex="20"
                      placeholder="Select Item Per Page"
                      toolTip="Select Item Per Page" 
                      onChange = {rowPerPageChanged}
                      options={_RowPerPageOptions}
                      defaultValue={rowPerPage} >
                  </MyDropDown>
                </Grid.Column>

                  <Grid.Column verticalAlign="middle">
                  3D Analysis
                  </Grid.Column>
                </Grid>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                  <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="permutated_number" handleClick={handleColumnClick}>
                    System<br/>3D
                  </MyTableWithSort> 
                  <Table.HeaderCell rowSpan={2} textAlign="center">#</Table.HeaderCell>
                  <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="NoOfHit" handleClick={handleColumnClick}>
                    Strike<br/>(ALL)
                  </MyTableWithSort>
                  <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="hitCount123" handleClick={handleColumnClick}>
                    Strike<br/>(TOP3)
                  </MyTableWithSort> 
                  <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="interval" handleClick={handleColumnClick}>
                    System<br/>Interval
                  </MyTableWithSort> 
                  <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="interval123" handleClick={handleColumnClick}>
                    Top3<br/>Interval
                  </MyTableWithSort> 
                  <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="systemInRange" handleClick={handleColumnClick}>
                    System<br/>In Range
                  </MyTableWithSort>
                  <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="top3Inrange" handleClick={handleColumnClick}>
                    Top3<br/>In Range
                  </MyTableWithSort>
                  <Table.HeaderCell colSpan={2} textAlign="center">
                      Graph
                  </Table.HeaderCell>

                  <Table.HeaderCell colSpan={3} textAlign="center">
                      System Frequency (%)
                  </Table.HeaderCell>

                  <Table.HeaderCell colSpan={3} textAlign="center">
                      Top3 Frequency (%)
                  </Table.HeaderCell>

                  <Table.HeaderCell colSpan={4} textAlign="center">
                      Rank (10 Years 3D)
                  </Table.HeaderCell>
             </Table.Row>
             <Table.Row>
                  <Table.HeaderCell textAlign="center">
                    System
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Top3
                  </Table.HeaderCell>

                  <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="short_Term" handleClick={handleColumnClick}>
                    Short
                  </MyTableWithSort>
                  <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="middle_Term" handleClick={handleColumnClick}>
                    Middle
                  </MyTableWithSort>
                  <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="long_Term" handleClick={handleColumnClick}>
                    Long
                  </MyTableWithSort>
                  <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="short_Term_Top3" handleClick={handleColumnClick}>
                    Short
                  </MyTableWithSort>
                  <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="middle_Term_Top3" handleClick={handleColumnClick}>
                    Middle
                  </MyTableWithSort>
                  <MyTableWithSort  rowSpan={2} textAlign="center" direction={direction} column={column} columnName="long_Term_Top3" handleClick={handleColumnClick}>
                    Long
                  </MyTableWithSort>


                  <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="All_Rank" handleClick={handleColumnClick}>
                    All<br/>Rank
                  </MyTableWithSort>
                  <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Top3_Rank" handleClick={handleColumnClick}>
                    Top3<br/>Rank
                  </MyTableWithSort>
                  <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="all_Rank_Month" handleClick={handleColumnClick}>
                    All Rank<br/>By Month
                  </MyTableWithSort>
                  <MyTableWithSort  textAlign="center" direction={direction} column={column} columnName="Top3_Rank_Month" handleClick={handleColumnClick}>
                    Top3<br/>By Month
                  </MyTableWithSort>
             </Table.Row>
            </Table.Header>
            <Table.Body>
                {analysis && analysis.length > 0 && analysis.map((x => {
                    return (
                        <Table.Row key={x.draw_ID}>
                            <Table.Cell textAlign="center">
                              <Draw3DLink title = ""  number={x.permutated_Number}></Draw3DLink>
                            </Table.Cell>

                          <Table.Cell textAlign="center">{x.draw_ID}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.noOfHit}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.hitCount123}</Table.Cell>

                            <Table.Cell  textAlign="right">{x.interval}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.interval123}</Table.Cell>
                            <Table.Cell  textAlign="center">
                                <NumberStatus status={x.systemInRange} />
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                <NumberStatus status={x.top3InRange} />
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                <Button icon="chart line" onClick={()=>{
                                  showSystemGraph(`${x.permutated_Number}`)
                                }}></Button>
                            </Table.Cell>
                            <Table.Cell  textAlign="center">
                                <Button icon="chart line" onClick={()=>{
                                  showTop3Graph(`${x.permutated_Number}`)
                                }}></Button>
                            </Table.Cell>

                            <Table.Cell  textAlign="right">{x.short_Term}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.middle_Term}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.long_Term}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.short_Term_Top3}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.middle_Term_Top3}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.long_Term_Top3}</Table.Cell>

                            <Table.Cell  textAlign="right">{x.all_Rank}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.top3_Rank}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.all_Rank_Month}</Table.Cell>
                            <Table.Cell  textAlign="right">{x.top3_Rank_Month}</Table.Cell>

                        </Table.Row>
                    )
                }))}
            </Table.Body>
            </Table>
            <Pagination activePage={page} totalPages={analysis && analysis.length > 0 ? analysis[0].totalPage : 10} onPageChange={handlePageChanged}/>
        </div>              
    );
}