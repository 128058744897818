import { makeAutoObservable, runInAction} from "mobx";
import agent from "../api/agent";
import { DayResult, DrawDateList } from "../models/IDayResult";


export default class DrawResultStore{
    dayResult: DayResult | null = null;
    dateResult: DrawDateList[] = [];
    loading = true;
    loadingInitial = true;
    loadingDate = true;
    selectedDate = "";

    constructor(){
        makeAutoObservable(this);
    }


    setSelectedDate = async(value: string) => {
        runInAction(()=>{
            this.selectedDate = value;

            this.loadDayResult();
        });
    }

    setDayResult = async(response: DayResult) => {
        this.dayResult = response;
    }  
    setLoadingInitial = (state : boolean) => {
        this.loadingInitial = state;
    }

    setLoadingDate = (state : boolean) => {
        this.loadingDate = state;
    }

    loadDayResult = async () => {
        //this.setLoadingInitial(true);
        try{
                agent.Draw4DResult.list(this.selectedDate).then(response=>{
                
                    this.setDayResult(response);
                    //this.setLoadingInitial(false);
                });
        }catch(err){
            console.log(err);
            //this.setLoadingInitial(false);
        }
    }

    loadDates = async () => {
        try{
            //this.setLoadingDate(true);
            agent.Draw4DResult.getDrawDates().then(response=>{
                //this.dateResult = [];
                window.setTimeout(()=>{
                    this.dateResult = [...response];
                
                    if(this.dateResult.length > 0){
                        this.setSelectedDate(this.dateResult[0].draw_Date);

                        //this.selectedDate = this.dateResult[0].draw_Date;
                    }
                    else{
                        this.dayResult = null;
                    }
                    
                    this.setLoadingInitial(false);
                },3000);
            });

            
        }catch(err){
            console.log(err);
            //this.setLoadingDate(false);
       
        }
    }
}