import React, { useEffect, useState } from "react";
import {  Label, Table } from "semantic-ui-react";
import agent from "../api/agent";
import {ForecastResultDirectNumber } from "../models/IDayResult";
import MiniLoading from "./MiniLoading";


export default function ShowDirect(){
    const system4D = localStorage.getItem("SelectedSystem4D")
    const [data, setData] = useState<ForecastResultDirectNumber[]>([]);
    
    const [loading, setLoading] = useState(true);

    useEffect(()=>{

        const selectedDate = localStorage.getItem("SelectedDate");

        agent.Draw4DResult.getForecastDirectNumber(system4D!, selectedDate!).then((response)=>{

            setData(response);
            setLoading(false);
            
            //isLoading = false;
        });
    },[system4D]);

    if (loading){
        return (<MiniLoading></MiniLoading>)
    }


    return (

        <div style={{overflow: "auto"}}>

        <Table celled unstackable selectable sortable>
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell colSpan={4} textAlign="left">
          <Label color='green' ribbon style={{marginLeft:"20px"}}>
            4DGM Recommended Direct Numbers
            </Label>


          </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
              <Table.HeaderCell textAlign="center">Direct Number</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Date Striked</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Striked Prize</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Direct Interval</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {data.map(((x,i) => {
            return (
                <Table.Row key={i} className="freeze-head-and-col">
                    <Table.Cell textAlign="center">{x.directNumber}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.drawDate}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.prizeCode}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.directInterval}
                    </Table.Cell>
                </Table.Row>
                )
        }))}
        </Table.Body>
        </Table>
        </div>

    )
}