import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Image,  Grid,   Popup,   Table, TableBody } from "semantic-ui-react";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/lib/LoadingComponent";
import { NumberSearch3DTo4D} from "../../app/models/IDayResult";
import * as Yup from 'yup';
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import DrawLink from "../../app/lib/drawlink";
import NumberStatus from "../../app/lib/NumberStatus";
import MyTextInputButton from "../../app/lib/MyTextInputButton";
import { _GetRating, _GetRatingContent } from "../../app/lib/CommonFunctions";
import { useStore } from "../../app/store/store";
import { history } from "../..";
import System3DData from "../../app/lib/System3DData";

  
export default observer(function View4DFrom3D(){
    let {id} = useParams<{id: string}>();
    const [data, setData] = useState<NumberSearch3DTo4D[]>([]);
    const [numberToSearch, setNumberToSearch] = useState(id === null || id === undefined ? "" : id);
    const [loaded, setLoaded] = useState(id == null || id.length === 0 ? true : false);
    const [column, setColumn] = useState("rank");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const {modalStore, userStore} = useStore();

    const isAllowed = true;
    //(userStore.user?.username === "ZACZAINAL" ||  userStore.user?.username === "SteveXie" || userStore.user?.username === "JasmineWoo");
    
    modalStore.closeModal();
    
    if(!userStore.isLogged){
        history.push("/");
    }

    let formData = {
        numberToInput: numberToSearch
    }

    const handleColumnClick = (columnName : string)=>{
        if (column !== columnName){
          setDirection("ascending");
        }
        else{
          if (direction === undefined){
            setDirection('ascending');
          }
          else{
            setDirection(direction === "ascending" ? "descending" : "ascending");
          }
        }
        setColumn(columnName);
      }

    const validationSchema = Yup.object({
        numberToInput: Yup.string().required("3D Number is required").matches(/^[0-9]{3,4}$/, 'Must be exactly 3-4 digits')
    });
    
    useEffect(()=>{
        if (!numberToSearch) return;
        if (!(numberToSearch.length === 3 || numberToSearch.length === 4) ) {
            setLoaded(true);
            return;
        }

        let newDirection = "";

        if (direction === "ascending"){
            newDirection = "asc";
        }
        if(direction === "descending"){
            newDirection = "desc";
        }

        agent.Draw4DResult.get3DInfo(numberToSearch, column, newDirection).then((response)=>{
            setData(response);
            setLoaded(true);
            //isLoading = false;
        });
        
    },[column,direction, numberToSearch, formData.numberToInput]);

    if (!loaded){
        return (
            <LoadingComponent></LoadingComponent>
        )
    }
    return (
        <Grid textAlign="center" verticalAlign='top'>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <u>System 3D to System 4D</u>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={14}>
                <Formik 
                    enableReinitialize
                    initialValues = {formData}
                    validationSchema = {validationSchema}
                    validate = {()=>{
                        setNumberToSearch("");
                    }}
                    onSubmit = {(o) => {
                        setLoaded(false);
                        setNumberToSearch(o.numberToInput);
                    }}>
                        {({handleSubmit}) => (
                            <Form className="ui form" onSubmit={handleSubmit} autoComplete="off">
                                <Grid verticalAlign="middle">
                                    <Grid.Row>
                                    <Grid.Column width={16} textAlign="center">
                                        <MyTextInputButton loading={false}
                                                 label="Enter 3D/4D" maxLength={4} name="numberToInput" placeholder="Enter 3D/4D Number to Search" />
                                    </Grid.Column >
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        )}
                </Formik>
                </Grid.Column>
                <Grid.Column width={1}>

                </Grid.Column>
            </Grid.Row> 
            <Grid.Row>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={14} textAlign="center">
                { loaded && data && data.length > 0 &&
                    <div style={{overflowX:"auto"}}>
                        <Table singleLine unstackable celled sortable className="freeze-head-and-col">
                        <Table.Header>
                            <Table.Row>
                            <MyTableWithSort rowSpan={2} textAlign="center" column={column} columnName="system_Number" handleClick={handleColumnClick} direction={direction}>
                                System<br/>Number
                            </MyTableWithSort>
                            <MyTableWithSort rowSpan={2} textAlign="center" column={column} columnName="rank" handleClick={handleColumnClick} direction={direction}>
                                Rating
                            </MyTableWithSort>
                            {isAllowed && <Table.HeaderCell rowSpan={2} textAlign="center">
                                e-WinBox?
                            </Table.HeaderCell>
                            }
                            <MyTableWithSort rowSpan={2} textAlign="center" column={column} columnName="monthRank" handleClick={handleColumnClick} direction={direction}>
                                All Month <br/>Freq Order
                            </MyTableWithSort>
                            <MyTableWithSort rowSpan={2} textAlign="center" column={column} columnName="monthRank_Top3" handleClick={handleColumnClick} direction={direction}>
                                Top3 Month <br/>Freq Order
                            </MyTableWithSort>

                            <MyTableWithSort rowSpan={2} textAlign="center" column={column} columnName="status" handleClick={handleColumnClick} direction={direction}>
                                Number<br/>Status (All)
                            </MyTableWithSort>
                            <MyTableWithSort rowSpan={2} textAlign="center" column={column} columnName="status_top3" handleClick={handleColumnClick} direction={direction}>
                                Number<br/>Status (Top3)
                            </MyTableWithSort>
                            <MyTableWithSort rowSpan={2} textAlign="center" column={column} columnName="awaken" handleClick={handleColumnClick} direction={direction}>
                                Awaken
                            </MyTableWithSort>

                            <Table.HeaderCell textAlign="center" colSpan="3">
                                Rank (All)
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" colSpan="3">
                                Rank (Top3)
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" colSpan="4">
                                Interval
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan="2" textAlign="center">
                                Sum 3 Digits
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan="4" textAlign="center">
                                Position
                            </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <MyTableWithSort textAlign="center" column={column} columnName="shortTerm_Rank" handleClick={handleColumnClick} direction={direction}>
                                    Short Term
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="middleTerm_Rank" handleClick={handleColumnClick} direction={direction}>
                                    Medium Term
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="longTerm_rank" handleClick={handleColumnClick} direction={direction}>
                                    Long Term
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="shortTerm_Rank123" handleClick={handleColumnClick} direction={direction}>
                                    Short Term
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="middleTerm_Rank123" handleClick={handleColumnClick} direction={direction}>
                                    Medium Term
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="longTerm_rank123" handleClick={handleColumnClick} direction={direction}>
                                    Long Term
                                </MyTableWithSort>

                                <MyTableWithSort textAlign="center" column={column} columnName="interval" handleClick={handleColumnClick} direction={direction}>
                                    All 
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="interval123" handleClick={handleColumnClick} direction={direction}>
                                    Top3 
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="interval_Avg" handleClick={handleColumnClick} direction={direction}>
                                    All Avg 
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="interval_Avg123" handleClick={handleColumnClick} direction={direction}>
                                    Top3 Avg
                                </MyTableWithSort>                                
                                <MyTableWithSort textAlign="center" column={column} columnName="sum3" handleClick={handleColumnClick} direction={direction}>
                                    All
                                </MyTableWithSort>
                                <MyTableWithSort textAlign="center" column={column} columnName="sum3_Top3" handleClick={handleColumnClick} direction={direction}>
                                    Top3
                                </MyTableWithSort>
                                <Table.HeaderCell textAlign="center">
                                   1
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                  2
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                   3
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                   4
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <TableBody>
                        {data && data.length > 0 && data.map((x => {
                            return (
                                <Table.Row key={x.row_No}>
                                    <Table.Cell textAlign="center">
                                    <DrawLink title="" number={x.system_Number}></DrawLink>
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                    <Popup content={_GetRatingContent(x.rank)}
                                        trigger ={<Image avatar src={_GetRating(x.rank)} />}>
                                    </Popup>
                                    </Table.Cell>
                                    {isAllowed &&
                                    <Table.Cell textAlign="center">{x.eWinBox}</Table.Cell>
                                    }
                                    <Table.Cell  textAlign="center">
                                    <Popup  content='Frequency Hit by Cur Month. 1 is highest.' trigger={<span>{x.monthRank}</span>} />                                        
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                    <Popup  content='Top3 Frequency Hit by Cur Month. 1 is highest.' trigger={<span>{x.monthRank_Top3}</span>} />                                        
                                    </Table.Cell>

                                    <Table.Cell  textAlign="center">
                                        <NumberStatus status = {x.status} />
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <NumberStatus status = {x.status_Top3} />
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        {x.awaken}
                                    </Table.Cell>
                                                        
                                    <Table.Cell  textAlign="right">{(x.shortTerm_Rank * 100).toFixed(0)}%</Table.Cell>
                                    <Table.Cell  textAlign="right">{(x.middleTerm_Rank * 100).toFixed(0)}%</Table.Cell>
                                    <Table.Cell  textAlign="right">{(x.longTerm_Rank * 100).toFixed(0)}%</Table.Cell>
                                    <Table.Cell  textAlign="right">{(x.shortTerm_Rank123 * 100).toFixed(0)}%</Table.Cell>
                                    <Table.Cell  textAlign="right">{(x.middleTerm_Rank123 * 100).toFixed(0)}%</Table.Cell>
                                    <Table.Cell  textAlign="right">{(x.longTerm_Rank123 * 100).toFixed(0)}%</Table.Cell>

                                    <Table.Cell  textAlign="right">{x.interval}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.interval123}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.interval_Avg}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.interval_Avg123}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.sum3}</Table.Cell>
                                    <Table.Cell  textAlign="right">{x.sum3_Top3}</Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <System3DData data={x.system3D_1}></System3DData>
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <System3DData data={x.system3D_2}></System3DData>
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <System3DData data={x.system3D_3}></System3DData>
                                    </Table.Cell>
                                    <Table.Cell  textAlign="center">
                                        <System3DData data={x.system3D_4}></System3DData>
                                    </Table.Cell>

                                </Table.Row>
                            )
                        }))}
                        </TableBody>
                        </Table>
                    </div>                        
                }   
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>
            </Grid.Row> 
        </Grid>

    );
}
);
