/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { Header, Segment, Image } from 'semantic-ui-react';

function MaintenanceMode() {
       return (
        <Segment
            textAlign='center'
            style={{ padding: '1em 0em' }}
            vertical>
            <Header as="h1">Draw Result Maintenance from 10:00pm to 11:30pm. Please refresh after 11:30pm</Header>
            <Image size="huge" className="centered" src="/assets/4DGM-graphic-2.jpg" circular></Image>
        </Segment>

    )
}

export default MaintenanceMode;