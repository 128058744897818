import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffect } from "react";
import { Table } from "semantic-ui-react";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import {  ExpressDirectNumberPart2 } from "../../app/models/IDayResult";

interface Props{
    data: ExpressDirectNumberPart2[];
}

export default observer( function ViewQuickDirectPart1(prop: Props){
    const [column, setColumn] = useState<"currentInterval" | "drawDate" | "prize"| "strikeNumber">("drawDate");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [data, setData] = useState<ExpressDirectNumberPart2[]>([]);

    const handleColumnClick = (columnName : string)=>{
        let tempDirection : "ascending" | "descending" | undefined;
        if (column !== columnName){
            tempDirection = "ascending";
        }
        else{
          if (direction === undefined){
            tempDirection = "ascending";
          }
          else{
            tempDirection = (direction === "ascending" ? "descending" : "ascending");
          }
        }

        let tempColumn : "currentInterval" | "drawDate" | "prize"| "strikeNumber";

        tempColumn = "currentInterval";

        if (columnName === "drawDate"){
            tempColumn = "drawDate";
        }

        if (columnName === "prize"){
            tempColumn = "prize";
        }

        if (columnName === "strikeNumber"){
            tempColumn = "strikeNumber";
        }


        setDirection(tempDirection);
        setColumn(tempColumn);

      }

    useEffect(() => {
        let sortableItems = [...prop.data];

        sortableItems.sort((a, b) => {

            if (a[column] < b[column]) {
                return direction === 'ascending' ? -1 : 1;
              }
              if (a[column] > b[column]) {
                return direction === 'ascending' ? 1 : -1;
              }

            return 0;
        });
        setData(sortableItems);

        },[column, direction, prop.data]);
    
    

    return (
        <div style={{overflowX:"auto"}}>

        <Table unstackable celled sortable>
             <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell colSpan="3">All Prizes (Last 10 years System Hits)</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                    <MyTableWithSort textAlign="center" column={column} columnName="strikeNumber" handleClick={handleColumnClick} direction={direction}>
                                Direct Number
                    </MyTableWithSort>
                    <MyTableWithSort textAlign="center" column={column} columnName="drawDate" handleClick={handleColumnClick} direction={direction}>
                                Draw Date
                    </MyTableWithSort>
                    <MyTableWithSort textAlign="center" column={column} columnName="prize" handleClick={handleColumnClick} direction={direction}>
                                Prize
                    </MyTableWithSort>
                    <MyTableWithSort textAlign="center" column={column} columnName="currentInterval" handleClick={handleColumnClick} direction={direction}>
                                Interval
                    </MyTableWithSort>

                    </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    data.map( 
                    (x, i)=>{
                            return(
                        <Table.Row key={i}>
                            <Table.Cell textAlign="center"> {x.strikeNumber}
                            </Table.Cell>

                            <Table.Cell textAlign="center"> {x.drawDate}
                            </Table.Cell>
                            <Table.Cell textAlign="center"> {x.prize}
                            </Table.Cell>
                            <Table.Cell textAlign="right"> {x.currentInterval}
                            </Table.Cell>
                        </Table.Row>
                    )
                })
            }
            </Table.Body>         
        </Table>
        </div>
    );
})