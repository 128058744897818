import React from "react";
import { Button } from "semantic-ui-react";
import { history } from "../..";
import { useStore } from "../store/store";
import ShowDirect from "./ShowDirect";

export default function ForecastAction(){
    const {modalStore} = useStore();
    const system4D  = localStorage.getItem("SelectedSystem4D");


    const analysisClick = () =>{
        modalStore.closeModal();
        history.push("/search/" + system4D);
    }

    const directNumberClick = ()=>{
        modalStore.closeModal();
        modalStore.openModal(<ShowDirect></ShowDirect>,"Show Recommended Direct Numbers","large", null, ()=>{})
    }

    return(
        <>
            <div style={{textAlign:"center"}}>
                <Button primary onClick={() => analysisClick() }>
                    Analysis
                </Button>
                <Button color='green' onClick={() => directNumberClick()}>
                    Direct Numbers
                </Button>
            </div>        
        </> 
    );
}