import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useState } from "react"
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Image, DropdownProps,  Grid,  Header, Input, InputOnChangeData, Label, Pagination,  PaginationProps, Popup, Table } from 'semantic-ui-react'
import { history } from "../..";
import agent from "../../app/api/agent";
import { _DrawTypeOptions, _RatingOptions, _RowPerPageOptions, _StatusOptions } from "../../app/lib/CommonData";
import { _GetRating, _GetRatingContent, _GetRowPerPage, _SetRowPerPage } from "../../app/lib/CommonFunctions";
import DrawLink from "../../app/lib/drawlink";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyDropDown from "../../app/lib/MyDropDown";
import MyTableWithSort from "../../app/lib/MyTableWithSort";
import NumberStatus from "../../app/lib/NumberStatus";
import { AnalysisResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import style from "./analysis.module.css";
import YearResult from "./yearresult";
import configData from "../../app/config.json";

export default observer(function OverviewAnalysisMonthly(){
    
    const [loading, setLoading] = useState(true);
    const [drawType, setDrawType] = useState("ABCD");
    const [analysis, setAnalysis] = useState<AnalysisResult[]>([]);
    const [column, setColumn] = useState("total");
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>("descending");
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("0");
    const [rowPerPage, setRowPerPage] = useState(_GetRowPerPage());
    const {userStore} = useStore();
    const periodDisplay = configData.PeriodDisplay;
    const periodYear = configData.PeriodYear;
    const [rating, setRating] = useState(0);

    if(!userStore.isLogged){
      history.push("/");
    }

    const ratingChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setRating(parseInt(option.value + ""));
      setLoading(true);
     
    }


    const rowPerPageChanged = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setRowPerPage(parseInt(option.value + ""));
      _SetRowPerPage(parseInt(option.value + ""));
      setLoading(true);
     
    }

    
    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
         setPage(1);
         setDrawType(option.value + "");
         setLoading(true);
        
    }

    const statusDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
      setPage(1);
      setStatus(option.value + "");
      setLoading(true);
     
    }

    const handleColumnClick = (columnName : string)=>{
      if (column !== columnName){
        setDirection("ascending");
      }
      else{
        if (direction === undefined){
          setDirection('ascending');
        }
        else{
          setDirection(direction === "ascending" ? "descending" : "ascending");
        }
      }
      setColumn(columnName);
      setPage(1);
      setLoading(true);

    }

    const handlePageChanged = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps)=>{
      setPage(parseInt(data.activePage!.toString()));
      setLoading(true);

    }

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData)=>{
      if(data.value.length === 4){
          if (!isNaN(parseFloat(data.value))){
            let newDirection:string;

            newDirection = "";
            if (direction === "ascending"){
              newDirection = "asc";
            }
            if (direction === "descending"){
              newDirection = "desc";
            }
            agent.Draw4DResult.getPageBy4DNumber(data.value, column, newDirection, drawType, status, rowPerPage, rating).then(response=>{
              if(response.pageNo === 0){
                toast.error("Number not found ! Check the Draw Type.");
                return;
              }  
              toast.info("Found number in row " + response.row_No);

              if (page !== response.pageNo){
                setPage(response.pageNo);
                setLoading(true);
              }
            });
          }
      }

    }

    useEffect(()=>{

        let newDirection = "none";
        if (direction !== undefined){
          if (direction === "ascending"){
            newDirection = "asc";
          }
          else{
            newDirection = "desc";
          }
        }
        agent.Draw4DResult.getAnalysis(drawType, column, newDirection, page, status, rowPerPage, rating).then((response)=>{

            setAnalysis(response);
            setLoading(false);
            
        });

        
    },[drawType, column, direction, page, status, rowPerPage, rating]);

    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }
    return (
      <div style={{overflowX:"auto"}}>
        <Table celled unstackable selectable sortable className="freeze-head-and-col">
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell colSpan="34" textAlign="center">
          <Header>4D By Cumulative Month {periodDisplay}</Header>
          </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan="34">
            <Grid centered>
                <Grid.Column verticalAlign="middle" textAlign="left" width={2}>
                    <MyDropDown zIndex="20" placeholder='Select Draw Type' toolTip="Select Draw Type" toolTipDirection="top center" 
                        onChange={handleDropDownSelect} options={_DrawTypeOptions} defaultValue={drawType}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column verticalAlign="middle"  width={3}>
                  <Input 
                      data-tooltip="Enter 4D Number to find the row number in the list"
                      data-position="bottom center"
                      fluid  maxLength={4} focus placeholder='Search 4D position in the list'  onChange={handleInputChanged}></Input>
                </Grid.Column>
                <Grid.Column verticalAlign="middle"  width={4}>
                    <MyDropDown zIndex="20" placeholder='Select rating to filter' toolTip="Select rating to filter" toolTipDirection="top center" 
                        onChange={ratingChanged} options={_RatingOptions} defaultValue={rating}>
                    </MyDropDown>

                </Grid.Column>
                <Grid.Column  width={3}>
                     <MyDropDown zIndex="20" placeholder='Select State' toolTip="Select Number State" toolTipDirection="top center" 
                        onChange={statusDropDownSelect} options={_StatusOptions} defaultValue={status}>
                    </MyDropDown>
                </Grid.Column>
                <Grid.Column  width={4}>
                  <MyDropDown zIndex="20" placeholder='Select Item Per Page' toolTip="Select Item Per Page" toolTipDirection="top center" 
                        onChange={rowPerPageChanged} options={_RowPerPageOptions} defaultValue={rowPerPage}>
                    </MyDropDown>
                </Grid.Column>  
              </Grid>
            
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="source_number" handleClick={handleColumnClick}>
            System<br/>Number
            </MyTableWithSort> 

            <Table.HeaderCell rowSpan={2} textAlign="center">
            Row #
            </Table.HeaderCell>


            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="total" handleClick={handleColumnClick}>
            All
            </MyTableWithSort> 
            
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="top3" handleClick={handleColumnClick}>
            Top 3
            </MyTableWithSort> 
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="forecast" handleClick={handleColumnClick}>
            Rating
            </MyTableWithSort> 
           
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="Status" handleClick={handleColumnClick}>
            Number<br/>Status (All)
            </MyTableWithSort> 
           
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="Status_Top3" handleClick={handleColumnClick}>
            Number<br/>Status (Top3)
            </MyTableWithSort> 

            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="Awaken" handleClick={handleColumnClick}>
            Awaken
            </MyTableWithSort> 

            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="draw_date" handleClick={handleColumnClick}>
            Last Drawn Date<br/>(All)
            </MyTableWithSort> 
           
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="max_Top3_Date" handleClick={handleColumnClick}>
            Last Drawn Date<br/>(Top 3)
            </MyTableWithSort> 
           
            <MyTableWithSort rowSpan={2} textAlign="center" direction={direction} column={column} columnName="avg_All" handleClick={handleColumnClick}>
            Yearly Average<br/>(All/Top3)
            </MyTableWithSort> 

            <Table.HeaderCell textAlign="center" colSpan={12}>
            By Month ({configData.CurrentYear})
            </Table.HeaderCell> 

            <Table.HeaderCell textAlign="center" colSpan={12}>
            Cumulative By Month {periodYear}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c01" handleClick={handleColumnClick}>
              Jan
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c02" handleClick={handleColumnClick}>
              Feb
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c03" handleClick={handleColumnClick}>
              Mar
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c04" handleClick={handleColumnClick}>
              Apr
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c05" handleClick={handleColumnClick}>
              May
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c06" handleClick={handleColumnClick}>
              June
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c07" handleClick={handleColumnClick}>
              July
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c08" handleClick={handleColumnClick}>
              Aug
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c09" handleClick={handleColumnClick}>
              Sep
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c10" handleClick={handleColumnClick}>
              Oct
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c11" handleClick={handleColumnClick}>
              Nov
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="c12" handleClick={handleColumnClick}>
              Dec
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m01" handleClick={handleColumnClick}>
              Jan
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m02" handleClick={handleColumnClick}>
              Feb
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m03" handleClick={handleColumnClick}>
              Mar
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m04" handleClick={handleColumnClick}>
              Apr
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m05" handleClick={handleColumnClick}>
              May
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m06" handleClick={handleColumnClick}>
              June
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m07" handleClick={handleColumnClick}>
              July
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m08" handleClick={handleColumnClick}>
              Aug
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m09" handleClick={handleColumnClick}>
              Sep
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m10" handleClick={handleColumnClick}>
              Oct
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m11" handleClick={handleColumnClick}>
              Nov
          </MyTableWithSort> 

          <MyTableWithSort textAlign="center" direction={direction} column={column} columnName="m12" handleClick={handleColumnClick}>
              Dec
          </MyTableWithSort> 

           
         </Table.Row>
        </Table.Header>
    
        <Table.Body>

          {analysis.map((x => {
            return (
                <Table.Row key={x.source_Number}>
                    <Table.Cell textAlign="center">
                      <DrawLink number={x.source_Number} title=""></DrawLink>
                    </Table.Cell>
                    <Table.Cell textAlign="center">{x.row_No}
                    </Table.Cell>
                    <Table.Cell  textAlign="right">{x.total}</Table.Cell>
                    <Table.Cell  textAlign="right">{x.top3}</Table.Cell>
                    <Table.Cell  textAlign="center">
                      <Popup content={_GetRatingContent(x.forecast)}
                       trigger ={<Image avatar src={_GetRating(x.forecast)} />}>
                      </Popup>
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      <NumberStatus status = {x.status} />
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      <NumberStatus status = {x.status_Top3} />
                    </Table.Cell>
                    <Table.Cell  textAlign="center">
                      {x.awaken}
                    </Table.Cell>
          
                    <Table.Cell  textAlign="center" className={style.lastdrawn}>
                      {x.draw_Date}<br/>
                      <Label data-tooltip={'All Interval: ' + x.current_Gap}  
                       circular size={"tiny"} color={"blue"}>{x.current_Gap}</Label>
                    </Table.Cell>
                    <Table.Cell  textAlign="center" className={style.lastdrawn}>
                      {x.max_Top3_Date}<br/>
                      <Label data-tooltip={'Top3 Interval: ' + x.top3_Current_Gap}  circular size={"tiny"} color={"blue"}>{x.top3_Current_Gap}</Label>
                    </Table.Cell>

                    <Table.Cell  textAlign="center"  className={style.lastdrawn}>
                      {x.avg_All}<br/>
                      <Label 
                      data-tooltip={'10 Years Avg: ' + x.avg_Top3 + " (Top3)"}
                       circular size={"tiny"} color={"red"}>{x.avg_Top3}</Label>
                    </Table.Cell>

                    <YearResult result={x.c01} top3={x.d01}></YearResult>
                    <YearResult result={x.c02} top3={x.d02}></YearResult>
                    <YearResult result={x.c03} top3={x.d03}></YearResult>
                    <YearResult result={x.c04} top3={x.d04}></YearResult>
                    <YearResult result={x.c05} top3={x.d05}></YearResult>
                    <YearResult result={x.c06} top3={x.d06}></YearResult>
                    <YearResult result={x.c07} top3={x.d07}></YearResult>
                    <YearResult result={x.c08} top3={x.d08}></YearResult>
                    <YearResult result={x.c09} top3={x.d09}></YearResult>
                    <YearResult result={x.c10} top3={x.d10}></YearResult>
                    <YearResult result={x.c11} top3={x.d11}></YearResult>
                    <YearResult result={x.c12} top3={x.d12}></YearResult>

                    <YearResult result={x.m01} top3={x.n01}></YearResult>
                    <YearResult result={x.m02} top3={x.n02}></YearResult>
                    <YearResult result={x.m03} top3={x.n03}></YearResult>
                    <YearResult result={x.m04} top3={x.n04}></YearResult>
                    <YearResult result={x.m05} top3={x.n05}></YearResult>
                    <YearResult result={x.m06} top3={x.n06}></YearResult>
                    <YearResult result={x.m07} top3={x.n07}></YearResult>
                    <YearResult result={x.m08} top3={x.n08}></YearResult>
                    <YearResult result={x.m09} top3={x.n09}></YearResult>
                    <YearResult result={x.m10} top3={x.n10}></YearResult>
                    <YearResult result={x.m11} top3={x.n11}></YearResult>
                    <YearResult result={x.m12} top3={x.n12}></YearResult>
                  

           </Table.Row>
            );
          }))
          }   
           </Table.Body>
      </Table>
        {analysis && analysis.length > 0 &&
          <Pagination activePage={page} totalPages={analysis[0].totalPage} onPageChange={handlePageChanged}/>
        }
      </div>
    );
});